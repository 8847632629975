import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  IconButton,
  Paper,
  Button,
  useTheme,
} from '@mui/material';
import Header2 from '../components/Header2';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { useAuth } from '../authContext';
import { GET_ALL_PRODUCT_CATEGORIES_BY_STORE } from '../data/StoreData';
import { useQuery } from '@apollo/client';
import { AiOutlineQuestionCircle, AiOutlineClose } from 'react-icons/ai';
import TenantProductCategoryHelpView from './help/TenantProductCategoriesHelpView';

/**
 * TenantProductCategoriesView stellt die
 * Warengruppen eines Stores dar.
 */
const TenantProductCategoriesView = () => {
  const navigate = useNavigate();
  const routerLocation: any = useRouterLocation();
  const [{ profile }] = useAuth();
  const theme = useTheme();
  const [helpOpen, setHelpOpen] = useState<boolean>(
    routerLocation.state.helpopen
  );

  // get all store product categories
  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: categoriesData,
  } = useQuery(GET_ALL_PRODUCT_CATEGORIES_BY_STORE, {
    variables: { storeid: routerLocation.state.storeId },
    fetchPolicy: 'network-only',
  });

  // Beim laden der Daten nichts anzeigen.
  if (categoriesLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (categoriesError) {
    console.log(
      `Categories Error! TenantProductCategoriesView: ${categoriesError.message}`
    );
  }

  // return
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      style={{ width: '100%' }}
    >
      <Container maxWidth='xs'>
        <Box mt={3}>
          <Header2
            title='Die Liste Ihrer Warengruppen.'
            subtitle={`Sie sind angemeldet als ${profile.email}`}
          />
        </Box>
        <Box mt={2}>
          {categoriesData && categoriesData.productcategory.length === 0 ? (
            <Box>
              <Typography
                component='h2'
                variant='subtitle1'
                mb={1}
                align='center'
              >
                Keine Warengruppen vorhanden.
              </Typography>
            </Box>
          ) : (
            <>
              {categoriesData &&
                categoriesData.productcategory.map((productCategory: any) => (
                  <Paper
                    elevation={0}
                    sx={{ p: 1.3, mb: 0.3 }}
                    key={productCategory.id}
                  >
                    <Box
                      sx={{
                        pl: 0.6,
                      }}
                    >
                      <Typography variant='body1'>
                        {productCategory.categoryname}
                      </Typography>
                    </Box>
                  </Paper>
                ))}
            </>
          )}
        </Box>
        <Box
          mt={3}
          mb={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() =>
              navigate('/tenantEditProductCategories', {
                state: {
                  storeId: routerLocation.state.storeId,
                  helpopen: helpOpen,
                },
              })
            }
          >
            Bearbeiten
          </Button>
          <Button
            sx={{
              marginTop: 2,
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() =>
              navigate('/tenant', {
                state: { helpopen: helpOpen },
              })
            }
          >
            Ok
          </Button>
        </Box>
        {/** Action end */}
      </Container>
      {helpOpen ? (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
          }}
          style={{ backgroundColor: '#9E9E9E', width: '20%' }}
        >
          <Box
            sx={{
              paddingTop: 1,
              paddingLeft: 1.5,
            }}
          >
            <IconButton onClick={() => setHelpOpen(false)}>
              <AiOutlineClose size='24' />
            </IconButton>
          </Box>
          <TenantProductCategoryHelpView />
        </Box>
      ) : (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
            paddingRight: 1.5,
            paddingTop: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => setHelpOpen(true)}>
              <AiOutlineQuestionCircle size='24' />
            </IconButton>
            <Typography
              onClick={() => setHelpOpen(true)}
              paragraph
              component='span'
              sx={{
                color: theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                  textDecoration: `underline ${theme.palette.text.primary}`,
                },
              }}
            >
              Hilfe
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TenantProductCategoriesView;
