import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import {
  ANSWER_TICKET_BY_ID,
  CLOSE_TICKET_BY_ID,
  REMOVE_TICKET,
} from '../queries/adminQueries';
import { useMutation } from '@apollo/client';
import { IInputError } from '../../interfaces/interfaces';

/**
 * TicketView
 */
const TicketView = (props: any) => {
  const theme = useTheme();
  const [ticketDeleteDialogVisible, setTicketDeleteDialogVisible] =
    useState<boolean>(false);
  const [answer, setAnswer] = useState<string>('');
  const [answerError, setAnswerError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [isAnswerEnabled, setIsAnswerEnabled] = useState<boolean>(false);
  const [isButtonSendEnabled, setIsButtonSendEnabled] =
    useState<boolean>(false);
  const [isButtonCloseEnabled, setIsButtonCloseEnabled] =
    useState<boolean>(false);
  const [isButtonDeleteEnabled, setIsButtonDeleteEnabled] =
    useState<boolean>(false);
  const [answerTicket] = useMutation(ANSWER_TICKET_BY_ID, {
    onError: (error) => {
      console.log(`Error! TicketView - answerTicket: ${error.message}`);
    },
  });
  const [closeTicket] = useMutation(CLOSE_TICKET_BY_ID, {
    onError: (error) => {
      console.log(`Error! TicketView - closeTicket: ${error.message}`);
    },
  });
  const [removeTicket] = useMutation(REMOVE_TICKET, {
    onError: (error) => {
      console.log(`Error! TicketView - removeTicket: ${error.message}`);
    },
  });

  /**
   * Handling Send Button Status.
   */
  useEffect(() => {
    if (
      answer === '' ||
      answerError.isError === true ||
      props.childPagePropsFromParent.answer !== null
    ) {
      setIsButtonSendEnabled(false);
    } else {
      setIsButtonSendEnabled(true);
    }
  }, [answer, answerError.isError, props.childPagePropsFromParent.answer]);

  /**
   * answer setzen
   */
  useEffect(() => {
    if (props.childPagePropsFromParent.answer !== null) {
      setAnswer(props.childPagePropsFromParent.answer);
      setIsAnswerEnabled(false);
    }
  }, [props.childPagePropsFromParent.answer]);

  /**
   * Button Status setzen
   */
  useEffect(() => {
    if (props.childPagePropsFromParent.status === 'answered') {
      setIsButtonCloseEnabled(true);
    }
    if (props.childPagePropsFromParent.status === 'closed') {
      setIsButtonDeleteEnabled(true);
    }
  }, [props.childPagePropsFromParent.status]);

  /**
   * Wenn On Focus reset.
   */
  const handleAnswerOnFocus = () => {
    if (props.childPagePropsFromParent.answer === null) {
      setIsAnswerEnabled(true);
    }
    setAnswerError({
      isError: false,
      message: '',
    });
  };

  /**
   * Event zum setzen der answer im State.
   * @param event
   */
  const handleAnswerInput = (event: any) => {
    setAnswer(event.target.value);
    if (answer !== '') {
      setAnswerError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Input Validierung der answer.
   */
  const checkAnswerInput = () => {
    if (answer === '') {
      setAnswerError({
        isError: true,
        message: 'Antwort ist erforderlich!',
      });
    } else {
      setAnswerError({
        isError: false,
        message: '',
      });
    }
  };

  // return
  return (
    <Container maxWidth='lg'>
      <Dialog
        open={ticketDeleteDialogVisible}
        onClose={() => setTicketDeleteDialogVisible(false)}
        aria-labelledby='delete-ticket-dialog-title'
        aria-describedby='delete-ticket-dialog-description'
      >
        <DialogTitle id='delete-ticket-dialog-title'>
          Soll das Ticket gelöscht werden?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='delete-ticket-dialog-description'>
            {' "'}
            {props.childPagePropsFromParent.subject}
            {'" '}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => {
              setTicketDeleteDialogVisible(false);
            }}
            variant='outlined'
            color='inherit'
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              removeTicket({
                variables: { id: props.childPagePropsFromParent.id },
              });
              setTicketDeleteDialogVisible(false);
              props.sendChildPageToParent('tickets');
            }}
            variant='outlined'
            color='inherit'
            autoFocus
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
      <Container>
        <Typography component='h2' variant='h6' align='center' mt={2}>
          Ticket
        </Typography>
      </Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Container maxWidth='xs'>
          <Typography component='h2' variant='subtitle2' align='center' mt={2}>
            Frage/Fehlerbeschreibung
          </Typography>
          <Box>
            <Box>
              <TextField
                color='secondary'
                variant='standard'
                sx={{ width: '100%' }}
                label='Geschäft'
                value={props.childPagePropsFromParent.store}
                inputProps={{ readOnly: true }}
              />
              <TextField
                color='secondary'
                variant='standard'
                sx={{ width: '100%' }}
                label='PLZ Ort'
                value={
                  props.childPagePropsFromParent.zipcode +
                  ' ' +
                  props.childPagePropsFromParent.place
                }
                inputProps={{ readOnly: true }}
              />
              <TextField
                color='secondary'
                variant='standard'
                sx={{ width: '100%' }}
                label='Kategorie'
                value={props.childPagePropsFromParent.category}
                inputProps={{ readOnly: true }}
              />
              <TextField
                color='secondary'
                variant='standard'
                sx={{ width: '100%' }}
                label='Betreff'
                value={props.childPagePropsFromParent.subject}
                inputProps={{ readOnly: true }}
              />
              <TextField
                color='secondary'
                variant='standard'
                sx={{ width: '100%' }}
                label='Kontaktperson'
                value={props.childPagePropsFromParent.contact}
                inputProps={{ readOnly: true }}
              />
              <TextField
                color='secondary'
                variant='standard'
                sx={{ width: '100%' }}
                label='Telefonnummer'
                value={props.childPagePropsFromParent.phone}
                inputProps={{ readOnly: true }}
              />
              <TextField
                color='secondary'
                variant='standard'
                sx={{ width: '100%' }}
                label='E-Mail'
                value={props.childPagePropsFromParent.email}
                inputProps={{ readOnly: true }}
              />
            </Box>
            <Box mt={2}>
              <TextField
                multiline
                sx={{ width: '100%', background: theme.palette.primary.main }}
                rows={10}
                variant='outlined'
                value={props.childPagePropsFromParent.ticket}
                inputProps={{ readOnly: true }}
              />
            </Box>
          </Box>
          <Typography component='h2' variant='subtitle2' align='center' mt={2}>
            Antwort
          </Typography>
          <Box mt={2}>
            <TextField
              multiline
              sx={{ width: '100%', background: theme.palette.primary.main }}
              rows={10}
              variant='outlined'
              value={answer}
              error={answerError.isError}
              helperText={answerError.message}
              onFocus={handleAnswerOnFocus}
              onChange={(e) => handleAnswerInput(e)}
              onBlur={checkAnswerInput}
              inputProps={
                isAnswerEnabled ? { readOnly: false } : { readOnly: true }
              }
            />
          </Box>
          <Box
            sx={{
              mt: 3,
              mb: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              type='submit'
              disabled={!isButtonSendEnabled}
              sx={{
                borderRadius: 30,
                width: '240px',
              }}
              variant='contained'
              color='secondary'
              onClick={() => {
                answerTicket({
                  variables: {
                    id: props.childPagePropsFromParent.id,
                    status: 'answered',
                    answer: answer,
                    answered: new Date(
                      Date.now() + 1000 * 60 * -new Date().getTimezoneOffset()
                    )
                      .toISOString()
                      .replace('T', ' ')
                      .replace('Z', ''),
                  },
                });
                props.sendChildPageToParent('tickets');
              }}
            >
              Senden
            </Button>
            <Button
              type='submit'
              disabled={!isButtonCloseEnabled}
              sx={{
                mt: 2,
                borderRadius: 30,
                width: '240px',
              }}
              variant='contained'
              color='secondary'
              onClick={() => {
                closeTicket({
                  variables: {
                    id: props.childPagePropsFromParent.id,
                    status: 'closed',
                    closed: new Date(
                      Date.now() + 1000 * 60 * -new Date().getTimezoneOffset()
                    )
                      .toISOString()
                      .replace('T', ' ')
                      .replace('Z', ''),
                  },
                });
                props.sendChildPageToParent('tickets');
              }}
            >
              Ticket schließen
            </Button>
            <Button
              type='submit'
              disabled={!isButtonDeleteEnabled}
              sx={{
                mt: 2,
                borderRadius: 30,
                width: '240px',
              }}
              variant='contained'
              color='secondary'
              onClick={() => {
                setTicketDeleteDialogVisible(true);
              }}
            >
              Ticket löschen
            </Button>
            <Button
              type='submit'
              sx={{
                mt: 2,
                mb: 2,
                borderRadius: 30,
                width: '240px',
              }}
              variant='contained'
              color='secondary'
              onClick={() => {
                props.sendChildPagePropsToParent({
                  page: props.childPagePropsFromParent.page,
                  pageSize: props.childPagePropsFromParent.pageSize,
                  sortOrder: props.childPagePropsFromParent.sortOrder,
                });
                props.sendChildPageToParent('tickets');
              }}
            >
              Zurück
            </Button>
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default TicketView;
