import React, { useState, useEffect } from 'react';
import { useLocation as useRouterLocation } from 'react-router-dom';
import TopBar from '../layout/TopBar/TopBar';
import {
  SideBar,
  StoresStartView,
  StoreView,
  StoreSearchView,
} from './components';

/**
 * StoresView ist die Hauptseite der Geschäfte.
 * Sie hat eine TopBar, eine SideBar und
 * unterschiedliche childPage,die ausgetauscht werden.
 */
const StoresView = () => {
  const routerLocation: any = useRouterLocation();
  const [childPage, setChildPage] = useState<string>('storesStart');
  const [childPageProps, setChildPageProps] = useState<any>({
    branchid: 0,
    branchname: '',
    expanded: {},
    categoryid: null,
    pagenumber: 1,
  });

  /**
   * Den Main Stores Screen setzen.
   */
  useEffect(() => {
    if (routerLocation.state !== null) {
      if (routerLocation.state.childpage === 'storesStart') {
        setChildPage('storesStart');
        setChildPageProps({
          ...childPageProps,
          branchid:
            routerLocation.state.branchid === undefined
              ? 0
              : routerLocation.state.branchid,
          branchname:
            routerLocation.state.branchname === undefined
              ? ''
              : routerLocation.state.branchname,
          expanded:
            routerLocation.state.expanded === undefined
              ? {}
              : routerLocation.state.expanded,
          storeid: 0,
          actiontype:
            routerLocation.state.actiontype === undefined
              ? 'all'
              : routerLocation.state.actiontype,
          pagenumber:
            routerLocation.state.pagenumber === undefined
              ? 1
              : routerLocation.state.pagenumber,
        });
      } else if (routerLocation.state.childpage === 'store') {
        setChildPage('store');
        setChildPageProps({
          ...childPageProps,
          storeid: routerLocation.state.storeid,
          branchid: routerLocation.state.branchid,
          expanded: routerLocation.state.expanded,
          categoryid: routerLocation.state.categoryid,
          actiontype: routerLocation.state.actiontype,
          pagenumber: routerLocation.state.pagenumber,
        });
      } else if (routerLocation.state.childpage === 'storeSearch') {
        setChildPage('storeSearch');
        setChildPageProps({
          ...childPageProps,
          storeid: routerLocation.state.storeid,
          storename: routerLocation.state.storename,
          branchid: routerLocation.state.branchid,
          expanded: routerLocation.state.expanded,
          categoryid: routerLocation.state.categoryid,
          actiontype: routerLocation.state.actiontype,
          pagenumber: routerLocation.state.pagenumber,
          searchparam: routerLocation.state.searchparam,
        });
      } else if (
        routerLocation.state.srcroute !== undefined &&
        routerLocation.state.srcroute === '/favorite'
      ) {
        setChildPage('store');
        setChildPageProps({
          ...childPageProps,
          storeid: routerLocation.state.storeid,
          storename: routerLocation.state.storename,
          branchid: routerLocation.state.branchid,
          expanded: routerLocation.state.expanded,
        });
      }
    }
    routerLocation.state = null;
  }, [childPageProps, routerLocation]);

  // return
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div>
        <TopBar
          sendChildPageToParent={setChildPage}
          childPageFromParent={childPage}
          sendChildPagePropsToParent={setChildPageProps}
          childPagePropsFromParent={childPageProps}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          //height: '100vh',
          width: '100vw',
        }}
      >
        <SideBar
          sendChildPageToParent={setChildPage}
          sendChildPagePropsToParent={setChildPageProps}
          childPagePropsFromParent={childPageProps}
        />
        {childPage === 'storesStart' ? (
          <StoresStartView
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : childPage === 'store' ? (
          <StoreView
            sendChildPageToParent={setChildPage}
            childPageFromParent={childPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : childPage === 'storeSearch' ? (
          <StoreSearchView
            sendChildPageToParent={setChildPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : null}
      </div>
    </div>
  );
};

export default StoresView;
