import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import {
  Container,
  Box,
  Divider,
  Grid,
  Link,
  useTheme,
  useMediaQuery,
  Pagination,
} from '@mui/material';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { useLocation } from '../locationContext';
import { useFilter } from '../filterContext';
import PortalProductCard from '../components/PortalProductCard';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_PORTAL_PRODUCTS } from './queries/searchQueries';
import { Config } from '../config';

/**
 * SearchView stellt im ausgewählten Ort und
 * eingestellten Umgebung die vorhandenen Artikel nach
 * der Suche dar.
 */
const SearchView = () => {
  const theme = useTheme();
  const matches600 = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const routerLocation: any = useRouterLocation();
  const [pagesTotal, setPagesTotal] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageStart, setPageStart] = useState<number>(1);
  const [pageEnd, setPageEnd] = useState<number>(1);
  const [{ location }] = useLocation();
  const [{ filter }] = useFilter();
  const MEDIA_URL: any =
    process.env.REACT_APP_MINIO_ENDPOINT !== undefined
      ? process.env.REACT_APP_MINIO_ENDPOINT
      : Config.minio_endpoint;
  const ITEMS_PER_PAGE: any =
    process.env.REACT_APP_ITEMS_PER_PAGE !== undefined
      ? process.env.REACT_APP_ITEMS_PER_PAGE
      : Config.items_per_page;

  // search portal products
  const [getSearchResult, { loading, error, data }] = useLazyQuery(
    SEARCH_PORTAL_PRODUCTS,
    {
      variables: {
        locations: `{${location.locationlist.toString()}}`,
        searchname: routerLocation.state.searchparam,
        minprice: filter.minprice,
        maxprice: filter.maxprice,
      },
      onCompleted: (searchData) => {
        setPagesTotal(
          Math.ceil(
            searchData.merkantdo_search_portal_products.length / ITEMS_PER_PAGE
          )
        );
      },
    }
  );

  /**
   * Falls Location nicht gesetzt ist wird
   * umgeleitet.
   */
  useEffect(() => {
    if (location.locationlist.length === 0) {
      navigate('/location');
    }
  }, [location.locationlist, navigate]);

  /**
   * Falls Suchbegriff vorhanden ist,
   * wird abgefragt.
   */
  useEffect(() => {
    if (routerLocation.state.searchparam) {
      getSearchResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerLocation.state.searchparam]);

  /**
   * Set Page Properties
   */
  useEffect(() => {
    if (data) {
      if (
        routerLocation.state !== null &&
        routerLocation.state.pageNumber !== undefined
      ) {
        setPageNumber(routerLocation.state.pageNumber);
      }
      setPageStart((pageNumber - 1) * ITEMS_PER_PAGE);
      setPageEnd(pageNumber * ITEMS_PER_PAGE);
      routerLocation.state.pageNumber = undefined;
    }
  }, [data, pageNumber, routerLocation.state, ITEMS_PER_PAGE]);

  // Beim laden der Daten nichts anzeigen.
  if (loading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftritt.
  if (error) {
    console.log(`Error! SearchView: ${error.message}`);
  }

  /**
   * SetPageNumber
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  /**
   * Navigation zur ProductView mit Parametern.
   * Die Parameter sind für die goBack() Funktion
   * in ProductView erforderlich.
   */
  const goToProduct = (storeid: number, productid: number) => {
    navigate('/product', {
      state: {
        srcroute: '/search',
        storeid,
        productid,
        searchparam: routerLocation.state.searchparam,
        pageNumber,
      },
    });
  };

  // return
  return (
    <Container maxWidth={false} disableGutters>
      <Divider />
      {data && data.merkantdo_search_portal_products.length === 0 ? (
        <Box
          mt={matches600 === true ? 6 : 0}
          p={1}
          sx={{ backgroundColor: theme.palette.primary.main }}
        >
          <Header
            title={`Suche für ${routerLocation.state.searchparam} in ${location.zipcode} ${location.place} (+${location.distance}km).`}
            subtitle='Keine Einträge vorhanden.'
          />
        </Box>
      ) : (
        <Box mb={3}>
          <>
            <Box
              mt={matches600 === true ? 6 : 0}
              p={1}
              sx={{ backgroundColor: theme.palette.primary.main }}
            >
              <Header
                title={`Suche für ${routerLocation.state.searchparam} in ${location.zipcode} ${location.place} (+${location.distance}km).`}
              />
            </Box>
            <Grid mt={0} mb={1} container spacing={1} justifyContent='center'>
              {data &&
                data.merkantdo_search_portal_products
                  .slice(pageStart, pageEnd)
                  .map((product: any, index: number) => (
                    <Grid item key={index} xs='auto' sm='auto' md='auto'>
                      <Link
                        underline='none'
                        component='button'
                        onClick={() =>
                          goToProduct(product.store_id, product.id)
                        }
                      >
                        <PortalProductCard
                          title={product.storename}
                          image={product.image}
                          actionstatus={
                            product.actionstatus === 'New'
                              ? 'Neu'
                              : product.actionstatus === 'Sale'
                              ? 'Reduziert'
                              : product.actionstatus
                          }
                          price={product.price.trim()}
                          discount={product.discount}
                          productname={product.productname}
                          mediaUri={
                            MEDIA_URL + '/' + product.store_id + '-data/'
                          }
                        />
                      </Link>
                    </Grid>
                  ))}
            </Grid>
          </>
        </Box>
      )}
      {data && data.merkantdo_search_portal_products.length !== 0 ? (
        <Box
          sx={{
            mb: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Pagination
            count={pagesTotal}
            page={pageNumber}
            onChange={handlePage}
          />
        </Box>
      ) : null}
    </Container>
  );
};

export default SearchView;
