import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  useTheme,
} from '@mui/material';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { useAuth } from '../authContext';
import { GET_ACCOUNT, UPDATE_EMAIL } from '../data/AccountData';
import { REMOVE_PASSWORD_CODE } from '../login/queries/loginQueries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IInputError } from '../interfaces/interfaces';
import Header2 from '../components/Header2';
import { AiOutlineQuestionCircle, AiOutlineClose } from 'react-icons/ai';
import TenantEmailHelpView from './help/TenantEmailHelpView';

/**
 * Hier wird E-Mail-Adresse für das Login
 * des Tenants geändert.
 */
const TenantEmailView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [{ profile }, dispatch] = useAuth();
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [confirmedEmail, setConfirmedEmail] = useState<string>('');
  const [confirmedEmailError, setConfirmedEmailError] = useState<
    Partial<IInputError>
  >({
    isError: false,
    message: '',
  });
  const [isButtonUpdateEnabled, setIsButtonUpdateEnabled] =
    useState<boolean>(false);
  const routerLocation: any = useRouterLocation();
  const [helpOpen, setHelpOpen] = useState<boolean>(
    routerLocation.state.helpopen
  );

  // account überprüfen ob vonhanden ist
  const [checkAccount] = useLazyQuery(GET_ACCOUNT);

  // account hinzufügen
  const [updateEmail] = useMutation(UPDATE_EMAIL);

  // removePasswordcode
  const [removePassordcode] = useMutation(REMOVE_PASSWORD_CODE);

  /**
   * Handling Update Button Status.
   */
  useEffect(() => {
    if (
      email === '' ||
      confirmedEmail === '' ||
      email !== confirmedEmail ||
      emailError.isError ||
      confirmedEmailError.isError
    ) {
      setIsButtonUpdateEnabled(false);
    } else {
      setIsButtonUpdateEnabled(true);
    }
  }, [confirmedEmail, confirmedEmailError.isError, email, emailError.isError]);

  /**
   * Email error wird zurückgesetzt.
   */
  const handleEmailOnFocus = () => {
    setEmailError({
      isError: false,
      message: '',
    });
  };

  /**
   * E-Mail-Adresse für das Login setzen.
   */
  const setEmailInput = (e: any) => {
    setEmail(e.target.value);
  };

  /**
   * Funktion zur Validierung der Email.
   */
  const checkEmailInput = async () => {
    // eslint-disable-next-line no-useless-escape
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === '') {
      // email ist leer
      setEmailError({
        isError: true,
        message: 'E-Mail-Adresse ist erforderlich.',
      });
    } else if (regexp.test(email)) {
      // email ist ok
      // this is a valid email address
      // überprüfen ob die email schon vorhanden ist
      try {
        const { data } = await checkAccount({
          variables: { email },
          fetchPolicy: 'network-only',
        });
        if (data.account.length === 0) {
          // email ist ok, und nicht vorhanden
          setEmailError({
            isError: false,
            message: '',
          });
        } else if (
          data.account.length !== 0 &&
          data.account[0].email !== profile.email
        ) {
          // email ist ok, aber schon vorhanden
          setEmailError({
            isError: true,
            message: 'Diese E-Mail-Adresse ist schon vorhanden.',
          });
        }
      } catch (error) {
        console.log('Error! TenantEmailView - checkEmailInput:', error);
      }
    } else {
      // email ist nicht leer, aber nicht ok
      // invalid email, maybe show an error to the user.
      setEmailError({
        isError: true,
        message: 'Ist keine gültige E-Mail-Adresse.',
      });
    }
  };

  /**
   * confirmedEmail error wird zurückgesetzt.
   */
  const handleConfirmedEmailOnFocus = () => {
    if (email === '') {
      setEmailError({
        isError: true,
        message: 'E-Mail-Adresse ist erforderlich!',
      });
    }
    setConfirmedEmailError({
      isError: false,
      message: '',
    });
  };

  /**
   * Confirmed E-Mail-Adresse wird im State gesetzt
   * und ob der Eintrag mit der E-Mail gleich ist.
   */
  const handleConfirmedEmailInput = (e: any) => {
    setConfirmedEmail(e.target.value);
    if (e.target.value === email) {
      setConfirmedEmailError({
        isError: false,
        message: '',
      });
    } else {
      setConfirmedEmailError({
        isError: true,
        message: 'E-Mail-Adressen sind nicht gleich!',
      });
    }
  };

  /**
   * Diese Funktion überprüft ob die beiden
   * Einträge für die E-Mail-Adresse gleich sind.
   */
  const checkConfirmedEmailInput = () => {
    if (confirmedEmail === '') {
      setConfirmedEmailError({
        isError: true,
        message: 'Neue E-Mail-Adresse wiederholen!',
      });
    } else if (email !== confirmedEmail) {
      setConfirmedEmailError({
        isError: true,
        message: 'E-Mail-Adressen sind nicht gleich!',
      });
    }
  };

  /**
   * Diese Funktion ändert Account email.
   */
  const updateAccountEmail = async () => {
    try {
      // In der Datenbank löschen, falls vorhanden
      await removePassordcode({
        variables: { email: profile.email },
      });
      // In die Datenbank schreiben
      await updateEmail({
        variables: {
          id: profile.account_id,
          email,
        },
      });
      const tempRememberMe: any = localStorage.getItem('rememberMe');
      if (JSON.parse(tempRememberMe) === true) {
        localStorage.setItem('email', email);
      }
      await dispatch({
        type: 'UPDATE_PROFILE',
        payload: {
          account_id: profile.account_id,
          userrole: 'tenant',
          tenant_id: profile.tenant_id,
          email,
          password: profile.password,
        },
      });
      navigate('/tenantLogindata', {
        state: {
          helpopen: helpOpen,
        },
      });
    } catch (error) {
      console.log('Error! TenantEmailView - updateAccountEmail:', error);
    }
  };

  // return
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      style={{ width: '100%' }}
    >
      <Container maxWidth='xs'>
        <Box mt={3}>
          <Header2
            title='E-Mail-Adresse ändern'
            subtitle='Geben Sie unten die neue E-Mail-Adresse ein, 
          die Sie beim Login verwenden möchten.'
          />
        </Box>
        <Box p={1} mt={2} sx={{ backgroundColor: theme.palette.primary.main }}>
          <TextField
            id='email'
            name='email'
            inputProps={{
              sx: {
                '&:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset`,
                  WebkitTextFillColor: theme.palette.text.primary,
                },
              },
            }}
            sx={{ width: '100%' }}
            variant='standard'
            color='secondary'
            margin='dense'
            required
            label='Neue E-Mail-Adresse'
            value={email}
            error={emailError.isError}
            helperText={emailError.message}
            FormHelperTextProps={{
              sx: { color: theme.palette.secondary.main },
            }}
            onFocus={handleEmailOnFocus}
            onChange={(e) => setEmailInput(e)}
            onBlur={checkEmailInput}
          />
          <TextField
            id='confirmedEmail'
            name='confirmedEmail'
            inputProps={{
              sx: {
                '&:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset`,
                  WebkitTextFillColor: theme.palette.text.primary,
                },
              },
            }}
            sx={{ width: '100%' }}
            variant='standard'
            color='secondary'
            margin='dense'
            required
            label='Neue E-Mail-Adresse wiederholen'
            value={confirmedEmail}
            error={confirmedEmailError.isError}
            helperText={confirmedEmailError.message}
            FormHelperTextProps={{
              sx: { color: theme.palette.secondary.main },
            }}
            onFocus={handleConfirmedEmailOnFocus}
            onChange={(e) => handleConfirmedEmailInput(e)}
            onBlur={checkConfirmedEmailInput}
          />
        </Box>
        <Box
          mt={3}
          mb={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            disabled={!isButtonUpdateEnabled}
            variant='contained'
            color='secondary'
            onClick={() => updateAccountEmail()}
          >
            Übernehmen
          </Button>
          <Button
            sx={{
              marginTop: 2,
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() =>
              navigate('/tenantLogindata', {
                state: {
                  helpopen: helpOpen,
                },
              })
            }
          >
            Abbrechen
          </Button>
        </Box>
      </Container>
      {helpOpen ? (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
          }}
          style={{ backgroundColor: '#9E9E9E', width: '20%' }}
        >
          <Box
            sx={{
              paddingTop: 1,
              paddingLeft: 1.5,
            }}
          >
            <IconButton onClick={() => setHelpOpen(false)}>
              <AiOutlineClose size='24' />
            </IconButton>
          </Box>
          <TenantEmailHelpView />
        </Box>
      ) : (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
            paddingRight: 1.5,
            paddingTop: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => setHelpOpen(true)}>
              <AiOutlineQuestionCircle size='24' />
            </IconButton>
            <Typography
              onClick={() => setHelpOpen(true)}
              paragraph
              component='span'
              sx={{
                color: theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                  textDecoration: `underline ${theme.palette.text.primary}`,
                },
              }}
            >
              Hilfe
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TenantEmailView;
