import React, { useState } from 'react';
import {
  Container,
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
} from '@mui/material';
import Header2 from '../components/Header2';
import { CLOSE_TICKET_BY_ID } from './queries/tenantQueries';
import { useMutation } from '@apollo/client';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';

/**
 * TenantTicketView
 */
const TenantTicketView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const routerLocation: any = useRouterLocation();
  const [ticketDeleteDialogVisible, setTicketDeleteDialogVisible] =
    useState<boolean>(false);

  // close ticket
  const [closeTicket] = useMutation(CLOSE_TICKET_BY_ID, {
    onError: (error) => {
      console.log(`Error! TenantView - closeTicket: ${error.message}`);
    },
  });

  // return
  return (
    <Container maxWidth='xs'>
      <Dialog
        open={ticketDeleteDialogVisible}
        onClose={() => setTicketDeleteDialogVisible(false)}
        aria-labelledby='delete-ticket-dialog-title'
        aria-describedby='delete-ticket-dialog-description'
      >
        <DialogTitle id='delete-ticket-dialog-title'>
          Soll das Ticket gelöscht werden?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='delete-ticket-dialog-description'>
            {' "'}
            {routerLocation.state.subject}
            {'" '}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => {
              setTicketDeleteDialogVisible(false);
            }}
            variant='outlined'
            color='inherit'
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              closeTicket({
                variables: {
                  id: routerLocation.state.id,
                  status: 'closed',
                  closed: new Date(
                    Date.now() + 1000 * 60 * -new Date().getTimezoneOffset()
                  )
                    .toISOString()
                    .replace('T', ' ')
                    .replace('Z', ''),
                },
              });
              setTicketDeleteDialogVisible(false);
              navigate('/tenant');
            }}
            variant='outlined'
            color='inherit'
            autoFocus
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={3}>
        <Header2 title='Ticket' />
      </Box>
      <Box mt={2}>
        <Typography variant='body1' ml={1} mt={2} mb={1}>
          Status:{' '}
          {routerLocation.state.status === 'open' ? 'offen' : 'beantwortet'}
        </Typography>
        <Typography variant='body1' ml={1} mb={1}>
          Betreff: {routerLocation.state.subject}
        </Typography>
        <TextField
          multiline
          sx={{ width: '100%', background: theme.palette.primary.main }}
          rows={10}
          color='secondary'
          variant='standard'
          value={routerLocation.state.ticket}
        />
      </Box>
      {routerLocation.state.status === 'answered' ? (
        <Box>
          <Typography variant='body1' ml={1} mt={2} mb={1}>
            Antwort:
          </Typography>
          <TextField
            multiline
            sx={{ width: '100%', background: theme.palette.primary.main }}
            rows={10}
            variant='standard'
            value={routerLocation.state.answer}
          />
        </Box>
      ) : null}
      <Box
        mt={3}
        mb={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            borderRadius: 30,
            width: '240px',
          }}
          type='submit'
          variant='contained'
          color='secondary'
          onClick={() => setTicketDeleteDialogVisible(true)}
        >
          Löschen
        </Button>
        <Button
          sx={{
            marginTop: 2,
            borderRadius: 30,
            width: '240px',
          }}
          type='submit'
          variant='contained'
          color='secondary'
          onClick={() => navigate('/tenant')}
        >
          Abbrechen
        </Button>
      </Box>
    </Container>
  );
};

export default TenantTicketView;
