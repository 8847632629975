import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * StoreAdvantagesView
 */
const StoreAdvantagesView = () => {
  const navigate = useNavigate();

  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={2}>
        <Typography component='h1' variant='h4' align='center' gutterBottom>
          Vorteile - Geschäft
        </Typography>
        <Typography component='h1' variant='h6' align='center' gutterBottom>
          Welche Vorteile ergeben sich für mein Geschäft?
        </Typography>
      </Box>
      <Box>
        <Typography component='p' variant='body1' paragraph>
          Merkando ist ein Portal für digitales Marketing. Für mein Geschäft
          ergeben sich dadurch viele Möglichkeiten.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          1. Präsentation meines Angebots im Internet-Browser auf einem PC oder
          Laptop und in einer App auf dem Smartphone. Damit erreiche viele
          potentielle Kunden, die gezielt mein Geschäft aufsuchen können.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          2. Die Kommunikation mit den Kunden ist sehr wichtig. Mit Merkantdo
          haben die Kunden die Möglichkeit mit meinem Geschäft Kontakt
          aufzunehmen und mir zu einem Artikel Fragen zu stellen. Darüberhinaus
          kann ich für meine Stammkunden (Favoritstatus beim Kunden)
          Informationen über neue Angebote senden.
        </Typography>
      </Box>
      <Box
        mt={3}
        mb={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type='submit'
          variant='contained'
          color='secondary'
          sx={{ borderRadius: 30, width: '240px' }}
          onClick={() => navigate('/help')}
        >
          Zurück
        </Button>
      </Box>
    </Container>
  );
};

export default StoreAdvantagesView;
