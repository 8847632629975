import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Container,
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { GET_ACCOUNT } from '../data/AccountData';
import { GET_TENANT_BY_INVOICEEMAIL } from './queries/tenantQueries';
import { useLazyQuery } from '@apollo/client';
import { IInputError } from '../interfaces/interfaces';

/**
 * RegisterLoginStoreView um sich am System
 * mit Email und Password als Kunde zu registrieren.
 */
const RegisterLoginStoreForm = (props: any) => {
  const theme = useTheme();
  const [serviceError, setServiceError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [invoiceEmailError, setInvoiceEmailError] = useState<
    Partial<IInputError>
  >({
    isError: false,
    message: '',
  });
  const [emailError, setEmailError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [passwordError, setPasswordError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [confirmedPasswordError, setConfirmedPasswordError] = useState<
    Partial<IInputError>
  >({
    isError: false,
    message: '',
  });
  const [isPasswordSecure, setIsPasswordSecure] = useState<boolean>(true);

  // check ob vorhanden ist
  const [getTenantByInvoiceEmail] = useLazyQuery(GET_TENANT_BY_INVOICEEMAIL);

  // account überprüfen ob vonhanden ist
  const [checkAccount] = useLazyQuery(GET_ACCOUNT);

  /**
   * Forward Button handling
   */
  useEffect(() => {
    if (
      props.logindataFromParent.serviceId === '' ||
      props.logindataFromParent.invoiceEmail === '' ||
      props.logindataFromParent.email === '' ||
      props.logindataFromParent.password === '' ||
      props.logindataFromParent.confirmedPassword !==
        props.logindataFromParent.password ||
      serviceError.isError === true ||
      invoiceEmailError.isError === true ||
      emailError.isError === true ||
      passwordError.isError === true ||
      confirmedPasswordError.isError === true
    ) {
      props.sendButtonStep0EnabledToParent(false);
    } else {
      props.sendButtonStep0EnabledToParent(true);
    }
  }, [
    props.logindataFromParent.serviceId,
    props.logindataFromParent.invoiceEmail,
    props.logindataFromParent.email,
    props.logindataFromParent.password,
    props.logindataFromParent.confirmedPassword,
    serviceError.isError,
    invoiceEmailError.isError,
    emailError.isError,
    passwordError.isError,
    confirmedPasswordError.isError,
    props,
  ]);

  /**
   * Funktion zum Umschalten des Password-Inputs,
   * sichtbar oder nicht sichtbar.
   */
  const handlePasswordSecure = () => {
    setIsPasswordSecure(!isPasswordSecure);
  };

  /**
   * Servicetype wird gesetzt.
   */
  const handleServicetypeInput = (e: any) => {
    let tempData = '';
    for (let i = 0; i < props.servicetypesFromParent.length; i++) {
      if (props.servicetypesFromParent[i].id === e.target.value) {
        tempData = props.servicetypesFromParent[i].servicename;
      }
    }
    props.sendLogindataToParent({
      ...props.logindataFromParent,
      serviceId: e.target.value,
      serviceName: tempData,
    });
  };

  /**
   * Funktion zur Validierung des Servicetype.
   */
  const checkServicetypeInput = () => {
    if (props.logindataFromParent.serviceId === '') {
      // props.logindataFromParent.serviceId ist leer
      setServiceError({
        isError: true,
        message: 'Service ist erforderlich.',
      });
    } else {
      setServiceError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Invoice Email wird im Parent State gesetzt.
   */
  const handleInvoiceEmailInput = (e: any) => {
    props.sendLogindataToParent({
      ...props.logindataFromParent,
      invoiceEmail: e.target.value,
    });
  };

  /**
   * Funktion zur Validierung der Email für die Rechnung.
   */
  const checkInvoiceEmailInput = async () => {
    // eslint-disable-next-line no-useless-escape
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (props.logindataFromParent.invoiceEmail === '') {
      // props.logindataFromParent.invoiceEmail ist leer
      setInvoiceEmailError({
        isError: true,
        message: 'E-Mail-Adresse für die Rechnung ist erforderlich.',
      });
    } else if (regexp.test(props.logindataFromParent.invoiceEmail)) {
      // props.logindataFromParent.invoiceEmail ist ok
      // this is a valid props.logindataFromParent.invoiceEmail address
      try {
        const { data } = await getTenantByInvoiceEmail({
          variables: { invoiceemail: props.logindataFromParent.invoiceEmail },
          fetchPolicy: 'network-only',
        });
        if (data.tenant.length === 0) {
          // props.logindataFromParent.invoiceEmail ist ok, und nicht vorhanden
          setInvoiceEmailError({ isError: false, message: '' });
        } else if (
          data.tenant.length !== 0 &&
          data.tenant.invoiceemail !== props.logindataFromParent.invoiceEmail
        ) {
          // props.logindataFromParent.invoiceEmail ist ok, aber schon vorhanden
          setInvoiceEmailError({
            isError: true,
            message:
              'Diese E-Mail-Adresse für die Rechnung ist schon vorhanden.',
          });
        }
      } catch (error) {
        console.log(
          'Error! RegisterLoginStoreForm - checkInvoiceEmailInput: ',
          error
        );
      }
    } else {
      // props.logindataFromParent.email ist nicht leer, aber nicht ok
      // invalid props.logindataFromParent.email, maybe show an error to the user.
      setInvoiceEmailError({
        isError: true,
        message: 'Ist keine gültige E-Mail-Adresse.',
      });
    }
  };

  /**
   * Email wird im Parent State gesetzt.
   */
  const handleEmailInput = (e: any) => {
    props.sendLogindataToParent({
      ...props.logindataFromParent,
      email: e.target.value,
    });
  };

  /**
   * Funktion zur Validierung der Email.
   */
  const checkEmailInput = async () => {
    // eslint-disable-next-line no-useless-escape
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (props.logindataFromParent.email === '') {
      // props.logindataFromParent.email ist leer
      setEmailError({
        isError: true,
        message: 'E-Mail-Adresse ist erforderlich.',
      });
    } else if (regexp.test(props.logindataFromParent.email)) {
      // props.logindataFromParent.email ist ok
      // this is a valid props.logindataFromParent.email address
      // überprüfen ob die props.logindataFromParent.email schon vorhanden ist
      try {
        const { data } = await checkAccount({
          variables: { email: props.logindataFromParent.email },
          fetchPolicy: 'network-only',
        });
        if (data.account.length === 0) {
          // props.logindataFromParent.email ist ok, und nicht vorhanden
          setEmailError({ isError: false, message: '' });
        } else if (
          data.account.length !== 0 &&
          data.account.email !== props.logindataFromParent.email
        ) {
          // props.logindataFromParent.email ist ok, aber schon vorhanden
          setEmailError({
            isError: true,
            message: 'Diese E-Mail-Adresse ist schon vorhanden.',
          });
        }
      } catch (error) {
        console.log('Error! RegisterLoginStoreForm - checkEmailInput: ', error);
      }
    } else {
      // props.logindataFromParent.email ist nicht leer, aber nicht ok
      // invalid props.logindataFromParent.email, maybe show an error to the user.
      setEmailError({
        isError: true,
        message: 'Ist keine gültige E-Mail-Adresse.',
      });
    }
  };

  /**
   * Password wird im Parent State gesetzt.
   */
  const handlePasswordInput = (e: any) => {
    props.sendLogindataToParent({
      ...props.logindataFromParent,
      password: e.target.value,
    });
  };

  /**
   * Funktion zur Validierung des Passwords.
   * Mindestens 6 Stellen, höchstens 20 Stellen,
   * mindesten ein Großbuchstabe, mindestens ein Kleinbuchstabe
   * und mindestens eine Ziffer.
   */
  const checkPasswordInput = () => {
    const regexp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,20}$/;
    if (props.logindataFromParent.password === '') {
      setPasswordError({
        isError: true,
        message: 'Passwort ist erforderlich!',
      });
    } else if (regexp.test(props.logindataFromParent.password)) {
      setPasswordError({ isError: false, message: '' });
    } else {
      setPasswordError({
        isError: true,
        message:
          'Muss zwischen 6 und 20 Zeichen sein, mindestens 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Ziffer beinhalten!',
      });
    }
  };

  /**
   * Confirmed Password wird gesetzt.
   */
  const handleConfirmedPasswordInput = (e: any) => {
    props.sendLogindataToParent({
      ...props.logindataFromParent,
      confirmedPassword: e.target.value,
    });
  };

  /**
   * Diese Funktion überprüft ob die beiden
   * Einträge für das Passwort gleich sind.
   */
  const checkConfirmedPasswordInput = () => {
    if (props.logindataFromParent.confirmedPassword === '') {
      setConfirmedPasswordError({
        isError: true,
        message: 'Passwort wiederholen!',
      });
    } else if (
      props.logindataFromParent.password !==
      props.logindataFromParent.confirmedPassword
    ) {
      setConfirmedPasswordError({
        isError: true,
        message: 'Passwörter sind nicht gleich!',
      });
    } else {
      setConfirmedPasswordError({ isError: false, message: '' });
    }
  };

  // return
  return (
    <Container maxWidth='xs' disableGutters>
      <Typography component='h1' variant='h6' align='center' sx={{ mt: 1 }}>
        Service und Logindaten
      </Typography>
      <Box mt={1}>
        <FormControl sx={{ width: '100%' }} variant='standard'>
          <InputLabel id='select-service-label' color='secondary'>
            Service *
          </InputLabel>
          <Select
            labelId='select-service-label'
            id='select-service'
            variant='standard'
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
            }}
            value={props.logindataFromParent.serviceId}
            onChange={(e) => {
              handleServicetypeInput(e);
            }}
            onBlur={checkServicetypeInput}
          >
            {props.servicetypesFromParent.map((item: any, index: number) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.servicename}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText error={serviceError.isError}>
            {serviceError.message}
          </FormHelperText>
        </FormControl>
        <TextField
          sx={{ width: '100%' }}
          color='secondary'
          variant='standard'
          required
          label='E-Mail-Adresse für die Rechnung'
          type='text'
          error={invoiceEmailError.isError}
          helperText={invoiceEmailError.message}
          FormHelperTextProps={{ sx: { color: theme.palette.secondary.main } }}
          value={props.logindataFromParent.invoiceEmail}
          onChange={(e) => handleInvoiceEmailInput(e)}
          onFocus={checkServicetypeInput}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              checkInvoiceEmailInput();
            }
          }}
          onBlur={checkInvoiceEmailInput}
        />
        <TextField
          sx={{ width: '100%' }}
          color='secondary'
          variant='standard'
          required
          label='E-Mail-Adresse für das Login'
          type='text'
          error={emailError.isError}
          helperText={emailError.message}
          FormHelperTextProps={{ sx: { color: theme.palette.secondary.main } }}
          value={props.logindataFromParent.email}
          onChange={(e) => handleEmailInput(e)}
          onFocus={checkInvoiceEmailInput}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              checkEmailInput();
            }
          }}
          onBlur={checkEmailInput}
        />
        <TextField
          sx={{ width: '100%' }}
          color='secondary'
          variant='standard'
          required
          label='Passwort'
          type={isPasswordSecure ? 'password' : 'text'}
          error={passwordError.isError}
          helperText={passwordError.message}
          FormHelperTextProps={{ sx: { color: theme.palette.secondary.main } }}
          value={props.logindataFromParent.password}
          onChange={(e) => handlePasswordInput(e)}
          onFocus={checkEmailInput}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              checkPasswordInput();
            }
          }}
          onBlur={checkPasswordInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  color='inherit'
                  size='small'
                  onClick={handlePasswordSecure}
                >
                  {isPasswordSecure ? (
                    <AiOutlineEye size='18' />
                  ) : (
                    <AiOutlineEyeInvisible size='18' />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          sx={{ width: '100%' }}
          color='secondary'
          variant='standard'
          required
          label='Passwort wiederholen'
          type={isPasswordSecure ? 'password' : 'text'}
          error={confirmedPasswordError.isError}
          helperText={confirmedPasswordError.message}
          FormHelperTextProps={{ sx: { color: theme.palette.secondary.main } }}
          value={props.logindataFromParent.confirmedPassword}
          onChange={(e) => handleConfirmedPasswordInput(e)}
          onFocus={checkPasswordInput}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              checkConfirmedPasswordInput();
            }
          }}
          onBlur={checkConfirmedPasswordInput}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  color='inherit'
                  size='small'
                  onClick={handlePasswordSecure}
                >
                  {isPasswordSecure ? (
                    <AiOutlineEye size='18' />
                  ) : (
                    <AiOutlineEyeInvisible size='18' />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Container>
  );
};

export default RegisterLoginStoreForm;
