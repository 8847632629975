import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Outlet} from 'react-router-dom';

// styles
const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    width: '100%', // Fix IE 11 issue.
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    //overflow: 'auto',
    paddingTop: 56,
    minHeight: '64vh',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
      minHeight: '85vh',
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    //overflow: 'hidden',
    overflow: 'auto',
  },
}));

/**
 * DefaultLayout
 *
 * @returns
 */
const AdminLayout = () => {
  const classes = useStyles();

  // return
  return (
    <div className={classes.root}>
      {/** <TopBar /> */}
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
