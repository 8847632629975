import React from 'react';
import { Container, Box, Typography } from '@mui/material';

/**
 * TenantRegularCustomerHelpView
 *
 */
const TenantRegularCustomerHelpView = () => {
  // return
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component='h1' variant='h5'>
          Stammkunden
        </Typography>
        <Typography variant='body1' mt={1}>
          Dies ist die Verwaltung Ihrer Stammkunden.
        </Typography>
        <Typography variant='body1' mt={1}>
          Stammkunden sind die Kunden, die Ihre Kunden Sie zu Favoriten
          hinzugefügt und die Option "Möchten Sie über neue Angebote informiert
          werden?" aktiviert haben.
        </Typography>
        <Typography variant='body1' mt={1}>
          Falls Stammkunden vorhanden sind werden sie im oberen Teil
          aufgelistet. Sie haben die Möglichkeit alle oder einzelne Stammkunden
          auszuwählen und über Ihre Angebote informieren.
        </Typography>
      </Box>
    </Container>
  );
};

export default TenantRegularCustomerHelpView;
