import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Container, Box, Button, TextField } from '@mui/material';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { useFilter } from '../filterContext';
import Header from '../components/Header';

/**
 * FilterView stellt im ausgewählten Ort und
 * eingestellten Umgebung den Filter dar.
 */
const FilterView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const routerLocation: any = useRouterLocation();
  const [{ filter }, dispatch] = useFilter();
  const [minprice, setMinprice] = useState<any>(filter.minprice);
  const [isMinpriceError, setIsMinpriceError] = useState<boolean>(false);
  const [errorMessageMinprice, setErrorMessageMinprice] = useState<string>('');
  const [maxprice, setMaxprice] = useState<any>(filter.maxprice);
  const [isMaxpriceError, setIsMaxpriceError] = useState<boolean>(false);
  const [errorMessageMaxprice, setErrorMessageMaxprice] = useState<string>('');

  /**
   * Funktion zum setzen des Minprice.
   */
  const handleMinpriceInput = (e: any) => {
    setErrorMessageMinprice('');
    if (e.target.value.match(/^[0-9]+$/)) {
      setMinprice(Number(e.target.value));
    } else if (e.target.value === '') {
      setMinprice(null);
    } else {
      setIsMinpriceError(true);
      setErrorMessageMinprice('Bitte nur Zahlen eingeben!');
    }
  };

  /**
   * Funktion zum setzen des Maxprice.
   */
  const handleMaxpriceInput = (e: any) => {
    setErrorMessageMaxprice('');
    if (e.target.value.match(/^[0-9]+$/)) {
      setMaxprice(Number(e.target.value));
    } else if (e.target.value === '') {
      setMaxprice(null);
    } else {
      setIsMaxpriceError(true);
      setErrorMessageMaxprice('Bitte nur Zahlen eingeben!');
    }
  };

  /**
   * Die Navigation kommt entweder von stores search oder
   * portal search, also globale suche. Beim store search muss
   * der der store wieder gesetzt werden
   */
  const goBack = () => {
    if (routerLocation.state.srcroute === '/stores') {
      navigate('/stores', {
        state: {
          childpage: routerLocation.state.childpage,
          branchid: routerLocation.state.branchid,
          storeid: routerLocation.state.storeid,
          storename: routerLocation.state.storename,
          expanded: routerLocation.state.expanded,
          searchparam: routerLocation.state.searchparam,
        },
      });
    } else {
      navigate('/search', {
        state: {
          searchparam: routerLocation.state.searchparam,
        },
      });
    }
  };

  /**
   * Funktion zum setzen des Filters.
   */
  const handleFilter = () => {
    if (minprice >= maxprice && maxprice > 0) {
      setIsMaxpriceError(true);
      setErrorMessageMaxprice(
        'Der max. Preis muss größer sein als min. Preis!'
      );
    } else {
      dispatch({
        type: 'SET_FILTER',
        payload: {
          minprice,
          maxprice,
        },
      });
    }
  };

  // return
  return (
    <Container maxWidth='xs'>
      <Box mt={3}>
        <Header
          title='Filter für die Suche setzen.'
          subtitle='Hier können Sie die Parameter für die Suche einstellen.'
        />
      </Box>
      <Box>
        <TextField
          id='minprice'
          name='minprice'
          variant='standard'
          color='secondary'
          margin='normal'
          sx={{ width: '100%' }}
          label='min. Preis'
          value={minprice === null ? '' : String(minprice)}
          error={isMinpriceError}
          helperText={errorMessageMinprice}
          FormHelperTextProps={{
            style: { color: theme.palette.error.main },
          }}
          onFocus={() => {
            setIsMinpriceError(false);
            setErrorMessageMinprice('');
          }}
          onChange={(e) => handleMinpriceInput(e)}
        />
        <TextField
          id='maxprice'
          name='maxprice'
          variant='standard'
          color='secondary'
          margin='normal'
          sx={{ width: '100%' }}
          label='max. Preis'
          value={maxprice === null ? '' : String(maxprice)}
          error={isMaxpriceError}
          helperText={errorMessageMaxprice}
          FormHelperTextProps={{
            style: { color: theme.palette.error.main },
          }}
          onFocus={() => {
            setIsMaxpriceError(false);
            setErrorMessageMaxprice('');
          }}
          onChange={(e) => handleMaxpriceInput(e)}
        />
      </Box>
      <Box
        mt={3}
        mb={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            borderRadius: 30,
            width: '240px',
          }}
          type='submit'
          variant='contained'
          color='secondary'
          onClick={() => {
            handleFilter();
            goBack();
          }}
        >
          Übernehmen
        </Button>
      </Box>
    </Container>
  );
};

export default FilterView;
