import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
} from '@mui/material';

/**
 * PortalProductCard mit Header.
 * @param props
 * @returns
 */
const PortalProductCard = (props: any) => {
  const theme = useTheme();

  // return
  return (
    <Card square={true} elevation={0} sx={{ maxWidth: 165, maxHeight: 330 }}>
      <CardHeader
        disableTypography={true}
        sx={{ height: 30 }}
        title={
          <Typography
            variant='body2'
            sx={{ fontWeight: theme.typography.fontWeightMedium }}
          >
            {props.title}
          </Typography>
        }
      />
      {props.actionstatus === 'None' ? (
        <Typography
          color='secondary'
          variant='body2'
          sx={{
            mt: 0.5,
            ml: 1,
            position: 'absolute',
            color: theme.palette.secondary.main,
          }}
        >
          {''}
        </Typography>
      ) : props.actionstatus === 'Reduziert' ? (
        <Typography
          color='secondary'
          variant='body1'
          sx={{
            mt: 1,
            ml: 1,
            position: 'absolute',
            color: theme.palette.secondary.main,
          }}
        >
          {props.actionstatus +
            '\u00A0' +
            '\u00A0' +
            '\u00A0' +
            '\u00A0' +
            '-' +
            props.discount +
            '%'}
        </Typography>
      ) : (
        <Typography
          color='secondary'
          variant='body2'
          sx={{
            mt: 1,
            ml: 1,
            position: 'absolute',
            color: theme.palette.secondary.main,
          }}
        >
          {props.actionstatus}
        </Typography>
      )}
      <CardMedia
        sx={{ width: 165, height: 220 }}
        image={props.mediaUri + props.image}
        title={props.productname}
      />
      <CardContent
        sx={{
          height: 75,
          flexGrow: 1,
          textAlign: 'left',
          p: theme.spacing(1),
        }}
      >
        <Typography variant='body1' sx={{ mt: 0.2 }}>
          {props.productname}
        </Typography>
        {props.actionstatus === 'Reduziert' ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Typography
              variant='body2'
              sx={{
                fontWeight: theme.typography.fontWeightMedium,
                color: theme.palette.text.disabled,
                textDecorationLine: 'line-through',
              }}
            >
              {props.price} €
            </Typography>
            <Typography
              variant='body2'
              sx={{
                marginLeft: 2,
                fontWeight: theme.typography.fontWeightMedium,
              }}
            >
              {(
                Math.round(
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  (parseFloat(props.price.trim()).toFixed(2) -
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    (parseFloat(props.price.trim()).toFixed(2) / 100) *
                      props.discount) *
                    100
                ) / 100
              ).toFixed(2)}{' '}
              €
            </Typography>
          </Box>
        ) : (
          <Typography
            variant='body1'
            sx={{ fontWeight: theme.typography.fontWeightMedium }}
          >
            {props.price} €
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default PortalProductCard;
