import React from 'react';
import { Box, Typography } from '@mui/material';

/**
 * Header2 component
 *
 * @param props
 * @returns
 */
const Header2 = (props: any) => {
  // return
  return (
    <Box>
      <Typography component='h2' variant='h4' align='center'>
        {props.title}
      </Typography>
      {props.subtitle !== undefined ? (
        <Typography
          component='h2'
          variant='subtitle1'
          align='center'
          color='textSecondary'
          mt={1}
        >
          {props.subtitle}
        </Typography>
      ) : null}
    </Box>
  );
};

export default Header2;
