import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Container,
  Box,
  Typography,
  TextField,
  Autocomplete,
} from '@mui/material';
import { GET_LOCATION_BY_ZIPCODE } from './queries/tenantQueries';
import { useLazyQuery } from '@apollo/client';
import { IInputError } from '../interfaces/interfaces';

/**
 * RegisterLoginStoreView um sich am System
 * mit Email und Password als Kunde zu registrieren.
 */
const RegisterAddressStoreForm = (props: any) => {
  const theme = useTheme();
  const [storeNameError, setStoreNameError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [storeOwnerError, setStoreOwnerError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [zipcodeError, setZipcodeError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [streetError, setStreetError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [phoneError, setPhoneError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [zipcodeDropdownList, setZipcodeDropdownList] = useState<any>([]);
  const [zipcodeDropdownListIsSet, setZipcodeDropdownListIsSet] =
    useState<boolean>(false);

  // get locations
  const [getLocations] = useLazyQuery(GET_LOCATION_BY_ZIPCODE);

  /**
   * Buttonstatus setzen
   */
  useEffect(() => {
    if (
      props.storedataFromParent.storeName === '' ||
      props.storedataFromParent.storeOwner === '' ||
      props.storedataFromParent.locationId === '' ||
      props.storedataFromParent.zipcode === '' ||
      props.storedataFromParent.place === '' ||
      props.storedataFromParent.street === '' ||
      props.storedataFromParent.phone === '' ||
      storeNameError.isError ||
      storeOwnerError.isError ||
      zipcodeError.isError ||
      streetError.isError ||
      phoneError.isError
    ) {
      props.sendButtonStep1EnabledToParent(false);
    } else {
      props.sendButtonStep1EnabledToParent(true);
    }
  }, [
    props.storedataFromParent.storeName,
    props.storedataFromParent.storeOwner,
    props.storedataFromParent.locationId,
    props.storedataFromParent.zipcode,
    props.storedataFromParent.place,
    props.storedataFromParent.street,
    props.storedataFromParent.phone,
    storeNameError.isError,
    storeOwnerError,
    zipcodeError.isError,
    streetError.isError,
    phoneError.isError,
    props,
  ]);

  /**
   * Wenn storeName geändert wird.
   * @param e
   */
  const handleStoreNameInput = (e: any) => {
    props.sendStoredataToParent({
      ...props.storedataFromParent,
      storeName: e.target.value,
    });
  };

  /**
   * storeName Input Validierung
   */
  const checkStoreNameInput = () => {
    if (props.storedataFromParent.storeName === '') {
      setStoreNameError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    } else {
      setStoreNameError({ isError: false, message: '' });
    }
  };

  /**
   * Wenn storeOwner geändert wird.
   * @param e
   */
  const handleStoreOwnerInput = (e: any) => {
    const regexp = /^[\p{L} ,.'-]+$/u;
    if (e.target.value === '' || regexp.test(e.target.value)) {
      props.sendStoredataToParent({
        ...props.storedataFromParent,
        storeOwner: e.target.value,
      });
    } else {
      setStoreOwnerError({
        isError: true,
        message: 'Eingabe ist fehlerhaft!',
      });
    }
  };

  /**
   * storeOwner Input Validierung
   */
  const checkStoreOwnerInput = () => {
    const regexp =
      /^(?:[\u00c0-\u01ffa-zA-Z'-]){2,}(?:\s[\u00c0-\u01ffa-zA-Z'-]{2,})+$/i;
    if (props.storedataFromParent.storeOwner === '') {
      setStoreOwnerError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
      props.sendButtonStep1EnabledToParent(false);
    } else if (regexp.test(props.storedataFromParent.storeOwner)) {
      // props.storedataFromParent.storeOwner ist ok
      // this is a valid props.storedataFromParent.storeOwner
      setStoreOwnerError({
        isError: false,
        message: '',
      });
    } else {
      setStoreOwnerError({
        isError: true,
        message: 'Eingabe ist fehlerhaft!',
      });
    }
  };

  /**
   * Wenn zipcode geändert wird.
   * @param e
   */
  const handleZipcodeInput = async (e: any) => {
    const regexp = /^[0-9\b]+$/;
    if (e.target.value === '' || regexp.test(e.target.value)) {
      props.sendStoredataToParent({
        ...props.storedataFromParent,
        zipcode: e.target.value,
      });
      setZipcodeError({
        isError: false,
        message: '',
      });
      if (e.target.value.length >= 3) {
        // ab 3 Stellen wird Anfrage ausgelöst
        try {
          const { data } = await getLocations({
            variables: { param: e.target.value + '%' },
          });
          setZipcodeDropdownList(data.location);
          setZipcodeDropdownListIsSet(true);
        } catch (error) {
          console.log('Error! in handleZipcodeInput: ', error);
        }
      } else {
        setZipcodeDropdownList([]);
        setZipcodeDropdownListIsSet(false);
      }
      if (e.target.value.length >= 6) {
        setZipcodeDropdownList([]);
        setZipcodeDropdownListIsSet(false);
        setZipcodeError({
          isError: true,
          message: 'Bitte nur 5 Ziffern eingeben!',
        });
      }
    } else {
      setZipcodeDropdownList([]);
      setZipcodeDropdownListIsSet(false);
      setZipcodeError({
        isError: true,
        message: 'Bitte nur Ziffern eingeben!',
      });
    }
  };

  /**
   * Wird beim "onPress" Event in Autocomplete Tag ausgelöst,
   * sobald ein Ort in der Liste ausgewählt wird.
   */
  const onSelectLocation = (item: any) => {
    if (item === null) {
      props.sendStoredataToParent({
        ...props.storedataFromParent,
        locationId: 0,
        zipcode: '',
        place: '',
      });
      setZipcodeError({ isError: true, message: 'Eingabe ist erforderlich!' });
    } else {
      props.sendStoredataToParent({
        ...props.storedataFromParent,
        locationId: item.id,
        zipcode: item.zipcode,
        place: item.place,
      });
      setZipcodeError({ isError: false, message: '' });
    }
    setZipcodeDropdownListIsSet(false);
  };

  /**
   * zipcode Input Validierung
   */
  const checkZipcodeInput = () => {
    if (props.storedataFromParent.zipcode === '') {
      setZipcodeError({ isError: true, message: 'Eingabe ist erforderlich!' });
    } else {
      setZipcodeError({ isError: false, message: '' });
    }
  };

  /**
   * Wenn street geändert wird.
   * @param e
   */
  const handleStreetInput = (e: any) => {
    props.sendStoredataToParent({
      ...props.storedataFromParent,
      street: e.target.value,
    });
  };

  /**
   * streetOwner Input Validierung
   */
  const checkStreetInput = () => {
    if (props.storedataFromParent.street === '') {
      setStreetError({ isError: true, message: 'Eingabe ist erforderlich!' });
    } else {
      setStreetError({ isError: false, message: '' });
    }
  };

  /**
   * Event zum setzen der Telefonnummer im State.
   * @param e
   */
  const handlePhoneInput = (e: any) => {
    props.sendStoredataToParent({
      ...props.storedataFromParent,
      phone: e.target.value,
    });
  };

  /**
   * phone Input Validierung.
   */
  const checkPhoneInput = () => {
    const regexp = /^([0-9()/+ -]*)$/;
    if (props.storedataFromParent.phone === '') {
      setPhoneError({
        isError: true,
        message: 'Telefonnummer ist erforderlich!',
      });
    } else if (!regexp.test(props.storedataFromParent.phone)) {
      setPhoneError({
        isError: true,
        message: 'Ist keine gültige Telefonnummer.',
      });
    } else {
      setPhoneError({
        isError: false,
        message: '',
      });
    }
  };

  // return
  return (
    <Container maxWidth='xs' disableGutters>
      <Typography component='h1' variant='h6' align='center' sx={{ mt: 1 }}>
        Geschäftsdaten
      </Typography>
      <Box mt={1}>
        <TextField
          color='secondary'
          variant='standard'
          required
          sx={{ width: '100%' }}
          label='Name des Geschäfts'
          value={props.storedataFromParent.storeName}
          error={storeNameError.isError}
          helperText={storeNameError.message}
          FormHelperTextProps={{ sx: { color: theme.palette.secondary.main } }}
          onChange={(e) => handleStoreNameInput(e)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              checkStoreNameInput();
            }
          }}
          onBlur={checkStoreNameInput}
        />
        <TextField
          color='secondary'
          variant='standard'
          required
          sx={{ width: '100%' }}
          label='Inhaber (Vor- und Nachname)'
          value={props.storedataFromParent.storeOwner}
          error={storeOwnerError.isError}
          helperText={storeOwnerError.message}
          FormHelperTextProps={{ sx: { color: theme.palette.secondary.main } }}
          onFocus={checkStoreNameInput}
          onChange={(e) => handleStoreOwnerInput(e)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              checkStoreOwnerInput();
            }
          }}
          onBlur={() => checkStoreOwnerInput()}
        />
        <Autocomplete
          popupIcon={null}
          clearIcon={null}
          open={zipcodeDropdownListIsSet}
          inputValue={props.storedataFromParent.zipcode}
          options={zipcodeDropdownList}
          getOptionLabel={(option: any) => `${option.zipcode} ${option.place}`}
          isOptionEqualToValue={(option: any, value: any) =>
            option.zipcode === value.zipcode && option.place === value.place
          }
          onChange={(e: object, value: any | null) => {
            onSelectLocation(value);
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              color='secondary'
              variant='standard'
              label='PLZ'
              required
              sx={{ width: '100%' }}
              onChange={(e) => handleZipcodeInput(e)}
              helperText={zipcodeError.message}
              FormHelperTextProps={{
                sx: { color: theme.palette.secondary.main },
              }}
            />
          )}
        />
        <TextField
          color='secondary'
          variant='standard'
          required
          sx={{ width: '100%' }}
          label='Ort'
          value={props.storedataFromParent.place}
        />
        <TextField
          color='secondary'
          variant='standard'
          required
          sx={{ width: '100%' }}
          label='Straße'
          value={props.storedataFromParent.street}
          error={streetError.isError}
          helperText={streetError.message}
          FormHelperTextProps={{ sx: { color: theme.palette.secondary.main } }}
          onFocus={checkZipcodeInput}
          onChange={(e) => handleStreetInput(e)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              checkStreetInput();
            }
          }}
          onBlur={() => checkStreetInput()}
        />
        <TextField
          color='secondary'
          variant='standard'
          required
          sx={{ width: '100%' }}
          label='Telefon'
          value={props.storedataFromParent.phone}
          error={phoneError.isError}
          helperText={phoneError.message}
          FormHelperTextProps={{ sx: { color: theme.palette.secondary.main } }}
          onFocus={checkStreetInput}
          onChange={(e) => handlePhoneInput(e)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              checkPhoneInput();
            }
          }}
          onBlur={() => checkPhoneInput()}
        />
      </Box>
    </Container>
  );
};

export default RegisterAddressStoreForm;
