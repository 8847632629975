import { gql } from '@apollo/client';

// graphql query get all branches
export const GET_ALL_BRANCHES = gql`
  query getAllBranches {
    storebranch {
      id
      branchname
    }
  }
`;

// graphql query get store branches
export const GET_STORE_BRANCHES = gql`
  query getStoreBranches($storeid: Int!) {
    storebranch(
      where: { storebranches: { store: { id: { _eq: $storeid } } } }
    ) {
      id
      branchname
    }
  }
`;

// graphql query branches by location
export const GET_LOCATION_BRANCHES = gql`
  query getLocationBranches($locations: _int4!) {
    merkantdo_locationbranches(args: { locations: $locations }) {
      id
      branchname
    }
  }
`;

// graphql subscribe branches by location
export const SUBSCRIBE_LOCATION_BRANCHES = gql`
  subscription getLocationBranches($locations: _int4!) {
    merkantdo_locationbranches(args: { locations: $locations }) {
      id
      branchname
    }
  }
`;

// graphql query branch stores by location
export const GET_BRANCH_STORES = gql`
  query getBranchStores($storebranchid: Int!, $locations: _int4!) {
    merkantdo_branchstores(
      args: { storebranchid: $storebranchid, locations: $locations }
    ) {
      id
      storename
    }
  }
`;
