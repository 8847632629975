import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import Header2 from '../components/Header2';

/**
 * PrivacyView
 */
const PrivacyView = () => {
  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={3} mb={2}>
        <Header2 title='Datenschutz' />
      </Box>
      <Box mt={2} mb={2}>
        <Typography component='p' variant='body1' paragraph>
          Unser Datenschutz steht im Einklang mit dem Bundesdatenschutzgesetz
          (BDSG) sowie dem Telemediengesetz (TMG).
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Personenbezogene Daten unserer Kunden werden nur genutzt, soweit sie
          für den Abschluss oder die Änderung des Vertragsverhältnisses
          erforderlich sind. Der Kunde verpflichtet sich, seine Daten im
          Online-Verwaltungsbereich jederzeit auf dem aktuellen Stand zu halten.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Die E-Mail-Adressen unserer Kunden nutzen wir für
          Auftragsbestätigungen und Rechnungen.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Personenbezogene Daten unserer Kunden geben wir nicht an Dritte
          weiter. Ausgenommen hiervon sind Dienstleistungspartner, soweit dies
          zur Ermittlung des Entgelts und zur Abrechnung mit den Kunden
          erforderlich ist.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Jeder Kunde hat ein Auskunftsrecht sowie ein Recht auf Berichtigung,
          Sperrung und Löschung seiner gespeicherten Daten. Sofern einer
          Löschung gesetzliche oder vertragliche Aufbewahrungspflichten oder
          andere gesetzliche Gründe widersprechen, werden die Daten lediglich
          gesperrt.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyView;
