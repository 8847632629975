import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * StoreCommunicateHowToView
 */
const StoreCommunicateHowToView = () => {
  const navigate = useNavigate();

  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={3}>
        <Typography component='h1' variant='h4' align='center' gutterBottom>
          Kommunikation mit dem Kunden
        </Typography>
        <Typography component='h1' variant='h6' align='center' gutterBottom>
          Wie kann ich mit meinen Kunden kommunizieren?
        </Typography>
      </Box>
      <Box>
        <Typography component='p' variant='body1' paragraph>
          Die Kommunikation mit dem Kunden erfolgt mit zwei verschiedenen
          Möglichkeiten.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          1. Möglichkeit: Ein Chat in dem Ihre Kunden fragen zum Artikel Ihnen
          stellen können.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          2. Möglichkeit: Bei den News haben Sie die Möglichkeit News an Kunden,
          die Ihr Geschäft zu Favoriten hinzugefügt haben und
          News-Benachrichtigung aktiviert haben, zu versenden.
        </Typography>
      </Box>
      <Box
        mt={3}
        mb={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type='submit'
          variant='contained'
          color='secondary'
          sx={{ borderRadius: 30, width: '240px' }}
          onClick={() => navigate('/help')}
        >
          Zurück
        </Button>
      </Box>
    </Container>
  );
};

export default StoreCommunicateHowToView;
