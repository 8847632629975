import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { useTheme } from '@mui/material/styles';
import {
  Container,
  Box,
  Typography,
  TextField,
  Autocomplete,
  Slider,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocation } from '../locationContext';
import {
  GET_LOCATION_BY_ZIP,
  GET_LOCATION_BY_PLACE,
  GET_LOCATIONS_BY_DISTANCE,
} from './queries/locationQueries';
import { useLazyQuery } from '@apollo/client';
import { IInputError } from '../interfaces/interfaces';

/**
 * LocationView ist für die Auswahl des Ortes und der Entfernung
 * in der nach Angeboten der Geschäfte gesucht werden kann zuständig.
 * Die Auswahl erfolgt nach PLZ oder Ortsnamen. Die Entfernung wird über einen
 * Slider eingestellt. Die Liste der Ortschaften wird im globalen
 * LocationContext gespeichert.
 */
const LocationView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [{ location }, dispatch] = useLocation();
  const [locationId, setLocationId] = useState<number>(location.id);
  const [zipcode, setZipcode] = useState<string>(location.zipcode);
  const [place, setPlace] = useState<string>(location.place);
  const [distance, setDistance] = useState<number>(location.distance);
  const [dropdownList, setDropdownList] = useState<any>([]);
  const [dropdownListIsSet, setDropdownListIsSet] = useState<boolean>(false);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [inputError, setInputError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });

  // locations by zip holen
  const [getLocationByZip] = useLazyQuery(GET_LOCATION_BY_ZIP);

  // locations by zip holen
  const [getLocationByPlace] = useLazyQuery(GET_LOCATION_BY_PLACE);

  // locations by distance holen
  const [getLocations] = useLazyQuery(GET_LOCATIONS_BY_DISTANCE);

  /**
   * Überprüfen ob im AsyncStorage Location vorhanden ist
   * und wenn vorhanden die Daten in den LocationContext kopieren.
   */
  const setContextFromStorageLocation = () => {
    // storage abfragen
    if (localStorage.getItem('locationId') !== null) {
      const storageLocationId: any = localStorage.getItem('locationId');
      const storageZipcode: any = localStorage.getItem('zipcode');
      const storagePlace: any = localStorage.getItem('place');
      const storageDistance: any = localStorage.getItem('distance');
      const storageLocationlist: any = localStorage.getItem('locationlist');
      // state setzen
      setLocationId(JSON.parse(storageLocationId));
      setZipcode(storageZipcode);
      setPlace(storagePlace);
      setDistance(JSON.parse(storageDistance));
      // context setzen
      dispatch({
        type: 'SET_LOCATION',
        payload: {
          id: JSON.parse(storageLocationId),
          zipcode: storageZipcode,
          place: storagePlace,
          distance: JSON.parse(storageDistance),
          locationlist: JSON.parse(storageLocationlist),
        },
      });
      setButtonEnabled(true);
    }
  };

  /**
   * Funktion setContextFromStorageLocation aufrufen.
   */
  useEffect(() => {
    setContextFromStorageLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Falls die Location schon gesetzt ist und danach
   * geändert wird werden die Einstellungen aus dem
   * LocationContext geholt und im InputValue gespeichert.
   * Danach werden die Einstellungen geändert.
   */
  useEffect(() => {
    if (location.id !== 0) {
      setInputValue(`${location.zipcode} ${location.place}`);
    } else {
      setInputValue('');
    }
  }, [location.id, location.place, location.zipcode]);

  /**
   * Wird verwendet um festzustellen ob es sich um Ziffern
   * oder Buchstaben bei der Eingabe handelt (PLZ oder Ort) und
   * je nach Eingabe werden dann die Queries und ihre Parameter gesetzt.
   * Bei Falscheingabe wird eine Message ausgegeben.
   */
  const handleInputChange = async (value: any) => {
    setInputValue(value);
    setInputError({
      isError: false,
      message: '',
    });
    // die Fallunterscheidungen ob PLZ oder Ort
    if (
      value.match(/^[A-Za-z\u00e4\u00f6\u00fc\u00c4\u00d6\u00dc\u00df\040]+$/) // Buchstaben
    ) {
      if (value.length >= 3) {
        // ab 3 Stellen wird Anfrage ausgelöst
        try {
          const { data } = await getLocationByPlace({
            variables: { param: value + '%' },
          });
          setDropdownList(data.location);
          setDropdownListIsSet(true);
        } catch (error) {
          console.log('Error! in handleInputChange - place: ', error);
        }
      }
    } else if (value.match(/^[0-9\b]+$/)) {
      // zipcode fall
      // Ziffern
      if (value.length >= 3) {
        // ab 3 Stellen wird Anfrage ausgelöst
        try {
          const { data } = await getLocationByZip({
            variables: { param: value + '%' },
          });
          setDropdownList(data.location);
          setDropdownListIsSet(true);
        } catch (error) {
          console.log('Error! in handleInputChange - zip: ', error);
        }
      }
      if (value.length >= 6) {
        // Fehler bei 6 oder mehr
        setInputError({
          isError: true,
          message: 'Bitte nur 5 Ziffern eingeben!',
        });
        setDropdownList([]);
        setDropdownListIsSet(false);
      }
    } else if (value.length === 0) {
      setInputError({
        isError: false,
        message: '',
      });
      //setErrorMessage('');
    } else {
      setInputError({
        isError: true,
        message: 'Bitte nur Ziffern oder Buchstaben!',
      });
      setDropdownList([]);
      setDropdownListIsSet(false);
    }
  };

  /**
   * Diese Funktion an den GraphQL Server liefert die Liste von Orten
   * die in der eingestellten Entfernung vom ausgewählten Ort vorhanden sind.
   * Die Parameter sind id und distance. Die Ergebnisse werden
   * von einer PostgreSQL Funktion 'search_locations' zurückgegeben. Die
   * id wird von der Auswahl in der Dropdown Liste ausgewählt,
   * die von der Funktion oben geliefert wurde.
   */
  const getLocationsByDistance = async () => {
    const tempLocations: any[] = [];
    try {
      const { data } = await getLocations({
        variables: { id: locationId, dist: distance },
      });
      for (let i = 0; i < data.merkantdo_location.length; i++) {
        tempLocations.push(data.merkantdo_location[i].id);
      }
      dispatch({
        type: 'SET_LOCATION',
        payload: {
          id: locationId,
          zipcode: zipcode,
          place: place,
          distance: distance,
          locationlist: tempLocations,
        },
      });
      try {
        localStorage.setItem('locationId', JSON.stringify(locationId));
        localStorage.setItem('zipcode', zipcode);
        localStorage.setItem('place', place);
        localStorage.setItem('distance', JSON.stringify(distance));
        localStorage.setItem('locationlist', JSON.stringify(tempLocations));
      } catch (error) {
        console.log(
          'Error! in getLocationsByDistance - set localStorage: ',
          error
        );
      }
      navigate('/start');
    } catch (error) {
      console.log('Error! in getLocationsByDistance - getLocations: ', error);
    }
  };

  /**
   * Wird beim "onPress" Event in Autocomplete Tag ausgelöst,
   * sobald ein Ort in der Liste ausgewählt wird.
   */
  const onSelectLocation = (item: any) => {
    setLocationId(item.id);
    setZipcode(item.zipcode);
    setPlace(item.place);
    setInputValue(`${item.zipcode} ${item.place}`);
    setDropdownListIsSet(false);
    setButtonEnabled(true);
  };

  /**
   * Wenn Distance geändert wird muss überprüft werden
   * ob eine Location schon vorhanden ist. Danach wird
   * entschieden ob der Button aktiviert wird oder nicht.
   */
  const handleDistanceChange = (event: any, newValue: any) => {
    if (locationId !== 0 && newValue !== location.distance) {
      setDistance(newValue);
      setButtonEnabled(true);
    } else {
      setDistance(newValue);
    }
  };

  // return
  return (
    <Container maxWidth='xs'>
      <Box sx={{ mt: 3 }}>
        <Header
          title='Ort und Entfernung wählen.'
          subtitle='Geben Sie unten die PLZ oder den Ortsnamen und die Enfernung vom Ort
          ein um sich über Angebote zu informieren.'
        />
      </Box>
      <Box>
        <Autocomplete
          popupIcon={null}
          clearIcon={null}
          id='flat-demo'
          open={dropdownListIsSet}
          inputValue={inputValue}
          options={dropdownList}
          getOptionLabel={(option: any) => `${option.zipcode} ${option.place}`}
          isOptionEqualToValue={(option: any, value: any) =>
            option.zipcode === value.zipcode && option.place === value.place
          }
          onChange={(e: object, value: any | null) => {
            onSelectLocation(value);
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              color='secondary'
              variant='standard'
              label='PLZ oder Ort'
              margin='normal'
              required
              sx={{ width: '100%' }}
              id='location'
              name='location'
              onChange={(e) => handleInputChange(e.target.value)}
              error={inputError.isError}
              helperText={inputError.message}
              FormHelperTextProps={{
                style: { color: theme.palette.error.main },
              }}
            />
          )}
        />
        <Typography id='continuous-slider' mt={3} mb={2}>
          Entfernung in km: {distance}
        </Typography>
        <Slider
          color='secondary'
          size='small'
          defaultValue={0}
          step={1}
          min={0}
          max={50}
          value={distance}
          onChange={handleDistanceChange}
          aria-labelledby='continuous-slider'
        />
      </Box>
      <Box
        mt={3}
        mb={3}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Button
          type='submit'
          sx={{
            borderRadius: 30,
            width: '240px',
          }}
          disabled={!buttonEnabled}
          variant='contained'
          color='secondary'
          onClick={() => getLocationsByDistance()}
        >
          Übernehmen
        </Button>
      </Box>
    </Container>
  );
};

export default LocationView;
