import React from 'react';
import { Container, Box, Typography } from '@mui/material';

/**
 * TenantProductListHelpView
 *
 */
const TenantProductListHelpView = () => {
  // return
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component='h1' variant='h5'>
          Artikelliste
        </Typography>
        <Typography variant='body1' mt={1}>
          Dies ist die Liste Ihrer Artikel in der jeweiligen Warengruppe.
        </Typography>
        <Typography variant='body1' mt={1}>
          Klicken Sie auf "Neu" um einen neuen Artikel anzulegen. Um ein Artikel
          zu ändern oder zu löschen klicken Sie auf das Icon oben rechts.
        </Typography>
      </Box>
    </Container>
  );
};

export default TenantProductListHelpView;
