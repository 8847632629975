import React from 'react';
import { Container, Box, Typography } from '@mui/material';

/**
 * TenantProductDetailHelpView
 *
 */
const TenantProductDetailHelpView = () => {
  // return
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component='h1' variant='h5'>
          Artikeldaten
        </Typography>
        <Typography variant='body1' mt={1}>
          Diese Seite ist zuständig für die Verwaltung der Artikel. Sie sehen
          ein Formular mit zwei Registern, Daten und Bilder.
        </Typography>
        <Typography variant='body1' mt={1}>
          Die Eingaben in dem Register "Daten" sind Warengruppe, Artikelname,
          Beschreibung, Preis. Falls der Preis reduziert wird wählen Sie bei
          Aktionen "Reduziert". In diesem Fall muss bei "Rabat in %" zusätzlich
          der Rabat in Prozent eingetragen werden. Bei Aktionen haben Sie die
          Möglichkeit den Artikel als "Neu", "Top" oder als "Reduziert" zu
          markieren. Bei "Verfügbarkeit" gibts die Möglichkeit ein Artikel neben
          "Vorhanden" als "Reserviert" oder "Ausverkauft" zu markieren.
        </Typography>
        <Typography variant='body1' mt={1}>
          Falls der Artikel zusätzlich auf der Startseite angezeigt werden soll,
          aktivieren Sie das.
        </Typography>
        <Typography variant='body1' mt={1}>
          Das Register "Bilder" ist für die Verwaltung der Bilder zuständig. Es
          können höchstens 8 Bilder pro Artkel hochgeladen werden.
        </Typography>
        <Typography variant='body1' mt={1}>
          Sobald alle Eingaben erfolgt sind, klicken Sie bei "Artikelstatus" auf
          "Aktiv" und "Übernehmen". Der Artikel ist jetzt online.
        </Typography>
      </Box>
    </Container>
  );
};

export default TenantProductDetailHelpView;
