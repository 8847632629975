import React from 'react';
import { Container, Box, Typography } from '@mui/material';

/**
 * TenantContractHelpView
 *
 */
const TenantContractHelpView = () => {
  // return
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component='h1' variant='h5'>
          Vertragsdaten
        </Typography>
        <Typography variant='body1' mt={1}>
          Die Daten Ihres Vertrages.
        </Typography>
        <Typography variant='body1' mt={1}>
          Die Vertragsdaten sind selbsterklärend. Es sind die Kundennummer, der
          Service, Status des Vertrages, Datum des Vertragsabschlusses, Datum
          der Rechnung und falls Sie den Vertrag gekündigt haben das Datum zu
          dem der Vertrag gekündigt wird.
        </Typography>
      </Box>
    </Container>
  );
};

export default TenantContractHelpView;
