export interface FilterState {
  filter: {
    minprice: null | number;
    maxprice: null | number;
  };
}

export const filterInitialState: FilterState = {
  filter: {
    minprice: null,
    maxprice: null,
  },
};
