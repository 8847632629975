import React from 'react';
import { Container, Box, Typography } from '@mui/material';

/**
 * TenantEditProductCategoriesHelpView
 *
 */
const TenantEditProductCategoriesHelpView = () => {
  // return
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component='h1' variant='h5'>
          Warengruppen - Liste
        </Typography>
        <Typography variant='body1' mt={1}>
          Dies ist die Liste der vorhandenen Warengruppen.
        </Typography>
        <Typography variant='body1' mt={1}>
          Hier sehen Sie eine Liste möglicher Warengruppen. Aktivieren Sie die
          Warengruppen die Sie benötigen.
        </Typography>
        <Typography variant='body1' mt={1}>
          Ist keine passende Warengruppe vorhaden, erstellen Sie sie neu und
          aktivieren Sie anschliessend.
        </Typography>
      </Box>
    </Container>
  );
};

export default TenantEditProductCategoriesHelpView;
