import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Container,
  Box,
  Typography,
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GET_ACTIVESERVICETYPES } from '../tenant/queries/tenantQueries';
import { useQuery } from '@apollo/client';
import Header2 from '../components/Header2';

/**
 * PricingView stellt im ausgewählten Ort und
 * eingestellten Umgebung den Filter dar.
 */
const PricingView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [servicetypes, setServicetypes] = useState<any>([]);
  const {
    loading: serviceLoading,
    error: serviceError,
    data: serviceData,
  } = useQuery(GET_ACTIVESERVICETYPES);

  /**
   * Servicetype holen und setzen.
   */
  useEffect(() => {
    if (serviceData) {
      setServicetypes(serviceData.merkantdo_activeservicetypes);
    }
  }, [serviceData]);

  // Beim laden der Daten nichts anzeigen.
  if (serviceLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (serviceError) {
    console.log('serviceError! in PricingView: ' + serviceError.message);
  }

  // return
  return (
    <Container
      maxWidth={false}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box mt={3} mb={2}>
        <Header2 title='Preisübersicht' />
      </Box>
      <Container sx={{ p: 2 }}>
        <Typography component='p' variant='subtitle1'>
          Im nachfolgenden eine Übersicht über verschiedene Möglichkeiten, die
          man auf dem Portal buchen kann um die Artikel dem Kunden zu
          präsentieren und über das vorhandene Angebot zu informieren.
        </Typography>
      </Container>
      <Container sx={{ p: 2 }}>
        <Grid container spacing={5} alignItems='flex-end'>
          {servicetypes.map((item: any, index: number) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card elevation={0}>
                <CardHeader
                  title={item.servicename}
                  titleTypographyProps={{ align: 'center' }}
                  sx={{
                    backgroundColor:
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[900],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                    }}
                  >
                    <Typography
                      component='h2'
                      variant='h3'
                      color='textPrimary'
                      align='center'
                    >
                      {item.serviceprice}
                      {'\u00a0'}
                    </Typography>
                    <Typography variant='h6' color='textPrimary' align='center'>
                      €/Monat
                    </Typography>
                  </Box>
                  <Typography variant='caption' align='center' paragraph>
                    zzgl. MwSt.
                  </Typography>
                  <Typography variant='subtitle1' align='center'>
                    3 Monate kostenlos
                  </Typography>
                  <Typography variant='subtitle1' align='center'>
                    {item.productnumber} Artikel insgesamt
                  </Typography>
                  <Typography variant='subtitle1' align='center'>
                    {item.actionnumber} Artikel davon auf der Startseite
                  </Typography>
                  <Typography variant='subtitle1' align='center'>
                    Ticket support
                  </Typography>
                  <Typography variant='subtitle1' align='center'>
                    1 Monat Kündigungsfrist
                  </Typography>
                </CardContent>
                <CardActions style={{ justifyContent: 'center' }}>
                  <Button
                    sx={{ width: '100%' }}
                    variant='outlined'
                    color='inherit'
                    onClick={() =>
                      navigate('/registerStore', {
                        state: {
                          serviceId: item.id,
                          serviceName: item.servicename,
                        },
                      })
                    }
                  >
                    Kostenlos testen
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Box
        m={3}
        //className={classes.footer}
      >
        <Typography component='p' variant='subtitle1' align='center'>
          Unsere Angebote richten sich an gewerbliche Kunden. Alle Preise sind
          Nettopreise zzgl. der gesetzlichen MwSt.
        </Typography>
      </Box>
    </Container>
  );
};

export default PricingView;
