import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  useTheme,
} from '@mui/material';
import {
  AiOutlineUnorderedList,
  AiOutlineQuestionCircle,
  AiOutlineClose,
} from 'react-icons/ai';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { useAuth } from '../authContext';
import { GET_ALL_PRODUCT_CATEGORIES_BY_STORE } from '../data/StoreData';
import { useQuery } from '@apollo/client';
import Header2 from '../components/Header2';
import TenantProductAssortmentHelpView from './help/TenantProductAssortmentHelpView';

/**
 * TenantProductAssortmentView stellt die
 * Warengruppen eines Tenants dar.
 */
const TenantProductAssortmentView = () => {
  const navigate = useNavigate();
  const [{ profile }] = useAuth();
  const routerLocation: any = useRouterLocation();
  const theme = useTheme();
  const [helpOpen, setHelpOpen] = useState<boolean>(
    routerLocation.state.helpopen
  );

  // get all store product categories
  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: categoriesData,
  } = useQuery(GET_ALL_PRODUCT_CATEGORIES_BY_STORE, {
    variables: { storeid: routerLocation.state.storeId },
  });

  // Beim laden der Daten nichts anzeigen.
  if (categoriesLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (categoriesError) {
    console.log(
      `Categories Error! TenantProductAssortmentView: ${categoriesError.message}`
    );
  }

  // return
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      style={{ width: '100%' }}
    >
      <Container maxWidth='xs'>
        <Box mt={3}>
          <Header2
            title='Sortiment in Warengruppen.'
            subtitle={`Sie sind angemeldet als ${profile.email}`}
          />
        </Box>
        <Box mt={2}>
          {categoriesData && categoriesData.productcategory.length === 0 ? (
            <Box>
              <Typography
                component='h2'
                variant='subtitle1'
                mb={1}
                align='center'
              >
                Keine Warengruppen vorhanden. Bitte erstellen Sie erst die
                Warengruppen!
              </Typography>
            </Box>
          ) : (
            <>
              {categoriesData &&
                categoriesData.productcategory.map((productCategory: any) => (
                  <Paper
                    elevation={0}
                    sx={{ p: 1, mb: 0.3 }}
                    key={productCategory.id}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        pl: 1,
                      }}
                    >
                      <Typography variant='body1'>
                        {productCategory.categoryname}
                      </Typography>
                      <IconButton
                        color='inherit'
                        size='small'
                        onClick={() =>
                          navigate('/tenantProductList', {
                            state: {
                              storeId: routerLocation.state.storeId,
                              productCategoryId: productCategory.id,
                              productCategoryName: productCategory.categoryname,
                              helpopen: helpOpen,
                            },
                          })
                        }
                      >
                        <AiOutlineUnorderedList size='20' />
                      </IconButton>
                    </Box>
                  </Paper>
                ))}
            </>
          )}
        </Box>
        <Box
          mt={3}
          mb={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() =>
              navigate('/tenant', {
                state: { helpopen: helpOpen },
              })
            }
          >
            Ok
          </Button>
        </Box>
      </Container>
      {helpOpen ? (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
          }}
          style={{ backgroundColor: '#9E9E9E', width: '20%' }}
        >
          <Box
            sx={{
              paddingTop: 1,
              paddingLeft: 1.5,
            }}
          >
            <IconButton onClick={() => setHelpOpen(false)}>
              <AiOutlineClose size='24' />
            </IconButton>
          </Box>
          <TenantProductAssortmentHelpView />
        </Box>
      ) : (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
            paddingRight: 1.5,
            paddingTop: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => setHelpOpen(true)}>
              <AiOutlineQuestionCircle size='24' />
            </IconButton>
            <Typography
              onClick={() => setHelpOpen(true)}
              paragraph
              component='span'
              sx={{
                color: theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                  textDecoration: `underline ${theme.palette.text.primary}`,
                },
              }}
            >
              Hilfe
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TenantProductAssortmentView;
