import { FilterState } from './filterState';
import { FilterActions } from './filterActions';

export const filterReducer = (state: FilterState, action: FilterActions) => {
  switch (action.type) {
    case 'SET_FILTER':
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
};
