import { gql } from '@apollo/client';

// graphql insert favorite
export const ADD_FAVORITE = gql`
  mutation addFavorite($favorite: favorite_insert_input!) {
    insert_favorite_one(object: $favorite) {
      id
      customer_id
      store_id
      messages_on
    }
  }
`;

// graphql query customer favorite
export const GET_FAVORITE = gql`
  query getFavorite($customerid: Int!, $storeid: Int!) {
    favorite(
      where: {
        _and: [
          { customer_id: { _eq: $customerid } }
          { store: { id: { _eq: $storeid } } }
        ]
      }
    ) {
      id
      customer_id
      messages_on
      store {
        id
        storename
      }
    }
  }
`;

// graphql query customer favorites
export const GET_FAVORITES = gql`
  query getFavorites($customerid: Int!) {
    favorite(where: { customer_id: { _eq: $customerid } }) {
      id
      customer_id
      messages_on
      store {
        id
        storename
      }
    }
  }
`;

// graphql mutation delete favorite
export const REMOVE_FAVORITE = gql`
  mutation removeFavorite($id: Int!) {
    delete_favorite(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

// graphql mutation update favorite
export const UPDATE_FAVORITE = gql`
  mutation updateFavorite($id: Int!, $messages_on: Boolean!) {
    update_favorite(
      where: { id: { _eq: $id } }
      _set: { messages_on: $messages_on }
    ) {
      affected_rows
      returning {
        id
        customer_id
        messages_on
        store {
          id
          storename
        }
      }
    }
  }
`;

// graphql query regular customers
export const GET_REGULAR_CUSTOMERS = gql`
  query getRegularCustomers($storeid: Int!) {
    favorite(
      where: {
        _and: [
          { store: { id: { _eq: $storeid } } }
          { messages_on: { _eq: true } }
        ]
      }
    ) {
      customer {
        account {
          email
        }
      }
    }
  }
`;
