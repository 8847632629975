import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * FavoriteCustomerHowToView
 */
const FavoriteCustomerHowToView = () => {
  const navigate = useNavigate();

  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={3}>
        <Typography component='h1' variant='h4' align='center' gutterBottom>
          Favoriten verwalten - Kunden
        </Typography>
        <Typography component='h1' variant='h6' align='center' gutterBottom>
          Wie kann ich als registrierter Kunde meine Favoriten verwalten?
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography component='p' variant='body1' paragraph>
          Um Favoriten anzulegen und zu verwalten muss man ein registrierter
          Kunde sein.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Um Favoriten anzulegen muss man das Geschäft auswählen das als Favorit
          angelegt werden soll. Das erfolg indem man auf Geschäfte oben klickt
          und danach in der Sidebar links die Branche und das Geschäft auswählt.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Oben im Kopf der Seite steht der Name des Geschäfts und ein Herz-Icon.
          Auf das Icon klicken um das Geschäft zu den Favoriten hinzuzufügen. Es
          erscheint ein Dialog mit der Frage ob man das Geschäft zu den
          Favoriten hinzufügen möchte und der Option ob man über neue Angebote
          informiert werden möchte.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Falls Sie informiert werden möchten aktivieren Sie diese Option. Wenn
          diese Option aktiviert ist, hat Ihr Favorit die Möglichkeit Sie über
          neue Angebote zu informieren. Andernfalls haben Sie immer noch die
          Möglichkeit ganz schnell zum Favorit zu kommen, indem Sie oben auf den
          Namen des Geschäfts klicken.
        </Typography>
      </Box>
      <Box
        mt={3}
        mb={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type='submit'
          variant='contained'
          color='secondary'
          sx={{ borderRadius: 30, width: '240px' }}
          onClick={() => navigate('/help')}
        >
          Zurück
        </Button>
      </Box>
    </Container>
  );
};

export default FavoriteCustomerHowToView;
