import React from 'react';
import { Container, Box, Typography, Avatar, IconButton } from '@mui/material';
import { IoNewspaperOutline } from 'react-icons/io5';
import {
  AiOutlineMessage,
  AiOutlineLogin,
  AiOutlineClose,
} from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../authContext';
import Header2 from '../components/Header2';

/**
 * Startview des Customers
 */
const CustomerView = () => {
  const navigate = useNavigate();
  const [{ profile }] = useAuth();

  // return
  return (
    <Container maxWidth='xs'>
      <Box mt={3}>
        <Header2
          title='Account'
          subtitle={`Sie sind angemeldet als ${profile.email}`}
        />
      </Box>
      <Box mt={1} sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 100,
              width: 150,
            }}
          >
            <Avatar style={{ backgroundColor: '#00E676' }}>
              <IconButton onClick={() => navigate('/customerMessagesGroups')}>
                <AiOutlineMessage size='22' />
              </IconButton>
            </Avatar>
            <Typography variant='subtitle2' mt={1}>
              Nachrichten
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 100,
              width: 150,
            }}
          >
            <Avatar style={{ backgroundColor: '#FF4081' }}>
              <IconButton onClick={() => navigate('/customerNews')}>
                <IoNewspaperOutline size='22' />
              </IconButton>
            </Avatar>
            <Typography variant='subtitle2' mt={1}>
              News
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 100,
              width: 150,
            }}
          >
            <Avatar style={{ backgroundColor: '#FF6D00' }}>
              <IconButton onClick={() => navigate('/customerLogindata')}>
                <AiOutlineLogin size='22' />
              </IconButton>
            </Avatar>
            <Typography variant='subtitle2' mt={1}>
              Logindaten
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 100,
              width: 150,
            }}
          >
            <Avatar style={{ backgroundColor: '#ff1744' }}>
              <IconButton onClick={() => navigate('/customerDelete')}>
                <AiOutlineClose size='22' />
              </IconButton>
            </Avatar>
            <Typography variant='subtitle2' mt={1}>
              Account löschen
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default CustomerView;
