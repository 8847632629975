import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * StoreRegisterHowToView
 */
const StoreRegisterHowToView = () => {
  const navigate = useNavigate();

  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={3}>
        <Typography component='h1' variant='h4' align='center' gutterBottom>
          Geschäft registrieren
        </Typography>
        <Typography component='h1' variant='h6' align='center' gutterBottom>
          Wie kann ich mein Geschäft registrieren?
        </Typography>
      </Box>
      <Box>
        <Typography component='p' variant='body1' paragraph>
          Unten in der Spalte &quot;Für Unternehmen&quot; gibt es einen Link
          &quot;Geschäft registrieren&quot;. Klicken Sie auf den Link und Sie
          kommen auf die Seite &quot;Geschäft registrieren&quot;. Die
          Registrierung erfolgt in mehreren Schritten.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          1. Schritt: Beim ersten Schritt wählen Sie den Service den Sie buchen
          möchten, tragen die E-Mail-Adressen für die Rechnung und für die
          Anmeldung und Passwort ein. Passwort wiederholen und weiter klicken.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          2. Schritt: Im zweiten Schritt tragen Sie den Namen des Geschäfts, den
          Vor- und Nachnamen des Inhabers, die Adresse und Ust-IdNr ein und
          klicken auf weiter.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          3. Schritt: Der dritte Schritt stellt eine Liste der Branchen dar.
          Wählen Sie die Branche zu der Ihr Geschäft gehört und klicken Sie auf
          weiter.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          4. Schritt: Im vierten Schritt sehen Sie die Zusammenfassung Ihrer
          Eingabe. Überprüfen Sie Ihre Daten. Wenn alle Daten richtig sind
          klicken Sie auf Registrieren. Falls Sie etwas korrigieren müssen gehen
          Sie zurück zu dem Schritt bei dem Sie etwas korrigieren müssen und
          danach wieder weiter bis zu vierten Schritt und Registrieren klicken.
        </Typography>
      </Box>
      <Box
        mt={3}
        mb={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type='submit'
          variant='contained'
          color='secondary'
          sx={{ borderRadius: 30, width: '240px' }}
          onClick={() => navigate('/help')}
        >
          Zurück
        </Button>
      </Box>
    </Container>
  );
};

export default StoreRegisterHowToView;
