import { gql } from '@apollo/client';

// graphql insert customer
export const ADD_CUSTOMER = gql`
  mutation addCustomer($customer: customer_insert_input!) {
    insert_customer_one(object: $customer) {
      id
      account_id
    }
  }
`;

// graphql mutation delete customer
export const REMOVE_CUSTOMER = gql`
  mutation removeCustomer($email: String!) {
    delete_account(where: { email: { _eq: $email } }) {
      affected_rows
    }
  }
`;

