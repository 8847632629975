import { gql } from '@apollo/client';

// graphql insert news
export const SEND_NEWS = gql`
  mutation insert_multiple_news($objects: [news_insert_input!]!) {
    insert_news(objects: $objects) {
      returning {
        id
        store_id
        message_to
        message
        created
      }
    }
  }
`;

// graphql subscription subscribe news
export const SUBSCRIBE_NEWS = gql`
  subscription subscribeNews($email: String!) {
    news(where: { message_to: { _eq: $email } }) {
      id
      store {
        storename
      }
      message_to
      message
      created
    }
  }
`;

// graphql mutation delete news
export const REMOVE_NEWS = gql`
  mutation removeNews($id: Int!) {
    delete_news(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
