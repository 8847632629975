import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Tab,
  Typography,
  TextField,
  InputLabel,
  Button,
  IconButton,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  Select,
  RadioGroup,
  Radio,
  Checkbox,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ImageList,
  ImageListItem,
  CardMedia,
  Modal,
  Divider,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Header2 from '../components/Header2';
import { Buffer } from 'buffer';
import {
  AiOutlineEdit,
  AiOutlineSave,
  AiOutlineFileImage,
  AiOutlineFileAdd,
  AiOutlineDelete,
  AiOutlineQuestionCircle,
  AiOutlineClose,
} from 'react-icons/ai';
import { BiArrowBack } from 'react-icons/bi';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import {
  GET_ALL_PRODUCT_CATEGORIES_BY_STORE,
  GET_STORE_SERVICE,
  GET_STORE_STARTPRODUCTS_COUNT,
} from '../data/StoreData';
import {
  GET_PRODUCTDATA_BY_ID,
  ADD_PRODUCT,
  UPDATE_PRODUCT_BY_ID,
  REMOVE_PRODUCT,
  ADD_IMAGE,
  REMOVE_IMAGE,
  GET_PRODUCT_IMAGES,
  UPDATE_IMAGE_SORT_ORDER,
} from './queries/tenantQueries';
import { IInputError, IImage, IUploadImage } from '../interfaces/interfaces';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import minioClient from '../minioClient';
import {
  PutObjectCommand,
  DeleteObjectCommand,
  DeleteObjectsCommand,
} from '@aws-sdk/client-s3';
import Resizer from 'react-image-file-resizer';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Config } from '../config';
import TenantProductDetailHelpView from './help/TenantProductDetailHelpView';

/**
 * View zur Bearbeitung eines Produkts.
 */
const TenantProductDetailView = () => {
  const theme = useTheme();
  const matches600 = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const routerLocation: any = useRouterLocation();
  const [activeTab, setActiveTab] = useState<string>('data');
  const [categoryId, setCategoryId] = useState<number>(0);
  const [categoryName, setCategoryName] = useState<string>('');
  const [categoryError, setCategoryError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [storeId, setStoreId] = useState<number>(0);
  const [storeService, setStoreService] = useState<{
    servicename: string;
    actionnumber: number;
  }>({ servicename: '', actionnumber: 0 });
  const [startOnAvailable, setOnStartAvailable] = useState<number>(0);
  const [productId, setProductId] = useState<number>(0);
  const [productName, setProductName] = useState<string>('');
  const [productNameError, setProductNameError] = useState<
    Partial<IInputError>
  >({
    isError: false,
    message: '',
  });
  const [description, setDescription] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<
    Partial<IInputError>
  >({
    isError: false,
    message: '',
  });
  const [price, setPrice] = useState<string>('');
  const [priceError, setPriceError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [discount, setDiscount] = useState<number | null>(null);
  const [discountError, setDiscountError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [salesprice, setSalesprice] = useState<string | undefined>(undefined);
  const [productstatus, setProductstatus] = useState<string>('inactive');
  const [availability, setAvailability] = useState<string>('available');
  const [actionstatus, setActionstatus] = useState<string>('None');
  const [startOn, setStartOn] = useState<boolean>(false);
  const [availableImages, setAvailableImages] = useState<Array<IImage>>([]);
  const [activeImage, setActiveImage] = useState<Partial<IImage>>({
    id: 0,
    image: undefined,
    sort_order: undefined,
  });
  const [uploadImages, setUploadImages] = useState<Array<IUploadImage>>([]);
  const [isButtonSaveEnabled, setIsButtonSaveEnabled] = useState<boolean>(true);
  const [isProductNew, setIsProductNew] = useState<boolean>(false);
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
  const [checkDataTypeDialogVisible, setCheckDataTypeDialogVisible] =
    useState<boolean>(false);
  const [deleteImageDialogVisible, setDeleteImageDialogVisible] =
    useState<boolean>(false);
  const [checkImageDialogVisible, setCheckImageDialogVisible] =
    useState<boolean>(false);
  const [uploadImageDialogVisible, setUploadImageDialogVisible] =
    useState<boolean>(false);
  const [productInactivateDialogVisible, setProductInactivateDialogVisible] =
    useState<boolean>(false);
  const [productActivateDialogVisible, setProductActivateDialogVisible] =
    useState<boolean>(false);
  const [productDeleteDialogVisible, setProductDeleteDialogVisible] =
    useState<boolean>(false);
  const [productStartOnDialogVisible, setProductStartOnDialogVisible] =
    useState<boolean>(false);
  const [helpOpen, setHelpOpen] = useState<boolean>(
    routerLocation.state.helpopen
  );

  // MEDIA_URL setzen
  const MEDIA_URL: any =
    process.env.REACT_APP_MINIO_ENDPOINT !== undefined
      ? process.env.REACT_APP_MINIO_ENDPOINT
      : Config.minio_endpoint;

  // getProductCategories
  const { data: categoriesData } = useQuery(
    GET_ALL_PRODUCT_CATEGORIES_BY_STORE,
    {
      variables: { storeid: routerLocation.state.storeId },
      onError: (error) => {
        console.log(
          `Error! TenantProductDetailView - getProductCategories: ${error.message}`
        );
      },
    }
  );

  // getImages
  const [getImages, { data: imagesData }] = useLazyQuery(GET_PRODUCT_IMAGES, {
    variables: { productid: productId },
    fetchPolicy: 'network-only',
    onError: (error) => {
      console.log(
        `Error! TenantProductDetailView - getImages: ${error.message}`
      );
    },
  });

  // updateImageSortOrder
  const [updateImageSortOrder] = useMutation(UPDATE_IMAGE_SORT_ORDER);

  // getProductdata
  useQuery(GET_PRODUCTDATA_BY_ID, {
    variables: { productid: routerLocation.state.productId },
    skip: routerLocation.state.productId === undefined,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setProductId(data.merkantdo_productdata[0].id);
      setProductName(data.merkantdo_productdata[0].productname);
      setCategoryId(data.merkantdo_productdata[0].productcategory_id);
      setCategoryName(data.merkantdo_productdata[0].categoryname);
      setStoreId(data.merkantdo_productdata[0].store_id);
      setProductstatus(data.merkantdo_productdata[0].productstatus);
      setActionstatus(data.merkantdo_productdata[0].actionstatus);
      setStartOn(data.merkantdo_productdata[0].start_on);
      setAvailability(data.merkantdo_productdata[0].availability);
      setDescription(data.merkantdo_productdata[0].description);
      setPrice(data.merkantdo_productdata[0].price);
      setDiscount(data.merkantdo_productdata[0].discount);
      getImages();
    },
    onError: (error) => {
      console.log(
        `Error! TenantProductDetailView - getProductdata: ${error.message}`
      );
    },
  });

  // addProduct
  const [addProduct] = useMutation(ADD_PRODUCT, {
    onCompleted: (data) => {
      setProductId(data.insert_product_one.id);
      setIsProductNew(false);
    },
    onError: (error) => {
      console.log(
        `Error! TenantProductDetailView - addProduct: ${error.message}`
      );
    },
  });

  // updateProduct
  const [updateProduct] = useMutation(UPDATE_PRODUCT_BY_ID, {
    onError: (error) => {
      console.log(
        `Error! TenantProductDetailView - updateProduct: ${error.message}`
      );
    },
  });

  // removeProduct
  const [removeProduct] = useMutation(REMOVE_PRODUCT, {
    onError: (error) => {
      console.log(
        `Error! TenantProductDetailView - removeProduct: ${error.message}`
      );
    },
  });

  // addImage
  const [addImage] = useMutation(ADD_IMAGE, {
    onError: (error) => {
      console.log(
        `Error! TenantProductDetailView - addImage: ${error.message}`
      );
    },
  });

  // removeImage
  const [removeImage] = useMutation(REMOVE_IMAGE, {
    onError: (error) => {
      console.log(
        `Error! TenantProductDetailView - removeImage: ${error.message}`
      );
    },
  });

  // geStoreStartProductsCount
  useQuery(GET_STORE_STARTPRODUCTS_COUNT, {
    variables: { storeid: routerLocation.state.storeId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setOnStartAvailable(data.product_aggregate.aggregate.totalCount);
    },
    onError: (error) => {
      console.log(
        `Error! TenantProductDetailView - geStoreStartProductsCount: ${error.message}`
      );
    },
  });

  // getStoreService
  useQuery(GET_STORE_SERVICE, {
    variables: { storeid: routerLocation.state.storeId },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setStoreService(data.store[0].storeservices[0].servicetype);
    },
    onError: (error) => {
      console.log(
        `Error! TenantProductDetailView - getStoreService: ${error.message}`
      );
    },
  });

  /**
   * Imagedaten holen und setzen.
   */
  useEffect(() => {
    if (imagesData) {
      if (imagesData.image.length > 0) {
        setAvailableImages(imagesData.image);
        setActiveImage({
          id: imagesData.image[0].id,
          image: imagesData.image[0].image,
        });
      } else {
        setAvailableImages([]);
      }
    }
  }, [imagesData]);

  /**
   * Salesprice setzen.
   */
  useEffect(() => {
    let tempSalesprice: any = null;
    if (actionstatus === 'Sale' && discount !== null) {
      tempSalesprice = (
        Math.round(
          /// eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /// @ts-ignore
          (parseFloat(price.trim()).toFixed(2) -
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (parseFloat(price.trim()).toFixed(2) / 100) * discount!) *
            100
        ) / 100
      ).toFixed(2);
      setSalesprice(tempSalesprice.replace('.', ','));
    } else {
      setSalesprice(undefined);
    }
  }, [actionstatus, discount, price]);

  /**
   * Handling Save Button Status.
   */
  useEffect(() => {
    if (
      categoryId === 0 ||
      productName === '' ||
      description === '' ||
      price === '' ||
      categoryError.isError ||
      productNameError.isError ||
      descriptionError.isError ||
      priceError.isError ||
      discountError.isError ||
      !isDataChanged
    ) {
      setIsButtonSaveEnabled(false);
    } else {
      setIsButtonSaveEnabled(true);
    }
  }, [
    categoryId,
    description,
    price,
    categoryError.isError,
    productNameError.isError,
    descriptionError.isError,
    priceError.isError,
    discountError.isError,
    isDataChanged,
    productName,
  ]);

  /**
   * Beim neuen Product die Felder auf default setzen.
   */
  const newProduct = () => {
    setIsProductNew(true);
    setActiveImage({ id: 0, image: undefined });
    if (routerLocation.state.action === 'newProduct') {
      setStoreId(routerLocation.state.storeId);
      setCategoryId(routerLocation.state.productCategoryId);
    }
    setProductId(0);
    setStartOn(false);
    setProductName('');
    setDescription('');
    setPrice('');
    setDiscount(null);
    setProductstatus('inactive');
    setAvailability('available');
    setActionstatus('None');
    setAvailableImages([]);
    setActiveTab('data');
  };

  /**
   * Falls eine action 'newProduct' gesendet wird.
   */
  useEffect(() => {
    if (routerLocation.state.action === 'newProduct') {
      newProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerLocation.state.action]);

  /**
   * Wenn Tab geändert wird.
   */
  const handleChangeTab = (event: any, value: string) => {
    setActiveTab(value);
  };

  /**
   * Wenn Category geändert wird.
   */
  const handleCategoryInput = (e: any) => {
    setCategoryId(e.target.value);
    setIsDataChanged(true);
  };

  /**
   * Category Input Validierung
   */
  const checkCategoryInput = () => {
    if (categoryId === 0) {
      setCategoryError({
        isError: true,
        message: 'Auswahl ist erforderlich!',
      });
    } else {
      setCategoryError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Wenn Productname geändert wird.
   */
  const handleProductnameInput = (e: any) => {
    setProductName(e.target.value);
    setIsDataChanged(true);
  };

  /**
   * Productname Input Validierung
   */
  const checkProductnameInput = () => {
    if (productName === '') {
      setProductNameError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    } else {
      setProductNameError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Wenn Description geändert wird.
   */
  const handleDescriptionInput = (event: any) => {
    setDescription(event.target.value);
    setIsDataChanged(true);
  };

  /**
   * Description Input Validierung
   */
  const checkDescriptionInput = () => {
    if (description === '') {
      setDescriptionError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    } else {
      setDescriptionError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Wenn Price geändert wird.
   */
  const handlePriceInput = (event: any) => {
    const regexp = /^[0-9\b]\d*(\.[0-9]{0,2})|[\b]?$/;
    //const regexp = /^0,0[1-9]|0,[1-9][0-9]|[0-9]\d*(,[0-9]{0,2})|[\b]?$/;
    if (event.target.value === '' || regexp.test(event.target.value)) {
      setPrice(event.target.value);
      setIsDataChanged(true);
    } else {
      setPriceError({
        isError: true,
        message: 'Bitte nur Ziffern eingeben!',
      });
    }
  };

  /**
   * Price Input Validierung
   */
  const checkPriceInput = () => {
    if (price === '') {
      setPriceError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    } else {
      setPriceError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Wenn Discount geändert wird.
   */
  const handleDiscountInput = (event: any) => {
    const regexp = /^[0-9\b]\d*?$/;
    //const regexp = /^[0-9\b]\d*(\.[0-9]{0,2})?$/;
    if (event.target.value === '' || regexp.test(event.target.value)) {
      setDiscount(event.target.value);
      setIsDataChanged(true);
    } else {
      setDiscountError({
        isError: true,
        message: 'Bitte nur Ziffern eingeben!',
      });
    }
  };

  /**
   * Discount Input Validierung
   */
  const checkDiscountInput = () => {
    if (discount === null) {
      setDiscountError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    } else if (discount <= 1 || discount >= 100) {
      setDiscountError({
        isError: true,
        message: 'Eingabe muss größer 0 und kleiner 100 sein!',
      });
    } else {
      setDiscountError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Wenn Productstatus geändert wird, wird überprüft
   * ob die Anzahl der Produkte die Beschränkungen durch
   * den Service nicht übersteigt.
   */
  const handleProductStatusInput = (event: any) => {
    if (startOn === true && startOnAvailable === storeService.actionnumber) {
      setProductStartOnDialogVisible(true);
    } else {
      if (availableImages.length === 0 && event.target.value === 'active') {
        setProductActivateDialogVisible(true);
      } else {
        setProductstatus(event.target.value);
        setIsDataChanged(true);
      }
    }
  };

  /**
   * Wenn Availability geändert wird.
   */
  const handleAvailabilityInput = (event: any) => {
    setAvailability(event.target.value);
    setIsDataChanged(true);
  };

  /**
   * Wenn Actionstatus geändert wird.
   */
  const handleActionstatusInput = (event: any) => {
    if (event.target.value !== 'Sale') {
      setDiscount(null);
    }
    setActionstatus(event.target.value);
    setIsDataChanged(true);
  };

  /**
   * Insert new Product.
   */
  const insertProduct = async () => {
    await addProduct({
      variables: {
        product: {
          id: undefined,
          store_id: storeId,
          productcategory_id: categoryId,
          productstatus,
          availability,
          actionstatus,
          start_on: startOn,
          productname: productName,
          description,
          price: parseFloat(price.replace(',', '.')).toFixed(2),
          discount,
        },
      },
    });
  };

  /**
   * Update Product
   */
  const saveProduct = async () => {
    await updateProduct({
      variables: {
        id: productId,
        productcategory_id: categoryId,
        productstatus,
        availability,
        actionstatus,
        start_on: startOn,
        productname: productName,
        description,
        price: parseFloat(price.replace(',', '.')).toFixed(2),
        discount,
      },
    });
    setIsDataChanged(false);
  };

  /**
   * v3 Funktion zum Löschen eines Images.
   */
  const handleImageDelete = async () => {
    const bucket = storeId + '-data';
    const params = {
      Bucket: bucket,
      Key: activeImage.image,
    };
    try {
      // remove image from minio
      await minioClient.send(new DeleteObjectCommand(params));
      // remove image from database
      await removeImage({
        variables: { id: activeImage.id },
        refetchQueries: [
          {
            query: GET_PRODUCT_IMAGES,
            variables: { productid: productId },
            fetchPolicy: 'network-only',
          },
        ],
      });
      if (availableImages.length === 1) {
        setProductstatus('inactive');
        // save Product
        await updateProduct({
          variables: {
            id: productId,
            productcategory_id: categoryId,
            productstatus: 'inactive',
            availability,
            actionstatus,
            start_on: startOn,
            productname: productName,
            description,
            price: parseFloat(price).toFixed(2),
            discount,
          },
        });
        setProductInactivateDialogVisible(true);
      }
    } catch (error) {
      console.log('Error! Unable to remove image: ' + error);
    }
    await getImages();
  };

  /**
   * Dialog für das Löschen eines Images.
   */
  const handleDeleteImageDialog = (action: string) => {
    if (action === 'delete') {
      handleImageDelete();
    }
    setDeleteImageDialogVisible(false);
  };

  /**
   * ArrayBuffer für Minio Upload erzeugen.
   */
  const getArrayBuffer = (file: any) => {
    return new Promise(function (resolve) {
      const reader: FileReader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  /**
   * DataURL für die Darstellung im Dialog.
   */
  const getBase64 = (file: any) => {
    return new Promise(function (resolve) {
      const reader: FileReader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  };

  /**
   * Image Resizer
   */
  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        400,
        'JPEG',
        70,
        0,
        (uri) => {
          resolve(uri);
        },
        'blob'
      );
    });

  /**
   * Upload Images mit FileReader als ArrayBuffer.
   * ArrayBufffer wird zum Minio Server als Image hochgeladen.
   */
  const handleFileImagesUpload = async (event: any) => {
    const files = event.target.files;
    const newImages = [...uploadImages];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const imageType = 'image/jpeg|image/jpg|image/png|image/webp';
      if (file.type.match(imageType)) {
        const resizedFile = await resizeFile(file);
        const newImage = {
          name: file.name,
          type: file.type,
          data: await getArrayBuffer(resizedFile),
          url: await getBase64(resizedFile),
        };
        if (availableImages.length + newImages.length < 8) {
          newImages.push(newImage);
        } else {
          setCheckImageDialogVisible(true);
        }
      } else {
        setCheckDataTypeDialogVisible(true);
      }
    }
    setUploadImages(newImages);
  };

  /**
   * Remove Image aus der Auswahl.
   */
  const handleImageRemove = (index: number | Array<number>): void => {
    const updatedList = [...uploadImages];
    if (Array.isArray(index)) {
      index.forEach((i) => {
        updatedList.splice(i, 1);
      });
    } else {
      updatedList.splice(index, 1);
    }
    setUploadImages(updatedList);
  };

  /**
   * v3 Image Upload zum Minio Server
   * und Insert in die Datenbank.
   */
  const handleImagesUpload = async () => {
    const bucket = storeId + '-data';
    for (let i = 0; i < uploadImages.length; i++) {
      const buffer = Buffer.from(uploadImages[i].data, 'base64');
      let params = {
        Bucket: bucket,
        Key: uploadImages[i].name!,
        Body: buffer,
        ContentType: uploadImages[i].type,
      };
      // uploadImage in minio server
      try {
        await minioClient.send(new PutObjectCommand(params));
        // inserImage in database
        try {
          await addImage({
            variables: {
              image: {
                id: undefined,
                product_id: productId,
                image: uploadImages[i].name!,
                sort_order: availableImages.length + i + 1,
              },
            },
          });
        } catch (error) {
          console.log('Error! Unable insert image in database: ' + error);
        }
      } catch (error) {
        console.log('Error! Unable to upload image: ' + error);
      }
    }
    await getImages();
  };

  /**
   * v3 Ein Produkt und seine Bilder löschen.
   */
  const handleProductDelete = async () => {
    const tempImages: Array<any> = [];
    for (let i = 0; i < availableImages.length; i++) {
      tempImages.push({
        Key: availableImages[i].image,
      });
    }
    const bucket = storeId + '-data';
    const params = {
      Bucket: bucket,
      Delete: {
        Objects: tempImages,
      },
    };
    try {
      if (tempImages.length > 0) {
        await minioClient.send(new DeleteObjectsCommand(params));
      }
      removeProduct({
        variables: { id: productId },
      });
      navigate('/tenantProductList', {
        state: {
          storeId: storeId,
          productCategoryId: categoryId,
          productCategoryName: categoryName,
        },
      });
    } catch (error) {
      console.log('Error! deleteObjects: ', error);
    }
  };

  /**
   * Hier wird überprüft ob der Service mit der Anzahl
   * der Startprodukte nich übersteigt.
   */
  const handleStartProducts = (value: boolean) => {
    if (value === false && startOnAvailable === storeService.actionnumber) {
      setProductStartOnDialogVisible(true);
    } else {
      setStartOn(!startOn);
      setIsDataChanged(true);
    }
  };

  /**
   * Funktion zu speicher von Änderungen oder
   * eines neuangelegten Products.
   */
  const hanglePoductSave = async () => {
    if (isProductNew === true) {
      try {
        await insertProduct();
      } catch (error) {
        console.log('Error! TenantProductDetailView - inserProduct:', error);
      }
    } else {
      try {
        await saveProduct();
      } catch (error) {
        console.log('Error! TenantProductDetailView - saveProduct:', error);
      }
    }
  };

  /**
   * reorder images
   *
   * @param availableImages
   * @param startIndex
   * @param endIndex
   * @returns
   */
  const reorder = (availableImages, startIndex, endIndex) => {
    const result: any = Array.from(availableImages);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * sort_order setzen und
   * in der datenbank speichern.
   *
   * @param param0
   */
  const handleDragEnd = ({ destination, source }) => {
    const reorderedImages = reorder(
      availableImages,
      source.index,
      destination.index
    );
    let tempImages: Array<IImage> = [];
    for (let i = 0; i < reorderedImages.length; i++) {
      let tempImage: any;
      if (reorderedImages[i].sort_order !== i + 1) {
        tempImage = { ...reorderedImages[i] };
        tempImage = { ...tempImage, sort_order: i + 1 };
        updateImageSortOrder({
          variables: {
            id: tempImage.id,
            sort_order: tempImage.sort_order,
          },
        });
      }
      if (reorderedImages[i].sort_order === i + 1) {
        tempImage = reorderedImages[i];
      }
      tempImages.push(tempImage);
    }
    setAvailableImages(tempImages);
  };

  // return
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      style={{ width: '100%' }}
    >
      <Container maxWidth='xs'>
        {/** Upload Image Dialog */}
        <Box>
          <Modal
            open={uploadImageDialogVisible}
            onClose={() => setUploadImageDialogVisible(false)}
            aria-labelledby='upload-image-modal-title'
            aria-describedby='upload-image-modal-description'
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '49.5%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                height: 440,
                bgcolor: 'background.paper',
                p: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography
                  id='upload-image-modal-title'
                  variant='h6'
                  component='h2'
                  align='center'
                >
                  Bilder hochladen
                </Typography>
                {/** Card Box */}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-around',
                  }}
                >
                  {uploadImages.map((image, index) => {
                    return (
                      <Box mt={1} key={index}>
                        <Box
                          mt={1}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img src={image.url} alt='' width='90' height='120' />
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginTop: 1,
                            }}
                          >
                            <Button
                              variant='outlined'
                              color='inherit'
                              size='small'
                              onClick={() => handleImageRemove(index)}
                            >
                              Entfernen
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                {/** Card Box end */}
              </Box>
              {/** Action Box */}
              <Box
                mt={1}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <FormControl>
                  <input
                    accept='image/*'
                    id='imgFiles'
                    style={{ display: 'none' }}
                    multiple
                    type='file'
                    onChange={handleFileImagesUpload}
                  />
                  <label htmlFor='imgFiles'>
                    <Button
                      variant='outlined'
                      color='inherit'
                      size='small'
                      component='span'
                    >
                      Bild auswählen
                    </Button>
                  </label>
                </FormControl>
                <Button
                  variant='outlined'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    handleImagesUpload();
                    setUploadImages([]);
                    setUploadImageDialogVisible(false);
                  }}
                >
                  Bilder hochladen
                </Button>
                <Button
                  variant='outlined'
                  color='inherit'
                  size='small'
                  onClick={() => setUploadImages([])}
                >
                  Alle löschen
                </Button>
                <Button
                  variant='outlined'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setUploadImages([]);
                    setUploadImageDialogVisible(false);
                  }}
                >
                  Abbrechen
                </Button>
              </Box>
              {/** Action Box end */}
            </Box>
          </Modal>
        </Box>
        {/** Upload Image Modal end */}
        {/** Product StartOn Dialog */}
        <Dialog
          open={productStartOnDialogVisible}
          onClose={() => setProductStartOnDialogVisible(false)}
          aria-labelledby='product-starton-dialog-title'
          aria-describedby='product-starton-dialog-description'
        >
          <DialogTitle id='product-starton-dialog-title'>
            Artikel auf der Startseite anzeigen nicht möglich!
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='product-inactivate-dialog-description'>
              Der Artikel kann nicht auf der Startseite angezeigt werden. Die
              Anzahl der möglichen Artikel auf der Startseite beim{' '}
              {storeService.servicename} ist auf {storeService.actionnumber}{' '}
              begrenzt.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setProductStartOnDialogVisible(false)}
              variant='outlined'
              color='inherit'
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        {/** Product StartOn Dialog end */}
        {/** Product Activate Dialog */}
        <Dialog
          open={productActivateDialogVisible}
          onClose={() => setProductActivateDialogVisible(false)}
          aria-labelledby='product-inactivate-dialog-title'
          aria-describedby='product-inactivate-dialog-description'
        >
          <DialogTitle id='product-inactivate-dialog-title'>
            Artikel aktivieren.
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='product-inactivate-dialog-description'>
              Der Artikel kann nicht aktiviert werden, solange keine Bilder
              vorhanden sind.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setProductActivateDialogVisible(false)}
              variant='outlined'
              color='inherit'
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        {/** Product Activate Dialog end */}
        {/** Product Inactivate Dialog */}
        <Dialog
          open={productInactivateDialogVisible}
          onClose={() => setProductInactivateDialogVisible(false)}
          aria-labelledby='product-inactivate-dialog-title'
          aria-describedby='product-inactivate-dialog-description'
        >
          <DialogTitle id='product-inactivate-dialog-title'>
            Artikel deaktiviert!
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='product-inactivate-dialog-description'>
              Es sind keine Bilder vorhanden. Der Artikel wurde deaktiviert!
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setProductInactivateDialogVisible(false)}
              variant='outlined'
              color='inherit'
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        {/** Product Inactivate Dialog end */}
        {/** Product Delete Dialog */}
        <Dialog
          open={productDeleteDialogVisible}
          onClose={() => setProductDeleteDialogVisible(false)}
          aria-labelledby='delete-product-dialog-title'
          aria-describedby='delete-product-dialog-description'
        >
          <DialogTitle id='delete-messages-dialog-title'>
            Soll der Artikel gelöscht werden?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='delete-messages-dialog-description'>
              {' "'}
              {productName}
              {'" '}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => {
                setProductDeleteDialogVisible(false);
              }}
              color='inherit'
              variant='outlined'
            >
              Abbrechen
            </Button>
            <Button
              onClick={() => {
                handleProductDelete();
                setProductDeleteDialogVisible(false);
              }}
              color='inherit'
              variant='outlined'
              autoFocus
            >
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
        {/** Product Delete Dialog end */}
        {/** Check Data Type Dialog */}
        <Dialog
          open={checkDataTypeDialogVisible}
          onClose={() => setCheckDataTypeDialogVisible(false)}
          aria-labelledby='check-data-type-dialog-title'
          aria-describedby='check-data-type-dialog-description'
        >
          <DialogTitle id='check-data-type-dialog-title'>
            Falscher Datentyp!
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='check-data-type-dialog-description'>
              Der Datentyp wird nicht unterstützt!
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setCheckDataTypeDialogVisible(false)}
              variant='outlined'
              color='inherit'
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        {/** Check Data Type Dialog end */}
        {/** Delete Image Dialog */}
        <Dialog
          open={deleteImageDialogVisible}
          onClose={() => setDeleteImageDialogVisible(false)}
          aria-labelledby='delete-image-dialog-title'
          aria-describedby='delete-image-dialog-description'
        >
          <DialogTitle sx={{ alignSelf: 'center' }} id='delete-dialog-title'>
            Bild löschen!
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ alignSelf: 'center' }}
              id='delete-image-dialog-description'
            >
              Soll das Bild gelöscht werden?
            </DialogContentText>
            <Box
              mt={2}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CardMedia
                sx={{ height: 120, width: 90 }}
                image={MEDIA_URL + '/' + storeId + '-data/' + activeImage.image}
              />
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => handleDeleteImageDialog('cancel')}
              color='inherit'
              variant='outlined'
            >
              Abbrechen
            </Button>
            <Button
              onClick={() => handleDeleteImageDialog('delete')}
              color='inherit'
              variant='outlined'
              autoFocus
            >
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
        {/** Delete Image Dialog end */}
        {/** Check Image Dialog */}
        <Dialog
          open={checkImageDialogVisible}
          onClose={() => setCheckImageDialogVisible(false)}
          aria-labelledby='check-image-dialog-title'
          aria-describedby='check-image-dialog-description'
        >
          <DialogTitle id='check-dialog-title'>
            Bild hinzufügen nicht möglich!
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='check-image-dialog-description'>
              Es sind höchstens 8 Bilder pro Artikel möglich.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setCheckImageDialogVisible(false)}
              variant='outlined'
              color='inherit'
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        {/** Check Image Dialog end */}
        {/** Header */}
        <Box mt={3}>
          <Header2
            title={isProductNew ? 'Artikel erstellen' : 'Artikel bearbeiten'}
          />
        </Box>
        {/** Header end */}
        {/** Tab Context */}
        <TabContext value={activeTab}>
          {/** Tab Navigation */}
          <Box
            mt={2}
            pl={2}
            pr={2}
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <TabList
              onChange={handleChangeTab}
              aria-label='Product tabs'
              textColor='secondary'
              indicatorColor='secondary'
            >
              <Tab sx={{ minWidth: '50%' }} label='Daten' value='data' />
              <Tab
                sx={{ minWidth: '50%' }}
                label='Bilder'
                value='images'
                disabled={productId === 0}
              />
            </TabList>
          </Box>
          {/** Tab Navigation end */}
          {/** Data Content */}
          <TabPanel value='data' sx={{ p: 0 }}>
            <Box p={2} sx={{ backgroundColor: theme.palette.primary.main }}>
              <FormControl sx={{ width: '100%', mt: 1 }}>
                <InputLabel
                  id='select-productcategory-label'
                  color='secondary'
                  margin='dense'
                  variant='standard'
                >
                  Warengruppe *
                </InputLabel>
                <Select
                  labelId='select-productcategory-label'
                  id='select-productcategory'
                  variant='standard'
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                  }}
                  value={categoryId}
                  onChange={(event) => handleCategoryInput(event)}
                  onBlur={() => checkCategoryInput()}
                >
                  {categoriesData.productcategory.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.categoryname}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText error={categoryError.isError}>
                  {categoryError.message}
                </FormHelperText>
              </FormControl>
              <TextField
                id='productName'
                name='productName'
                sx={{ width: '100%' }}
                variant='standard'
                color='secondary'
                margin='dense'
                required
                inputProps={{
                  sx: {
                    '&:-webkit-autofill': {
                      WebkitBoxShadow: `0 0 0 1000px ${theme.palette.primary.main} inset`,
                      WebkitTextFillColor: theme.palette.text.primary,
                    },
                  },
                }}
                label='Artikelname'
                value={productName}
                error={productNameError.isError}
                helperText={productNameError.message}
                FormHelperTextProps={{
                  sx: { color: theme.palette.secondary.main },
                }}
                onChange={handleProductnameInput}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    checkProductnameInput();
                  }
                }}
                onBlur={checkProductnameInput}
              />
              <TextField
                id='description'
                name='description'
                multiline
                //rows={4}
                sx={{ width: '100%' }}
                variant='standard'
                color='secondary'
                margin='dense'
                required
                label='Beschreibung'
                value={description}
                error={descriptionError.isError}
                helperText={descriptionError.message}
                FormHelperTextProps={{
                  sx: { color: theme.palette.secondary.main },
                }}
                onChange={handleDescriptionInput}
                onBlur={checkDescriptionInput}
              />
              {actionstatus === 'Sale' ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    width: '100%',
                  }}
                >
                  <TextField
                    sx={{ width: '49%' }}
                    id='price'
                    name='price'
                    variant='standard'
                    color='secondary'
                    margin='dense'
                    required
                    inputProps={{
                      sx: {
                        '&:-webkit-autofill': {
                          WebkitBoxShadow: `0 0 0 1000px ${theme.palette.primary.main} inset`,
                          WebkitTextFillColor: theme.palette.text.primary,
                        },
                      },
                    }}
                    label='Alter Preis'
                    value={price.trim()}
                    error={priceError.isError}
                    helperText={priceError.message}
                    FormHelperTextProps={{
                      sx: { color: theme.palette.secondary.main },
                    }}
                    onChange={handlePriceInput}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        checkPriceInput();
                      }
                    }}
                    onBlur={checkPriceInput}
                  />
                  <TextField
                    id='discount'
                    name='discount'
                    sx={{ width: '49%' }}
                    variant='standard'
                    color='secondary'
                    margin='dense'
                    inputProps={{
                      sx: {
                        '&:-webkit-autofill': {
                          WebkitBoxShadow: `0 0 0 1000px ${theme.palette.primary.main} inset`,
                          WebkitTextFillColor: theme.palette.text.primary,
                        },
                      },
                    }}
                    label='Rabatt in %'
                    value={discount}
                    error={discountError.isError}
                    helperText={discountError.message}
                    FormHelperTextProps={{
                      sx: { color: theme.palette.secondary.main },
                    }}
                    onChange={handleDiscountInput}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        checkDiscountInput();
                      }
                    }}
                    onBlur={checkDiscountInput}
                  />
                </Box>
              ) : (
                <TextField
                  id='price'
                  name='price'
                  sx={{ width: '100%' }}
                  variant='standard'
                  color='secondary'
                  margin='dense'
                  required
                  inputProps={{
                    sx: {
                      '&:-webkit-autofill': {
                        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.primary.main} inset`,
                        WebkitTextFillColor: theme.palette.text.primary,
                      },
                    },
                  }}
                  label='Preis'
                  value={price.trim()}
                  error={priceError.isError}
                  helperText={priceError.message}
                  FormHelperTextProps={{
                    sx: { color: theme.palette.secondary.main },
                  }}
                  onChange={handlePriceInput}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      checkPriceInput();
                    }
                  }}
                  onBlur={checkPriceInput}
                />
              )}
              {actionstatus === 'Sale' ? (
                <Box>
                  <TextField
                    id='salesprice'
                    name='salesprice'
                    sx={{ width: '100%' }}
                    variant='standard'
                    color='secondary'
                    margin='dense'
                    label='Neuer Preis'
                    value={salesprice}
                    InputLabelProps={{
                      shrink: salesprice !== undefined ? true : false,
                    }}
                    inputProps={{ readOnly: true }}
                  />
                </Box>
              ) : (
                <Box />
              )}
              <FormControl component='fieldset' sx={{ width: '100%' }}>
                <FormLabel
                  sx={{
                    color: theme.palette.text.disabled,
                    marginTop: theme.spacing(1),
                    fontSize: 10,
                    '&.Mui-focused': {
                      color: theme.palette.secondary.main,
                      fontSize: 10,
                    },
                  }}
                  required
                  color='secondary'
                >
                  Aktionen
                </FormLabel>
                <RadioGroup
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  aria-label='actionstatus'
                  name='actionstatus'
                  value={actionstatus}
                  onChange={handleActionstatusInput}
                >
                  <FormControlLabel
                    value='None'
                    control={<Radio color='secondary' />}
                    label='Keine'
                  />
                  <FormControlLabel
                    value='New'
                    control={<Radio color='secondary' />}
                    label='Neu'
                  />
                  <FormControlLabel
                    value='Top'
                    control={<Radio color='secondary' />}
                    label='Top'
                  />
                  <FormControlLabel
                    value='Sale'
                    control={<Radio color='secondary' />}
                    label='Reduziert'
                  />
                </RadioGroup>
              </FormControl>
              <Divider
                sx={{
                  borderBottomWidth: '1px',
                  background: theme.palette.text.disabled,
                }}
              />
              <FormControl
                component='fieldset'
                sx={{ width: '100%', marginBottom: 1 }}
              >
                <FormLabel
                  sx={{
                    color: theme.palette.text.disabled,
                    marginTop: theme.spacing(1),
                    fontSize: 10,
                    '&.Mui-focused': {
                      color: theme.palette.secondary.main,
                      fontSize: 10,
                    },
                  }}
                  required
                  color='secondary'
                >
                  Verfügbarkeit
                </FormLabel>
                <RadioGroup
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  aria-label='availability'
                  name='availability'
                  value={availability}
                  onChange={handleAvailabilityInput}
                >
                  <FormControlLabel
                    value='available'
                    control={<Radio color='secondary' />}
                    label='Vorhanden'
                  />
                  <FormControlLabel
                    value='reserved'
                    control={<Radio color='secondary' />}
                    label='Reserviert'
                  />
                  <FormControlLabel
                    value='soldout'
                    control={<Radio color='secondary' />}
                    label='Ausverkauft'
                  />
                </RadioGroup>
              </FormControl>
              <Divider
                sx={{
                  borderBottomWidth: '1px',
                  background: theme.palette.text.disabled,
                }}
              />
              <FormControl>
                <FormLabel
                  sx={{
                    color: theme.palette.text.disabled,
                    marginTop: theme.spacing(1),
                    fontSize: 10,
                    '&.Mui-focused': {
                      color: theme.palette.secondary.main,
                      fontSize: 10,
                    },
                  }}
                  required
                  color='secondary'
                >
                  Startseite
                </FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={startOn}
                      checked={startOn}
                      onChange={() => handleStartProducts(startOn)}
                      color='secondary'
                    />
                  }
                  label='Auf der Startseite anzeigen?'
                />
              </FormControl>
              <Divider
                sx={{
                  borderBottomWidth: '1px',
                  background: theme.palette.text.disabled,
                }}
              />
            </Box>
          </TabPanel>
          {/** Data Content end */}
          {/** Images Content */}
          <TabPanel value='images' sx={{ p: 0 }}>
            {/** Außenbox */}
            <Box
              pt={0.7}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                padding: 0,
                backgroundColor: theme.palette.primary.main,
              }}
            >
              {/** Left Box */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '40px',
                }}
              >
                {/** Image List Box */}
                <Box>
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId='droppable'>
                      {(provided) => (
                        <ImageList
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            mt: 0,
                            width: 40,
                          }}
                          cols={1}
                          rowHeight={40}
                        >
                          {availableImages.length === 0 ? (
                            <ImageListItem
                              sx={{
                                marginLeft: 0.2,
                                marginRight: 0.2,
                                marginBottom: 0.2,
                              }}
                            >
                              <img src='/images/placeholder.png' alt='' />
                            </ImageListItem>
                          ) : (
                            <Box>
                              {availableImages.map((image: IImage, index) => (
                                <Draggable
                                  key={image.id}
                                  draggableId={image.id.toString()}
                                  index={index}
                                >
                                  {(provided) => (
                                    <ImageListItem
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      key={image.id}
                                      sx={{
                                        border:
                                          activeImage.id === image.id ? 1 : 0,
                                        color: theme.palette.secondary.main,
                                        marginLeft: 0.2,
                                        marginRight: 0.2,
                                        marginBottom: 0.2,
                                      }}
                                    >
                                      <img
                                        id={image.id.toString()}
                                        src={`${
                                          MEDIA_URL +
                                          '/' +
                                          storeId +
                                          '-data/' +
                                          image.image
                                        }`}
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                        }}
                                        loading='lazy'
                                        onMouseOver={() => {
                                          setActiveImage({
                                            id: image.id,
                                            image: image.image,
                                            sort_order: image.sort_order,
                                          });
                                        }}
                                        onClick={() => {
                                          setActiveImage({
                                            id: image.id,
                                            image: image.image,
                                            sort_order: image.sort_order,
                                          });
                                        }}
                                        alt=''
                                      />
                                    </ImageListItem>
                                  )}
                                </Draggable>
                              ))}
                            </Box>
                          )}
                          {provided.placeholder}
                        </ImageList>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Box>
                {/** Image List Box end */}
                <Box
                  sx={{
                    pb: 0.5,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: theme.palette.primary.main,
                  }}
                >
                  <IconButton
                    size='small'
                    onClick={() => setUploadImageDialogVisible(true)}
                  >
                    <AiOutlineFileAdd size='22' />
                  </IconButton>
                  <IconButton
                    size='small'
                    onClick={() => setDeleteImageDialogVisible(true)}
                  >
                    <AiOutlineDelete size='22' />
                  </IconButton>
                </Box>
                {/** Left Box end */}
              </Box>
              {/** Media Box */}
              {availableImages.length === 0 ? (
                <Box>
                  <CardMedia
                    sx={{
                      height: matches600 === true ? 404 : 443,
                      width: matches600 === true ? 303 : 332,
                    }}
                    image='/images/placeholder.png'
                  />
                </Box>
              ) : (
                <Box>
                  <CardMedia
                    sx={{
                      height: matches600 === true ? 404 : 443,
                      width: matches600 === true ? 303 : 332,
                    }}
                    image={
                      MEDIA_URL + '/' + storeId + '-data/' + activeImage.image
                    }
                  />
                </Box>
              )}
              {/** Media Box end */}
            </Box>
            {/** Außenbox ende */}
          </TabPanel>
          {/** Images Content end */}
        </TabContext>
        <Box
          pl={2}
          pr={1}
          pb={1}
          sx={{ backgroundColor: theme.palette.primary.main }}
        >
          <FormControl component='fieldset' sx={{ width: '100%' }}>
            <FormLabel
              sx={{
                color: theme.palette.text.disabled,
                marginTop: theme.spacing(1),
                fontSize: 10,
                '&.Mui-focused': {
                  color: theme.palette.secondary.main,
                  fontSize: 10,
                },
              }}
              required
              color='secondary'
            >
              Artikelstatus
            </FormLabel>
            <RadioGroup
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
              aria-label='productstatus'
              name='productstatus'
              value={productstatus}
              onChange={handleProductStatusInput}
            >
              <FormControlLabel
                value='active'
                control={<Radio color='secondary' />}
                label='Aktiv'
              />
              <FormControlLabel
                value='inactive'
                control={<Radio color='secondary' />}
                label='Inaktiv'
              />
            </RadioGroup>
          </FormControl>
        </Box>
        {/** Tab Context end */}
        {/** Action Grid */}
        <Box
          mt={1}
          mb={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 80,
              width: 80,
            }}
          >
            <Avatar style={{ backgroundColor: '#00E676' }}>
              <IconButton
                disabled={!isButtonSaveEnabled}
                onClick={() => hanglePoductSave()}
              >
                <AiOutlineSave size='20' />
              </IconButton>
            </Avatar>
            <Typography
              variant='subtitle2'
              style={{
                marginTop: 6,
                color:
                  isDataChanged && isButtonSaveEnabled
                    ? theme.palette.text.primary
                    : theme.palette.text.disabled,
              }}
            >
              Speichern
            </Typography>
          </Box>
          {activeTab === 'data' ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 80,
                width: 80,
              }}
            >
              <Avatar style={{ backgroundColor: '#F50057' }}>
                <IconButton
                  onClick={() => setActiveTab('images')}
                  disabled={productId === 0}
                >
                  <AiOutlineFileImage size='20' />
                </IconButton>
              </Avatar>
              <Typography
                variant='subtitle2'
                style={{
                  marginTop: 6,
                  color:
                    productId === 0
                      ? theme.palette.text.disabled
                      : theme.palette.text.primary,
                }}
              >
                Bilder
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 80,
                width: 80,
              }}
            >
              <Avatar style={{ backgroundColor: '#F50057' }}>
                <IconButton onClick={() => setActiveTab('data')}>
                  <AiOutlineEdit size='20' />
                </IconButton>
              </Avatar>
              <Typography variant='subtitle2' style={{ marginTop: 6 }}>
                Daten
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 80,
              width: 80,
            }}
          >
            <Avatar style={{ backgroundColor: '#2979FF' }}>
              <IconButton
                onClick={() => {
                  newProduct();
                  setIsDataChanged(false);
                }}
              >
                <AiOutlineFileAdd size='20' />
              </IconButton>
            </Avatar>
            <Typography variant='subtitle2' style={{ marginTop: 6 }}>
              Neu
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 80,
              width: 80,
            }}
          >
            <Avatar style={{ backgroundColor: '#FF1744' }}>
              <IconButton onClick={() => setProductDeleteDialogVisible(true)}>
                <AiOutlineDelete size='20' />
              </IconButton>
            </Avatar>
            <Typography variant='subtitle2' style={{ marginTop: 6 }}>
              Löschen
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: 80,
              width: 80,
            }}
          >
            <Avatar style={{ backgroundColor: '#78909C' }}>
              <IconButton
                onClick={() =>
                  navigate('/tenantProductList', {
                    state: {
                      storeId: storeId,
                      productCategoryId: categoryId,
                      productCategoryName: categoryName,
                      helpopen: helpOpen,
                    },
                  })
                }
              >
                <BiArrowBack size='20' />
              </IconButton>
            </Avatar>
            <Typography variant='subtitle2' style={{ marginTop: 6 }}>
              Zurück
            </Typography>
          </Box>
        </Box>
        {/** Action Grid end */}
      </Container>
      {helpOpen ? (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
          }}
          style={{ backgroundColor: '#9E9E9E', width: '20%' }}
        >
          <Box
            sx={{
              paddingTop: 1,
              paddingLeft: 1.5,
            }}
          >
            <IconButton onClick={() => setHelpOpen(false)}>
              <AiOutlineClose size='24' />
            </IconButton>
          </Box>
          <TenantProductDetailHelpView />
        </Box>
      ) : (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
            paddingRight: 1.5,
            paddingTop: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => setHelpOpen(true)}>
              <AiOutlineQuestionCircle size='24' />
            </IconButton>
            <Typography
              onClick={() => setHelpOpen(true)}
              paragraph
              component='span'
              sx={{
                color: theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                  textDecoration: `underline ${theme.palette.text.primary}`,
                },
              }}
            >
              Hilfe
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TenantProductDetailView;
