import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
} from '@mui/material';
import { AiOutlineDelete } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
import { useAuth } from '../authContext';
import { SUBSCRIBE_NEWS, REMOVE_NEWS } from '../data/NewsData';
import { useMutation, useSubscription } from '@apollo/client';
import Header2 from '../components/Header2';

/**
 * Hier werden die News des Kunden verwaltet.
 */
const CustomerNewsView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [{ profile }] = useAuth();
  const [newsId, setNewsId] = useState<number>(0);
  const [storeName, setStoreName] = useState<string>('');
  const [newsMessage, setNewsMessage] = useState<string>('');
  useState<boolean>(false);
  const [deleteNewsDialogVisible, setDeleteNewsDialogVisible] =
    useState<boolean>(false);
  const {
    loading: newsLoading,
    error: newsError,
    data: newsData,
  } = useSubscription(SUBSCRIBE_NEWS, {
    variables: { email: profile.email },
  });
  const [removeNews, { loading: removeNewsLoading, error: removeNewsError }] =
    useMutation(REMOVE_NEWS);

  moment().locale('de');

  /**
   * Dialog für das Löschen der News
   */
  const handleNewsDeleteDialog = (action: string) => {
    if (action === 'delete') {
      removeNews({
        variables: {
          id: newsId,
        },
      });
    }
    setDeleteNewsDialogVisible(false);
  };

  // Beim laden nichts anzeigen.
  if (newsLoading) {
    return null;
  }

  // Falls Fehler auftritt.
  if (newsError) {
    console.log(`Error! CustomerNewsView: ${newsError.message}`);
  }

  // Beim laden nichts anzeigen.
  if (removeNewsLoading) {
    return null;
  }

  // Falls Fehler beim Löschen auftreten.
  if (removeNewsError) {
    console.log(`Error! CustomerNewsView: ${removeNewsError.message}`);
  }

  // return
  return (
    <Container maxWidth='xs'>
      <Dialog
        open={deleteNewsDialogVisible}
        onClose={() => setDeleteNewsDialogVisible(false)}
        aria-labelledby='delete-news-dialog-title'
        aria-describedby='delete-news-dialog-description'
      >
        <DialogTitle id='delete-news-dialog-title'>
          Sollen die News gelöscht werden?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='delete-news-dialog-description'>
            {' "'}
            {storeName !== '' ? storeName : null}
            {'" '} {' "'}
            {newsMessage !== '' ? newsMessage : null}
            {'" '}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => handleNewsDeleteDialog('cancel')}
            variant='outlined'
            color='inherit'
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => handleNewsDeleteDialog('delete')}
            variant='outlined'
            color='inherit'
            autoFocus
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={3}>
        <Header2
          title='News'
          subtitle={`Sie sind angemeldet als ${profile.email}`}
        />
      </Box>
      <Box mt={3}>
        {newsData.news.length === 0 ? (
          <Box>
            <Typography
              component='h2'
              variant='subtitle1'
              mb={1}
              align='center'
            >
              Keine News vorhanden.
            </Typography>
          </Box>
        ) : (
          <>
            {newsData.news.map((newsItem: any) => (
              <Paper elevation={0} sx={{ p: 1, mb: 0.5 }} key={newsItem.id}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant='body1'
                    sx={{ fontWeight: theme.typography.fontWeightBold }}
                  >
                    {newsItem.store.storename}
                  </Typography>
                  <Box>
                    <IconButton
                      color='inherit'
                      size='small'
                      onClick={() => {
                        setNewsId(newsItem.id);
                        setStoreName(newsItem.store.storename);
                        setNewsMessage(newsItem.message);
                        setDeleteNewsDialogVisible(true);
                      }}
                    >
                      <AiOutlineDelete size='22' />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='body1'>{newsItem.message}</Typography>
                  <Typography variant='body1'>
                    {moment(newsItem.created).format('HH:MM, DD MM')}
                  </Typography>
                </Box>
              </Paper>
            ))}
          </>
        )}
      </Box>
      <Box
        mt={3}
        mb={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            borderRadius: 30,
            width: '240px',
          }}
          type='submit'
          variant='contained'
          color='secondary'
          onClick={() => navigate('/customer')}
        >
          Ok
        </Button>
      </Box>
    </Container>
  );
};

export default CustomerNewsView;
