import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * HelpCustomerAccountCreateView
 */
const HelpCustomerAccountCreateView = () => {
  const navigate = useNavigate();

  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={2}>
        <Typography component='h1' variant='h4' align='center' gutterBottom>
          Nutzerkonto erstellen - Kunden
        </Typography>
        <Typography component='h1' variant='h6' align='center' gutterBottom>
          Wie kann ich als Kunde ein Nutzerkonto erstellen?
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography component='p' variant='body1' paragraph>
          Um ein Nutzerkonto als Kunde zu erstellen klickt man oben ganz rechts
          den Icon Login an. Danach kommt man auf die Login-Seite. Unter dem
          Login-Button rechts steht &quot;Noch kein Konto? Registrieren&quot;
          Auf den Link klicken und man ist auf der Seite
          &quot;Kunenregistrierung&quot;.
        </Typography>
      </Box>
      <Box
        mt={3}
        mb={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type='submit'
          variant='contained'
          color='secondary'
          sx={{ borderRadius: 30, width: '240px' }}
          onClick={() => navigate('/help')}
        >
          Zurück
        </Button>
      </Box>
    </Container>
  );
};

export default HelpCustomerAccountCreateView;
