import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  CardMedia,
  Button,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
} from '@mui/material';
import {
  AiOutlineDelete,
  AiOutlineMessage,
  AiOutlineQuestionCircle,
  AiOutlineClose,
} from 'react-icons/ai';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
import { useAuth } from '../authContext';
import {
  SUBSCRIBE_MESSAGEGROUPS,
  UPDATE_MESSAGE_STATUS,
  REMOVE_MESSAGES,
} from '../data/MessagesData';
import { useMutation, useSubscription } from '@apollo/client';
import Header2 from '../components/Header2';
import { Config } from '../config';
import TenantMessagesGroupsHelpView from './help/TenantMessagesGroupsHelpView';

/**
 * Hier werden die Nachrichten der Kunden verwaltet.
 */
const TenantMessagesGroupsView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const routerLocation: any = useRouterLocation();
  const [{ profile }] = useAuth();
  const [productName, setProductName] = useState<string>('');
  const [chat, setChat] = useState<string>('');
  const [deleteMessageDialogVisible, setDeleteMessageDialogVisible] =
    useState<boolean>(false);
  const [helpOpen, setHelpOpen] = useState<boolean>(
    routerLocation.state.helpopen
  );

  const MEDIA_URL: any =
    process.env.REACT_APP_MINIO_ENDPOINT !== undefined
      ? process.env.REACT_APP_MINIO_ENDPOINT
      : Config.minio_endpoint;

  // subscribe message groups
  const {
    loading: messageGroupsLoading,
    error: messageGroupsError,
    data: messageGroupsData,
  } = useSubscription(SUBSCRIBE_MESSAGEGROUPS, {
    variables: { email: profile.email },
    fetchPolicy: 'network-only',
  });

  // update message status
  const [updateMessageStatus] = useMutation(UPDATE_MESSAGE_STATUS);

  // remove messages
  const [removeMessages, { loading: removeLoading, error: removeError }] =
    useMutation(REMOVE_MESSAGES);

  moment().locale('de');

  /**
   * Dialog für das Löschen eines Eintrages
   * in der Nachrichtenliste.
   */
  const handleDeleteDialog = (action: string) => {
    if (action === 'delete') {
      removeMessages({
        variables: {
          chat: chat,
        },
      });
    }
    setDeleteMessageDialogVisible(false);
  };

  /**
   * Navigation
   */
  const goProductMessages = (
    messagefrom: string,
    chat: string,
    productid: number,
    productname: string,
    storeid: number,
    image: string
  ) => {
    navigate('/tenantMessages', {
      state: {
        messagefrom,
        chat,
        productid,
        productname,
        storeid,
        image,
        helpopen: helpOpen,
      },
    });
  };

  /**
   * Diese Funktion ändert message status.
   */
  const handleMessageStatus = async (id: number, value: string) => {
    try {
      await updateMessageStatus({
        variables: {
          id: id,
          status: value,
        },
      });
    } catch (error) {
      console.log(
        'Error! TenantMessagesGroupsView - handleMessageStatus:',
        error
      );
    }
  };

  // Beim laden nichts anzeigen.
  if (messageGroupsLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftritt.
  if (messageGroupsError) {
    console.log(
      `Error! TenantMessagesGroupsView: ${messageGroupsError.message}`
    );
  }

  // Beim laden nichts anzeigen.
  if (removeLoading) {
    return null;
  }

  // Falls Fehler beim Löschen auftreten.
  if (removeError) {
    console.log(`Error! TenantMessagesGroupsView: ${removeError.message}`);
  }

  // return
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      style={{ width: '100%' }}
    >
      <Container maxWidth='xs'>
        <Dialog
          open={deleteMessageDialogVisible}
          onClose={() => setDeleteMessageDialogVisible(false)}
          aria-labelledby='delete-dialog-title'
          aria-describedby='delete-dialog-description'
        >
          <DialogTitle id='delete-dialog-title'>
            Soll die Nachricht gelöscht werden?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='delete-dialog-description'>
              {' "'}
              {productName !== '' ? productName : null}
              {'" '}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => handleDeleteDialog('cancel')}
              variant='outlined'
              color='inherit'
            >
              Abbrechen
            </Button>
            <Button
              onClick={() => handleDeleteDialog('delete')}
              variant='outlined'
              color='inherit'
              autoFocus
            >
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
        <Box mt={3}>
          <Header2
            title='Die Nachrichten Ihrer Kunden.'
            subtitle={`Sie sind angemeldet als ${profile.email}`}
          />
        </Box>
        <Box mt={2}>
          {!messageGroupsLoading &&
          messageGroupsData &&
          messageGroupsData.message.length === 0 ? (
            <Box>
              <Typography
                component='h2'
                variant='subtitle1'
                mb={1}
                align='center'
              >
                Keine Nachrichten vorhanden.
              </Typography>
            </Box>
          ) : (
            <>
              {!messageGroupsLoading &&
                messageGroupsData &&
                messageGroupsData.message.map((messageGroup: any) => (
                  <Paper
                    elevation={0}
                    sx={{ mb: 0.2 }}
                    key={messageGroup.product.id}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {messageGroup.product.images.length === 0 ? (
                        <CardMedia
                          sx={{
                            height: 100,
                            width: 75,
                            [theme.breakpoints.down('xs')]: {
                              height: 64,
                              width: 48,
                            },
                          }}
                          image='/images/placeholder.png'
                        />
                      ) : (
                        <CardMedia
                          sx={{
                            height: 100,
                            width: 75,
                            [theme.breakpoints.down('xs')]: {
                              height: 64,
                              width: 48,
                            },
                          }}
                          image={
                            MEDIA_URL +
                            '/' +
                            messageGroup.product.storeproductcategory.store.id +
                            '-data/' +
                            messageGroup.product.images[0].image
                          }
                        />
                      )}
                      <Box
                        sx={{
                          ml: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          width: '80%',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          {profile.email !== messageGroup.message_from ? (
                            <Typography
                              variant='body1'
                              sx={{
                                color:
                                  messageGroup.status === 'new' ||
                                  messageGroup.status === 'tenanthadread' ||
                                  messageGroup.status === 'customeranswered'
                                    ? '#FF6D00' // orange
                                    : undefined,
                                fontWeight: theme.typography.fontWeightBold,
                              }}
                            >
                              {messageGroup.message_from}
                            </Typography>
                          ) : (
                            <Typography
                              variant='body1'
                              sx={{
                                color:
                                  messageGroup.status === 'customerhadread'
                                    ? '#2979FF' // blau
                                    : undefined,
                                fontWeight: theme.typography.fontWeightBold,
                              }}
                            >
                              {messageGroup.message_to}
                            </Typography>
                          )}
                          <Box>
                            <IconButton
                              color='inherit'
                              size='small'
                              onClick={() => {
                                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                messageGroup.status === 'new' ||
                                messageGroup.status === 'customeranswered'
                                  ? handleMessageStatus(
                                      messageGroup.id,
                                      'tenanthadread'
                                    )
                                  : null;
                                goProductMessages(
                                  profile.email !== messageGroup.message_from
                                    ? messageGroup.message_from
                                    : messageGroup.message_to,
                                  messageGroup.chat,
                                  messageGroup.product.id,
                                  messageGroup.product.productname,
                                  messageGroup.product.storeproductcategory
                                    .store.id,
                                  messageGroup.product.images.length !== 0
                                    ? messageGroup.product.images[0].image
                                    : 'none'
                                );
                              }}
                            >
                              <AiOutlineMessage size='22' />
                            </IconButton>
                            <IconButton
                              color='inherit'
                              size='small'
                              onClick={() => {
                                setProductName(
                                  messageGroup.product.productname
                                );
                                setChat(messageGroup.chat);
                                setDeleteMessageDialogVisible(true);
                              }}
                            >
                              <AiOutlineDelete size='22' />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box>
                          <Typography
                            style={{
                              color: theme.palette.text.primary,
                              textDecoration: 'none',
                              fontWeight: theme.typography.fontWeightBold,
                            }}
                          >
                            {messageGroup.product.productname}
                          </Typography>
                          <Typography
                            variant='body1'
                            style={{ wordWrap: 'break-word' }}
                          >
                            {messageGroup.message}
                          </Typography>
                          <Typography variant='body1'>
                            {moment(messageGroup.created).format(
                              'HH:MM, DD MM'
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                ))}
            </>
          )}
        </Box>
        <Box
          mt={3}
          mb={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() =>
              navigate('/tenant', {
                state: { helpopen: helpOpen },
              })
            }
          >
            Ok
          </Button>
        </Box>
      </Container>
      {helpOpen ? (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
          }}
          style={{ backgroundColor: '#9E9E9E', width: '20%' }}
        >
          <Box
            sx={{
              paddingTop: 1,
              paddingLeft: 1.5,
            }}
          >
            <IconButton onClick={() => setHelpOpen(false)}>
              <AiOutlineClose size='24' />
            </IconButton>
          </Box>
          <TenantMessagesGroupsHelpView />
        </Box>
      ) : (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
            paddingRight: 1.5,
            paddingTop: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => setHelpOpen(true)}>
              <AiOutlineQuestionCircle size='24' />
            </IconButton>
            <Typography
              onClick={() => setHelpOpen(true)}
              paragraph
              component='span'
              sx={{
                color: theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                  textDecoration: `underline ${theme.palette.text.primary}`,
                },
              }}
            >
              Hilfe
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TenantMessagesGroupsView;
