import {
  split,
  InMemoryCache,
  HttpLink,
  ApolloClient,
  NormalizedCacheObject,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { Config } from './config';

const HASURA_HTTP_URL: any =
  process.env.REACT_APP_HASURA_HTTP_URL !== undefined
    ? process.env.REACT_APP_HASURA_HTTP_URL
    : Config.hasura_http_url;
const HASURA_WS_URL: any =
  process.env.REACT_APP_HASURA_WS_URL !== undefined
    ? process.env.REACT_APP_HASURA_WS_URL
    : Config.hasura_ws_url;

/**
 * http link
 */
const httpLink = new HttpLink({
  uri: HASURA_HTTP_URL,
  headers: {
    'x-hasura-default-role': 'public',
  },
});

/**
 * WebSocket link
 */
const wsLink = new GraphQLWsLink(
  createClient({
    url: HASURA_WS_URL,
    connectionParams: {
      reconnect: true,
      lazy: true,
      headers: {
        'x-hasura-default-role': 'public',
      },
    },
  })
);

// The split function takes three parameters:
// 1. A function that's called for each operation to execute
// 2. The Link to use for an operation if the function returns a "truthy" value
// 3. The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

// Apollo InMemoryCache
const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Subscription: {
      fields: {
        message: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,

          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
        ticket: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,

          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
        news: {
          // Don't cache separate results based on
          // any of this field's arguments.
          keyArgs: false,

          // Concatenate the incoming list items with
          // the existing list items.
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
      },
    },
  },
});

/**
 * ApolloClient
 */
const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: splitLink,
  cache,
});

export default apolloClient;
