import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from './authContext';
import DefaultLayout from './layout/Main/DefaultLayout';
import StoreLayout from './layout/Main/StoreLayout';
import AdminLayout from './admin/layout/Main';
import { StartView, LocationView, SearchView, FilterView } from './portal';
import { StoresView, ProductView } from './stores';
import {
  CustomerView,
  CustomerMessagesGroupsView,
  CustomerMessagesView,
  CustomerNewsView,
  CustomerLogindataView,
  CustomerEmailView,
  CustomerPasswordView,
  CustomerDeleteView,
} from './customer';
import {
  TenantView,
  TenantMessagesGroupsView,
  TenantMessagesView,
  TenantTicketNewView,
  TenantTicketView,
  TenantRegularCustomerView,
  TenantProductAssortmentView,
  TenantProductListView,
  TenantProductDetailView,
  TenantProductCategoriesView,
  TenantEditProductCategoriesView,
  TenantStoreView,
  TenantLogindataView,
  TenantEmailView,
  TenantPasswordView,
  TenantContractView,
} from './tenant';
import AdminView from './admin/AdminView';
import {
  AboutUsView,
  AppsView,
  MarketingView,
  PricingView,
  ContactView,
  TermsAndConditionsView,
  PrivacyView,
  ImprintView,
} from './common';
import {
  HelpView,
  MerkantdoInfoView,
  MerkantdoForView,
  HelpCustomerAdminView,
  HelpCustomerAccountView,
  HelpCustomerAccountCreateView,
  FavoriteCustomerHowToView,
  LogindataCustomerHowToView,
  StoreAdminView,
  StoreAdvantagesView,
  StoreRegisterHowToView,
  StoreProductAdminHowToView,
  StoreCommunicateHowToView,
} from './help';
import FavoriteView from './favorite/FavoriteView';
import {
  LoginView,
  RegisterCustomerView,
  ForgotPasswordView,
  ResetPasswordView,
} from './login';
import RegisterStoreView from './tenant/RegisterStoreView';
import NotFound from './NotFound';

const AppNavigator = () => {
  const [{ isAuthenticated, profile }] = useAuth();

  return (
    <Routes>
      {isAuthenticated === true && profile.userrole === 'customer' ? (
        <>
          <Route path='/' element={<DefaultLayout />}>
            <Route path='/' element={<StartView />} />
            <Route path='start' element={<StartView />} />
            <Route path='search' element={<SearchView />} />
            <Route path='filter' element={<FilterView />} />
            <Route path='location' element={<LocationView />} />
            {/** <Route path='stores' element={<StoresView />} /> */}
            <Route path='product' element={<ProductView />} />
            <Route path='favorite' element={<FavoriteView />} />
            <Route path='customer' element={<CustomerView />} />
            <Route
              path='customerMessagesGroups'
              element={<CustomerMessagesGroupsView />}
            />
            <Route path='customerMessages' element={<CustomerMessagesView />} />
            <Route path='customerNews' element={<CustomerNewsView />} />
            <Route
              path='customerLogindata'
              element={<CustomerLogindataView />}
            />
            <Route path='customerEmail' element={<CustomerEmailView />} />
            <Route path='customerPassword' element={<CustomerPasswordView />} />
            <Route path='customerDelete' element={<CustomerDeleteView />} />
            <Route path='registerStore' element={<RegisterStoreView />} />
            <Route path='aboutUs' element={<AboutUsView />} />
            <Route path='apps' element={<AppsView />} />
            <Route path='marketing' element={<MarketingView />} />
            <Route path='pricing' element={<PricingView />} />
            <Route path='contact' element={<ContactView />} />
            <Route path='terms' element={<TermsAndConditionsView />} />
            <Route path='privacy' element={<PrivacyView />} />
            <Route path='imprint' element={<ImprintView />} />
            <Route path='help' element={<HelpView />} />
            <Route path='merkantdoInfo' element={<MerkantdoInfoView />} />
            <Route path='merkantdoFor' element={<MerkantdoForView />} />
            <Route
              path='helpCustomerAdmin'
              element={<HelpCustomerAdminView />}
            />
            <Route
              path='helpCustomerAccount'
              element={<HelpCustomerAccountView />}
            />
            <Route
              path='helpCustomerAccountCreate'
              element={<HelpCustomerAccountCreateView />}
            />
            <Route
              path='favoriteCustomerHowTo'
              element={<FavoriteCustomerHowToView />}
            />
            <Route
              path='logindataCustomerHowTo'
              element={<LogindataCustomerHowToView />}
            />
            <Route path='storeAdmin' element={<StoreAdminView />} />
            <Route path='storeAdvantages' element={<StoreAdvantagesView />} />
            <Route
              path='storeRegisterHowTo'
              element={<StoreRegisterHowToView />}
            />
            <Route
              path='storeProductAdminHowTo'
              element={<StoreProductAdminHowToView />}
            />
            <Route
              path='storeCommunicateHowTo'
              element={<StoreCommunicateHowToView />}
            />
            <Route path='/' element={<Navigate to='/start' />} />
          </Route>
          <Route path='/' element={<StoreLayout />}>
            <Route path='stores' element={<StoresView />} />
          </Route>
        </>
      ) : isAuthenticated === true && profile.userrole === 'tenant' ? (
        <>
          <Route path='/' element={<DefaultLayout />}>
            <Route path='/' element={<StartView />} />
            <Route path='start' element={<StartView />} />
            <Route path='search' element={<SearchView />} />
            <Route path='filter' element={<FilterView />} />
            <Route path='location' element={<LocationView />} />
            {/** <Route path='stores' element={<StoresView />} /> */}
            <Route path='product' element={<ProductView />} />
            <Route path='tenant' element={<TenantView />} />
            <Route
              path='tenantMessagesGroups'
              element={<TenantMessagesGroupsView />}
            />
            <Route path='tenantMessages' element={<TenantMessagesView />} />
            <Route path='tenantTicketNew' element={<TenantTicketNewView />} />
            <Route path='tenantTicket' element={<TenantTicketView />} />
            <Route
              path='tenantRegularCustomer'
              element={<TenantRegularCustomerView />}
            />
            <Route
              path='tenantProductAssortment'
              element={<TenantProductAssortmentView />}
            />
            <Route
              path='tenantProductList'
              element={<TenantProductListView />}
            />
            <Route
              path='tenantProductDetail'
              element={<TenantProductDetailView />}
            />
            <Route
              path='tenantProductCategories'
              element={<TenantProductCategoriesView />}
            />
            <Route
              path='tenantEditProductCategories'
              element={<TenantEditProductCategoriesView />}
            />
            <Route path='tenantLogindata' element={<TenantLogindataView />} />
            <Route path='tenantEmail' element={<TenantEmailView />} />
            <Route path='tenantPassword' element={<TenantPasswordView />} />
            <Route path='tenantStore' element={<TenantStoreView />} />
            <Route path='tenantContract' element={<TenantContractView />} />
            <Route path='registerStore' element={<RegisterStoreView />} />
            <Route path='aboutUs' element={<AboutUsView />} />
            <Route path='apps' element={<AppsView />} />
            <Route path='marketing' element={<MarketingView />} />
            <Route path='pricing' element={<PricingView />} />
            <Route path='contact' element={<ContactView />} />
            <Route path='terms' element={<TermsAndConditionsView />} />
            <Route path='privacy' element={<PrivacyView />} />
            <Route path='imprint' element={<ImprintView />} />
            <Route path='help' element={<HelpView />} />
            <Route path='merkantdoInfo' element={<MerkantdoInfoView />} />
            <Route path='merkantdoFor' element={<MerkantdoForView />} />
            <Route
              path='helpCustomerAdmin'
              element={<HelpCustomerAdminView />}
            />
            <Route
              path='helpCustomerAccount'
              element={<HelpCustomerAccountView />}
            />
            <Route
              path='helpCustomerAccountCreate'
              element={<HelpCustomerAccountCreateView />}
            />
            <Route
              path='favoriteCustomerHowTo'
              element={<FavoriteCustomerHowToView />}
            />
            <Route
              path='logindataCustomerHowTo'
              element={<LogindataCustomerHowToView />}
            />
            <Route path='storeAdmin' element={<StoreAdminView />} />
            <Route path='storeAdvantages' element={<StoreAdvantagesView />} />
            <Route
              path='storeRegisterHowTo'
              element={<StoreRegisterHowToView />}
            />
            <Route
              path='storeProductAdminHowTo'
              element={<StoreProductAdminHowToView />}
            />
            <Route
              path='storeCommunicateHowTo'
              element={<StoreCommunicateHowToView />}
            />
            <Route path='/' element={<Navigate to='/start' />} />
          </Route>
          <Route path='/' element={<StoreLayout />}>
            <Route path='stores' element={<StoresView />} />
          </Route>
        </>
      ) : isAuthenticated === true && profile.userrole === 'portaladmin' ? (
        <>
          <Route path='/' element={<AdminLayout />}>
            <Route path='admin' element={<AdminView />} />
          </Route>
        </>
      ) : (
        <>
          <Route path='/' element={<DefaultLayout />}>
            <Route path='start' element={<StartView />} />
            <Route path='search' element={<SearchView />} />
            <Route path='filter' element={<FilterView />} />
            <Route path='location' element={<LocationView />} />
            {/** <Route path='stores' element={<StoresView />} /> */}
            <Route path='product' element={<ProductView />} />
            <Route path='favorite' element={<FavoriteView />} />
            <Route path='login' element={<LoginView />} />
            <Route path='registerCustomer' element={<RegisterCustomerView />} />
            <Route path='forgotPassword' element={<ForgotPasswordView />} />
            <Route path='resetPassword' element={<ResetPasswordView />} />
            <Route path='registerStore' element={<RegisterStoreView />} />
            <Route path='aboutUs' element={<AboutUsView />} />
            <Route path='apps' element={<AppsView />} />
            <Route path='marketing' element={<MarketingView />} />
            <Route path='pricing' element={<PricingView />} />
            <Route path='contact' element={<ContactView />} />
            <Route path='terms' element={<TermsAndConditionsView />} />
            <Route path='privacy' element={<PrivacyView />} />
            <Route path='imprint' element={<ImprintView />} />
            <Route path='help' element={<HelpView />} />
            <Route path='merkantdoInfo' element={<MerkantdoInfoView />} />
            <Route path='merkantdoFor' element={<MerkantdoForView />} />
            <Route
              path='helpCustomerAdmin'
              element={<HelpCustomerAdminView />}
            />
            <Route
              path='helpCustomerAccount'
              element={<HelpCustomerAccountView />}
            />
            <Route
              path='helpCustomerAccountCreate'
              element={<HelpCustomerAccountCreateView />}
            />
            <Route
              path='favoriteCustomerHowTo'
              element={<FavoriteCustomerHowToView />}
            />
            <Route
              path='logindataCustomerHowTo'
              element={<LogindataCustomerHowToView />}
            />
            <Route path='storeAdmin' element={<StoreAdminView />} />
            <Route path='storeAdvantages' element={<StoreAdvantagesView />} />
            <Route
              path='storeRegisterHowTo'
              element={<StoreRegisterHowToView />}
            />
            <Route
              path='storeProductAdminHowTo'
              element={<StoreProductAdminHowToView />}
            />
            <Route
              path='storeCommunicateHowTo'
              element={<StoreCommunicateHowToView />}
            />
            <Route path='/' element={<Navigate to='/start' />} />
            <Route path='/customer' element={<Navigate to='/login' />} />
            <Route path='/customerNews' element={<Navigate to='/login' />} />
            <Route
              path='/customerMessagesGroups'
              element={<Navigate to='/login' />}
            />
            <Route
              path='/customerMessages'
              element={<Navigate to='/login' />}
            />
            <Route
              path='/customerLogindata'
              element={<Navigate to='/login' />}
            />
            <Route path='/customerEmail' element={<Navigate to='/login' />} />
            <Route
              path='/customerPassword'
              element={<Navigate to='/login' />}
            />
            <Route path='/customerDelete' element={<Navigate to='/login' />} />
            <Route path='/tenant' element={<Navigate to='/login' />} />
            <Route
              path='/tenantMessagesGroups'
              element={<Navigate to='/login' />}
            />
            <Route path='/tenantMessages' element={<Navigate to='/login' />} />
            <Route path='/tenantTicketNew' element={<Navigate to='/login' />} />
            <Route path='/tenantTicket' element={<Navigate to='/login' />} />
            <Route
              path='/tenantRegularCustomer'
              element={<Navigate to='/login' />}
            />
            <Route
              path='/tenantProductAssortment'
              element={<Navigate to='/login' />}
            />
            <Route
              path='/tenantProductList'
              element={<Navigate to='/login' />}
            />
            <Route
              path='/tenantProductDetail'
              element={<Navigate to='/login' />}
            />
            <Route
              path='/tenantProductCategories'
              element={<Navigate to='/login' />}
            />
            <Route
              path='/tenantEditProductCategories'
              element={<Navigate to='/login' />}
            />
            <Route path='/tenantStore' element={<Navigate to='/login' />} />
            <Route path='/tenantContract' element={<Navigate to='/login' />} />
            <Route path='/tenantLogindata' element={<Navigate to='/login' />} />
            <Route path='/tenantEmail' element={<Navigate to='/login' />} />
            <Route path='/tenantPassword' element={<Navigate to='/login' />} />
            <Route path='/admin' element={<Navigate to='/login' />} />
          </Route>
          <Route path='/' element={<StoreLayout />}>
            <Route path='stores' element={<StoresView />} />
          </Route>
        </>
      )}
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default AppNavigator;
