import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Card,
  CardMedia,
  Button,
  IconButton,
  useTheme,
} from '@mui/material';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import {
  AiOutlineEdit,
  AiOutlineQuestionCircle,
  AiOutlineClose,
} from 'react-icons/ai';
import { QUERY_ALL_STORE_CATEGORY_PRODUCTS } from '../data/StoreData';
import { useQuery } from '@apollo/client';
import Header2 from '../components/Header2';
import { Config } from '../config';
import TenantProductListHelpView from './help/TenantProductListHelpView';

/**
 * Produktliste einer Warengruppe
 * des Geschäfts.
 */
const TenantProductListView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const routerLocation: any = useRouterLocation();
  const [helpOpen, setHelpOpen] = useState<boolean>(
    routerLocation.state.helpopen
  );

  const MEDIA_URL: any =
    process.env.REACT_APP_MINIO_ENDPOINT !== undefined
      ? process.env.REACT_APP_MINIO_ENDPOINT
      : Config.minio_endpoint;

  // get store category products
  const {
    loading: productsLoading,
    error: productsError,
    data: productsData,
  } = useQuery(QUERY_ALL_STORE_CATEGORY_PRODUCTS, {
    variables: {
      storeid: routerLocation.state.storeId,
      categoryid: routerLocation.state.productCategoryId,
    },
    fetchPolicy: 'network-only',
  });

  // Beim laden der Daten nichts anzeigen.
  if (productsLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (productsError) {
    console.log('Error! TenantProductListView: ' + productsError.message);
  }

  // return
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      style={{ width: '100%' }}
    >
      <Container maxWidth='xs'>
        <Box mt={3}>
          <Header2
            title='Artikelliste der Warengruppe'
            subtitle={routerLocation.state.productCategoryName}
          />
        </Box>
        <Box mt={2}>
          {productsData &&
          productsData.merkantdo_storeallcategoryproducts.length === 0 ? (
            <Box>
              <Typography
                component='h2'
                variant='subtitle1'
                mb={1}
                align='center'
              >
                Keine Artikel vorhanden.
              </Typography>
            </Box>
          ) : (
            <>
              {productsData &&
                productsData.merkantdo_storeallcategoryproducts.map(
                  (product: any, index: number) => (
                    <Box key={index}>
                      <Card
                        variant='outlined'
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          border: 'none',
                          p: 0.2,
                          mb: 0.2,
                          height: 88,
                          [theme.breakpoints.down('xs')]: {
                            height: 64,
                          },
                        }}
                        key={index}
                      >
                        {product.image === null ? (
                          <CardMedia
                            sx={{
                              height: 88,
                              width: 66,
                              [theme.breakpoints.down('xs')]: {
                                height: 64,
                                width: 48,
                              },
                            }}
                            image='/images/placeholder.png'
                            title={product.productname}
                          />
                        ) : (
                          <CardMedia
                            sx={{
                              height: 88,
                              width: 66,
                              [theme.breakpoints.down('xs')]: {
                                height: 64,
                                width: 48,
                              },
                            }}
                            image={
                              MEDIA_URL +
                              '/' +
                              product.store_id +
                              '-data/' +
                              product.image
                            }
                            title={product.productname}
                          />
                        )}
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            width: '100%',
                            p: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                              pl: 1,
                            }}
                          >
                            <Typography variant='body1'>
                              {product.productname}
                            </Typography>
                            <IconButton
                              color='inherit'
                              size='small'
                              onClick={() =>
                                navigate('/tenantProductDetail', {
                                  state: {
                                    storeId: routerLocation.state.storeId,
                                    productCategoryId:
                                      routerLocation.state.productCategoryId,
                                    productCategoryName:
                                      routerLocation.state.productCategoryName,
                                    productId: product.id,
                                    helpopen: helpOpen,
                                  },
                                })
                              }
                            >
                              <AiOutlineEdit size='20' />
                            </IconButton>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'start',
                              width: '100%',
                              pl: 1,
                            }}
                          >
                            <Typography
                              variant='body2'
                              sx={{
                                width: '50%',
                                fontWeight: theme.typography.fontWeightMedium,
                              }}
                            >
                              Aktion:{' '}
                              {product.actionstatus === 'None'
                                ? 'Keine'
                                : product.actionstatus === 'New'
                                ? 'Neu'
                                : product.actionstatus === 'Sale'
                                ? 'Reduziert'
                                : product.actionstatus}
                            </Typography>
                            <Typography
                              variant='body2'
                              sx={{
                                width: '50%',
                                fontWeight: theme.typography.fontWeightMedium,
                              }}
                            >
                              Status:{' '}
                              {product.productstatus === 'active'
                                ? 'Aktiv'
                                : 'Inaktiv'}
                            </Typography>
                          </Box>
                          {product.actionstatus === 'Sale' ? (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'start',
                                width: '100%',
                                pl: 1,
                              }}
                            >
                              <Typography
                                variant='body2'
                                sx={{
                                  width: '50%',
                                  fontWeight: theme.typography.fontWeightMedium,
                                  color: theme.palette.text.disabled,
                                  textDecorationLine: 'line-through',
                                }}
                              >
                                Alter Preis: {product.price} €
                              </Typography>
                              <Typography
                                variant='body2'
                                sx={{
                                  width: '50%',
                                  fontWeight: theme.typography.fontWeightMedium,
                                }}
                              >
                                Neuer Preis:
                                {(
                                  Math.round(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    (parseFloat(product.price.trim()).toFixed(
                                      2
                                    ) -
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      (parseFloat(product.price.trim()).toFixed(
                                        2
                                      ) /
                                        100) *
                                        product.discount) *
                                      100
                                  ) / 100
                                )
                                  .toFixed(2)
                                  .replace('.', ',')}{' '}
                                €
                              </Typography>
                            </Box>
                          ) : (
                            <Typography
                              variant='body2'
                              sx={{
                                pl: 1,
                                width: '50%',
                                fontWeight: theme.typography.fontWeightMedium,
                              }}
                            >
                              Preis: {product.price} €
                            </Typography>
                          )}
                        </Box>
                      </Card>
                    </Box>
                  )
                )}
            </>
          )}
        </Box>
        <Box
          mt={2}
          mb={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() =>
              navigate('/tenantProductDetail', {
                state: {
                  storeId: routerLocation.state.storeId,
                  productCategoryId: routerLocation.state.productCategoryId,
                  action: 'newProduct',
                  helpopen: helpOpen,
                },
              })
            }
          >
            Neu
          </Button>
          <Button
            type='submit'
            sx={{ marginTop: 2, borderRadius: 30, width: '240px' }}
            variant='contained'
            color='secondary'
            onClick={() =>
              navigate('/tenantProductAssortment', {
                state: {
                  storeId: routerLocation.state.storeId,
                  helpopen: helpOpen,
                },
              })
            }
          >
            Ok
          </Button>
        </Box>
      </Container>
      {helpOpen ? (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
          }}
          style={{ backgroundColor: '#9E9E9E', width: '20%' }}
        >
          <Box
            sx={{
              paddingTop: 1,
              paddingLeft: 1.5,
            }}
          >
            <IconButton onClick={() => setHelpOpen(false)}>
              <AiOutlineClose size='24' />
            </IconButton>
          </Box>
          <TenantProductListHelpView />
        </Box>
      ) : (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
            paddingRight: 1.5,
            paddingTop: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => setHelpOpen(true)}>
              <AiOutlineQuestionCircle size='24' />
            </IconButton>
            <Typography
              onClick={() => setHelpOpen(true)}
              paragraph
              component='span'
              sx={{
                color: theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                  textDecoration: `underline ${theme.palette.text.primary}`,
                },
              }}
            >
              Hilfe
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TenantProductListView;
