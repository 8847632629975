import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  IconButton,
  useTheme,
} from '@mui/material';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { useAuth } from '../authContext';
import {
  GET_ALL_PRODUCT_CATEGORIES,
  GET_ALL_PRODUCT_CATEGORIES_BY_STORE,
  ADD_STORE_PRODUCTCATEGORY,
  REMOVE_STORE_PRODUCTCATEGORY,
  ADD_PRODUCTCATEGORY,
  CHECK_STORE_CATEGORY_PRODUCTS,
  CHECK_PRODUCT_CATEGORY,
} from '../data/StoreData';
import { GET_PRODUCTCATEGORIES_ID } from './queries/tenantQueries';
import Header2 from '../components/Header2';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { IInputError, IProductCategory } from '../interfaces/interfaces';
import { AiOutlineQuestionCircle, AiOutlineClose } from 'react-icons/ai';
import TenantEditProductCategoriesHelpView from './help/TenantEditProductCategoriesHelpView';

/**
 * View zur Einstellungen der Product Categories.
 */
const TenantEditProductCategoryView = () => {
  const navigate = useNavigate();
  const routerLocation: any = useRouterLocation();
  const [{ profile }] = useAuth();
  const [createCategoryDialogVisible, setCreateCategoryDialogVisible] =
    useState<boolean>(false);
  const [isButtonCreateEnabled, setIsButtonCreateEnabled] =
    useState<boolean>(false);
  const [newId, setNewId] = useState<number>(0);
  const [newProductCategory, setNewProductCategory] = useState<string>('');
  const [newProductCategoryError, setNewProductCategoryError] = useState<
    Partial<IInputError>
  >({
    isError: false,
    message: '',
  });
  const [notEmptyCategoryDialogVisible, setNotEmptyCategoryDialogVisible] =
    useState<boolean>(false);
  const [productCategories, setProductCategories] = useState<
    Array<IProductCategory>
  >([]);
  const theme = useTheme();
  const [helpOpen, setHelpOpen] = useState<boolean>(
    routerLocation.state.helpopen
  );

  // get all product categories
  const {
    loading: allCategoriesLoading,
    error: allCategoriesError,
    data: allCategoriesData,
  } = useQuery(GET_ALL_PRODUCT_CATEGORIES, { fetchPolicy: 'network-only' });

  // get all store product categories
  const {
    loading: storeCategoriesLoading,
    error: storeCategoriesError,
    data: storeCategoriesData,
  } = useQuery(GET_ALL_PRODUCT_CATEGORIES_BY_STORE, {
    variables: { storeid: routerLocation.state.storeId },
    fetchPolicy: 'network-only',
  });

  // add store product category
  const [addStoreProductcategory] = useMutation(ADD_STORE_PRODUCTCATEGORY, {
    refetchQueries: [
      {
        query: GET_ALL_PRODUCT_CATEGORIES_BY_STORE,
        variables: { storeid: routerLocation.state.storeId },
        fetchPolicy: 'network-only',
      },
    ],
  });

  // remove store product category
  const [
    removeProductcategory,
    { loading: removeLoading, error: removeError },
  ] = useMutation(REMOVE_STORE_PRODUCTCATEGORY, {
    refetchQueries: [
      {
        query: GET_ALL_PRODUCT_CATEGORIES_BY_STORE,
        variables: { storeid: routerLocation.state.storeId },
        fetchPolicy: 'network-only',
      },
    ],
  });

  // get id
  const [getId] = useLazyQuery(GET_PRODUCTCATEGORIES_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setNewId(data.productcategory[0].id + 1);
    },
  });

  // add product category
  const [addProductcategory] = useMutation(ADD_PRODUCTCATEGORY, {
    refetchQueries: [
      {
        query: GET_ALL_PRODUCT_CATEGORIES,
        fetchPolicy: 'network-only',
      },
    ],
  });

  // store category products überprüfen
  const [checkStoreCategoryProducts] = useLazyQuery(
    CHECK_STORE_CATEGORY_PRODUCTS
  );

  // check product category ob vorhanden ist
  const [checkProductCategory] = useLazyQuery(CHECK_PRODUCT_CATEGORY);

  /**
   * Store Product Categories setzen.
   */
  useEffect(() => {
    if (allCategoriesData && storeCategoriesData) {
      let tempChecked: any;
      let tempCategory: any;
      const tempCategories: Array<IProductCategory> = [];
      for (let i = 0; i < allCategoriesData.productcategory.length; i++) {
        tempChecked = false;
        for (let j = 0; j < storeCategoriesData.productcategory.length; j++) {
          if (
            allCategoriesData.productcategory[i].id ===
            storeCategoriesData.productcategory[j].id
          ) {
            tempChecked = true;
          }
        }
        tempCategory = {
          categoryId: allCategoriesData.productcategory[i].id,
          categoryName: allCategoriesData.productcategory[i].categoryname,
          checked: tempChecked,
        };
        tempCategories.push(tempCategory);
      }
      setProductCategories(tempCategories);
    }
  }, [allCategoriesData, storeCategoriesData]);

  /**
   * setIsButtonCreateEnabled validation
   */
  useEffect(() => {
    if (newProductCategory.length === 0) {
      setNewProductCategoryError({
        isError: true,
        message: 'Eigabe ist erforderlich!',
      });
    } else if (newProductCategory.length < 2) {
      setNewProductCategoryError({
        isError: true,
        message: 'Eigabe ist zu kurz!',
      });
    } else if (!newProductCategory.match(/^[A-Z]\w*$/)) {
      setNewProductCategoryError({
        isError: true,
        message: 'Der erste Buchstaben muß groß sein!',
      });
    } else if (
      !newProductCategory.match(
        /^[A-Za-z\u00e4\u00f6\u00fc\u00c4\u00d6\u00dc\u00df\040]+$/
      )
    ) {
      setNewProductCategoryError({
        isError: true,
        message: 'Bitte nur Buchstaben eingeben!',
      });
    } else {
      setNewProductCategoryError({
        isError: false,
        message: '',
      });
      setIsButtonCreateEnabled(true);
    }
  }, [newProductCategory]);

  /**
   * Store Product Categories verwalten.
   */
  const handleProductCategory = async (
    categoryid: number,
    checkedState: boolean
  ) => {
    const tempCategories: Array<IProductCategory> = [];
    for (let i = 0; i < productCategories.length; i++) {
      // current category in array
      if (productCategories[i].categoryId === categoryid) {
        //change the checkedState
        tempCategories.push({
          categoryId: productCategories[i].categoryId,
          categoryName: productCategories[i].categoryName,
          checked: checkedState,
        });
        if (checkedState === false) {
          // add category
          addStoreProductcategory({
            variables: {
              productcategory: {
                store_id: routerLocation.state.storeId,
                productcategory_id: categoryid,
              },
            },
          });
        } else {
          // remove category
          try {
            // account überprüfen ob vonhanden ist
            const { data } = await checkStoreCategoryProducts({
              variables: {
                storeid: routerLocation.state.storeId,
                categoryid: categoryid,
              },
              fetchPolicy: 'network-only',
            });
            if (data.product.length === 0) {
              // artikel sind in der productcategory nicht vorhanden.
              // wird gelöscht
              removeProductcategory({
                variables: {
                  storeid: routerLocation.state.storeId,
                  productcategoryid: categoryid,
                },
              });
            } else {
              setNotEmptyCategoryDialogVisible(true);
            }
          } catch (error) {
            console.log(
              'Error! TenantEditProductCategoryView - handleProductCategory:',
              error
            );
          }
        }
      } else {
        tempCategories.push(productCategories[i]);
      }
    }
    setProductCategories(tempCategories);
  };

  /**
   * Dialog für das Erstellen einer neuen Warrengruppe.
   */
  const handleCreateCategoryDialog = async (action: string) => {
    if (action === 'create' && !newProductCategoryError.isError) {
      // überprüfen ob schon vorhanden ist
      try {
        const { data } = await checkProductCategory({
          variables: {
            categoryname: newProductCategory,
          },
          fetchPolicy: 'network-only',
        });
        if (data.productcategory.length === 0) {
          // productcategory nicht vorhanden.
          // wird erstellt.
          addProductcategory({
            variables: {
              productcategory: {
                id: newId,
                categoryname: newProductCategory,
              },
            },
          });
          setNewProductCategory('');
          setCreateCategoryDialogVisible(false);
        } else {
          setNewProductCategoryError({
            isError: true,
            message:
              'Diese Warrengruppe ist schon vorhanden. Bitte aus der Liste wählen!',
          });
        }
      } catch (error) {
        console.log(
          'Error! TenantEditProductCategoryView - handleCreateCategoryDialog:',
          error
        );
      }
    } else if (action === 'cancel') {
      setNewProductCategory('');
      setCreateCategoryDialogVisible(false);
    }
  };

  /**
   * Wenn newProductCategory geändert wird.
   * @param e
   */
  const handleNewProductCategory = (e: any) => {
    setNewProductCategory(e.target.value);
  };

  // Beim laden der Daten nichts anzeigen.
  if (allCategoriesLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (allCategoriesError) {
    console.log(
      `Error! TenantNewProductCategoryView: ${allCategoriesError.message}`
    );
  }

  // Beim laden der Daten nichts anzeigen.
  if (storeCategoriesLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (storeCategoriesError) {
    console.log(
      `Error! TenantNewProductCategoryView: ${storeCategoriesError.message}`
    );
  }

  // Beim laden nichts anzeigen.
  if (removeLoading) {
    return null;
  }

  // Falls Fehler beim Löschen auftreten.
  if (removeError) {
    console.log(`Error! TenantNewProductCategoryView: ${removeError.message}`);
  }

  // return
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      style={{ width: '100%' }}
    >
      <Container maxWidth='xs'>
        {/** Create Category Dialog */}
        <Dialog
          open={createCategoryDialogVisible}
          onClose={() => setCreateCategoryDialogVisible(false)}
          aria-labelledby='create-dialog-title'
          aria-describedby='create-dialog-description'
        >
          <DialogTitle id='create-dialog-title'>
            Eine neue Warengruppe erstellen.
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='create-dialog-description'>
              <TextField
                color='secondary'
                required
                sx={{ width: '100%' }}
                variant='standard'
                label='Name der Warrengruppe'
                value={newProductCategory}
                error={newProductCategoryError.isError}
                helperText={newProductCategoryError.message}
                onChange={(e) => handleNewProductCategory(e)}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => handleCreateCategoryDialog('cancel')}
              variant='outlined'
              color='inherit'
            >
              Abbrechen
            </Button>
            <Button
              disabled={!isButtonCreateEnabled}
              onClick={() => handleCreateCategoryDialog('create')}
              variant='outlined'
              color='inherit'
              autoFocus
            >
              Erstellen
            </Button>
          </DialogActions>
        </Dialog>
        {/** Create Category Dialog end */}
        {/** Not empty Category Dialog */}
        <Dialog
          open={notEmptyCategoryDialogVisible}
          onClose={() => setNotEmptyCategoryDialogVisible(false)}
          aria-labelledby='available-category-title'
          aria-describedby='available-category-description'
        >
          <DialogTitle id='available-category-title'>
            Warrengruppe ist nicht leer!
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='available-favorite-description'>
              Warrengruppe kann nicht deaktiviert werder.
              <br />
              Es sind Artikel in der Warrengruppe vorhanden.
              <br />
              Die Artikel müssen erst gelöscht oder einer anderen Warrengruppe
              zugeordnet werden.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => setNotEmptyCategoryDialogVisible(false)}
              variant='outlined'
              color='inherit'
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        {/** Not empty Category Dialog end */}
        {/** Header */}
        <Box mt={3}>
          <Header2
            title='Warengruppe auswählen oder erstellen.'
            subtitle={`Sie sind angemeldet als ${profile.email}`}
          />
        </Box>
        {/** Header end */}
        {/** Content List */}
        <Box mt={2}>
          {productCategories.map((productCategory: any, index: number) => (
            <FormGroup key={index}>
              <Paper elevation={0} sx={{ p: 0.3, mb: 0.3 }}>
                <FormControlLabel
                  sx={{ ml: 0.1 }}
                  control={
                    <Checkbox
                      checked={productCategory.checked}
                      onChange={() =>
                        handleProductCategory(
                          productCategory.categoryId,
                          productCategory.checked
                        )
                      }
                      color='secondary'
                    />
                  }
                  label={productCategory.categoryName}
                />
              </Paper>
            </FormGroup>
          ))}
        </Box>
        {/** Content List end */}
        {/** Action */}
        <Box
          mt={3}
          mb={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() => {
              setCreateCategoryDialogVisible(true);
              getId();
            }}
          >
            Neu
          </Button>
          <Button
            sx={{
              marginTop: 2,
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() =>
              navigate('/tenantProductCategories', {
                state: {
                  storeId: routerLocation.state.storeId,
                  helpopen: helpOpen,
                },
              })
            }
          >
            Ok
          </Button>
        </Box>
      </Container>
      {helpOpen ? (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
          }}
          style={{ backgroundColor: '#9E9E9E', width: '20%' }}
        >
          <Box
            sx={{
              paddingTop: 1,
              paddingLeft: 1.5,
            }}
          >
            <IconButton onClick={() => setHelpOpen(false)}>
              <AiOutlineClose size='24' />
            </IconButton>
          </Box>
          <TenantEditProductCategoriesHelpView />
        </Box>
      ) : (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
            paddingRight: 1.5,
            paddingTop: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => setHelpOpen(true)}>
              <AiOutlineQuestionCircle size='24' />
            </IconButton>
            <Typography
              onClick={() => setHelpOpen(true)}
              paragraph
              component='span'
              sx={{
                color: theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                  textDecoration: `underline ${theme.palette.text.primary}`,
                },
              }}
            >
              Hilfe
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TenantEditProductCategoryView;
