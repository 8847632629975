import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  FormControl,
  TextField,
  Button,
  useTheme,
} from '@mui/material';
import { IInputError } from '../interfaces/interfaces';
import Header2 from '../components/Header2';
import axios from 'axios';
import { Config } from '../config';

/**
 * ContactView
 *
 */
const ContactView = () => {
  const theme = useTheme();
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [subject, setSubject] = useState<string>('');
  const [subjectError, setSubjectError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [message, setMessage] = useState<string>('');
  const [messageError, setMessageError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [isButtonSendEnabled, setIsButtonSendEnabled] =
    useState<boolean>(false);
  const NODEMAILER_URL: any =
    process.env.REACT_APP_NODEMAILER_URL !== undefined
      ? process.env.REACT_APP_NODEMAILER_URL
      : Config.nodemailer_url;

  /**
   * Button Login Status setzen.
   */
  useEffect(() => {
    if (
      name === '' ||
      email === '' ||
      subject === '' ||
      message === '' ||
      nameError.isError === true ||
      emailError.isError === true ||
      subjectError.isError === true ||
      messageError.isError === true ||
      dataChanged === false
    ) {
      setIsButtonSendEnabled(false);
    } else {
      setIsButtonSendEnabled(true);
    }
  }, [
    name,
    email,
    subject,
    message,
    nameError.isError,
    emailError.isError,
    subjectError.isError,
    messageError.isError,
    dataChanged,
  ]);

  /**
   * name error wird zurückgesetzt.
   */
  const handleNameOnFocus = () => {
    setNameError({
      isError: false,
      message: '',
    });
  };

  /**
   * Wenn name geändert wird.
   * @param e
   */
  const handleNameInput = (e: any) => {
    setName(e.target.value);
    setDataChanged(true);
    const regexp = /^[\p{L} ,.'-]+$/u;
    if (e.target.value === '') {
      setNameError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    } else if (!regexp.test(e.target.value)) {
      setNameError({
        isError: true,
        message: 'Eingabe ist fehlerhaft!',
      });
    } else {
      setNameError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * name Input Validierung
   */
  const checkNameInput = () => {
    const regexp = /^[\p{L} ,.'-]+$/u;
    if (name === '') {
      setNameError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    } else if (!regexp.test(name)) {
      setNameError({
        isError: true,
        message: 'Eingabe ist fehlerhaft!',
      });
    } else {
      setNameError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * email error wird zurückgesetzt.
   */
  const handleEmailOnFocus = () => {
    setEmailError({
      isError: false,
      message: '',
    });
  };

  /**
   * Set Email
   */
  const handleEmailInput = (e: any) => {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setEmail(e.target.value);
    if (regexp.test(e.target.value)) {
      setEmailError({
        isError: false,
        message: '',
      });
    } else {
      setEmailError({
        isError: true,
        message: '',
      });
    }
  };

  /**
   * Funktion zur Validierung der Email.
   */
  const checkEmailInput = () => {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === '') {
      setEmailError({
        isError: true,
        message: 'E-Mail-Adresse ist erforderlich!',
      });
    } else if (regexp.test(email)) {
      // this is a valid email address
      setEmailError({
        isError: false,
        message: '',
      });
    } else {
      // invalid email, maybe show an error to the user.
      setEmailError({
        isError: true,
        message: 'Ist keine gültige E-Mail-Adresse.',
      });
    }
  };

  /**
   * subject error wird zurückgesetzt.
   */
  const handleSubjectOnFocus = () => {
    setSubjectError({
      isError: false,
      message: '',
    });
  };

  /**
   * Wenn subject geändert wird.
   * @param e
   */
  const handleSubjectInput = (e: any) => {
    setSubject(e.target.value);
    setDataChanged(true);
    if (e.target.value !== '') {
      setSubjectError({
        isError: false,
        message: '',
      });
    } else {
      setSubjectError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    }
  };

  /**
   * subject Input Validierung
   */
  const checkSubjectInput = () => {
    if (subject === '') {
      setSubjectError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    } else {
      setSubjectError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * message error wird zurückgesetzt.
   */
  const handleMessageOnFocus = () => {
    setMessageError({
      isError: false,
      message: '',
    });
  };

  /**
   * Wenn message geändert wird.
   * @param e
   */
  const handleMessageInput = (e: any) => {
    setMessage(e.target.value);
    setDataChanged(true);
    if (e.target.value !== '') {
      setMessageError({
        isError: false,
        message: '',
      });
    } else {
      setMessageError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    }
  };

  /**
   * message Input Validierung
   */
  const checkMessageInput = () => {
    if (message === '') {
      setMessageError({
        isError: true,
        message: 'Eingabe ist erforderlich!',
      });
    } else {
      setMessageError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Funktion zum versenden der E-Mail.
   */
  const sendEmail = () => {
    axios({
      baseURL: NODEMAILER_URL,
      method: 'POST',
      url: '/contact',
      data: {
        name,
        email,
        subject,
        message,
      },
    }).then((response) => {
      if (response.data.status === 'success') {
        //alert('Message Sent.');
        resetForm();
      } else if (response.data.status === 'fail') {
        console.log('Message failed to send.');
      }
    });
  };

  /**
   * Reset Form
   */
  const resetForm = () => {
    setName('');
    setEmail('');
    setSubject('');
    setMessage('');
  };

  // return
  return (
    <Container maxWidth='xs'>
      <Box mt={3} mb={2}>
        <Header2 title='Kontakt' subtitle='Bitte ausfüllen und senden..' />
      </Box>
      <Box>
        <FormControl required sx={{ width: '100%' }}>
          <TextField
            id='name'
            name='name'
            sx={{ m: theme.spacing(1, 0, 1) }}
            inputProps={{
              sx: {
                '&:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset`,
                  WebkitTextFillColor: theme.palette.text.primary,
                },
              },
            }}
            variant='standard'
            color='secondary'
            margin='dense'
            required
            label='Name'
            value={name}
            error={nameError.isError}
            helperText={nameError.message}
            onFocus={handleNameOnFocus}
            onChange={(e) => handleNameInput(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkNameInput();
              }
            }}
            onBlur={checkNameInput}
          />
          <TextField
            id='email'
            name='email'
            sx={{ m: theme.spacing(1, 0, 1) }}
            inputProps={{
              sx: {
                '&:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 1000px ${theme.palette.background.default} inset`,
                  WebkitTextFillColor: theme.palette.text.primary,
                },
              },
            }}
            variant='standard'
            color='secondary'
            margin='dense'
            required
            label='E-Mail-Adresse'
            value={email}
            autoComplete='email'
            error={emailError.isError}
            helperText={emailError.message}
            FormHelperTextProps={{
              sx: { color: theme.palette.secondary.main },
            }}
            onFocus={handleEmailOnFocus}
            onChange={(e) => handleEmailInput(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkEmailInput();
              }
            }}
            onBlur={checkEmailInput}
          />
          <TextField
            id='subject'
            name='subject'
            variant='standard'
            color='secondary'
            margin='dense'
            required
            label='Betreff'
            value={subject}
            error={subjectError.isError}
            helperText={subjectError.message}
            FormHelperTextProps={{
              sx: { color: theme.palette.secondary.main },
            }}
            onFocus={handleSubjectOnFocus}
            onChange={(e) => handleSubjectInput(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkSubjectInput();
              }
            }}
            onBlur={checkSubjectInput}
          />
          <TextField
            id='message'
            name='message'
            variant='standard'
            color='secondary'
            margin='dense'
            required
            multiline
            rows={10}
            label='Nachricht'
            value={message}
            error={messageError.isError}
            helperText={messageError.message}
            onFocus={handleMessageOnFocus}
            onChange={(e) => handleMessageInput(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkMessageInput();
              }
            }}
            onBlur={checkMessageInput}
          />
        </FormControl>
      </Box>
      <Box
        mt={4}
        mb={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Button
          type='submit'
          sx={{
            borderRadius: 30,
            width: '240px',
            alignSelf: 'center',
          }}
          disabled={!isButtonSendEnabled}
          variant='contained'
          color='secondary'
          onClick={() => sendEmail()}
        >
          Senden
        </Button>
      </Box>
    </Container>
  );
};

export default ContactView;
