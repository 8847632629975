import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * StoreProductAdminHowToView
 */
const StoreProductAdminHowToView = () => {
  const navigate = useNavigate();

  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={2}>
        <Typography component='h1' variant='h4' align='center' gutterBottom>
          Artikel verwalten
        </Typography>
        <Typography component='h1' variant='h6' align='center' gutterBottom>
          Wie verwalte ich meine Artikel in meinem Geschäft?
        </Typography>
      </Box>
      <Box>
        <Typography component='p' variant='body1' paragraph>
          Die Artikel des Sortiments sind in Warengroppen organisiert. Die
          verwendeten Warengruppen werden in Warengruppen aktiviert oder neu
          erstellt und aktiviert. Klickt man auf das Icon Sortiment sesen Sie
          die verwedeten Warengruppem.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Um ein Artikel zu erstellen klickt man auf das Icon rechts in der
          Warengruppe und in der Warengruppe auf den Butten Neu. Die
          Artikel-Seite besteht auf zwei Registern, Daten und Bilder. Register
          Bilder ist solange deaktiviert bis die Artikeldate eingetragen und
          gespeichert (Übernehmen, der erste Icon unten) sind.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Danach wechselt man in das Register Bilder und kann die Bilder
          auswählen und hochladen. Bis jetzt ist der Artikel noch nicht
          aktiviert. Unten kann man den Artikel aktivieren. Danach ist der
          Artikel online.
        </Typography>
      </Box>
      <Box
        mt={3}
        mb={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type='submit'
          variant='contained'
          color='secondary'
          sx={{ borderRadius: 30, width: '240px' }}
          onClick={() => navigate('/help')}
        >
          Zurück
        </Button>
      </Box>
    </Container>
  );
};

export default StoreProductAdminHowToView;
