import { gql } from '@apollo/client';

// graphql query by zipcode
export const GET_LOCATION_BY_ZIP = gql`
  query getLocationsByZip($param: String!) {
    location(where: { zipcode: { _like: $param } }) {
      id
      zipcode
      place
      state
    }
  }
`;

// graphql query by place
export const GET_LOCATION_BY_PLACE = gql`
  query getLocationsByPlace($param: String!) {
    location(where: { place: { _ilike: $param } }) {
      id
      zipcode
      place
      state
    }
  }
`;

// graphql query by distance
export const GET_LOCATIONS_BY_DISTANCE = gql`
  query getLocationsByIdAndDistance($id: Int!, $dist: Int!) {
    merkantdo_location(args: { locationid: $id, distancekm: $dist }) {
      id
    }
  }
`;
