import React from 'react';
import { Container, Box, Typography } from '@mui/material';

/**
 * TenantStoreHelpView
 *
 */
const TenantStoreHelpView = () => {
  // return
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component='h1' variant='h5'>
          Geschäftsdaten
        </Typography>
        <Typography variant='body1' mt={1}>
          Auf dieser Seite werden die Daten Ihres Geschäfts dargestellt.
        </Typography>
        <Typography variant='body1' mt={1}>
          Bei den Geschäftsdaten handelt es sich um den Namen des Geschäfts, die
          Adresse, Telefonnummer und die Branchen zu der Ihr Geschäft gehört.
          Falls Sie die Branchen ändern oder eine neue hinzufügen wollen klicken
          Sie auf das Icon rechts und wählen die Branche aus die Sie benötigen.
        </Typography>
      </Box>
    </Container>
  );
};

export default TenantStoreHelpView;
