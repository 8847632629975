import React from 'react';
import { Container, Box, Typography } from '@mui/material';

/**
 * TenantLogindataHelpView
 *
 */
const TenantLogindataHelpView = () => {
  // return
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component='h1' variant='h5'>
          Logindaten
        </Typography>
        <Typography variant='body1' mt={1}>
          Hier sehen Sie die Logindaten.
        </Typography>
        <Typography variant='body1' mt={1}>
          Es sind die Emailadresse und das Passwort für das Login. Klicken Sie
          rechts auf das Symbol um Änderungen vorzunehmen.
        </Typography>
      </Box>
    </Container>
  );
};

export default TenantLogindataHelpView;
