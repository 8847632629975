import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * MerkantdoInfoView
 */
const MerkantdoInfoView = () => {
  const navigate = useNavigate();

  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={2}>
        <Typography component='h1' variant='h4' align='center' gutterBottom>
          Was ist merkantdo?
        </Typography>
        <Typography component='h1' variant='h6' align='center' gutterBottom>
          Was ist merkantdo und was kann man damit machen?
        </Typography>
      </Box>
      <Box>
        <Typography component='p' variant='body1' paragraph>
          Merkantdo ist ein Portal für den stationären Handel. Es bietet dem
          Handel die Möglichkeit seine Angebote über das Internet den Kunden zu
          präsentieren und über bestimmte Aktionen zu informieren.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Für den interessierte Kunden eröffnen sich damit völlig neue
          Möglichkeiten. Er kann sich jederzeit über neue Angebore, Aktionen der
          Geschäfte informieren und gezielt die Geschäfte aufsuchen.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Dies ist möglich auf zwei verschiedene Arten. Erstens klassisch über
          das Web im Browser und zweitens über eine App. Mit einer App für iOS
          und Android ist man völlig unabhängig. Man kann sich jederzeit und von
          überall informieren.
        </Typography>
      </Box>
      <Box
        mt={3}
        mb={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type='submit'
          variant='contained'
          color='secondary'
          sx={{ borderRadius: 30, width: '240px' }}
          onClick={() => navigate('/help')}
        >
          Zurück
        </Button>
      </Box>
    </Container>
  );
};

export default MerkantdoInfoView;
