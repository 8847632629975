import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Container, Box, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Header2 from '../components/Header2';

/**
 * TermsAndConditionsView
 *
 */
const TermsAndConditionsView = () => {
  const theme = useTheme();

  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={3} mb={2}>
        <Header2 title='Allgemeine Geschäftsbedingungen' />
      </Box>
      <Box mt={2} mb={4}>
        <Typography mb={2} component='h1' variant='h5'>
          1. Geltungsbereich, Änderungen, Vertragsbeginn
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          1.1 Die nachstehenden Geschäftsbedingungen sind Bestandteil eines
          jeden Vertrages zwischen Peter Klassen GbR, Friedrich-Petri-Str. 34,
          32791 Lage, (im Folgenden: merkantdo) und dem Kunden. Maßgeblich ist
          jeweils die zum Zeitpunkt des Vertragsschlusses gültige Fassung.
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          1.2 Ein Vertragsabschluss setzt die Angabe vollständiger und richtiger
          Daten voraus.
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          1.3 Der Vertrag kommt mit der Freischaltung der Zugangskennung durch
          merkando zustande.
        </Typography>
        <Typography mb={2} component='h1' variant='h5'>
          2. Leistungen der merkantdo
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          2.1 Der Leistungsumfang der einzelnen Dienste ergibt sich aus der zum
          Zeitpunkt der Bestellung aktuellen Beschreibung des Dienstes.
        </Typography>
        <Typography mb={2} component='h1' variant='h5'>
          3. Zahlungsbedingungen
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          3.1 Es gelten die jeweils aktuellen Preise, die unter der
          <RouterLink
            to={'/pricing'}
            style={{
              textDecoration: 'none',
            }}
            color='textPrimary'
          >
            <Typography
              variant='body1'
              sx={{
                mb: 1,
                color: theme.palette.text.primary,
                textDecoration: 'none',
                '&:hover, &:focus, &:active': {
                  color: theme.palette.text.primary,
                  textDecoration: `underline ${theme.palette.text.primary}`,
                },
              }}
            >
              Preisübersicht veröffentlicht sind.
            </Typography>
          </RouterLink>
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          3.2 Die Abrechnung erfolgt durch Rechnungsstellung. Sämtliche Entgelte
          sind dann sofort nach Erhalt der Rechnung zur Zahlung fällig.
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          3.3 Bei der Überschreitung von eventuell in der Rechnung eingeräumten
          Zahlungsfristen sind wir auch ohne Mahnung berechtigt, Verzugszinsen
          zu berechnen. Die Höhe des Verzugszinses beträgt 8 Prozentpunkte über
          dem Basiszinssatz.
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          3.4 Wir sind darüber hinaus berechtigt, im Verzugsfall die
          Internetpräsenz des Kunden zu sperren.
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          3.5 Rechnungen werden per E-Mail als Anhang zugestellt.
        </Typography>
        <Typography mb={2} component='h1' variant='h5'>
          4. Pflichten des Kunden
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          4.1 Bei der Anmeldung hat der Kunde seine Kenndaten (Name, Anschrift,
          etc.) wahrheitsgemäß mitzuteilen.
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          4.2 Der Kunde ist verpflichtet seine Login-Daten vor dem Zugriff
          Dritter zu schützen.
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          4.3 Es ist dem Kunden untersagt, solche Materialen zu veröffentlichen
          oder zu versenden, die gegen geltendes Recht insbesondere dem
          Urheberrecht und dem Strafrecht verstoßen oder sonst wie sittenwidrig
          sind.
        </Typography>
        <Typography mb={2} component='h1' variant='h5'>
          5. Gewährleistung und Haftung der merkantdo
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          5.1 Für unmittelbare Schäden, Folgeschäden oder entgangenen Gewinn
          durch technische Probleme und Störungen innerhalb des Internets, die
          nicht in unserem Einflussbereich liegen, übernehmen wir keine Haftung.
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          5.2 Für Schäden, die durch Datenverluste auf den Servern entstehen,
          haftet merkando nicht außer im Falle eines grobfahrlässigen oder
          vorsätzlichen Verschuldens von merkando, seinen Mitarbeitern oder
          Erfüllungsgehilfen.
        </Typography>
        <Typography mb={2} component='h1' variant='h5'>
          6. Vertragslaufzeit, Kündigung
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          6.1 Soweit vertraglich nichts anderes vereinbart wurde, werden die
          Verträge auf unbefristete Zeit geschlossen.
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          6.2 Der Vertrag ist von beiden Seiten jeweils mit einer Frist von 30
          Tagen zum Monatsende, ohne Angabe von Gründen, kündbar. Eine Kündigung
          kann in Textform per Brief, Fax, E-Mail oder über den gesicherten
          Online-Administrationsbereich erfolgen, soweit diese Möglichkeit zur
          Verfügung steht.
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          6.3 merkando ist darüber hinaus berechtigt, das Vertragsverhältnis aus
          wichtigem Grund ohne Einhaltung einer Frist zu kündigen. Ein solcher
          wichtiger Grund liegt unter anderem dann vor, wenn der Kunde für zwei
          aufeinander folgende Monate mit der Bezahlung sich in Verzug befindet.
        </Typography>
        <Typography mb={2} component='h1' variant='h5'>
          7. Datenschutz
        </Typography>
        <Typography component='div' variant='body1' paragraph>
          7.1 Für den Datenschutz gelten die
          <RouterLink
            to={'/privacy'}
            color='textPrimary'
            style={{
              textDecoration: 'none',
            }}
          >
            <Typography
              variant='body1'
              sx={{
                mb: 1,
                color: theme.palette.text.primary,
                textDecoration: 'none',
                '&:hover, &:focus, &:active': {
                  color: theme.palette.text.primary,
                  textDecoration: `underline ${theme.palette.text.primary}`,
                },
              }}
            >
              Datenschutzbestimmungen der merkantdo.
            </Typography>
          </RouterLink>
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditionsView;
