import React, { useState } from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { PDFViewer, BlobProvider } from '@react-pdf/renderer';
import InvoiceTemplate from './InvoiceTemplate';
import minioClient from '../../minioClient';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import { UPDATE_INVOICE_STATUS } from '../queries/adminQueries';
import { useMutation } from '@apollo/client';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/de';
import { Config } from '../../config';

/**
 * InvoiceView
 */
const InvoiceView = (props: any) => {
  const [invoice, setInvoice] = useState<any>(null);
  const [isButtonSendEnabled, setIsButtonSendEnabled] = useState<boolean>(
    props.childPagePropsFromParent.status === 'open' ? true : false
  );

  // storestatus update
  const [updateInvoiceStatus] = useMutation(UPDATE_INVOICE_STATUS);

  // nodemailer url
  const NODEMAILER_URL: any =
    process.env.REACT_APP_NODEMAILER_URL !== undefined
      ? process.env.REACT_APP_NODEMAILER_URL
      : Config.nodemailer_url;

  // fileName
  const fileName =
    'Rechnung-' +
    props.childPagePropsFromParent.store_id +
    '-' +
    props.childPagePropsFromParent.id +
    '-' +
    moment(props.childPagePropsFromParent.invoicedate).format('L') +
    '.pdf';

  /**
   * upload file in minio server
   */
  const handleInvoiceUpload = async () => {
    const bucket = props.childPagePropsFromParent.store_id + '-data';
    let params = {
      Bucket: bucket,
      Key: fileName,
      Body: invoice,
      ContentType: 'application/pdf',
    };
    // an den minio server senden
    try {
      await minioClient.send(new PutObjectCommand(params));
      // an den nodemailer senden
      try {
        const response = await axios({
          baseURL: NODEMAILER_URL,
          method: 'POST',
          url: '/noreply',
          data: {
            email: props.childPagePropsFromParent.invoiceemail,
            subject: 'Ihre MERKANTDO Rechnung',
            message:
              '\n' +
              'Hallo ' +
              props.childPagePropsFromParent.storeowner +
              ',' +
              '\n' +
              '\nIhre neue Rechnung vom ' +
              moment(props.childPagePropsFromParent.invoicedate).format('L') +
              ' liegt auch auf Ihrem Account bereit.' +
              '\n' +
              '\n' +
              '\nMit freundlichen Grüßen,' +
              '\nIhr MERKANTDO-Kundenservice' +
              '\n' +
              '\nBeachten Sie, dass diese E-Mail-Adresse nur zum Versenden, nicht aber zum Empfang von' +
              '\nE-Mails eingerichtet ist.' +
              '\n' +
              '\nBitte antworten Sie nicht auf diese E-Mail.',
          },
        });
        if (response.data.status === 'success') {
          try {
            await updateInvoiceStatus({
              variables: {
                id: props.childPagePropsFromParent.id,
                status: 'posted',
              },
            });
            setIsButtonSendEnabled(false);
          } catch (error) {
            console.log('Error! Unable update Invoice status:', error);
          }
        }
      } catch (error) {
        console.log('Error! Unable to send Invoice: ', error);
      }
    } catch (error) {
      console.log('Error! Unable to upload Invoice: ' + error);
    }
  };

  // return
  return (
    <Container maxWidth='lg'>
      <Box>
        {props.childPagePropsFromParent.status === 'open' ? (
          <Typography component='h2' variant='h6' align='center' mt={2}>
            Rechnung versenden
          </Typography>
        ) : (
          <Typography component='h2' variant='h6' align='center' mt={2}>
            Rechnung gesendet
          </Typography>
        )}
      </Box>
      <Box
        mt={3}
        mb={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PDFViewer
          showToolbar={false}
          style={{
            width: '100%',
            height: '80vh',
          }}
        >
          <InvoiceTemplate props={props.childPagePropsFromParent} />
        </PDFViewer>
        <Box>
          <BlobProvider
            document={
              <InvoiceTemplate props={props.childPagePropsFromParent} />
            }
          >
            {({ blob, url, loading, error }) => {
              // Do whatever you need with blob here
              loading ? <Typography>Loading...</Typography> : setInvoice(blob);
              return null;
            }}
          </BlobProvider>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            disabled={!isButtonSendEnabled}
            sx={{
              marginTop: 3,
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() => {
              handleInvoiceUpload();
            }}
          >
            Senden
          </Button>
          <Button
            sx={{
              marginTop: 2,
              marginBottom: 4,
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() => {
              props.sendChildPageToParent('invoices');
            }}
          >
            Ok
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default InvoiceView;
