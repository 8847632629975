import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  useTheme,
} from '@mui/material';
import Header2 from '../components/Header2';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../authContext';
import { UPDATE_PASSWORD } from '../data/AccountData';
import { LOGIN_BY_CREDENTIALS } from '../login/queries/loginQueries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { IInputError } from '../interfaces/interfaces';
import CryptoJS from 'crypto-js';

/**
 * Hier wird Password geändert.
 */
const CustomerPasswordView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [{ profile }, dispatch] = useAuth();
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [currentPasswordError, setCurrentPasswordError] = useState<
    Partial<IInputError>
  >({
    isError: false,
    message: '',
  });
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [confirmedPassword, setConfirmedPassword] = useState<string>('');
  const [confirmedPasswordError, setConfirmedPasswordError] = useState<
    Partial<IInputError>
  >({
    isError: false,
    message: '',
  });
  const passwordSecret = 'uk72tab';
  const [isButtonUpdateEnabled, setIsButtonUpdateEnabled] =
    useState<boolean>(false);
  const [isPasswordSecure, setIsPasswordSecure] = useState<boolean>(true);

  // current password überprüfen ob vonhanden ist
  const [checkLogin] = useLazyQuery(LOGIN_BY_CREDENTIALS);

  // password update
  const [updatePassword] = useMutation(UPDATE_PASSWORD);

  /**
   * Handling Update Button Status
   */
  useEffect(() => {
    if (
      currentPassword === '' ||
      password === '' ||
      password !== confirmedPassword ||
      currentPasswordError.isError === true ||
      passwordError.isError === true ||
      confirmedPasswordError.isError === true
    ) {
      setIsButtonUpdateEnabled(false);
    } else {
      setIsButtonUpdateEnabled(true);
    }
  }, [
    currentPassword,
    password,
    confirmedPassword,
    currentPasswordError.isError,
    passwordError.isError,
    confirmedPasswordError.isError,
  ]);

  /**
   * Funktion zum Umschalten des Password-Inputs,
   * sichtbar oder nicht sichtbar.
   */
  const handlePasswordSecure = () => {
    setIsPasswordSecure(!isPasswordSecure);
  };

  /**
   * Current Password error wird zurückgesetzt.
   */
  const handleCurrentPasswordOnFocus = () => {
    setCurrentPasswordError({
      isError: false,
      message: '',
    });
    setPasswordError({
      isError: false,
      message: '',
    });
  };

  /**
   * Current Password wird gesetzt.
   */
  const handleCurrentPasswordInput = (e: any) => {
    setCurrentPassword(e.target.value);
  };

  /**
   * Funktion überprüftt ob das aktuelle
   * Password eingetragen ist.
   */
  const checkCurrentPasswordInput = async () => {
    if (currentPassword === '') {
      setCurrentPasswordError({
        isError: true,
        message: 'Aktuelles Password ist erforderlich!',
      });
    } else {
      try {
        const { data } = await checkLogin({
          variables: { email: profile.email },
          fetchPolicy: 'network-only',
        });
        const bytes = CryptoJS.AES.decrypt(
          data.account[0].password,
          passwordSecret
        );
        if (currentPassword !== bytes.toString(CryptoJS.enc.Utf8)) {
          setCurrentPasswordError({
            isError: true,
            message: 'Aktuelles Passwort ist falsch!',
          });
        }
      } catch (error) {
        console.log(
          'Error! CustomerPasswordView - checkCurrentPasswordInput:',
          error
        );
      }
    }
  };

  /**
   * Current Password wird überprüft und
   * Password Error zurückgesetzt.
   */
  const handlePasswordOnFocus = () => {
    if (currentPassword === '') {
      setCurrentPasswordError({
        isError: true,
        message: 'Aktuelles Password ist erforderlich!',
      });
    } else {
      setCurrentPasswordError({
        isError: false,
        message: '',
      });
      setPasswordError({
        isError: false,
        message: '',
      });
    }
    setPasswordError({
      isError: false,
      message: '',
    });
  };

  /**
   * Password wird gesetzt.
   */
  const handlePasswordInput = (e: any) => {
    setPassword(e.target.value);
  };

  /**
   * Funktion zur Validierung des Passwords.
   * Mindestens 6 Stellen, höchstens 20 Stellen,
   * mindesten ein Großbuchstabe, mindestens ein Kleinbuchstabe
   * und mindestens eine Ziffer.
   */
  const checkPasswordInput = () => {
    const regexp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,20}$/;
    if (password === '') {
      setPasswordError({
        isError: true,
        message: 'Neues Password ist erforderlich!',
      });
    } else if (regexp.test(password)) {
      setPasswordError({
        isError: false,
        message: '',
      });
    } else {
      setPasswordError({
        isError: true,
        message:
          'Muss zwischen 6 und 20 Zeichen sein, mindestens 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Ziffer beinhalten!',
      });
    }
  };

  /**
   * Confirmed Password error wird zurückgesetzt.
   */
  const handleConfirmedPasswordOnFocus = () => {
    if (password === '') {
      setPasswordError({
        isError: true,
        message: 'Neues Password ist erforderlich!',
      });
    }
    setConfirmedPasswordError({
      isError: false,
      message: '',
    });
  };

  /**
   * Confirmed Password wird gesetzt.
   */
  const handleConfirmedPasswordInput = (e: any) => {
    setConfirmedPassword(e.target.value);
    if (e.target.value === password) {
      setConfirmedPasswordError({
        isError: false,
        message: '',
      });
    } else {
      setConfirmedPasswordError({
        isError: true,
        message: 'Passwörter sind nicht gleich!',
      });
    }
  };

  /**
   * Diese Funktion überprüft ob die beiden
   * Einträge für das Passwort gleich sind.
   */
  const checkConfirmedPasswordInput = () => {
    if (confirmedPassword === '') {
      setConfirmedPasswordError({
        isError: true,
        message: 'Neues Password wiederholen!',
      });
    } else if (password !== confirmedPassword) {
      setConfirmedPasswordError({
        isError: true,
        message: 'Passwörter sind nicht gleich!',
      });
    }
  };

  /**
   * Diese Funktion ändert Account password.
   */
  const updateAccountPassword = async () => {
    try {
      await updatePassword({
        variables: {
          id: profile.account_id,
          password: CryptoJS.AES.encrypt(password, passwordSecret).toString(),
        },
      });
      const tempRememberMe: any = localStorage.getItem('rememberMe');
      if (JSON.parse(tempRememberMe) === true) {
        localStorage.setItem(
          'password',
          CryptoJS.AES.encrypt(password, passwordSecret).toString()
        );
      }
      await dispatch({
        type: 'UPDATE_PROFILE',
        payload: {
          account_id: profile.account_id,
          userrole: 'customer',
          customer_id: profile.customer_id,
          email: profile.email,
          password: CryptoJS.AES.encrypt(password, passwordSecret).toString(),
        },
      });
      navigate('/customerLogindata');
    } catch (error) {
      console.log(
        'Error! CustomerPasswordView - updateAccountPassword:',
        error
      );
    }
  };

  // return
  return (
    <Container maxWidth='xs'>
      <Box mt={3}>
        <Header2
          title='Passwort ändern'
          subtitle='Ändern Sie das Passwort für Ihr Merkantdo Konto.'
        />
      </Box>
      <Box p={1} mt={2} sx={{ backgroundColor: theme.palette.primary.main }}>
        <TextField
          id='currentPassword'
          name='currentPassword'
          variant='standard'
          color='secondary'
          margin='dense'
          required
          sx={{ width: '100%' }}
          label='Aktuelles Passwort:'
          value={currentPassword}
          type={isPasswordSecure ? 'password' : 'text'}
          error={currentPasswordError.isError}
          helperText={currentPasswordError.message}
          FormHelperTextProps={{
            sx: { color: theme.palette.secondary.main },
          }}
          onFocus={handleCurrentPasswordOnFocus}
          onChange={(e) => handleCurrentPasswordInput(e)}
          onBlur={checkCurrentPasswordInput}
          InputProps={{
            endAdornment: (
              // eslint-disable-next-line react/jsx-indent
              <InputAdornment position='end'>
                <IconButton color='inherit' onClick={handlePasswordSecure}>
                  {isPasswordSecure ? (
                    <AiOutlineEye size='22' />
                  ) : (
                    <AiOutlineEyeInvisible size='22' />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id='password'
          name='password'
          variant='standard'
          color='secondary'
          margin='dense'
          required
          sx={{ width: '100%' }}
          label='Neues Passwort:'
          value={password}
          type={isPasswordSecure ? 'password' : 'text'}
          error={passwordError.isError}
          helperText={passwordError.message}
          FormHelperTextProps={{
            sx: { color: theme.palette.secondary.main },
          }}
          onFocus={handlePasswordOnFocus}
          onChange={(e) => handlePasswordInput(e)}
          onBlur={checkPasswordInput}
          InputProps={{
            endAdornment: (
              // eslint-disable-next-line react/jsx-indent
              <InputAdornment position='end'>
                <IconButton color='inherit' onClick={handlePasswordSecure}>
                  {isPasswordSecure ? (
                    <AiOutlineEye size='22' />
                  ) : (
                    <AiOutlineEyeInvisible size='22' />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id='confirmedPassword'
          name='confirmedPassword'
          variant='standard'
          color='secondary'
          margin='dense'
          required
          sx={{ width: '100%' }}
          label='Password wiederholen:'
          value={confirmedPassword}
          type={isPasswordSecure ? 'password' : 'text'}
          error={confirmedPasswordError.isError}
          helperText={confirmedPasswordError.message}
          FormHelperTextProps={{
            sx: { color: theme.palette.secondary.main },
          }}
          onFocus={handleConfirmedPasswordOnFocus}
          onChange={(e) => handleConfirmedPasswordInput(e)}
          onBlur={checkConfirmedPasswordInput}
          InputProps={{
            endAdornment: (
              // eslint-disable-next-line react/jsx-indent
              <InputAdornment position='end'>
                <IconButton color='inherit' onClick={handlePasswordSecure}>
                  {isPasswordSecure ? (
                    <AiOutlineEye size='22' />
                  ) : (
                    <AiOutlineEyeInvisible size='22' />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        mt={3}
        mb={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            borderRadius: 30,
            width: '240px',
          }}
          type='submit'
          disabled={!isButtonUpdateEnabled}
          variant='contained'
          color='secondary'
          onClick={updateAccountPassword}
        >
          Übernehmen
        </Button>
        <Button
          sx={{
            marginTop: 2,
            borderRadius: 30,
            width: '240px',
          }}
          type='submit'
          variant='contained'
          color='secondary'
          onClick={() => navigate('/customerLogindata')}
        >
          Abbrechen
        </Button>
      </Box>
    </Container>
  );
};

export default CustomerPasswordView;
