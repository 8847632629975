import { gql } from '@apollo/client';

// graphql insert account
export const ADD_ACCOUNT = gql`
  mutation addAccount($account: account_insert_input!) {
    insert_account_one(object: $account) {
      id
      email
      password
      userrole
    }
  }
`;

// graphql query get account
export const GET_ACCOUNT = gql`
  query getAccount($email: String!) {
    account(where: { email: { _eq: $email } }) {
      id
      email
      password
      userrole
    }
  }
`;

// graphql update email
export const UPDATE_EMAIL = gql`
  mutation updateEmail($id: Int!, $email: String!) {
    update_account_by_pk(pk_columns: { id: $id }, _set: { email: $email }) {
      id
      email
    }
  }
`;

// graphql update password
export const UPDATE_PASSWORD = gql`
  mutation updatePassword($id: Int!, $password: String!) {
    update_account_by_pk(
      pk_columns: { id: $id }
      _set: { password: $password }
    ) {
      id
      password
    }
  }
`;
