import { gql } from '@apollo/client';

// graphql query tickets count
export const GET_ALL_TICKETS_COUNT = gql`
  query getAllTicketsCount {
    ticket_aggregate {
      aggregate {
        totalCount: count
      }
    }
  }
`;

// graphql query open tickets count
export const GET_OPEN_TICKETS_COUNT = gql`
  query getOpenTicketsCount {
    ticket_aggregate(where: { status: { _eq: "open" } }) {
      aggregate {
        totalCount: count
      }
    }
  }
`;

// graphql query get tickets offset
export const GET_TICKETS_OFFSET = gql`
  query getTicketsOffset($limit: Int!, $offset: Int!, $sortOrder: order_by!) {
    ticket(limit: $limit, offset: $offset, order_by: { id: $sortOrder }) {
      id
      store_id
      store {
        location {
          zipcode
          place
        }
        storename
      }
      status
      category
      subject
      contact
      phone
      email
      ticket
      answer
      created
      answered
      closed
    }
  }
`;

// graphql mutation delete ticket
export const REMOVE_TICKET = gql`
  mutation removeTicket($id: Int!) {
    delete_ticket(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

// graphql answer ticket answer by id
export const ANSWER_TICKET_BY_ID = gql`
  mutation update_ticket(
    $id: Int!
    $status: merkantdo_ticketstatus!
    $answer: String!
    $answered: timestamp!
  ) {
    update_ticket_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status, answer: $answer, answered: $answered }
    ) {
      id
      status
      answer
      answered
    }
  }
`;

// graphql close ticket by id
export const CLOSE_TICKET_BY_ID = gql`
  mutation update_ticket(
    $id: Int!
    $status: merkantdo_ticketstatus!
    $closed: timestamp!
  ) {
    update_ticket_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status, closed: $closed }
    ) {
      id
      status
      closed
    }
  }
`;

// graphql query all stores count
export const GET_ALL_STORES_COUNT = gql`
  query getAllStoresCount {
    store_aggregate {
      aggregate {
        totalCount: count
      }
    }
  }
`;

// graphql query new stores count
export const GET_NEW_STORES_COUNT = gql`
  query getNewStoresCount {
    store_aggregate(where: { storestatus: { _eq: "new" } }) {
      aggregate {
        totalCount: count
      }
    }
  }
`;

// graphql query get stores offset
export const GET_STORES_OFFSET = gql`
  query getStoresOffset($limit: Int!, $offset: Int!, $sortOrder: order_by!) {
    store(limit: $limit, offset: $offset, order_by: { id: $sortOrder }) {
      id
      storestatus
      location {
        zipcode
        place
      }
      storename
      storeowner
      street
      created
      activated
      invoice
      terminated
    }
  }
`;

// graphql update storestatus
export const UPDATE_STORE_STATUS = gql`
  mutation updateStoreStatus($id: Int!, $storestatus: merkantdo_storestatus!) {
    update_store_by_pk(
      pk_columns: { id: $id }
      _set: { storestatus: $storestatus }
    ) {
      id
      storestatus
    }
  }
`;

// graphql query storebranches count
export const GET_ALL_STOREBRANCHES_COUNT = gql`
  query getAllStoreBranchesCount {
    storebranch_aggregate {
      aggregate {
        totalCount: count
      }
    }
  }
`;

// graphql query get storebranches offset
export const GET_STOREBRANCHES_OFFSET = gql`
  query getStorebranchesOffset(
    $limit: Int!
    $offset: Int!
    $sortOrder: order_by!
  ) {
    storebranch(
      limit: $limit
      offset: $offset
      order_by: { branchname: $sortOrder }
    ) {
      id
      branchname
    }
  }
`;

// graphql query get storebranches id
export const GET_STOREBRANCHES_ID = gql`
  query getStorebranchesId {
    storebranch(order_by: { id: desc }) {
      id
    }
  }
`;

// graphql update storebranch
export const UPDATE_STOREBRANCH = gql`
  mutation updateStorebranch($id: Int!, $branchname: String!) {
    update_storebranch_by_pk(
      pk_columns: { id: $id }
      _set: { branchname: $branchname }
    ) {
      id
      branchname
    }
  }
`;

// graphql query check branch
export const CHECK_BRANCHNAME = gql`
  query checkBranchname($branchname: String!) {
    storebranch(where: { branchname: { _eq: $branchname } }) {
      id
      branchname
    }
  }
`;

// graphql insert storebranch
export const ADD_STOREBRANCH = gql`
  mutation addStorebranch($storebranch: storebranch_insert_input!) {
    insert_storebranch_one(object: $storebranch) {
      id
      branchname
    }
  }
`;

// graphql mutation delete storebranch
export const REMOVE_STOREBRANCH = gql`
  mutation removeStorebranch($id: Int!) {
    delete_storebranch(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

// graphql query productcategories count
export const GET_ALL_PRODUCTCATEGORIES_COUNT = gql`
  query getAllProductcategoriesCount {
    productcategory_aggregate {
      aggregate {
        totalCount: count
      }
    }
  }
`;

// graphql query get productcategories offset
export const GET_PRODUCTCATEGORIES_OFFSET = gql`
  query getProductcategoriesOffset(
    $limit: Int!
    $offset: Int!
    $sortOrder: order_by!
  ) {
    productcategory(
      limit: $limit
      offset: $offset
      order_by: { categoryname: $sortOrder }
    ) {
      id
      categoryname
    }
  }
`;

// graphql query get productcategory id
export const GET_PRODUCTCATEGORIES_ID = gql`
  query getProductcategoriesId {
    productcategory(order_by: { id: desc }) {
      id
    }
  }
`;

// graphql update productcategory
export const UPDATE_PRODUCTCATEGORY = gql`
  mutation updateProductcategory($id: Int!, $categoryname: String!) {
    update_productcategory_by_pk(
      pk_columns: { id: $id }
      _set: { categoryname: $categoryname }
    ) {
      id
      categoryname
    }
  }
`;

// graphql insert productcategory
export const ADD_PRODUCTCATEGORY = gql`
  mutation addProductcategory($productcategory: productcategory_insert_input!) {
    insert_productcategory_one(object: $productcategory) {
      id
      categoryname
    }
  }
`;

// graphql query check productcategory
export const CHECK_CATEGORYNAME = gql`
  query checkProductcategory($categoryname: String!) {
    productcategory(where: { categoryname: { _eq: $categoryname } }) {
      id
      categoryname
    }
  }
`;

// graphql mutation delete productcategory
export const REMOVE_PRODUCTCATEGORY = gql`
  mutation removeProductcategory($id: Int!) {
    delete_productcategory(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

// graphql query all invoices count
export const GET_ALL_INVOICES_COUNT = gql`
  query getAllInvoicesCount {
    invoice_aggregate {
      aggregate {
        totalCount: count
      }
    }
  }
`;

// graphql query open invoices count
export const GET_OPEN_INVOICES_COUNT = gql`
  query getOpenInvoicesCount {
    invoice_aggregate (where: { status: { _neq: "billpaid" } }) {
      aggregate {
        totalCount: count
      }
    }
  }
`;

// graphql query get invoices offset
export const GET_INVOICES_OFFSET = gql`
  query getInvoicesOffset($limit: Int!, $offset: Int!, $sortOrder: order_by!) {
    invoice(limit: $limit, offset: $offset, order_by: { id: $sortOrder }) {
      id
      store_id
      storeservice_id
      invoicedate
      serviceprice
      status
      storeservice {
        servicetype {
          servicename
        }
        store {
          storeowner
          storename
          street
          location {
            zipcode
            place
          }
          tenant {
            id
            invoiceemail
          }
          storestatus
        }
      }
    }
  }
`;

// graphql query get invoices
export const GET_INVOICES = gql`
  query getInvoices {
    invoice(order_by: { id: desc }) {
      id
      store_id
      storeservice_id
      invoicedate
      serviceprice
      status
      storeservice {
        servicetype {
          servicename
        }
        store {
          storeowner
          storename
          street
          location {
            zipcode
            place
          }
          tenant {
            id
            invoiceemail
          }
        }
      }
    }
  }
`;

// graphql update invoicestatus
export const UPDATE_INVOICE_STATUS = gql`
  mutation updateInvoiceStatus($id: Int!, $status: merkantdo_invoicestatus!) {
    update_invoice_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
      id
      status
    }
  }
`;
