import React from 'react';
import { Container, Box, Typography, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Header2 from '../components/Header2';

/**
 * ImprintView
 *
 */
const ImprintView = () => {
  const theme = useTheme();

  // mail client aufrufen
  const sendMail = () => {
    const mailto = 'mailto:info@merkantdo.de';
    window.location.href = mailto;
  };

  // return
  return (
    <Container maxWidth='xs'>
      <Box mt={3} mb={2}>
        <Header2 title='Impressum' />
      </Box>
      <Box mt={4}>
        <Typography component='p' variant='body1' align='left' mb={1}>
          Peter Klassen
        </Typography>
        <Typography component='p' variant='body1' align='left' mb={1}>
          Friedrich-Petri-Str. 34
        </Typography>
        <Typography component='p' variant='body1' align='left' mb={1}>
          32791 Lage
        </Typography>
        <Typography component='p' variant='body1' align='left' mb={1}>
          Deutschland
        </Typography>
        <Typography component='p' variant='body1' align='left' mb={1}>
          Tel.: 0155 61910490
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            marginBottom: 1,
          }}
        >
          <Typography component='p' variant='body1' align='left'>
            E-Mail:&nbsp;
          </Typography>
          <RouterLink
            style={{
              textDecoration: 'none',
            }}
            to='#'
            onClick={() => sendMail()}
          >
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                  textDecoration: `underline ${theme.palette.text.primary}`,
                },
              }}
            >
              info@merkantdo.de
            </Typography>
          </RouterLink>
        </Box>
      </Box>
    </Container>
  );
};

export default ImprintView;
