import React, { useState } from 'react';
import {
  Container,
  Box,
  Paper,
  Typography,
  Button,
  IconButton,
  useTheme,
} from '@mui/material';
import Header2 from '../components/Header2';
import { AiOutlineEdit } from 'react-icons/ai';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { AiOutlineQuestionCircle, AiOutlineClose } from 'react-icons/ai';
import { useAuth } from '../authContext';
import TenantLogindataHelpView from './help/TenantLogindataHelpView';

/**
 * TenantLogindataView stellt die Daten
 * beim Login eines Tenants dar.
 */
const TenantLogindataView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [{ profile }] = useAuth();
  const routerLocation: any = useRouterLocation();
  const [helpOpen, setHelpOpen] = useState<boolean>(
    routerLocation.state.helpopen
  );

  // return
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      style={{ width: '100%' }}
    >
      <Container maxWidth='xs'>
        <Box mt={3}>
          <Header2
            title='Logindaten'
            subtitle='Hier haben Sie die Möglichkeit Ihre Logindaten zu ändern.'
          />
        </Box>
        <Box mt={2}>
          <Paper elevation={0} sx={{ p: 1.5, mb: 0.5 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant='caption'
                sx={{ fontWeight: theme.typography.fontWeightMedium }}
              >
                E-Mail:
              </Typography>
              <IconButton
                color='inherit'
                size='small'
                onClick={() =>
                  navigate('/tenantEmail', {
                    state: {
                      helpopen: helpOpen,
                    },
                  })
                }
              >
                <AiOutlineEdit size='22' />
              </IconButton>
            </Box>
            <div>
              <Typography variant='body1'>{profile.email}</Typography>
            </div>
          </Paper>
          <Paper elevation={0} sx={{ p: 1.5, mb: 1 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                variant='caption'
                sx={{ fontWeight: theme.typography.fontWeightMedium }}
              >
                Passwort:
              </Typography>
              <IconButton
                color='inherit'
                size='small'
                onClick={() =>
                  navigate('/tenantPassword', {
                    state: {
                      helpopen: helpOpen,
                    },
                  })
                }
              >
                <AiOutlineEdit size='22' />
              </IconButton>
            </Box>
            <Box>
              <Typography variant='body1'>******</Typography>
            </Box>
          </Paper>
        </Box>
        <Box
          mt={3}
          mb={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() =>
              navigate('/tenant', {
                state: {
                  helpopen: helpOpen,
                },
              })
            }
          >
            Ok
          </Button>
        </Box>
      </Container>
      {helpOpen ? (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
          }}
          style={{ backgroundColor: '#9E9E9E', width: '20%' }}
        >
          <Box
            sx={{
              paddingTop: 1,
              paddingLeft: 1.5,
            }}
          >
            <IconButton onClick={() => setHelpOpen(false)}>
              <AiOutlineClose size='24' />
            </IconButton>
          </Box>
          <TenantLogindataHelpView />
        </Box>
      ) : (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
            paddingRight: 1.5,
            paddingTop: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => setHelpOpen(true)}>
              <AiOutlineQuestionCircle size='24' />
            </IconButton>
            <Typography
              onClick={() => setHelpOpen(true)}
              paragraph
              component='span'
              sx={{
                color: theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                  textDecoration: `underline ${theme.palette.text.primary}`,
                },
              }}
            >
              Hilfe
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TenantLogindataView;
