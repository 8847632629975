import { createTheme } from '@mui/material/styles';

const darkTheme = (createTheme as any)({
  palette: {
    mode: 'dark',
    primary: {
      main: '#141a21', // dunkelgrau: header, footer, sidebars
    },
    secondary: {
      main: '#E61E1F', // rot: buttons, slider
    },
    background: {
      default: '#263238', // dunkelgrau: content
      paper: '#141a21', // paper
    },
    error: {
      main: '#E61E1F', // rot: error
    },
  },
  typography: {
    fontSize: 12,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 450,
    fontWeightBold: 600,
    // textTransform: 'none',
    button: {
      textTransform: 'none',
    },
    h1: {
      fontSize: 36,
    },
    h2: {
      fontSize: 30,
    },
    h3: {
      fontSize: 26,
    },
    h4: {
      fontSize: 22,
      fontWeight: 380,
    },
    h5: {
      fontSize: 20,
      fontWeight: 380,
    },
    h6: {
      fontSize: 18,
      fontWeight: 380,
    },
    subtitle1: {
      fontSize: 14,
      fontWeight: 380,
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 380,
    },
    body1: {
      fontSize: 14,
      fontWeight: 380,
    },
    body2: {
      fontSize: 15,
      fontWeight: 450,
    },
    caption: {
      fontSize: 13,
      fontWeight: 380,
    },
  },
});

export default darkTheme;
