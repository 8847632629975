import React from 'react';
import { Container, Box, Typography } from '@mui/material';

/**
 * TenantMessagesHelpView
 *
 */
const TenantMessagesHelpView = () => {
  // return
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component='h1' variant='h5'>
          Nachrichten - Artikel
        </Typography>
        <Typography variant='body1' mt={1}>
          Dies sind die Nanchrichten Ihrer Kunden zum Artikel.
        </Typography>
        <Typography variant='body1' mt={1}>
          Die Verwaltung der Nachrichten ist aufgebaut als Chat-System.
        </Typography>
      </Box>
    </Container>
  );
};

export default TenantMessagesHelpView;
