import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  InputLabel,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  Button,
  useTheme,
} from '@mui/material';
import Header2 from '../components/Header2';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { ADD_TICKET } from './queries/tenantQueries';
import { IInputError } from '../interfaces/interfaces';
import { useMutation } from '@apollo/client';

/**
 * Ein neues Ticket erstellen.
 */
const TenantTicketNewView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const routerLocation: any = useRouterLocation();
  const [category, setCategory] = useState<string>('');
  const [categoryError, setCategoryError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [subject, setSubject] = useState<string>('');
  const [subjectError, setSubjectError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [contact, setContact] = useState<string>('');
  const [contactError, setContactError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [phone, setPhone] = useState<string>('');
  const [phoneError, setPhoneError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [ticket, setTicket] = useState<string>('');
  const [ticketError, setTicketError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [isButtonSendEnabled, setIsButtonSendEnabled] =
    useState<boolean>(false);
  const [addTicket] = useMutation(ADD_TICKET);

  /**
   * Handling Send Button Status.
   */
  useEffect(() => {
    if (
      category === '' ||
      contact === '' ||
      phone === '' ||
      email === '' ||
      ticket === '' ||
      categoryError.isError === true ||
      contactError.isError === true ||
      phoneError.isError === true ||
      emailError.isError === true ||
      ticketError.isError === true
    ) {
      setIsButtonSendEnabled(false);
    } else {
      setIsButtonSendEnabled(true);
    }
  }, [
    category,
    categoryError.isError,
    contact,
    contactError.isError,
    email,
    emailError.isError,
    phone,
    phoneError.isError,
    ticket,
    ticketError.isError,
  ]);

  /**
   * Event zum setzen der category im State.
   * @param event
   */
  const handleCategoryInput = (event: any) => {
    setCategory(event.target.value);
    if (category !== '') {
      setCategoryError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * category Input Validierung.
   */
  const checkCategoryInput = () => {
    if (category === '') {
      setCategoryError({
        isError: true,
        message: 'Kategorie ist erforderlich!',
      });
    } else {
      setCategoryError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Wenn onFocus subject error reset.
   */
  const handleSubjectOnFocus = () => {
    setSubjectError({
      isError: false,
      message: '',
    });
  };

  /**
   * Event zum setzen des Betreff im State.
   * @param event
   */
  const handleSubjectInput = (event: any) => {
    setSubject(event.target.value);
    if (subject !== '') {
      setSubjectError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * subject Input Validierung.
   */
  const checkSubjectInput = () => {
    if (subject === '') {
      setSubjectError({
        isError: true,
        message: 'Betreff ist erforderlich!',
      });
    } else {
      setSubjectError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Wenn OnFocus contact error reset.
   */
  const handleContactOnFocus = () => {
    setContactError({
      isError: false,
      message: '',
    });
  };

  /**
   * Event zum setzen der Kontaktperson im State.
   * @param event
   */
  const handleContactInput = (event: any) => {
    setContact(event.target.value);
    if (contact !== '') {
      setContactError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * contact Input Validierung.
   */
  const checkContactInput = () => {
    if (contact === '') {
      setContactError({
        isError: true,
        message: 'Kontaktperson ist erforderlich!',
      });
    } else {
      setContactError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Wenn OnFocus phone error reset.
   */
  const handlePhoneOnFocus = () => {
    setPhoneError({
      isError: false,
      message: '',
    });
  };

  /**
   * Event zum setzen der Telefonnummer im State.
   * @param event
   */
  const handlePhoneInput = (event: any) => {
    setPhone(event.target.value);
    if (phone !== '') {
      setPhoneError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * phone Input Validierung.
   */
  const checkPhoneInput = () => {
    const regexp = /^([0-9()/+ -]*)$/;
    if (phone === '') {
      setPhoneError({
        isError: true,
        message: 'Telefonnummer ist erforderlich!',
      });
    } else if (!regexp.test(phone)) {
      setPhoneError({
        isError: true,
        message: 'Ist keine gültige Telefonnummer.',
      });
    } else {
      setPhoneError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Wenn OnFocus email error reset.
   */
  const handleEmailOnFocus = () => {
    setEmailError({
      isError: false,
      message: '',
    });
  };

  /**
   * Event zum setzen der E-Mail im State.
   * @param event
   */
  const handleEmailInput = (event: any) => {
    setEmail(event.target.value);
    if (email !== '') {
      setEmailError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * email Input Validierung.
   */
  const checkEmailInput = () => {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email === '') {
      setEmailError({
        isError: true,
        message: 'E-Mail ist erforderlich!',
      });
    } else if (!regexp.test(email)) {
      setEmailError({
        isError: true,
        message: 'Ist keine gültige E-Mail-Adresse.',
      });
    } else {
      setEmailError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Wenn On Focus reset.
   */
  const handleTicketOnFocus = () => {
    setTicketError({
      isError: false,
      message: '',
    });
  };

  /**
   * Event zum setzen des ticket im State.
   * @param event
   */
  const handleTicketInput = (event: any) => {
    setTicket(event.target.value);
    if (ticket !== '') {
      setTicketError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Input Validierung des ticket.
   */
  const checkTicketInput = () => {
    if (ticket === '') {
      setTicketError({
        isError: true,
        message: 'Fehlerbeschreibung ist erforderlich!',
      });
    } else {
      setTicketError({
        isError: false,
        message: '',
      });
    }
  };

  // return
  return (
    <Container maxWidth='xs'>
      <Box mt={3}>
        <Header2
          title='Neues Ticket'
          subtitle='Bitte füllen Sie folgendes Formular aus und geben Sie uns möglichst
          genaue Angaben, so dass wir Ihre Anfrage schnellstmöglich bearbeiten
          können.'
        />
        <Box>
          <FormControl sx={{ width: '100%' }} variant='standard'>
            <InputLabel id='select-category-label' color='secondary'>
              Kategorie *
            </InputLabel>
            <Select
              labelId='select-category-label'
              id='select-category'
              variant='standard'
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
              }}
              value={category}
              onChange={(e) => handleCategoryInput(e)}
              onBlur={checkCategoryInput}
            >
              <MenuItem value={'invoice'}>Rechnung</MenuItem>
              <MenuItem value={'support'}>Support</MenuItem>
              <MenuItem value={'contract'}>Vertrag</MenuItem>
            </Select>
            <FormHelperText error={categoryError.isError}>
              {categoryError.message}
            </FormHelperText>
          </FormControl>
          <Box>
            <TextField
              sx={{ width: '100%' }}
              color='secondary'
              variant='standard'
              required
              label='Betreff'
              autoComplete='off'
              type='text'
              value={subject}
              error={subjectError.isError}
              helperText={subjectError.message}
              onFocus={() => {
                handleSubjectOnFocus();
                checkCategoryInput();
              }}
              onChange={(e) => handleSubjectInput(e)}
              onBlur={checkSubjectInput}
            />
            <TextField
              sx={{ width: '100%' }}
              color='secondary'
              variant='standard'
              required
              label='Kontaktperson'
              autoComplete='off'
              type='text'
              value={contact}
              error={contactError.isError}
              helperText={contactError.message}
              onFocus={handleContactOnFocus}
              onChange={(e) => handleContactInput(e)}
              onBlur={checkContactInput}
            />
            <TextField
              sx={{ width: '100%' }}
              color='secondary'
              variant='standard'
              required
              label='Telefonnummer'
              autoComplete='off'
              type='text'
              value={phone}
              error={phoneError.isError}
              helperText={phoneError.message}
              onFocus={handlePhoneOnFocus}
              onChange={(e) => handlePhoneInput(e)}
              onBlur={checkPhoneInput}
            />
            <TextField
              sx={{ width: '100%' }}
              color='secondary'
              variant='standard'
              required
              label='E-Mail'
              autoComplete='off'
              type='text'
              value={email}
              error={emailError.isError}
              helperText={emailError.message}
              onFocus={handleEmailOnFocus}
              onChange={(e) => handleEmailInput(e)}
              onBlur={checkEmailInput}
            />
          </Box>
          <Box mt={2}>
            <Typography variant='body1' color='textSecondary' sx={{ mb: 1 }}>
              Frage/Fehlerbeschreibung *
            </Typography>
            <TextField
              multiline
              sx={{ width: '100%', background: theme.palette.primary.main }}
              rows={10}
              variant='standard'
              value={ticket}
              error={ticketError.isError}
              helperText={ticketError.message}
              onFocus={handleTicketOnFocus}
              onChange={(e) => handleTicketInput(e)}
              onBlur={checkTicketInput}
            />
          </Box>
        </Box>
      </Box>
      <Box
        mt={2}
        mb={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type='submit'
          sx={{
            borderRadius: 30,
            width: '240px',
          }}
          disabled={!isButtonSendEnabled}
          variant='contained'
          color='secondary'
          onClick={() => {
            addTicket({
              variables: {
                ticket: {
                  id: undefined,
                  store_id: routerLocation.state.storeid,
                  status: undefined,
                  category: category,
                  subject: subject,
                  contact: contact,
                  phone: phone,
                  email: email,
                  ticket: ticket,
                  answer: undefined,
                  created: undefined,
                  answered: undefined,
                },
              },
            });
            navigate('/tenant');
          }}
        >
          Senden
        </Button>
        <Button
          type='submit'
          sx={{
            mt: 2,
            borderRadius: 30,
            width: '240px',
          }}
          variant='contained'
          color='secondary'
          onClick={() => navigate('/tenant')}
        >
          Abbrechen
        </Button>
      </Box>
    </Container>
  );
};

export default TenantTicketNewView;
