import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import Header2 from '../components/Header2';

/**
 * AppsView
 *
 */
const AppsView = () => {
  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={3} mb={2}>
        <Header2 title='Unsere Apps' />
      </Box>
      <Box>
        <Typography component='h1' variant='h5' align='center'>
          Apps für Android und iOS
        </Typography>
      </Box>
      <Box pt={2}>
        <Typography component='p' variant='body1' paragraph>
          Unsere Apps für Android und iOS sind in der Testphase und in Kürze
          verfügbar.
        </Typography>
      </Box>
    </Container>
  );
};

export default AppsView;
