import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import 'moment/locale/de';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: '100%',
  },
  header: {
    margin: 20,
    padding: 20,
    width: '45%',
    height: '100',
  },
  headerText: {
    fontSize: 38,
    color: '#e61e1f',
  },
  subHeader: {
    marginTop: 20,
    marginLeft: 20,
    width: '45%',
    height: '20',
  },
  subHeaderText: {
    paddingLeft: 20,
    fontSize: 10,
    textDecoration: 'underline',
  },
  contentHeader: {
    flexDirection: 'row',
    margin: 20,
    marginTop: 0,
    marginBottom: 0,
    padding: 20,
    paddingTop: 0,
    width: '94%',
    height: '120',
  },
  contentSubHeader: {
    flexDirection: 'column',
    margin: 20,
    marginTop: 0,
    marginBottom: 0,
    padding: 20,
    width: '94%',
    height: '60',
  },
  content: {
    margin: 20,
    marginTop: 0,
    padding: 20,
    flexGrow: 1,
  },
  contentText: {
    fontSize: 12,
  },
  address: {
    flexDirection: 'column',
    width: '50%',
    height: '100%',
  },
  addressText: {
    fontSize: 12,
  },
  data: {
    flexDirection: 'row',
    marginLeft: 40,
    width: '40%',
    height: '100%',
  },
  dataText: {
    fontSize: 11,
  },
  footer: {
    margin: 20,
    padding: 20,
    flexGrow: 1,
  },
});

// Create Template Component
const InvoiceTemplate = (props: any) => {
  return (
    <Document
      title={
        'Rechnung-' +
        props.props.store_id +
        '-' +
        props.props.id +
        '-' +
        moment(props.props.invoicedate).format('L')
      }
    >
      <Page size='A4' style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.headerText}>merkantdo</Text>
        </View>
        <View style={styles.subHeader}>
          <Text style={styles.subHeaderText}>
            Peter Klassen - Friedrich-Petri-Str. 34 - 32791 Lage
          </Text>
        </View>
        <View style={styles.contentHeader}>
          <View style={styles.address}>
            <Text style={styles.addressText}>{props.props.storeowner}</Text>
            <Text style={styles.addressText}>{props.props.storename}</Text>
            <Text style={[styles.addressText, { marginTop: 4 }]}>
              {props.props.street}
            </Text>
            <Text style={styles.addressText}>
              {props.props.zipcode + ' '}
              {props.props.place}
            </Text>
            <Text style={styles.addressText}>Deutschland</Text>
          </View>
          <View style={styles.data}>
            <View
              style={{
                flexDirection: 'column',
                width: 80,
              }}
            >
              <Text style={styles.dataText}>KundenNr.: </Text>
              <Text style={[styles.dataText, { marginTop: 4 }]}>
                RechnungsNr.:{' '}
              </Text>
              <Text style={[styles.dataText, { marginTop: 4 }]}>Datum: </Text>
              <Text style={[styles.dataText, { marginTop: 4 }]}>Telefon: </Text>
            </View>
            <View
              style={{
                flexDirection: 'column',
                width: 120,
              }}
            >
              <Text style={styles.dataText}>{props.props.store_id}</Text>
              <Text style={[styles.dataText, { marginTop: 4 }]}>
                {props.props.id}
              </Text>
              <Text style={[styles.dataText, { marginTop: 4 }]}>
                {moment(props.props.invoicedate).format('L')}
              </Text>
              <Text style={[styles.dataText, { marginTop: 4 }]}>
                +49 (0)5232 962203
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.contentSubHeader}>
          <View
            style={{
              width: '50%',
              height: 60,
            }}
          >
            <Text>Rechnung</Text>
          </View>
        </View>
        <View style={styles.content}>
          <View
            style={{
              flexDirection: 'row',
              backgroundColor: '#CCCCCC',
              height: 24,
              paddingTop: 6,
            }}
          >
            <Text style={{ fontSize: 12, width: 60 }}>Pos.</Text>
            <Text style={{ fontSize: 12, width: 240 }}>Bezeichnung</Text>
            <Text style={{ fontSize: 12, width: 100 }}>Menge</Text>
            <Text style={{ fontSize: 12, width: 140 }}>Einzelpreis</Text>
            <Text style={{ fontSize: 12, width: 100 }}>Rabatt</Text>
            <Text style={{ fontSize: 12, width: 100 }}>MwSt</Text>
            <Text style={{ fontSize: 12, width: 140 }}>Gesamtpreis</Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              height: 24,
              paddingTop: 6,
            }}
          >
            <Text style={{ fontSize: 12, width: 60 }}>1</Text>
            <Text style={{ fontSize: 12, width: 240 }}>
              {props.props.servicename}
            </Text>
            <Text style={{ fontSize: 12, width: 100 }}>1</Text>
            <Text style={{ fontSize: 12, width: 140 }}>
              {parseFloat(props.props.serviceprice)
                .toFixed(2)
                .replace('.', ',')}{' '}
              €
            </Text>
            <Text style={{ fontSize: 12, width: 100 }}>
              {props.props.storestatus === 'free'
                ? parseFloat(props.props.serviceprice)
                    .toFixed(2)
                    .replace('.', ',')
                : parseFloat('0').toFixed(2).replace('.', ',')}{' '}
              €
            </Text>
            <Text style={{ fontSize: 12, width: 100 }}>19,00 %</Text>
            <Text style={{ fontSize: 12, width: 140 }}>
              {props.props.storestatus === 'free'
                ? parseFloat('0').toFixed(2).replace('.', ',')
                : ((parseFloat(props.props.serviceprice) / 100) * 119)
                    .toFixed(2)
                    .replace('.', ',')}{' '}
              €
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              height: 4,
              paddingTop: 6,
              border: 0,
            }}
          >
            <Text style={{ fontSize: 12, width: 60 }}></Text>
            <Text style={{ fontSize: 12, width: 100 }}></Text>
            <Text style={{ fontSize: 12, width: 100 }}></Text>
            <Text style={{ fontSize: 12, width: 140 }}></Text>
            <Text style={{ fontSize: 12, width: 240 }}></Text>
            <Text style={{ fontSize: 12, width: 100 }}></Text>
            <Text style={{ fontSize: 12, width: 140 }}></Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              height: 24,
              paddingTop: 6,
            }}
          >
            <Text style={{ fontSize: 12, width: 60 }}></Text>
            <Text style={{ fontSize: 12, width: 100 }}></Text>
            <Text style={{ fontSize: 12, width: 100 }}></Text>
            <Text style={{ fontSize: 12, width: 140 }}></Text>
            <Text style={{ fontSize: 12, width: 240 }}>Gesamtnettopreis </Text>
            <Text style={{ fontSize: 12, width: 100 }}></Text>
            <Text style={{ fontSize: 12, width: 140 }}>
              {props.props.storestatus === 'free'
                ? parseFloat('0').toFixed(2).replace('.', ',')
                : parseFloat(props.props.serviceprice)
                    .toFixed(2)
                    .replace('.', ',')}{' '}
              €
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              height: 24,
              paddingTop: 6,
            }}
          >
            <Text style={{ fontSize: 12, width: 60 }}></Text>
            <Text style={{ fontSize: 12, width: 100 }}></Text>
            <Text style={{ fontSize: 12, width: 100 }}></Text>
            <Text style={{ fontSize: 12, width: 140 }}></Text>
            <Text style={{ fontSize: 12, width: 240 }}>Mehrwertsteuer</Text>
            <Text style={{ fontSize: 12, width: 100 }}>19,00 %</Text>
            <Text style={{ fontSize: 12, width: 140 }}>
              {props.props.storestatus === 'free'
                ? parseFloat('0').toFixed(2).replace('.', ',')
                : ((parseFloat(props.props.serviceprice) / 100) * 19)
                    .toFixed(2)
                    .replace('.', ',')}{' '}
              €
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              height: 24,
              paddingTop: 6,
            }}
          >
            <Text style={{ fontSize: 12, width: 60 }}></Text>
            <Text style={{ fontSize: 12, width: 100 }}></Text>
            <Text style={{ fontSize: 12, width: 100 }}></Text>
            <Text style={{ fontSize: 12, width: 140 }}></Text>
            <Text style={{ fontSize: 12, width: 240 }}>Endpreis Brutto</Text>
            <Text style={{ fontSize: 12, width: 100 }}></Text>
            <Text style={{ fontSize: 12, width: 140 }}>
              {props.props.storestatus === 'free'
                ? parseFloat('0').toFixed(2).replace('.', ',')
                : ((parseFloat(props.props.serviceprice) / 100) * 119)
                    .toFixed(2)
                    .replace('.', ',')}{' '}
              €
            </Text>
          </View>
          <View
            style={{
              marginTop: 80,
            }}
          >
            <Text style={{ fontSize: 11 }}>
              Bitte überweisen Sie den Rechnungsbetrag innerhalb von 30 Tagen
              bis zum {moment(props.props.invoicedate).add(30, 'd').format('L')}{' '}
              auf unser unten genanntes Konto. Bitte immer die RechnungsNr. auf
              der Überweisung angeben.
              {'\n'}
              Für weitere Fragen stehen wir Ihnen sehr gerne zur Verfügung.
            </Text>
          </View>
        </View>
        <View style={styles.footer}>
          <View
            style={{
              flexDirection: 'column',
              alignSelf: 'flex-end',
              marginTop: 60,
            }}
          >
            <Text style={{ fontSize: 11 }}>Kreditinstitut:</Text>
            <Text style={{ fontSize: 11 }}>Commerzbank</Text>
            <Text style={{ fontSize: 11 }}>IBAN: DE3423 4562 3435 6765</Text>
            <Text style={{ fontSize: 11 }}>BIC: COBADEFFXXX</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default InvoiceTemplate;
