import { gql } from '@apollo/client';

// graphql query search global portal products by name
export const SEARCH_STORE_PRODUCTS = gql`
  query searchStoreProducts(
    $storeid: Int!
    $searchname: String!
    $minprice: Int
    $maxprice: Int
  ) {
    merkantdo_search_store_products(
      args: {
        storeid: $storeid
        searchname: $searchname
        minprice: $minprice
        maxprice: $maxprice
      }
    ) {
      id
      productstatus
      actionstatus
      productname
      description
      price
      discount
      store_id
      storename
      image
    }
  }
`;
