import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  List,
  ListItem,
} from '@mui/material';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

/**
 * RegisterLoginStoreView um sich am System
 * mit Email und Password als Kunde zu registrieren.
 */
const RegisterReviewStoreForm = (props: any) => {
  const [isPasswordSecure, setIsPasswordSecure] = useState<boolean>(true);
  const [selectedBranches, setSelectedBranches] = useState<any>([]);

  /**
   * Ausgewählte Branchen des Stores setzen.
   */
  useEffect(() => {
    const tempBranches: Array<any> = [];
    for (let i = 0; i < props.branchesdataFromParent.length; i++) {
      if (props.branchesdataFromParent[i].checked === true) {
        tempBranches.push({
          branchId: props.branchesdataFromParent[i].branchId,
          branchName: props.branchesdataFromParent[i].branchName,
        });
      }
    }
    setSelectedBranches(tempBranches);
  }, [props.branchesdataFromParent]);

  /**
   * Funktion zum Umschalten des Password-Inputs,
   * sichtbar oder nicht sichtbar.
   */
  const handlePasswordSecure = () => {
    setIsPasswordSecure(!isPasswordSecure);
  };

  // return
  return (
    <Container maxWidth='xs' disableGutters>
      <Typography component='h1' variant='h6' align='center' sx={{ mt: 1 }}>
        Service und Logindaten
      </Typography>
      <Box mt={1}>
        <TextField
          color='secondary'
          variant='standard'
          sx={{ width: '100%' }}
          label='Service'
          value={props.logindataFromParent.serviceName}
        />
        <TextField
          color='secondary'
          variant='standard'
          sx={{ width: '100%' }}
          label='E-Mail-Adresse für die Rechnung'
          value={props.logindataFromParent.invoiceEmail}
        />
        <TextField
          color='secondary'
          variant='standard'
          sx={{ width: '100%' }}
          label='E-Mail-Adresse für das Login'
          value={props.logindataFromParent.email}
        />
        <TextField
          color='secondary'
          variant='standard'
          sx={{ width: '100%' }}
          type={isPasswordSecure ? 'password' : 'text'}
          label='Passwort'
          value={props.logindataFromParent.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  color='inherit'
                  size='small'
                  onClick={handlePasswordSecure}
                >
                  {isPasswordSecure ? (
                    <AiOutlineEye size='18' />
                  ) : (
                    <AiOutlineEyeInvisible size='18' />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography
          component='h1'
          variant='h6'
          align='center'
          sx={{ mt: 1, mb: 1 }}
        >
          Geschäftsdaten
        </Typography>
        <TextField
          color='secondary'
          variant='standard'
          sx={{ width: '100%' }}
          label='Name des Geschäfts'
          value={props.storedataFromParent.storeName}
        />
        <TextField
          color='secondary'
          variant='standard'
          sx={{ width: '100%' }}
          label='Inhaber (Vor- und Nachname)'
          value={props.storedataFromParent.storeOwner}
        />
        <TextField
          color='secondary'
          variant='standard'
          sx={{ width: '100%' }}
          label='PLZ'
          value={props.storedataFromParent.zipcode}
        />
        <TextField
          color='secondary'
          variant='standard'
          sx={{ width: '100%' }}
          label='Ort'
          value={props.storedataFromParent.place}
        />
        <TextField
          color='secondary'
          variant='standard'
          sx={{ width: '100%' }}
          label='Straße'
          value={props.storedataFromParent.street}
        />
        <TextField
          color='secondary'
          variant='standard'
          sx={{ width: '100%' }}
          label='Telefon'
          value={props.storedataFromParent.phone}
        />
        {/**
        <TextField
          color='secondary'
          variant='standard'
          sx={{ width: '100%' }}
          label='USt-IdNr'
          value={props.storedataFromParent.vatRegNo}
        />
        */}
        <Typography
          component='h1'
          variant='h6'
          align='center'
          sx={{ mt: 1, mb: 1 }}
        >
          Geschäftsbranchen
        </Typography>
        <List sx={{ pt: 0, pb: 0 }}>
          {selectedBranches.map((branch: any) => (
            <ListItem
              key={branch.branchId}
              dense
              disableGutters
              sx={{ pt: 0, pb: 0 }}
            >
              <TextField
                color='secondary'
                variant='standard'
                sx={{ width: '100%' }}
                label='Branche'
                value={branch.branchName}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Container>
  );
};

export default RegisterReviewStoreForm;
