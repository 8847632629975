import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import {
  UPDATE_STOREBRANCH,
  REMOVE_STOREBRANCH,
} from '../queries/adminQueries';
import { useMutation } from '@apollo/client';

/**
 * BranchView
 */
const BranchView = (props: any) => {
  const [branchname, setBranchname] = useState<string>(
    props.childPagePropsFromParent.branchname
  );
  const [isStatusEdit, setIsStatusEdit] = useState<boolean>(false);
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
  const [isButtonSaveEnabled, setIsButtonSaveEnabled] =
    useState<boolean>(false);
  const [branchDeleteDialogVisible, setBranchDeleteDialogVisible] =
    useState<boolean>(false);

  // update storebranch
  const [updateStorebranch] = useMutation(UPDATE_STOREBRANCH);

  // delete storebranch
  const [removeStorebranch] = useMutation(REMOVE_STOREBRANCH, {
    onError: (error) => {
      console.log(`Error! BranchView - removeBranch: ${error.message}`);
    },
  });

  /**
   * Button Status setzen
   */
  useEffect(() => {
    if (isDataChanged) {
      setIsButtonSaveEnabled(true);
    } else {
      setIsButtonSaveEnabled(false);
    }
  }, [isDataChanged]);

  /**
   * Wenn branchname geändert wird.
   */
  const handleBranchnameChange = (event: any) => {
    setBranchname(event.target.value);
    setIsDataChanged(true);
  };

  /**
   * Diese Funktion ändert branchname.
   */
  const handleStorebranch = async () => {
    try {
      await updateStorebranch({
        variables: {
          id: props.childPagePropsFromParent.id,
          branchname,
        },
      });
      setIsButtonSaveEnabled(false);
      setIsDataChanged(false);
    } catch (error) {
      console.log('Error! BranchView - handleStorebranch:', error);
    }
  };

  // return
  return (
    <Container maxWidth='xs'>
      {/** Delete Branch Dialog */}
      <Dialog
        open={branchDeleteDialogVisible}
        onClose={() => setBranchDeleteDialogVisible(false)}
        aria-labelledby='delete-dialog-title'
        aria-describedby='delete-dialog-description'
      >
        <DialogTitle id='delete-dialog-title'>
          Soll die Branche gelöscht werden?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='delete-dialog-description'>
            {' "'}
            {branchname}
            {'" '}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => setBranchDeleteDialogVisible(false)}
            variant='outlined'
            color='inherit'
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              removeStorebranch({
                variables: { id: props.childPagePropsFromParent.id },
              });
              setBranchDeleteDialogVisible(false);
              props.sendChildPagePropsToParent({
                page: props.childPagePropsFromParent.page,
                pageSize: props.childPagePropsFromParent.pageSize,
                sortOrder: props.childPagePropsFromParent.sortOrder,
              });
              props.sendChildPageToParent('branches');
            }}
            variant='outlined'
            color='inherit'
            autoFocus
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
      {/** Delete Branch Dialog end */}
      <Typography component='h2' variant='h6' align='center' mt={2}>
        Branche
      </Typography>
      <Box mt={2}>
        <TextField
          color='secondary'
          variant='standard'
          required
          sx={{ width: '100%' }}
          label='Branchenname'
          autoComplete='off'
          type='text'
          value={branchname}
          inputProps={{ readOnly: !isStatusEdit }}
          onChange={handleBranchnameChange}
        />
      </Box>
      {isStatusEdit ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            type='submit'
            disabled={!isButtonSaveEnabled}
            sx={{
              marginTop: 3,
              borderRadius: 30,
              width: '240px',
            }}
            variant='contained'
            color='secondary'
            onClick={handleStorebranch}
          >
            Übernehmen
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            type='submit'
            sx={{
              marginTop: 3,
              borderRadius: 30,
              width: '240px',
            }}
            variant='contained'
            color='secondary'
            onClick={() => {
              setIsStatusEdit(true);
              setIsDataChanged(false);
            }}
          >
            Bearbeiten
          </Button>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type='submit'
          sx={{
            marginTop: 2,
            borderRadius: 30,
            width: '240px',
          }}
          variant='contained'
          color='secondary'
          onClick={() => setBranchDeleteDialogVisible(true)}
        >
          Löschen
        </Button>
        <Button
          type='submit'
          sx={{
            marginTop: 2,
            borderRadius: 30,
            width: '240px',
          }}
          variant='contained'
          color='secondary'
          onClick={() => {
            props.sendChildPagePropsToParent({
              page: props.childPagePropsFromParent.page,
              pageSize: props.childPagePropsFromParent.pageSize,
              sortOrder: props.childPagePropsFromParent.sortOrder,
            });
            props.sendChildPageToParent('branches');
          }}
        >
          Ok
        </Button>
      </Box>
    </Container>
  );
};

export default BranchView;
