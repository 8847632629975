import { gql } from '@apollo/client';

// graphql query location by zipcode
export const GET_LOCATION_BY_ZIPCODE = gql`
  query getLocationByZipcode($param: String!) {
    location(where: { zipcode: { _like: $param } }) {
      id
      zipcode
      place
    }
  }
`;

// graphql insert tenant
export const ADD_TENANT = gql`
  mutation addTenant($tenant: tenant_insert_input!) {
    insert_tenant_one(object: $tenant) {
      id
      account_id
      invoiceemail
    }
  }
`;

// graphql query get tenant by invoiceemail
export const GET_TENANT_BY_INVOICEEMAIL = gql`
  query getTenant($invoiceemail: String!) {
    tenant(where: { invoiceemail: { _eq: $invoiceemail } }) {
      id
      account_id
      invoiceemail
    }
  }
`;

// graphql insert store
export const ADD_STORE = gql`
  mutation addStore($store: store_insert_input!) {
    insert_store_one(object: $store) {
      id
      location_id
      tenant_id
      storetype
      storestatus
      storename
      storeowner
      street
      phone
      created
    }
  }
`;

// graphql update store
export const UPDATE_STORE = gql`
  mutation update_store(
    $id: Int!
    $location_id: Int!
    $storename: String!
    $storeowner: String!
    $street: String!
    $phone: String!
  ) {
    update_store_by_pk(
      pk_columns: { id: $id }
      _set: {
        location_id: $location_id
        storename: $storename
        storeowner: $storeowner
        street: $street
        phone: $phone
      }
    ) {
      id
      location_id
      storetype
      storestatus
      storename
      storeowner
      street
      phone
    }
  }
`;

// graphql terminate contract
export const TERMINATE_CONTRACT = gql`
  mutation terminate_contract($id: Int!, $terminated: date!) {
    update_store_by_pk(
      pk_columns: { id: $id }
      _set: { terminated: $terminated }
    ) {
      id
      storestatus
      terminated
    }
  }
`;

// graphql terminate back contract
export const TERMINATE_BACK_CONTRACT = gql`
  mutation terminate_back_contract($id: Int!) {
    update_store_by_pk(pk_columns: { id: $id }, _set: { terminated: null }) {
      id
      storestatus
      terminated
    }
  }
`;

// graphql query servicetypes
export const GET_ACTIVESERVICETYPES = gql`
  query getActiveServicetypes {
    merkantdo_activeservicetypes {
      id
      servicestatus
      servicename
      productnumber
      actionnumber
      serviceprice
    }
  }
`;

// graphql query storeservice  by storeid
export const GET_STORESERVICE_BY_STORE_ID = gql`
  query getStoreserviceByStoreId($storeid: Int!) {
    storeservice(
      where: { store_id: { _eq: $storeid } }
      distinct_on: store_id
      order_by: [{ store_id: asc }, { service_updated: desc }]
    ) {
      id
      store_id
      servicetype_id
      service_updated
      servicetype {
        servicename
        serviceprice
      }
    }
  }
`;

// graphql insert storeservice
export const ADD_STORESERVICE = gql`
  mutation addStoreservice($storeservice: storeservice_insert_input!) {
    insert_storeservice_one(object: $storeservice) {
      id
      store_id
      servicetype_id
      service_updated
    }
  }
`;

// graphql insert storebranches
export const ADD_STOREBRANCHES = gql`
  mutation AddStoreBranches($branches: [storebranches_insert_input!]!) {
    insert_storebranches(objects: $branches) {
      returning {
        store_id
        storebranch_id
      }
    }
  }
`;

// graphql mutation delete st0rebranch from storebranches
export const REMOVE_STOREBRANCH_BY_STORE = gql`
  mutation removeStorebranchByStore($storeid: Int!, $storebranchid: Int!) {
    delete_storebranches(
      where: {
        _and: [
          { store_id: { _eq: $storeid } }
          { storebranch_id: { _eq: $storebranchid } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

// graphql query get productcategory id
export const GET_PRODUCTCATEGORIES_ID = gql`
  query getProductcategoriesId {
    productcategory(order_by: { id: desc }) {
      id
    }
  }
`;

// graphql query product  by id
export const GET_PRODUCTDATA_BY_ID = gql`
  query getProductdataById($productid: Int!) {
    merkantdo_productdata(args: { productid: $productid }) {
      id
      productstatus
      actionstatus
      availability
      start_on
      productname
      description
      price
      discount
      productcategory_id
      categoryname
      store_id
      storename
    }
  }
`;

// graphql insert product
export const ADD_PRODUCT = gql`
  mutation addProduct($product: product_insert_input!) {
    insert_product_one(object: $product) {
      id
      store_id
      productcategory_id
      productstatus
      availability
      actionstatus
      start_on
      productname
      description
      price
      discount
    }
  }
`;

// graphql update product by id
export const UPDATE_PRODUCT_BY_ID = gql`
  mutation update_product(
    $id: Int!
    $productcategory_id: Int!
    $productstatus: merkantdo_productstatus!
    $availability: merkantdo_availability!
    $actionstatus: merkantdo_actionstatus!
    $start_on: Boolean!
    $productname: String!
    $description: String!
    $price: numeric!
    $discount: Int
  ) {
    update_product_by_pk(
      pk_columns: { id: $id }
      _set: {
        productcategory_id: $productcategory_id
        productstatus: $productstatus
        availability: $availability
        actionstatus: $actionstatus
        start_on: $start_on
        productname: $productname
        description: $description
        price: $price
        discount: $discount
      }
    ) {
      id
      store_id
      productcategory_id
      productstatus
      availability
      actionstatus
      start_on
      productname
      description
      price
      discount
    }
  }
`;

// graphql mutation delete product
export const REMOVE_PRODUCT = gql`
  mutation removeProduct($id: Int!) {
    delete_product(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

// graphql query get product images
export const GET_PRODUCT_IMAGES = gql`
  query getProductImages($productid: Int!) {
    image(
      where: { product_id: { _eq: $productid } }
      order_by: { sort_order: asc }
    ) {
      id
      product_id
      image
      sort_order
    }
  }
`;

// graphql insert image
export const ADD_IMAGE = gql`
  mutation AddImage($image: image_insert_input!) {
    insert_image_one(object: $image) {
      id
      product_id
      image
      sort_order
    }
  }
`;

// graphql update image sort_order
export const UPDATE_IMAGE_SORT_ORDER = gql`
  mutation updateImageSortOrder($id: Int!, $sort_order: Int!) {
    update_image_by_pk(
      pk_columns: { id: $id }
      _set: { sort_order: $sort_order }
    ) {
      id
      product_id
      image
      sort_order
    }
  }
`;

// graphql mutation delete image
export const REMOVE_IMAGE = gql`
  mutation removeImage($id: Int!) {
    delete_image(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

// graphql insert ticket
export const ADD_TICKET = gql`
  mutation addTicket($ticket: ticket_insert_input!) {
    insert_ticket_one(object: $ticket) {
      id
      store_id
      status
      category
      subject
      contact
      phone
      email
      ticket
      answer
      created
      answered
      closed
    }
  }
`;

// graphql query get tickets
export const GET_STORE_TICKETS = gql`
  query getStoreTickets($storeid: Int!, $status: merkantdo_ticketstatus!) {
    ticket(
      where: {
        _and: [{ store_id: { _eq: $storeid } }, { status: { _neq: $status } }]
      }
    ) {
      id
      store_id
      status
      category
      subject
      contact
      phone
      email
      ticket
      answer
      created
      answered
      closed
    }
  }
`;

// graphql subscription get tickets
export const SUBSCRIBE_STORE_TICKETS = gql`
  subscription subscribeStoreTickets(
    $storeid: Int!
    $status: merkantdo_ticketstatus!
  ) {
    ticket(
      where: {
        _and: [{ store_id: { _eq: $storeid } }, { status: { _neq: $status } }]
      }
    ) {
      id
      store_id
      status
      category
      subject
      contact
      phone
      email
      ticket
      answer
      created
      answered
      closed
    }
  }
`;

// graphql close ticket by id
export const CLOSE_TICKET_BY_ID = gql`
  mutation update_ticket(
    $id: Int!
    $status: merkantdo_ticketstatus!
    $closed: timestamp!
  ) {
    update_ticket_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status, closed: $closed }
    ) {
      id
      status
      closed
    }
  }
`;

// graphql query get ticket
/**
export const GET_TICKET = gql`
  query getTicket($id: Int!) {
    ticket(where: { id: { _eq: $id } }) {
      id
      store_id
      status
      category
      subject
      contact
      phone
      email
      ticket
      answer
      created
      answered
      closed
    }
  }
`;
*/

// graphql query get tickets
export const GET_STORE_OPEN_INVOICES = gql`
  query getStoreOpenInvoices($storeid: Int!) {
    invoice(
      order_by: { id: desc }
      where: {
        _and: [{ store_id: { _eq: $storeid } }, { status: { _neq: "billpaid" } }]
      }
    ) {
      id
      store_id
      invoicedate
      status
    }
  }
`;
