import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import {
  Container,
  Box,
  Typography,
  Divider,
  Grid,
  Link,
  useTheme,
  Pagination,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocation } from '../../locationContext';
import PortalProductCard from '../../components/PortalProductCard';
import {
  QUERY_START_PRODUCTS,
  QUERY_START_BRANCH_PRODUCTS,
} from '../../data/PortalData';
import { useQuery } from '@apollo/client';
import { Config } from '../../config';

/**
 * Startseite der Geschäfte.
 */
const StoresStartView = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [{ location }] = useLocation();
  const [products, setProducts] = useState<any>([]);
  const [actiontype, setActiontype] = useState<string>(
    props.childPagePropsFromParent.actiontype
  );
  const [actiontypeNew, setActiontypeNew] = useState<any>([]);
  const [actiontypeTop, setActiontypeTop] = useState<any>([]);
  const [actiontypeSale, setActiontypeSale] = useState<any>([]);
  const [pagesTotal, setPagesTotal] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(
    props.childPagePropsFromParent.pagenumber
  );
  const [pageStart, setPageStart] = useState<number>(1);
  const [pageEnd, setPageEnd] = useState<number>(1);
  const MEDIA_URL: any =
    process.env.REACT_APP_MINIO_ENDPOINT !== undefined
      ? process.env.REACT_APP_MINIO_ENDPOINT
      : Config.minio_endpoint;
  const ITEMS_PER_PAGE: any =
    process.env.REACT_APP_ITEMS_PER_PAGE !== undefined
      ? process.env.REACT_APP_ITEMS_PER_PAGE
      : Config.items_per_page;

  // query start products
  const {
    loading: productsLoading,
    error: productsError,
    data: productsData,
  } = useQuery(QUERY_START_PRODUCTS, {
    variables: {
      locations: `{${location.locationlist.toString()}}`,
    },
    fetchPolicy: 'network-only',
    skip: props.childPagePropsFromParent.branchid > 0,
  });

  // query start branch products
  const {
    loading: productsBranchLoading,
    error: productsBranchError,
    data: productsBranchData,
  } = useQuery(QUERY_START_BRANCH_PRODUCTS, {
    variables: {
      storebranchid: props.childPagePropsFromParent.branchid,
      locations: `{${location.locationlist.toString()}}`,
    },
    fetchPolicy: 'network-only',
    skip: props.childPagePropsFromParent.branchid === 0,
  });

  /**
   * Setzen von vorhandenen Aktionstypen.
   */
  useEffect(() => {
    const tempActiontypeNew: Array<string> = [];
    const tempActiontypeTop: Array<string> = [];
    const tempActiontypeSale: Array<string> = [];
    if (productsData) {
      setProducts(productsData.merkantdo_startproducts);
      for (let i = 0; i < productsData.merkantdo_startproducts.length; i++) {
        if (productsData.merkantdo_startproducts[i].actionstatus === 'New') {
          tempActiontypeNew.push(productsData.merkantdo_startproducts[i]);
        } else if (
          productsData.merkantdo_startproducts[i].actionstatus === 'Top'
        ) {
          tempActiontypeTop.push(productsData.merkantdo_startproducts[i]);
        } else if (
          productsData.merkantdo_startproducts[i].actionstatus === 'Sale'
        ) {
          tempActiontypeSale.push(productsData.merkantdo_startproducts[i]);
        }
      }
      setActiontypeNew(tempActiontypeNew);
      setActiontypeTop(tempActiontypeTop);
      setActiontypeSale(tempActiontypeSale);
    } else if (productsBranchData) {
      setProducts(productsBranchData.merkantdo_startbranchproducts);
      for (
        let i = 0;
        i < productsBranchData.merkantdo_startbranchproducts.length;
        i++
      ) {
        if (
          productsBranchData.merkantdo_startbranchproducts[i].actionstatus ===
          'New'
        ) {
          tempActiontypeNew.push(
            productsBranchData.merkantdo_startbranchproducts[i]
          );
        } else if (
          productsBranchData.merkantdo_startbranchproducts[i].actionstatus ===
          'Top'
        ) {
          tempActiontypeTop.push(
            productsBranchData.merkantdo_startbranchproducts[i]
          );
        } else if (
          productsBranchData.merkantdo_startbranchproducts[i].actionstatus ===
          'Sale'
        ) {
          tempActiontypeSale.push(
            productsBranchData.merkantdo_startbranchproducts[i]
          );
        }
      }
      setActiontypeNew(tempActiontypeNew);
      setActiontypeTop(tempActiontypeTop);
      setActiontypeSale(tempActiontypeSale);
    }
  }, [
    productsBranchData,
    productsData,
    props.childPagePropsFromParent.branchid,
  ]);

  /**
   * pagesTotal bei unterschiedliche actiontype setzen.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculatePagesTotal = (type: string) => {
    if (productsData !== undefined) {
      if (type === 'all') {
        const totalCount = productsData.merkantdo_startproducts.length;
        setPagesTotal(Math.ceil(totalCount / ITEMS_PER_PAGE));
      } else if (type === 'New') {
        const totalCount = actiontypeNew.length;
        setPagesTotal(Math.ceil(totalCount / ITEMS_PER_PAGE));
      } else if (type === 'Top') {
        const totalCount = actiontypeTop.length;
        setPagesTotal(Math.ceil(totalCount / ITEMS_PER_PAGE));
      } else if (type === 'Sale') {
        const totalCount = actiontypeSale.length;
        setPagesTotal(Math.ceil(totalCount / ITEMS_PER_PAGE));
      }
    } else if (productsBranchData !== undefined) {
      if (type === 'all') {
        const totalCount =
          productsBranchData.merkantdo_startbranchproducts.length;
        setPagesTotal(Math.ceil(totalCount / ITEMS_PER_PAGE));
      } else if (type === 'New') {
        const totalCount = actiontypeNew.length;
        setPagesTotal(Math.ceil(totalCount / ITEMS_PER_PAGE));
      } else if (type === 'Top') {
        const totalCount = actiontypeTop.length;
        setPagesTotal(Math.ceil(totalCount / ITEMS_PER_PAGE));
      } else if (type === 'Sale') {
        const totalCount = actiontypeSale.length;
        setPagesTotal(Math.ceil(totalCount / ITEMS_PER_PAGE));
      }
    }
  };

  /**
   * Falls Location nicht gesetzt ist wird
   * umgeleitet.
   */
  useEffect(() => {
    if (location.locationlist.length === 0) {
      navigate('/location');
    }
  }, [location.locationlist, navigate]);

  /**
   * Set Page Pagination Properties
   */
  useEffect(() => {
    if (productsData !== undefined) {
      if (
        props.childPagePropsFromParent !== undefined &&
        props.childPagePropsFromParent.actiontype !== undefined
      ) {
        setActiontype(props.childPagePropsFromParent.actiontype);
        setPageNumber(props.childPagePropsFromParent.pagenumber);
      }
      calculatePagesTotal(actiontype);
      setPageStart((pageNumber - 1) * ITEMS_PER_PAGE);
      setPageEnd(pageNumber * ITEMS_PER_PAGE);
    } else if (productsBranchData !== undefined) {
      if (
        props.childPagePropsFromParent !== undefined &&
        props.childPagePropsFromParent.actiontype !== undefined
      ) {
        setActiontype(props.childPagePropsFromParent.actiontype);
        setPageNumber(props.childPagePropsFromParent.pagenumber);
      }
      calculatePagesTotal(actiontype);
      setPageStart((pageNumber - 1) * ITEMS_PER_PAGE);
      setPageEnd(pageNumber * ITEMS_PER_PAGE);
    }
  }, [
    ITEMS_PER_PAGE,
    actiontype,
    calculatePagesTotal,
    pageNumber,
    productsBranchData,
    productsData,
    props.childPagePropsFromParent,
  ]);

  /**
   * Set PageNumber
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
    props.sendChildPagePropsToParent({
      ...props.childPagePropsFromParent,
      pagenumber: value,
    });
  };

  // Beim laden der Daten nichts anzeigen.
  if (productsLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftritt.
  if (productsError) {
    console.log(`Error! StoresStartView: ${productsError.message}`);
  }

  // Beim laden der Daten nichts anzeigen.
  if (productsBranchLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftritt.
  if (productsBranchError) {
    console.log(`Error! StoresStartView: ${productsBranchError.message}`);
  }

  /**
   * Navigation zur ProductView mit Parametern.
   * Die Parameter sind für die goBack() Funktion
   * in ProductView erforderlich.
   */
  const goToProduct = (
    storeid: number,
    categoryid: number,
    productid: number,
    actiontype: string
  ) => {
    navigate('/product', {
      state: {
        srcroute: '/stores',
        childpage: 'storesStart',
        drawerisopen: false,
        branchid: props.childPagePropsFromParent.branchid,
        branchname: props.childPagePropsFromParent.branchname,
        expanded: props.childPagePropsFromParent.expanded,
        storeid,
        productid,
        actiontype,
        pagenumber: pageNumber,
      },
    });
  };

  // return
  return (
    <Container maxWidth={false} disableGutters>
      <Divider />
      {products.length === 0 ? (
        <Box p={1} sx={{ backgroundColor: theme.palette.primary.main }}>
          <Header
            title={`Angebote in ${location.zipcode} ${location.place} (+${location.distance}km).`}
            subtitle='Keine Einträge vorhanden.'
          />
        </Box>
      ) : (
        <Box mb={3}>
          <>
            <Box
              p={1}
              sx={{
                backgroundColor: theme.palette.primary.main,
              }}
            >
              {props.childPagePropsFromParent.branchid === 0 ? (
                <Header
                  title={`Angebote in ${location.zipcode} ${location.place} (+${location.distance}km).`}
                />
              ) : (
                <Header
                  title={`Angebote in ${props.childPagePropsFromParent.branchname}`}
                />
              )}
              <Box
                mt={0.5}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Link
                  underline={actiontype === 'all' ? 'always' : 'hover'}
                  component='button'
                  color='inherit'
                  onClick={() => {
                    props.sendChildPagePropsToParent({
                      ...props.childPagePropsFromParent,
                      actiontype: 'all',
                      pagenumber: 1,
                    });
                    calculatePagesTotal('all');
                  }}
                >
                  <Typography variant='body1'>Alle</Typography>
                </Link>
                {actiontypeNew.length > 0 ? (
                  <Link
                    underline={actiontype === 'New' ? 'always' : 'hover'}
                    component='button'
                    color='inherit'
                    sx={{ marginLeft: 2 }}
                    onClick={() => {
                      props.sendChildPagePropsToParent({
                        ...props.childPagePropsFromParent,
                        actiontype: 'New',
                        pagenumber: 1,
                      });
                      calculatePagesTotal('New');
                    }}
                  >
                    <Typography variant='body1'>Neu</Typography>
                  </Link>
                ) : null}
                {actiontypeTop.length > 0 ? (
                  <Link
                    underline={actiontype === 'Top' ? 'always' : 'hover'}
                    component='button'
                    color='inherit'
                    sx={{ marginLeft: 2 }}
                    onClick={() => {
                      props.sendChildPagePropsToParent({
                        ...props.childPagePropsFromParent,
                        actiontype: 'Top',
                        pagenumber: 1,
                      });

                      calculatePagesTotal('Top');
                    }}
                  >
                    <Typography variant='body1'>Top</Typography>
                  </Link>
                ) : null}
                {actiontypeSale.length > 0 ? (
                  <Link
                    underline={actiontype === 'Sale' ? 'always' : 'hover'}
                    component='button'
                    color='inherit'
                    sx={{ marginLeft: 2 }}
                    onClick={() => {
                      props.sendChildPagePropsToParent({
                        ...props.childPagePropsFromParent,
                        actiontype: 'Sale',
                        pagenumber: 1,
                      });
                      calculatePagesTotal('Sale');
                    }}
                  >
                    <Typography variant='body1'>Reduziert</Typography>
                  </Link>
                ) : null}
              </Box>
            </Box>
            <Box mt={1} mb={1}>
              <Grid container spacing={1} justifyContent='center'>
                {products && actiontype === 'all' ? (
                  products
                    .slice(pageStart, pageEnd)
                    .map((product: any, index: number) => (
                      <Grid item key={index} xs='auto' sm='auto' md='auto'>
                        <Link
                          underline='none'
                          component='button'
                          onClick={() =>
                            goToProduct(
                              product.store_id,
                              product.productcategory_id,
                              product.id,
                              'all'
                            )
                          }
                        >
                          <PortalProductCard
                            title={product.storename}
                            image={product.image}
                            actionstatus={
                              product.actionstatus === 'New'
                                ? 'Neu'
                                : product.actionstatus === 'Sale'
                                ? 'Reduziert'
                                : product.actionstatus
                            }
                            price={product.price.trim()}
                            discount={product.discount}
                            productname={product.productname}
                            mediaUri={
                              MEDIA_URL + '/' + product.store_id + '-data/'
                            }
                          />
                        </Link>
                      </Grid>
                    ))
                ) : (
                  <Box />
                )}
                {products && actiontype === 'New' ? (
                  actiontypeNew
                    .slice(pageStart, pageEnd)
                    .map((product: any, index: number) => (
                      <Grid item key={index} xs='auto' sm='auto' md='auto'>
                        <Link
                          underline='none'
                          component='button'
                          onClick={() =>
                            goToProduct(
                              product.store_id,
                              product.productcategory_id,
                              product.id,
                              'New'
                            )
                          }
                        >
                          <PortalProductCard
                            title={product.storename}
                            image={product.image}
                            actionstatus={
                              product.actionstatus === 'New'
                                ? 'Neu'
                                : product.actionstatus === 'Sale'
                                ? 'Reduziert'
                                : product.actionstatus
                            }
                            price={product.price.trim()}
                            discount={product.discount}
                            productname={product.productname}
                            mediaUri={
                              MEDIA_URL + '/' + product.store_id + '-data/'
                            }
                          />
                        </Link>
                      </Grid>
                    ))
                ) : (
                  <Box />
                )}
                {products && actiontype === 'Top' ? (
                  actiontypeTop
                    .slice(pageStart, pageEnd)
                    .map((product: any, index: number) => (
                      <Grid item key={index} xs='auto' sm='auto' md='auto'>
                        <Link
                          underline='none'
                          component='button'
                          onClick={() =>
                            goToProduct(
                              product.store_id,
                              product.productcategory_id,
                              product.id,
                              'Top'
                            )
                          }
                        >
                          <PortalProductCard
                            title={product.storename}
                            image={product.image}
                            actionstatus={
                              product.actionstatus === 'New'
                                ? 'Neu'
                                : product.actionstatus === 'Sale'
                                ? 'Reduziert'
                                : product.actionstatus
                            }
                            price={product.price.trim()}
                            discount={product.discount}
                            productname={product.productname}
                            mediaUri={
                              MEDIA_URL + '/' + product.store_id + '-data/'
                            }
                          />
                        </Link>
                      </Grid>
                    ))
                ) : (
                  <Box />
                )}
                {products && actiontype === 'Sale' ? (
                  actiontypeSale
                    .slice(pageStart, pageEnd)
                    .map((product: any, index: number) => (
                      <Grid item key={index} xs='auto' sm='auto' md='auto'>
                        <Link
                          underline='none'
                          component='button'
                          onClick={() =>
                            goToProduct(
                              product.store_id,
                              product.productcategory_id,
                              product.id,
                              'Sale'
                            )
                          }
                        >
                          <PortalProductCard
                            title={product.storename}
                            image={product.image}
                            actionstatus={
                              product.actionstatus === 'New'
                                ? 'Neu'
                                : product.actionstatus === 'Sale'
                                ? 'Reduziert'
                                : product.actionstatus
                            }
                            price={product.price.trim()}
                            discount={product.discount}
                            productname={product.productname}
                            mediaUri={
                              MEDIA_URL + '/' + product.store_id + '-data/'
                            }
                          />
                        </Link>
                      </Grid>
                    ))
                ) : (
                  <Box />
                )}
              </Grid>
            </Box>
          </>
        </Box>
      )}
      {products && products.length !== 0 ? (
        <Box
          sx={{
            mb: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Pagination
            count={pagesTotal}
            page={pageNumber}
            onChange={handlePage}
          />
        </Box>
      ) : null}
    </Container>
  );
};

export default StoresStartView;
