import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import {
  Container,
  Box,
  Divider,
  Grid,
  Link,
  useTheme,
  useMediaQuery,
  Pagination,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useFilter } from '../../filterContext';
import PortalProductCard from '../../components/PortalProductCard';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_STORE_PRODUCTS } from '../queries/storeSearchQueries';
import { Config } from '../../config';

/**
 * StoreSearchView stellt im ausgewählten Store
 * die vorhandenen Artikel nach der Suche dar.
 */
const StoreSearchView = (props: any) => {
  const theme = useTheme();
  const matches600 = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const [pagesTotal, setPagesTotal] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageStart, setPageStart] = useState<number>(1);
  const [pageEnd, setPageEnd] = useState<number>(1);
  const [{ filter }] = useFilter();

  // media url setzen
  const MEDIA_URL: any =
    process.env.REACT_APP_MINIO_ENDPOINT !== undefined
      ? process.env.REACT_APP_MINIO_ENDPOINT
      : Config.minio_endpoint;

  // items per page setzen
  const ITEMS_PER_PAGE: any =
    process.env.REACT_APP_ITEMS_PER_PAGE !== undefined
      ? process.env.REACT_APP_ITEMS_PER_PAGE
      : Config.items_per_page;

  // search store products
  const [getStoreSearchResult, { loading, error, data }] = useLazyQuery(
    SEARCH_STORE_PRODUCTS,
    {
      variables: {
        storeid: props.childPagePropsFromParent.storeid,
        searchname: props.childPagePropsFromParent.searchparam,
        minprice: filter.minprice,
        maxprice: filter.maxprice,
      },
      onCompleted: (searchData) => {
        setPagesTotal(
          Math.ceil(
            searchData.merkantdo_search_store_products.length / ITEMS_PER_PAGE
          )
        );
      },
    }
  );

  /**
   * Falls Suchbegriff vorhanden ist,
   * wird abgefragt.
   */
  useEffect(() => {
    if (
      props.childPagePropsFromParent.searchparam &&
      props.childPagePropsFromParent.searchparam !== ''
    ) {
      getStoreSearchResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.childPagePropsFromParent.searchparam]);

  /**
   * Set Page Properties
   */
  useEffect(() => {
    if (data) {
      if (
        props.childPagePropsFromParent !== undefined &&
        props.childPagePropsFromParent.pagenumber !== undefined
      ) {
        setPageNumber(props.childPagePropsFromParent.pagenumber);
      }
      setPageStart((pageNumber - 1) * ITEMS_PER_PAGE);
      setPageEnd(pageNumber * ITEMS_PER_PAGE);
    }
  }, [ITEMS_PER_PAGE, data, pageNumber, props.childPagePropsFromParent]);

  // Beim laden der Daten nichts anzeigen.
  if (loading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftritt.
  if (error) {
    console.log(`Error! SearchView: ${error.message}`);
  }

  /**
   * SetPageNumber
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
    props.sendChildPagePropsToParent({
      ...props.childPagePropsFromParent,
      pagenumber: value,
    });
  };

  /**
   * Navigation zur ProductView mit Parametern.
   * Die Parameter sind für die goBack() Funktion
   * in ProductView erforderlich.
   */
  const goToProduct = (storeid: number, productid: number) => {
    navigate('/product', {
      state: {
        srcroute: '/stores',
        childpage: 'storeSearch',
        storeid,
        storename: props.childPagePropsFromParent.storename,
        productid,
        branchid: props.childPagePropsFromParent.branchid,
        expanded: props.childPagePropsFromParent.expanded,
        searchparam: props.childPagePropsFromParent.searchparam,
        pagenumber: pageNumber,
      },
    });
  };

  // return
  return (
    <Container maxWidth={false} disableGutters>
      <Divider />
      {data && data.merkantdo_search_store_products.length === 0 ? (
        <Box
          mt={matches600 === true ? 6 : 0}
          p={1}
          sx={{ backgroundColor: theme.palette.primary.main }}
        >
          <Header
            title={`Suche für ${props.childPagePropsFromParent.searchparam} 
            in in ${props.childPagePropsFromParent.storename}`}
            subtitle='Keine Einträge vorhanden.'
          />
        </Box>
      ) : (
        <Box mb={3}>
          <>
            <Box
              mt={matches600 === true ? 6 : 0}
              p={1}
              sx={{ backgroundColor: theme.palette.primary.main }}
            >
              <Header
                title={`Suche für ${props.childPagePropsFromParent.searchparam} 
              in ${props.childPagePropsFromParent.storename}`}
              />
            </Box>
            <Grid mt={0} mb={1} container spacing={1} justifyContent='center'>
              {data &&
                data.merkantdo_search_store_products
                  .slice(pageStart, pageEnd)
                  .map((product: any, index: number) => (
                    <Grid item key={index} xs='auto' sm='auto' md='auto'>
                      <Link
                        underline='none'
                        component='button'
                        onClick={() =>
                          goToProduct(product.store_id, product.id)
                        }
                      >
                        <PortalProductCard
                          title={product.storename}
                          image={product.image}
                          actionstatus={
                            product.actionstatus === 'New'
                              ? 'Neu'
                              : product.actionstatus === 'Sale'
                              ? 'Reduziert'
                              : product.actionstatus
                          }
                          price={product.price.trim()}
                          discount={product.discount}
                          productname={product.productname}
                          mediaUri={
                            MEDIA_URL + '/' + product.store_id + '-data/'
                          }
                        />
                      </Link>
                    </Grid>
                  ))}
            </Grid>
          </>
        </Box>
      )}
      {data && data.merkantdo_search_store_products.length !== 0 ? (
        <Box
          sx={{
            mb: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Pagination
            count={pagesTotal}
            page={pageNumber}
            onChange={handlePage}
          />
        </Box>
      ) : null}
    </Container>
  );
};

export default StoreSearchView;
