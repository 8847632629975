import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * StoreAdminView
 */
const StoreAdminView = () => {
  const navigate = useNavigate();

  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={3}>
        <Typography component='h1' variant='h4' align='center' gutterBottom>
          Administration - Geschäft
        </Typography>
        <Typography component='h1' variant='h6' align='center' gutterBottom>
          Wie kann ich die Daten meines Geschäfts verwalten?
        </Typography>
      </Box>
      <Box>
        <Typography component='p' variant='body1' paragraph>
          Nach dem Login kommnt man auf die Account-Seite. Diese Seite ist die
          Hauptseite der Administration. Es befinden sich sechs Icons
          (Nachrichten, Stammkunden, Sortiment, Warrengruppen, Geschäft und
          Logindaten) auf der Seite. Beim klicken werden Sie in den jeweiligen
          Bereich weitergeleitet.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          1. Nachrichten: Die Nachrichten-Seite stellt eine Liste von
          Nachrichten, die Sie Von Kunden zu einem Artikel bekommen. Es ist ein
          Chat-System.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          2. Stammkunden: Bei Stammkunden handelt es sich um Kunden, die Ihr
          Geschäft als Favorit bei sich eingetragen und Benachrichtigung über
          neue Angebote aktiviert haben. Damit haben Sie die Möglichkeit Ihre
          Stammkunden über neue Angebote oder Aktionen zu informieren. Je nach
          Auswahl haben Sie die Möglichkeit alle Stammkunden oder nur einzelne
          zu benachrichtigen.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          3. Sortiment: Die Sortiment-Seite stellt Ihr Sortiment gruppiert in
          Warengruppen dar. Beim klicken das Liste-Icon rechts kommen Sie auf
          die Artikelliste, die zu dieser Warrengruppe gehört. Beim klicken auf
          den Stift-Icon ist man auf der Seite des Artikels. Hier kann man alle
          daten des Artikels ändern, Artikel lösche und neue erstellen.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          4. Warengruppen: Die Seite der Warengruppen stellt eine Liste
          vorhandener Warengruppen dar. Es ist erforderlich die Artikel einer
          Warengruppe zuzuordnen damin man auch schnell zu bestimmten Artikeln
          navigieren kann. Hier müssen Sie die Warengruppen Bearbeiten und aus
          den vorhandenen aktivieren, die Sie verwenden. Sollte eine Warengruppe
          nicht vorhanden sein erstellen sie die Warengruppe, indem Sie auf den
          Button Neu klicken.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          5. Geschäft: Die Geschäft-Seite stellt die Daten Ihres Geschäfts dar.
          Um die Daten zu bearbeiten müssen Sie auf den Button Bearbeiten
          klicken.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          6. Logindaten: Auf dieser Seite haben Sie die Möglichkeit Ihre
          Anmeldedaten zu ändern.
        </Typography>
      </Box>
      <Box
        mt={3}
        mb={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type='submit'
          variant='contained'
          color='secondary'
          sx={{ borderRadius: 30, width: '240px' }}
          onClick={() => navigate('/help')}
        >
          Zurück
        </Button>
      </Box>
    </Container>
  );
};

export default StoreAdminView;
