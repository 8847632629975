import { gql } from '@apollo/client';

// graphql query login by credentials
export const LOGIN_BY_CREDENTIALS = gql`
  query getLoginByCredentials($email: String!) {
    account(where: { email: { _eq: $email } }) {
      id
      email
      password
      userrole
    }
  }
`;

// graphql query GetCustomerProfile
export const GET_CUSTOMER_PROFILE = gql`
  query getCustomerProfile($account_id: Int!) {
    customer(where: { account_id: { _eq: $account_id } }) {
      id
      account_id
    }
  }
`;

// graphql query GetTenantProfile
export const GET_TENANT_PROFILE = gql`
  query getTenantProfile($account_id: Int!) {
    tenant(where: { account_id: { _eq: $account_id } }) {
      id
      account_id
    }
  }
`;

// graphql insert forgot passwrd code
export const ADD_PASSWORD_CODE = gql`
  mutation addPasswordCode($passwordcode: passwordcode_insert_input!) {
    insert_passwordcode_one(object: $passwordcode) {
      email
      code
    }
  }
`;

// graphql query get forgot password code
export const GET_PASSWORD_CODE = gql`
  query getPasswordCode($email: String!) {
    passwordcode(where: { email: { _eq: $email } }) {
      email
      code
    }
  }
`;

// graphql mutation delete password code
export const REMOVE_PASSWORD_CODE = gql`
  mutation removePasswordCode($email: String!) {
    delete_passwordcode(where: { email: { _eq: $email } }) {
      affected_rows
    }
  }
`;

// graphql reset password
export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!, $password: String!) {
    update_account(
      where: { email: { _eq: $email } }
      _set: { password: $password }
    ) {
      affected_rows
    }
  }
`;

// graphql query GetPortaladminProfile
export const GET_PORTALADMIN_PROFILE = gql`
  query getPortaladminProfile($account_id: Int!) {
    portaladmin(where: { account_id: { _eq: $account_id } }) {
      id
      account_id
    }
  }
`;
