import React from 'react';
import { Container, Box, Typography } from '@mui/material';

/**
 * TenantProductAssortmentHelpView
 *
 */
const TenantProductAssortmentHelpView = () => {
  // return
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component='h1' variant='h5'>
          Sortiment
        </Typography>
        <Typography variant='body1' mt={1}>
          Dies ist die Verwaltung Ihres Sortiments in den Warengruppen des
          Geschäfts.
        </Typography>
        <Typography variant='body1' mt={1}>
          Nach der Registrierung eines Geschäfts sind noch keine Warengruppen
          vorhanden. Nachdem Sie die benötigten Warengruppen ausgewählt haben,
          können Sie die Artikel eingeben. Klicken Sie auf das Icon rechts in
          der jeweiligen Warengruppe.
        </Typography>
      </Box>
    </Container>
  );
};

export default TenantProductAssortmentHelpView;
