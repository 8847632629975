import React from 'react';
import { Container, Box, Typography } from '@mui/material';

/**
 * TenantMessagesGroupsHelpView
 *
 */
const TenantMessagesGroupsHelpView = () => {
  // return
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component='h1' variant='h5'>
          Nachrichten - Liste
        </Typography>
        <Typography variant='body1' mt={1}>
          Dies ist die Liste Ihrer Nachrichten zu den Artikeln.
        </Typography>
        <Typography variant='body1' mt={1}>
          Die Kunden haben die Möglichkeit Fragen zu Ihren Artikeln zu stellen.
          Die Seite stellt eine Liste der Nachrichten dar.
        </Typography>
      </Box>
    </Container>
  );
};

export default TenantMessagesGroupsHelpView;
