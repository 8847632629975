import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const NotFound = () => {
  return (
    <Container maxWidth={false}>
      <Box mt={10} sx={{ textAlign: 'center' }}>
        <Typography variant='h1'>404</Typography>
        <Typography variant='h2'>Die Seite wurde nicht gefunden</Typography>
      </Box>
    </Container>
  );
};

export default NotFound;
