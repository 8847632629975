import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Paper,
  Link,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
  useTheme,
} from '@mui/material';
import { AiOutlineRight, AiOutlineDelete } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useLocation } from '../locationContext';
import { useAuth } from '../authContext';
import {
  GET_FAVORITES,
  REMOVE_FAVORITE,
  UPDATE_FAVORITE,
} from '../data/FavoriteData';
import { GET_STORE_BRANCHES } from '../data/BranchesData';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import Header from '../components/Header';

/**
 * FavoriteView stellt die eingetragenen
 * Favoriten als Liste dar. Vom Eintrag in
 * der Liste kann man direkt zum Geschäft navigieren
 * oder den Eintag Löschen.
 */
const FavoriteView = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [{ location }] = useLocation();
  const [{ isAuthenticated, profile }] = useAuth();
  const [customerId, setCustomerId] = useState<number>(0);
  const [favoriteId, setFavoriteId] = useState<number>(0);
  const [favoriteName, setFavoriteName] = useState<string>('');
  const [deleteFavoriteDialogVisible, setDeleteFavoriteDialogVisible] =
    useState<boolean>(false);
  const [loginDialogVisible, setLoginDialogVisible] = useState<boolean>(false);

  // get favorites
  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(GET_FAVORITES, {
    variables: { customerid: customerId },
    fetchPolicy: 'network-only',
  });

  // remove favorite
  const [removeFavorite, { loading: removeLoading, error: removeError }] =
    useMutation(REMOVE_FAVORITE, {
      refetchQueries: [
        {
          query: GET_FAVORITES,
          variables: { customerid: customerId },
          fetchPolicy: 'network-only',
        },
      ],
      //awaitRefetchQueries: true,
    });

  // update favorite
  const [updateFavorite] = useMutation(UPDATE_FAVORITE, {
    refetchQueries: [
      {
        query: GET_FAVORITES,
        variables: { customerid: customerId },
      },
    ],
  });

  // get store branches
  const [
    getBranches,
    {
      loading: storeBranchesLoading,
      error: storeBranchesError,
      //data: storeBranchesData,
    },
  ] = useLazyQuery(GET_STORE_BRANCHES);

  /**
   * Falls nicht angemeldet wird umgeleitet.
   */
  useEffect(() => {
    if (isAuthenticated === false) {
      setLoginDialogVisible(true);
    }
  }, [isAuthenticated]);

  /**
   * Falls der Kunde eingeloggt ist wird im
   * lokalen State custumer_id gesetzt.
   */
  useEffect(() => {
    if (profile !== null && profile.userrole === 'customer') {
      setCustomerId(profile.customer_id);
    } else {
      setCustomerId(0);
    }
  }, [customerId, profile]);

  /**
   * Falls Location nicht gesetzt ist wird
   * umgeleitet.
   */
  useEffect(() => {
    if (location.locationlist === '') {
      navigate('/location');
    }
  }, [location.locationlist, navigate]);

  // Beim laden der Daten nichts anzeigen.
  if (queryLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (queryError) {
    console.log(`Error! FavoriteView: ${queryError.message}`);
  }

  // Beim laden nichts anzeigen.
  if (removeLoading) {
    return null;
  }

  // Falls Fehler beim Löschen auftreten.
  if (removeError) {
    console.log(`Error! FavoriteView: ${removeError.message}`);
  }

  // Beim laden nichts anzeigen.
  if (storeBranchesLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (storeBranchesError) {
    console.log(`Error! FavoriteView: ${storeBranchesError.message}`);
  }

  /**
   * Dialog für das Login um sich die Einträge
   * in der Favoritenliste anzuzeigen.
   */
  const handleLoginDialog = (action: string) => {
    if (action === 'login') {
      navigate('/login', { state: { srcroute: '/favorite' } });
    } else {
      setLoginDialogVisible(false);
      navigate('/start');
    }
  };

  /**
   * Dialog für das Löschen eines Eintrages
   * in der Favoritenliste.
   */
  const handleDeleteDialog = (action: string) => {
    if (action === 'delete') {
      removeFavorite({ variables: { id: favoriteId } });
    }
    setDeleteFavoriteDialogVisible(false);
  };

  /**
   * Funktion zum Löschen eines Eintages
   * in der Favoritenliste.
   */
  const handleDelete = (id: number, name: string) => {
    setFavoriteId(id);
    setFavoriteName(name);
    setDeleteFavoriteDialogVisible(true);
  };

  /**
   * Navigation zum Store
   * @param storeid
   * @param storename
   */
  const goToStore = async (storeid: number, storename: string) => {
    try {
      const { data } = await getBranches({
        variables: { storeid: storeid },
        fetchPolicy: 'network-only',
      });
      if (data.storebranch.length !== 0) {
        navigate('/stores', {
          state: {
            srcroute: '/favorite',
            storeid: storeid,
            storename: storename,
            branchid: data.storebranch[0].id,
            expanded: { [data.storebranch[0].id]: true },
          },
        });
      }
    } catch (error) {
      console.log('Error! FavoriteView - goToStore:', error);
    }
  };

  // return
  return (
    <Container maxWidth='xs'>
      <Dialog
        open={loginDialogVisible}
        onClose={() => setLoginDialogVisible(false)}
        aria-labelledby='login-dialog-title'
        aria-describedby='login-dialog-description'
      >
        <DialogTitle id='login-dialog-title'>
          Anmeldung erforderlich!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='login-dialog-description'>
            Um Favoriten anzusehen müssen Sie sich anmelden.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => handleLoginDialog('cancel')}
            variant='outlined'
            color='inherit'
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => handleLoginDialog('login')}
            variant='outlined'
            color='inherit'
            autoFocus
          >
            Anmelden
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteFavoriteDialogVisible}
        onClose={() => setDeleteFavoriteDialogVisible(false)}
        aria-labelledby='delete-dialog-title'
        aria-describedby='delete-dialog-description'
      >
        <DialogTitle id='delete-dialog-title'>Favorit löschen!</DialogTitle>
        <DialogContent>
          <DialogContentText id='delete-dialog-description'>
            Soll der Favorit {'"'}
            {favoriteName}
            {'"'} gelöscht werden ?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => handleDeleteDialog('cancel')}
            variant='outlined'
            color='inherit'
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => handleDeleteDialog('delete')}
            variant='outlined'
            color='inherit'
            autoFocus
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
      {queryData.favorite.length === 0 ? (
        <Box mt={3}>
          <Header title='Favoriten' subtitle='Keine Favoriten vorhanden.' />
        </Box>
      ) : (
        <>
          <Box mt={3} mb={2}>
            <Header title='Favoriten' subtitle='Die Liste Ihrer Favoriten.' />
          </Box>
          {queryData.favorite.map((favorite: any, index: number) => (
            <Box key={index} mt={0.5}>
              <Paper elevation={0} sx={{ p: 0.5, pl: 1.2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <Link
                    sx={{
                      color: theme.palette.text.primary,
                      textDecoration: 'none',
                      '&:hover, &:focus, &:active': {
                        color: theme.palette.text.primary,
                        textDecoration: `underline ${theme.palette.text.primary}`,
                      },
                    }}
                    component='button'
                    color='textPrimary'
                    onClick={() =>
                      goToStore(favorite.store.id, favorite.store.storename)
                    }
                  >
                    <Typography variant='subtitle2'>
                      {favorite.store.storename}
                    </Typography>
                  </Link>
                  <Box>
                    <IconButton
                      color='inherit'
                      size='small'
                      onClick={() =>
                        handleDelete(favorite.id, favorite.store.storename)
                      }
                    >
                      <AiOutlineDelete size='20' />
                    </IconButton>
                    <IconButton
                      color='inherit'
                      size='small'
                      onClick={() =>
                        goToStore(favorite.store.id, favorite.store.storename)
                      }
                    >
                      <AiOutlineRight size='20' />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={favorite.messages_on}
                        onChange={() =>
                          updateFavorite({
                            variables: {
                              id: favorite.id,
                              messages_on: !favorite.messages_on,
                            },
                          })
                        }
                        color='secondary'
                      />
                    }
                    label='Möchten Sie über neue Angebote informiert werden?'
                  />
                </Box>
              </Paper>
            </Box>
          ))}
        </>
      )}
    </Container>
  );
};

export default FavoriteView;
