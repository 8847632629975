import React, { useState } from 'react';
import {
  Container,
  AppBar,
  Box,
  Toolbar,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  IconButton,
  useTheme,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiOutlineLogin,
  AiOutlineLogout,
  AiOutlineUser,
} from 'react-icons/ai';
import { BiAdjust } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../authContext';
import { ThemeContext } from '../../../themeContext';

const items = [
  {
    title: 'Dashboard',
    page: 'dashboard',
  },
  {
    title: 'Tickets',
    page: 'tickets',
  },
  {
    title: 'Geschäfte',
    page: 'customers',
  },
  {
    title: 'Rechnungen',
    page: 'invoices',
  },
  {
    title: 'Branchen',
    page: 'branches',
  },
  {
    title: 'Warengruppen',
    page: 'productCategories',
  },
];

/**
 * Admin Topbar Component
 */
const Topbar = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [{ isAuthenticated, profile }, dispatch] = useAuth();
  const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);

  /**
   * Logout
   */
  const doLogout = () => {
    dispatch({
      type: 'LOGOUT',
    });
  };

  // return
  return (
    <Container maxWidth={false} disableGutters>
      {/** AppBar */}
      <AppBar
        elevation={0}
        //position='fixed'
        //sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
            }}
          >
            {/* Drawer Icons. Sichtbar bis sm 900px. */}
            <Box
              sx={{
                display: {
                  xs: 'block',
                  sm: 'block',
                  md: 'block',
                  lg: 'block',
                  xl: 'block',
                },
              }}
            >
              {!drawerIsOpen ? (
                <IconButton
                  color='inherit'
                  onClick={() => setDrawerIsOpen(true)}
                >
                  <AiOutlineMenu size='22' />
                </IconButton>
              ) : null}
            </Box>
            {/* Ende Drawer Icons */}
            <RouterLink to='/' style={{ paddingLeft: theme.spacing(1) }}>
              <img alt='merkantdo' src='/images/logos/logo-merkantdo.svg' />
            </RouterLink>
          </Box>
          {/** Icons rechts. Sichtbar ab lg, 1200px */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box>
              <ThemeContext.Consumer>
                {(themeContext) => (
                  <IconButton
                    color='inherit'
                    size='small'
                    onClick={themeContext.toggleTheme}
                  >
                    <BiAdjust size='22' />
                  </IconButton>
                )}
              </ThemeContext.Consumer>
              {!isAuthenticated ? (
                <IconButton
                  color='inherit'
                  size='small'
                  onClick={() => navigate('/login')}
                >
                  <AiOutlineLogin size='22' />
                </IconButton>
              ) : (
                <>
                  {profile.userrole === 'portaladmin' ? (
                    <IconButton
                      color='inherit'
                      size='small'
                      onClick={() => {
                        props.sendChildPageToParent('logindata');
                        props.sendChildPagePropsToParent({});
                      }}
                    >
                      <AiOutlineUser size='22' />
                    </IconButton>
                  ) : null}
                  <IconButton
                    color='inherit'
                    size='small'
                    onClick={() => {
                      doLogout();
                      navigate('/login');
                    }}
                  >
                    <AiOutlineLogout size='22' />
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
          {/** Ende Icons rechts. */}
        </Toolbar>
      </AppBar>
      {/** Ende AppBar */}
      {/** Drawer */}
      <Box
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'block',
            lg: 'block',
            xl: 'block',
          },
        }}
      >
        <Drawer variant='persistent' open={drawerIsOpen}>
          <Box
            pr={1}
            sx={{
              height: '56px',
              width: '240px',
              [theme.breakpoints.up('sm')]: {
                height: '64px',
                width: '240px',
              },
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {drawerIsOpen ? (
              <IconButton
                color='inherit'
                onClick={() => setDrawerIsOpen(false)}
              >
                <AiOutlineClose size='22' />
              </IconButton>
            ) : null}
          </Box>
          <List>
            {items.map((item) => (
              <ListItemButton
                sx={{ height: 32, pl: 2 }}
                key={item.title}
                onClick={() => {
                  props.sendChildPageToParent(item.page);
                  props.sendChildPagePropsToParent({});
                  setDrawerIsOpen(false);
                }}
              >
                <ListItemText sx={{ m: 0, ml: 1 }}>{item.title}</ListItemText>
              </ListItemButton>
            ))}
          </List>
        </Drawer>
      </Box>
    </Container>
  );
};

export default Topbar;
