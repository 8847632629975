import { LocationState } from './locationState';
import { LocationActions } from './locationActions';

export const locationReducer = (
  state: LocationState,
  action: LocationActions
) => {
  switch (action.type) {
    case 'SET_LOCATION':
      return {
        ...state,
        location: action.payload,
      };
    case 'REMOVE_LOCATION':
      return {
        ...state,
        location: null,
      };
    default:
      return state;
  }
};
