import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * MerkantdoForView
 */
const MerkantdoForView = () => {
  const navigate = useNavigate();

  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={3}>
        <Typography component='h1' variant='h4' align='center' gutterBottom>
          Für wen ist merkantdo?
        </Typography>
        <Typography component='h1' variant='h6' align='center' gutterBottom>
          Für wen ist merkando nützlich und an wen richtet sich merkantdo?
        </Typography>
      </Box>
      <Box>
        <Typography component='p' variant='body1' paragraph>
          Merkantdo richtet sich an den stationären Händler und seine Kunden.
          Der Händler hat die Möglichkeit sein Angebot über das Internet den
          Kunden zu präsentieren und informieren.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Für den Kunden bedeutet das er sich jederzeit und schnell über das
          Angebot in seiner Stadt über den gesuchten Artikel informieren und die
          betreffenden Geschäfte kontaktieren oder aufsuchen kann.
        </Typography>
      </Box>
      <Box
        mt={3}
        mb={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type='submit'
          variant='contained'
          color='secondary'
          sx={{ borderRadius: 30, width: '240px' }}
          onClick={() => navigate('/help')}
        >
          Zurück
        </Button>
      </Box>
    </Container>
  );
};

export default MerkantdoForView;
