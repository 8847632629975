import React from 'react';
import { Container, Box, Typography } from '@mui/material';

/**
 * TenantPasswordHelpView
 *
 */
const TenantPasswordHelpView = () => {
  // return
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component='h1' variant='h5'>
          Login Passwort ändern
        </Typography>
        <Typography variant='body1' mt={1}>
          Hier haben Sie die Möglichkeit das Passwort für das Login zu ändern.
        </Typography>
      </Box>
    </Container>
  );
};

export default TenantPasswordHelpView;
