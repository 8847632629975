import React from 'react';
import { Container, Box, Typography } from '@mui/material';

/**
 * TenantProductCategoriesHelpView
 *
 */
const TenantProductCategoriesHelpView = () => {
  // return
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component='h1' variant='h5'>
          Warengruppen
        </Typography>
        <Typography variant='body1' mt={1}>
          Dies ist die Verwaltung Ihrer Warengruppen des Geschäfts.
        </Typography>
        <Typography variant='body1' mt={1}>
          Nach der Registrierung eines Geschäfts sind noch keine Warengruppen
          vorhanden. Klicken Sie auf Bearbeiten um die passenden Warengruppen
          auszuwählen.
        </Typography>
      </Box>
    </Container>
  );
};

export default TenantProductCategoriesHelpView;
