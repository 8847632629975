import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import {
  Container,
  Box,
  Typography,
  Divider,
  Grid,
  Link,
  useTheme,
  Pagination,
} from '@mui/material';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { useLocation } from '../locationContext';
import PortalProductCard from '../components/PortalProductCard';
import { QUERY_START_PRODUCTS } from '../data/PortalData';
import { useQuery } from '@apollo/client';
import { Config } from '../config';

/**
 * StartView ist für die Darstellung der Artikel
 * der Geschäfte auf der Startseite zuständig.
 */
const StartView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [{ location }] = useLocation();
  const routerLocation: any = useRouterLocation();
  const [actiontype, setActiontype] = useState<string>('all');
  const [actiontypeNew, setActiontypeNew] = useState<any>([]);
  const [actiontypeTop, setActiontypeTop] = useState<any>([]);
  const [actiontypeSale, setActiontypeSale] = useState<any>([]);
  const [pagesTotal, setPagesTotal] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageStart, setPageStart] = useState<number>(1);
  const [pageEnd, setPageEnd] = useState<number>(1);

  const MEDIA_URL: any =
    process.env.REACT_APP_MINIO_ENDPOINT !== undefined
      ? process.env.REACT_APP_MINIO_ENDPOINT
      : Config.minio_endpoint;

  const ITEMS_PER_PAGE: any =
    process.env.REACT_APP_ITEMS_PER_PAGE !== undefined
      ? process.env.REACT_APP_ITEMS_PER_PAGE
      : Config.items_per_page;

  // query start products
  const { loading, error, data } = useQuery(QUERY_START_PRODUCTS, {
    variables: {
      locations: `{${location.locationlist.toString()}}`,
    },
    fetchPolicy: 'network-only',
  });

  /**
   * Falls Location nicht gesetzt ist wird
   * umgeleitet.
   */
  useEffect(() => {
    if (location.locationlist.length === 0) {
      navigate('/location');
    }
  }, [location.locationlist, navigate]);

  /**
   * Setzen von vorhandenen Aktionstypen.
   */
  useEffect(() => {
    const tempActiontypeNew: Array<string> = [];
    const tempActiontypeTop: Array<string> = [];
    const tempActiontypeSale: Array<string> = [];
    if (data) {
      for (let i = 0; i < data.merkantdo_startproducts.length; i++) {
        if (data.merkantdo_startproducts[i].actionstatus === 'New') {
          tempActiontypeNew.push(data.merkantdo_startproducts[i]);
        } else if (data.merkantdo_startproducts[i].actionstatus === 'Top') {
          tempActiontypeTop.push(data.merkantdo_startproducts[i]);
        } else if (data.merkantdo_startproducts[i].actionstatus === 'Sale') {
          tempActiontypeSale.push(data.merkantdo_startproducts[i]);
        }
      }
      setActiontypeNew(tempActiontypeNew);
      setActiontypeTop(tempActiontypeTop);
      setActiontypeSale(tempActiontypeSale);
    }
  }, [data]);

  /**
   * pagesTotal bei unterschiedliche actiontype setzen.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculatePagesTotal = (type: string) => {
    if (type === 'all') {
      const totalCount = data.merkantdo_startproducts.length;
      setPagesTotal(Math.ceil(totalCount / ITEMS_PER_PAGE));
    } else if (type === 'New') {
      const totalCount = actiontypeNew.length;
      setPagesTotal(Math.ceil(totalCount / ITEMS_PER_PAGE));
    } else if (type === 'Top') {
      const totalCount = actiontypeTop.length;
      setPagesTotal(Math.ceil(totalCount / ITEMS_PER_PAGE));
    } else if (type === 'Sale') {
      const totalCount = actiontypeSale.length;
      setPagesTotal(Math.ceil(totalCount / ITEMS_PER_PAGE));
    }
  };

  /**
   * Set Page Pagination Properties
   */
  useEffect(() => {
    if (data) {
      if (routerLocation.state !== null) {
        setActiontype(routerLocation.state.actiontype);
        setPageNumber(routerLocation.state.pagenumber);
      }
      calculatePagesTotal(actiontype);
      setPageStart((pageNumber - 1) * ITEMS_PER_PAGE);
      setPageEnd(pageNumber * ITEMS_PER_PAGE);
      routerLocation.state = null;
    }
  }, [
    actiontype,
    data,
    pageNumber,
    routerLocation.state,
    calculatePagesTotal,
    ITEMS_PER_PAGE,
    routerLocation,
  ]);

  /**
   * Set PageNumber
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
  };

  // Beim laden der Daten nichts anzeigen.
  if (loading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftritt.
  if (error) {
    console.log(`Error! StartView: ${error.message}`);
  }

  /**
   * Navigation zur ProductView mit Parametern.
   * Die Parameter sind für die goBack() und goStore()
   * Funktionen in ProductView erforderlich.
   */
  const goToProduct = (
    storeid: number,
    categoryid: number,
    productid: number,
    actiontype: string
  ) => {
    navigate('/product', {
      state: {
        srcroute: '/start',
        storeid,
        categoryid,
        productid,
        actiontype,
        pagenumber: pageNumber,
      },
    });
  };

  // return
  return (
    <Container maxWidth={false} disableGutters>
      <Divider />
      {data && data.merkantdo_startproducts.length === 0 ? (
        <Box p={1} sx={{ backgroundColor: theme.palette.primary.main }}>
          <Header
            title={`Angebote in ${location.zipcode} ${location.place} (+${location.distance}km).`}
            subtitle='Keine Einträge vorhanden.'
          />
        </Box>
      ) : (
        <Box mb={3}>
          <>
            <Box
              p={1}
              sx={{
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <Header
                title={`Angebote in ${location.zipcode} ${location.place} (+${location.distance}km).`}
              />
              <Box
                mt={0.5}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Link
                  underline={actiontype === 'all' ? 'always' : 'hover'}
                  component='button'
                  color='inherit'
                  onClick={() => {
                    setActiontype('all');
                    setPageNumber(1);
                    calculatePagesTotal('all');
                  }}
                >
                  <Typography variant='body1'>Alle</Typography>
                </Link>
                {actiontypeNew.length > 0 ? (
                  <Link
                    underline={actiontype === 'New' ? 'always' : 'hover'}
                    component='button'
                    color='inherit'
                    sx={{ marginLeft: 2 }}
                    onClick={() => {
                      setActiontype('New');
                      setPageNumber(1);
                      calculatePagesTotal('New');
                    }}
                  >
                    <Typography variant='body1'>Neu</Typography>
                  </Link>
                ) : null}
                {actiontypeTop.length > 0 ? (
                  <Link
                    underline={actiontype === 'Top' ? 'always' : 'hover'}
                    component='button'
                    color='inherit'
                    sx={{ marginLeft: 2 }}
                    onClick={() => {
                      setActiontype('Top');
                      setPageNumber(1);
                      calculatePagesTotal('Top');
                    }}
                  >
                    <Typography variant='body1'>Top</Typography>
                  </Link>
                ) : null}
                {actiontypeSale.length > 0 ? (
                  <Link
                    underline={actiontype === 'Sale' ? 'always' : 'hover'}
                    component='button'
                    color='inherit'
                    sx={{ marginLeft: 2 }}
                    onClick={() => {
                      setActiontype('Sale');
                      setPageNumber(1);
                      calculatePagesTotal('Sale');
                    }}
                  >
                    <Typography variant='body1'>Reduziert</Typography>
                  </Link>
                ) : null}
              </Box>
            </Box>
            <Box mt={1} mb={1}>
              <Grid container spacing={1} justifyContent='center'>
                {data && actiontype === 'all' ? (
                  data.merkantdo_startproducts
                    .slice(pageStart, pageEnd)
                    .map((product: any, index: number) => (
                      <Grid item key={index} xs='auto' sm='auto' md='auto'>
                        <Link
                          underline='none'
                          component='button'
                          onClick={() =>
                            goToProduct(
                              product.store_id,
                              product.productcategory_id,
                              product.id,
                              'all'
                            )
                          }
                        >
                          <PortalProductCard
                            title={product.storename}
                            image={product.image}
                            actionstatus={
                              product.actionstatus === 'New'
                                ? 'Neu'
                                : product.actionstatus === 'Sale'
                                ? 'Reduziert'
                                : product.actionstatus
                            }
                            price={product.price.trim()}
                            discount={product.discount}
                            productname={product.productname}
                            mediaUri={
                              MEDIA_URL + '/' + product.store_id + '-data/'
                            }
                          />
                        </Link>
                      </Grid>
                    ))
                ) : (
                  <Box />
                )}
                {data && actiontype === 'New' ? (
                  actiontypeNew
                    .slice(pageStart, pageEnd)
                    .map((product: any, index: number) => (
                      <Grid item key={index} xs='auto' sm='auto' md='auto'>
                        <Link
                          underline='none'
                          component='button'
                          onClick={() =>
                            goToProduct(
                              product.store_id,
                              product.productcategory_id,
                              product.id,
                              'New'
                            )
                          }
                        >
                          <PortalProductCard
                            title={product.storename}
                            image={product.image}
                            actionstatus={
                              product.actionstatus === 'New'
                                ? 'Neu'
                                : product.actionstatus === 'Sale'
                                ? 'Reduziert'
                                : product.actionstatus
                            }
                            price={product.price.trim()}
                            discount={product.discount}
                            productname={product.productname}
                            mediaUri={
                              MEDIA_URL + '/' + product.store_id + '-data/'
                            }
                          />
                        </Link>
                      </Grid>
                    ))
                ) : (
                  <Box />
                )}
                {data && actiontype === 'Top' ? (
                  actiontypeTop
                    .slice(pageStart, pageEnd)
                    .map((product: any, index: number) => (
                      <Grid item key={index} xs='auto' sm='auto' md='auto'>
                        <Link
                          underline='none'
                          component='button'
                          onClick={() =>
                            goToProduct(
                              product.store_id,
                              product.productcategory_id,
                              product.id,
                              'Top'
                            )
                          }
                        >
                          <PortalProductCard
                            title={product.storename}
                            image={product.image}
                            actionstatus={
                              product.actionstatus === 'New'
                                ? 'Neu'
                                : product.actionstatus === 'Sale'
                                ? 'Reduziert'
                                : product.actionstatus
                            }
                            price={product.price.trim()}
                            discount={product.discount}
                            productname={product.productname}
                            mediaUri={
                              MEDIA_URL + '/' + product.store_id + '-data/'
                            }
                          />
                        </Link>
                      </Grid>
                    ))
                ) : (
                  <Box />
                )}
                {data && actiontype === 'Sale' ? (
                  actiontypeSale
                    .slice(pageStart, pageEnd)
                    .map((product: any, index: number) => (
                      <Grid item key={index} xs='auto' sm='auto' md='auto'>
                        <Link
                          underline='none'
                          component='button'
                          onClick={() =>
                            goToProduct(
                              product.store_id,
                              product.productcategory_id,
                              product.id,
                              'Sale'
                            )
                          }
                        >
                          <PortalProductCard
                            title={product.storename}
                            image={product.image}
                            actionstatus={
                              product.actionstatus === 'New'
                                ? 'Neu'
                                : product.actionstatus === 'Sale'
                                ? 'Reduziert'
                                : product.actionstatus
                            }
                            price={product.price.trim()}
                            discount={product.discount}
                            productname={product.productname}
                            mediaUri={
                              MEDIA_URL + '/' + product.store_id + '-data/'
                            }
                          />
                        </Link>
                      </Grid>
                    ))
                ) : (
                  <Box />
                )}
              </Grid>
            </Box>
          </>
        </Box>
      )}
      {data && data.merkantdo_startproducts.length !== 0 ? (
        <Box
          sx={{
            mb: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Pagination
            count={pagesTotal}
            page={pageNumber}
            onChange={handlePage}
          />
        </Box>
      ) : null}
    </Container>
  );
};

export default StartView;
