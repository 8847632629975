import React, { useState } from 'react';
//import SideBar from './layout/SideBar/SideBar';
import TopBar from './layout/TopBar/TopBar';
import {
  DashboardView,
  TicketsView,
  TicketView,
  CustomersView,
  CustomerView,
  InvoicesView,
  InvoiceView,
  BranchesView,
  BranchView,
  ProductCategoriesView,
  ProductCategoryView,
  LogindataView,
  EmailView,
  PasswordView,
} from './components';

/**
 * AdminView
 */
const AdminView = () => {
  const [childPage, setChildPage] = useState<string>('dashboard');
  const [childPageProps, setChildPageProps] = useState<any>({});

  // return
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
      }}
    >
      <TopBar
        childPageFromParent={childPage}
        sendChildPageToParent={setChildPage}
        sendChildPagePropsToParent={setChildPageProps}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100vh',
          width: '100vw',
        }}
      >
        {/** <SideBar childPageFromParent={childPage} sendChildPageToParent={setChildPage} /> */}
        {childPage === 'dashboard' ? (
          <DashboardView
            sendChildPageToParent={setChildPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : childPage === 'tickets' ? (
          <TicketsView
            sendChildPageToParent={setChildPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : childPage === 'ticket' ? (
          <TicketView
            sendChildPageToParent={setChildPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : childPage === 'customers' ? (
          <CustomersView
            sendChildPageToParent={setChildPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : childPage === 'customer' ? (
          <CustomerView
            sendChildPageToParent={setChildPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : childPage === 'invoices' ? (
          <InvoicesView
            sendChildPageToParent={setChildPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : childPage === 'invoice' ? (
          <InvoiceView
            sendChildPageToParent={setChildPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : childPage === 'branches' ? (
          <BranchesView
            sendChildPageToParent={setChildPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : childPage === 'branch' ? (
          <BranchView
            sendChildPageToParent={setChildPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : childPage === 'productCategories' ? (
          <ProductCategoriesView
            sendChildPageToParent={setChildPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : childPage === 'productCategory' ? (
          <ProductCategoryView
            sendChildPageToParent={setChildPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : childPage === 'logindata' ? (
          <LogindataView
            sendChildPageToParent={setChildPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : childPage === 'email' ? (
          <EmailView
            sendChildPageToParent={setChildPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : childPage === 'password' ? (
          <PasswordView
            sendChildPageToParent={setChildPage}
            sendChildPagePropsToParent={setChildPageProps}
            childPagePropsFromParent={childPageProps}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AdminView;
