import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  useTheme,
} from '@mui/material';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { GET_PASSWORD_CODE, RESET_PASSWORD } from './queries/loginQueries';
import { useMutation, useLazyQuery } from '@apollo/client';
import { IInputError } from '../interfaces/interfaces';
import Header from '../components/Header';
import CryptoJS from 'crypto-js';

/**
 * ResetPasswordScreen
 */
const ResetPasswordView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const routerLocation: any = useRouterLocation();
  const [code, setCode] = useState<string>('');
  const [codeError, setCodeError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [dbcode, setDbcode] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [confirmedPassword, setConfirmedPassword] = useState<string>('');
  const [confirmedPasswordError, setConfirmedPasswordError] = useState<
    Partial<IInputError>
  >({
    isError: false,
    message: '',
  });
  const passwordSecret = 'uk72tab';
  const [isButtonResetEnabled, setIsButtonResetEnabled] =
    useState<boolean>(false);
  const [isPasswordSecure, setIsPasswordSecure] = useState<boolean>(true);
  const [resetErrorMessage, setResetErrorMessage] = useState<string>('');

  // getPasswordCode
  const [getPasswordCode] = useLazyQuery(GET_PASSWORD_CODE, {
    variables: { email: routerLocation.state.email },
    fetchPolicy: 'network-only',
  });

  // reserPassword
  const [resetPassord] = useMutation(RESET_PASSWORD);

  /**
   * Wenn keine email vorhanden ist.
   */
  useEffect(() => {
    if (routerLocation.state.email === undefined) {
      navigate('/forgotPassword');
    }
  }, [navigate, routerLocation.state.email]);

  /**
   * Button Senden Status setzen.
   */
  useEffect(() => {
    if (
      code === '' ||
      codeError.isError === true ||
      password === '' ||
      password !== confirmedPassword ||
      passwordError.isError === true ||
      confirmedPasswordError.isError === true
    ) {
      setIsButtonResetEnabled(false);
    } else {
      setIsButtonResetEnabled(true);
    }
  }, [
    code,
    codeError.isError,
    confirmedPassword,
    confirmedPasswordError.isError,
    password,
    passwordError.isError,
  ]);

  /**
   * Funktion zum Umschalten des Password-Inputs,
   * sichtbar oder nicht sichtbar.
   */
  const handlePasswordSecure = () => {
    setIsPasswordSecure(!isPasswordSecure);
  };

  /**
   * code error wird zurückgesetzt.
   */
  const handleCodeOnFocus = () => {
    setCodeError({
      isError: false,
      message: '',
    });
  };

  /**
   * Set code
   */
  const handleCodeInput = async (e: any) => {
    const regexp = /^[0-9\b]+$/;
    setCode(e.target.value);
    if (regexp.test(e.target.value)) {
      if (e.target.value.length === 4) {
        // Datenbank abfragen
        try {
          const { data } = await getPasswordCode();
          setDbcode(data.passwordcode[0].code);
          if (e.target.value !== data.passwordcode[0].code) {
            setCodeError({
              isError: true,
              message: 'Ist kein gültiger Code.',
            });
          } else {
            setCodeError({
              isError: false,
              message: '',
            });
          }
        } catch (error) {
          // do something with the error here
          console.log('Error! handleCodeInput: ', error);
        }
      } else {
        setCodeError({
          isError: true,
          message: 'Der Code hat keine 4 Stellen.',
        });
      }
    } else {
      setCodeError({
        isError: true,
        message: 'Ist kein gültiger Code.',
      });
    }
  };

  /**
   * Funktion zur Validierung des code.
   */
  const checkCodeInput = () => {
    const regexp = /^[0-9\b]+$/;
    if (code === '') {
      setCodeError({
        isError: true,
        message: 'Code ist erforderlich!',
      });
    } else if (regexp.test(code)) {
      if (code.length !== 4) {
        setCodeError({
          isError: true,
          message: 'Der Code hat keine 4 Stellen.',
        });
        if (code !== dbcode) {
          setCodeError({
            isError: true,
            message: 'Ist kein gültiger Code.',
          });
        } else {
          setCodeError({
            isError: false,
            message: '',
          });
        }
      }
    } else {
      setCodeError({
        isError: true,
        message: 'Ist kein gültiger code.',
      });
    }
  };

  /**
   * Password Error zurückgesetzt.
   */
  const handlePasswordOnFocus = () => {
    setPasswordError({
      isError: false,
      message: '',
    });
  };

  /**
   * Password wird gesetzt.
   */
  const handlePasswordInput = (e: any) => {
    setPassword(e.target.value);
  };

  /**
   * Funktion zur Validierung des Passwords.
   * Mindestens 6 Stellen, höchstens 20 Stellen,
   * mindesten ein Großbuchstabe, mindestens ein Kleinbuchstabe
   * und mindestens eine Ziffer.
   */
  const checkPasswordInput = () => {
    const regexp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,20}$/;
    if (password === '') {
      setPasswordError({
        isError: true,
        message: 'Neues Password ist erforderlich!',
      });
    } else if (regexp.test(password)) {
      setPasswordError({
        isError: false,
        message: '',
      });
    } else {
      setPasswordError({
        isError: true,
        message:
          'Muss zwischen 6 und 20 Zeichen sein, mindestens 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Ziffer beinhalten!',
      });
    }
  };

  /**
   * Confirmed Password error wird zurückgesetzt.
   */
  const handleConfirmedPasswordOnFocus = () => {
    if (password === '') {
      setPasswordError({
        isError: true,
        message: 'Neues Password ist erforderlich!',
      });
    }
    setConfirmedPasswordError({
      isError: false,
      message: '',
    });
  };

  /**
   * Confirmed Password wird gesetzt.
   */
  const handleConfirmedPasswordInput = (e: any) => {
    setConfirmedPassword(e.target.value);
    if (e.target.value === password) {
      setConfirmedPasswordError({
        isError: false,
        message: '',
      });
    } else {
      setConfirmedPasswordError({
        isError: true,
        message: 'Passwörter sind nicht gleich!',
      });
    }
  };

  /**
   * Diese Funktion überprüft ob die beiden
   * Einträge für das Passwort gleich sind.
   */
  const checkConfirmedPasswordInput = () => {
    if (confirmedPassword === '') {
      setConfirmedPasswordError({
        isError: true,
        message: 'Neues Password wiederholen!',
      });
    } else if (password !== confirmedPassword) {
      setConfirmedPasswordError({
        isError: true,
        message: 'Passwörter sind nicht gleich!',
      });
    }
  };

  /**
   * E-Mail senden
   */
  const handleResetPassword = async () => {
    try {
      // In der Datenbank löschen, falls vorhanden
      const { data } = await resetPassord({
        variables: {
          email: routerLocation.state.email,
          password: CryptoJS.AES.encrypt(password, passwordSecret).toString(),
        },
      });
      if (data.update_account.affected_rows === 1) {
        navigate('/login');
      } else {
        setResetErrorMessage('Etwas ist schiefgelaufen!');
      }
    } catch (error) {
      console.log('Error! Unable reset password: ', error);
    }
  };

  // return
  return (
    <Container maxWidth='xs'>
      <Box mt={3} mb={2}>
        <Header
          title='Passwort reset'
          subtitle='Geben Sie den erhaltenen Code und Ihr neues Passwort ein.'
        />
      </Box>
      <Box>
        <Typography
          variant='subtitle1'
          align='center'
          sx={{ color: theme.palette.secondary.main }}
        >
          {resetErrorMessage}
        </Typography>
        <FormControl required sx={{ width: '100%' }}>
          <TextField
            id='code'
            name='code'
            sx={{ m: theme.spacing(1, 0, 1) }}
            variant='standard'
            color='secondary'
            margin='dense'
            required
            label='Code'
            value={code}
            error={codeError.isError}
            helperText={codeError.message}
            FormHelperTextProps={{
              sx: { color: theme.palette.secondary.main },
            }}
            onFocus={handleCodeOnFocus}
            onChange={(e) => handleCodeInput(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                checkCodeInput();
              }
            }}
            onBlur={checkCodeInput}
          />
          <TextField
            id='password'
            name='password'
            variant='standard'
            color='secondary'
            margin='dense'
            required
            label='Neues Passwort:'
            value={password}
            type={isPasswordSecure ? 'password' : 'text'}
            error={passwordError.isError}
            helperText={passwordError.message}
            FormHelperTextProps={{
              sx: { color: theme.palette.secondary.main },
            }}
            onFocus={handlePasswordOnFocus}
            onChange={(e) => handlePasswordInput(e)}
            onBlur={checkPasswordInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton color='inherit' onClick={handlePasswordSecure}>
                    {isPasswordSecure ? (
                      <AiOutlineEye size='22' />
                    ) : (
                      <AiOutlineEyeInvisible size='22' />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            id='confirmedPassword'
            name='confirmedPassword'
            variant='standard'
            color='secondary'
            margin='dense'
            required
            label='Password wiederholen:'
            value={confirmedPassword}
            type={isPasswordSecure ? 'password' : 'text'}
            error={confirmedPasswordError.isError}
            helperText={confirmedPasswordError.message}
            FormHelperTextProps={{
              sx: { color: theme.palette.secondary.main },
            }}
            onFocus={handleConfirmedPasswordOnFocus}
            onChange={(e) => handleConfirmedPasswordInput(e)}
            onBlur={checkConfirmedPasswordInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton color='inherit' onClick={handlePasswordSecure}>
                    {isPasswordSecure ? (
                      <AiOutlineEye size='22' />
                    ) : (
                      <AiOutlineEyeInvisible size='22' />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Box>
      <Box mt={2} mb={2}>
        <Box
          mt={2}
          mb={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            type='submit'
            sx={{
              mt: 2,
              borderRadius: 30,
              width: '240px',
            }}
            disabled={!isButtonResetEnabled}
            variant='contained'
            color='secondary'
            onClick={() => handleResetPassword()}
          >
            Senden
          </Button>
          <Button
            sx={{
              mt: 2,
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() => navigate('/login')}
          >
            Abbrechen
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPasswordView;
