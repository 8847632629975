import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Icon,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@mui/material';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import {
  GET_ALL_STOREBRANCHES_COUNT,
  GET_STOREBRANCHES_OFFSET,
  ADD_STOREBRANCH,
  GET_STOREBRANCHES_ID,
  CHECK_BRANCHNAME,
} from '../queries/adminQueries';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { IInputError } from '../../interfaces/interfaces';

/**
 * BranchesView
 */
const BranchesView = (props: any) => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [createBranchDialogVisible, setCreateBranchDialogVisible] =
    useState<boolean>(false);
  const [isButtonCreateEnabled, setIsButtonCreateEnabled] =
    useState<boolean>(false);
  const [newId, setNewId] = useState<number>(0);
  const [newBranch, setNewBranch] = useState<string>('');
  const [newBranchError, setNewBranchError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });

  // Die Anzahl der Einträge ermitteln.
  const {
    loading: countLoading,
    error: countError,
    data: countData,
  } = useQuery(GET_ALL_STOREBRANCHES_COUNT, {
    fetchPolicy: 'network-only',
  });

  // Die Einträge der einzelnen Offsets holen.
  const {
    loading: offsetLoading,
    error: offsetError,
    data: offsetData,
    fetchMore,
  } = useQuery(GET_STOREBRANCHES_OFFSET, {
    variables: {
      offset: page * pageSize,
      limit: pageSize,
      sortOrder: sortOrder,
    },
    fetchPolicy: 'network-only',
  });

  // get id
  const [getId] = useLazyQuery(GET_STOREBRANCHES_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setNewId(data.storebranch[0].id + 1);
    },
  });

  // check Branchname ob vorhanden ist
  const [checkBranchname] = useLazyQuery(CHECK_BRANCHNAME);

  // add storebranch
  const [addStorebranch] = useMutation(ADD_STOREBRANCH, {
    refetchQueries: [
      {
        query: GET_STOREBRANCHES_OFFSET,
        variables: {
          offset: page * pageSize,
          limit: pageSize,
          sortOrder: sortOrder,
        },
      },
    ],
  });

  /**
   * Falls die Navigation von Branch kommt.
   */
  useEffect(() => {
    if (Object.keys(props.childPagePropsFromParent).length !== 0) {
      setPage(props.childPagePropsFromParent.page);
      setPageSize(props.childPagePropsFromParent.pageSize);
      setSortOrder(props.childPagePropsFromParent.sortOrder);
    }
  }, [props.childPagePropsFromParent]);

  /**
   * setIsButtonCreateEnabled validation
   */
  useEffect(() => {
    if (newBranch.length === 0) {
      setNewBranchError({
        isError: true,
        message: 'Eigabe ist erforderlich!',
      });
    } else if (newBranch.length < 2) {
      setNewBranchError({
        isError: true,
        message: 'Eigabe ist zu kurz!',
      });
    } else if (!newBranch.match(/^[A-Z]\w*$/)) {
      setNewBranchError({
        isError: true,
        message: 'Der erste Buchstaben muß groß sein!',
      });
    } else if (
      !newBranch.match(
        /^[A-Za-z\u00e4\u00f6\u00fc\u00c4\u00d6\u00dc\u00df\040]+$/
      )
    ) {
      setNewBranchError({
        isError: true,
        message: 'Bitte nur Buchstaben eingeben!',
      });
    } else {
      setNewBranchError({
        isError: false,
        message: '',
      });
      setIsButtonCreateEnabled(true);
    }
  }, [newBranch]);

  // Beim laden der Daten nichts anzeigen.
  if (countLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (countError) {
    console.log('Error! BranchesView: ' + countError.message);
  }

  // Beim laden der Daten nichts anzeigen.
  if (offsetLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (offsetError) {
    console.log('Error! BranchesView: ' + offsetError.message);
  }

  /**
   * Funktion um den Offset zu setzen.
   */
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchMore({
      variables: {
        offset: newPage * pageSize,
        limit: pageSize,
        sortOrder,
      },
    });
  };

  /**
   * Funktion um pageSize zu ändern.
   */
  const handleChangePageSize = (event) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  /**
   * Handle sortOrder
   */
  const handleSortOrder = () => {
    if (sortOrder === 'asc') {
      setSortOrder('desc');
    } else {
      setSortOrder('asc');
    }
  };

  /**
   * Dialog für das Erstellen einer neuen Warrengruppe.
   */
  const handleCreateBranchDialog = async (action: string) => {
    if (action === 'create' && !newBranchError.isError) {
      // überprüfen ob schon vorhanden ist
      try {
        const { data } = await checkBranchname({
          variables: {
            branchname: newBranch,
          },
          fetchPolicy: 'network-only',
        });
        if (data.storebranch.length === 0) {
          // branche nicht vorhanden.
          // wird erstellt.
          addStorebranch({
            variables: {
              storebranch: {
                id: newId,
                branchname: newBranch,
              },
            },
          });
          setCreateBranchDialogVisible(false);
        } else {
          setNewBranchError({
            isError: true,
            message: 'Diese Branche ist schon vorhanden.',
          });
        }
      } catch (error) {
        console.log('Error! BranchesView - handleCreateBranchDialog:', error);
      }
    } else if (action === 'cancel') {
      setNewBranch('');
      setCreateBranchDialogVisible(false);
    }
  };

  // return
  return (
    <Container maxWidth='xs'>
      {/** Create Branch Dialog */}
      <Dialog
        open={createBranchDialogVisible}
        onClose={() => setCreateBranchDialogVisible(false)}
        aria-labelledby='create-dialog-title'
        aria-describedby='create-dialog-description'
      >
        <DialogTitle id='create-dialog-title'>
          Eine neue Branche erstellen.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='create-dialog-description'>
            <TextField
              color='secondary'
              required
              sx={{ width: '100%' }}
              variant='standard'
              label='Name der Branche'
              value={newBranch}
              error={newBranchError.isError}
              helperText={newBranchError.message}
              onChange={(e) => setNewBranch(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => handleCreateBranchDialog('cancel')}
            variant='outlined'
            color='inherit'
          >
            Abbrechen
          </Button>
          <Button
            disabled={!isButtonCreateEnabled}
            onClick={() => handleCreateBranchDialog('create')}
            variant='outlined'
            color='inherit'
            autoFocus
          >
            Erstellen
          </Button>
        </DialogActions>
      </Dialog>
      {/** Create Branch Dialog end */}
      <Box>
        <Typography component='h2' variant='h6' align='center' mt={2}>
          Branchen
        </Typography>
      </Box>
      <Box mt={2} mb={4}>
        <TableContainer>
          <Table stickyHeader aria-label='sticky table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    '&:hover': {
                      backgroundColor: 'primary.main',
                    },
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  onClick={handleSortOrder}
                >
                  Branche
                  {sortOrder === 'asc' ? (
                    <Icon>
                      <AiOutlineArrowUp size='16' />
                    </Icon>
                  ) : (
                    <Icon>
                      <AiOutlineArrowDown size='16' />
                    </Icon>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            {offsetData !== undefined && offsetData.storebranch.length > 0 ? (
              <TableBody>
                {offsetData.storebranch.map((storebranch: any) => (
                  <TableRow
                    key={storebranch.id}
                    hover
                    onClick={() => {
                      props.sendChildPagePropsToParent({
                        id: storebranch.id,
                        branchname: storebranch.branchname,
                        page,
                        pageSize,
                        sortOrder,
                      });
                      props.sendChildPageToParent('branch');
                    }}
                  >
                    <TableCell>{storebranch.branchname}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <Typography variant='caption'>Keine vorhanden!</Typography>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={'Zeilen:'}
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={countData.storebranch_aggregate.aggregate.totalCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangePageSize}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 4,
          }}
        >
          <Button
            type='submit'
            sx={{
              marginTop: 2,
              borderRadius: 30,
              width: '240px',
            }}
            variant='contained'
            color='secondary'
            onClick={() => {
              setCreateBranchDialogVisible(true);
              getId();
            }}
          >
            Neu
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default BranchesView;
