import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  CardMedia,
  TextField,
  Button,
  Paper,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import 'moment/locale/de';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { useAuth } from '../authContext';
import { SUBSCRIBE_MESSAGES, ADD_MESSAGE } from '../data/MessagesData';
import { IInputError } from '../interfaces/interfaces';
import { useMutation, useSubscription } from '@apollo/client';
import Header2 from '../components/Header2';
import { Config } from '../config';

/**
 * Zeigt alle Messages zu einem Product.
 */
const CustomerMessagesView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const routerLocation: any = useRouterLocation();
  const [{ profile }] = useAuth();
  const [email] = useState<string>(profile.email);
  const [productId] = useState<number>(routerLocation.state.productid);
  const [message, setMessage] = useState<string>('');
  const [messageError, setMessageError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [isButtonSendEnabled, setIsButtonSendEnabled] =
    useState<boolean>(false);
  const MEDIA_URL: any =
    process.env.REACT_APP_MINIO_ENDPOINT !== undefined
      ? process.env.REACT_APP_MINIO_ENDPOINT
      : Config.minio_endpoint;

  // subscribe messages
  const {
    loading: messagesLoading,
    error: messagesError,
    data: messagesData,
  } = useSubscription(SUBSCRIBE_MESSAGES, {
    variables: {
      email: profile.email,
      chat: routerLocation.state.chat,
    },
  });

  // add message
  const [addMessage] = useMutation(ADD_MESSAGE);

  moment().locale('de');

  /**
   * Handling Send Button Status.
   */
  useEffect(() => {
    if (message === '' || messageError.isError) {
      setIsButtonSendEnabled(false);
    } else {
      setIsButtonSendEnabled(true);
    }
  }, [message, messageError.isError]);

  /**
   * On Focus wird zurückgesetzt.
   */
  const handleMessageOnFocus = () => {
    setMessageError({
      isError: false,
      message: '',
    });
  };

  /**
   * Funktion zum setzen der Message im State.
   */
  const handleMessageChange = (event: any) => {
    setMessage(event.target.value);
    const regexp = /[\S\s]+[\S]+/;
    if (!regexp.test(event.target.value)) {
      setMessageError({
        isError: true,
        message: 'Eingabe ist fehlerhaft!',
      });
    } else {
      setMessageError({
        isError: false,
        message: '',
      });
    }
  };

  // Beim laden nichts anzeigen.
  if (messagesLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftritt.
  if (messagesError) {
    console.log(`Error! CustomerMessagesView: ${messagesError.message}`);
  }

  // return
  return (
    <Container maxWidth='xs'>
      {messagesData.message.length === 0 ? (
        <Box>
          <Box mt={3}>
            <Header2 title='Keine Nachrichten vorhanden.' />
          </Box>
          <Box
            mt={3}
            mb={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                marginTop: 2,
                borderRadius: 30,
                width: '240px',
              }}
              type='submit'
              variant='contained'
              color='secondary'
              onClick={() => navigate('/customerMessagesGroups')}
            >
              Ok
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              mt: 3,
            }}
          >
            {routerLocation.state.image === 'none' ? (
              <CardMedia
                sx={{
                  mr: 2,
                  height: 80,
                  width: 60,
                  [theme.breakpoints.down('xs')]: {
                    height: 64,
                    width: 48,
                  },
                }}
                image='/images/placeholder.png'
              />
            ) : (
              <CardMedia
                sx={{
                  mr: 2,
                  height: 80,
                  width: 60,
                  [theme.breakpoints.down('xs')]: {
                    height: 64,
                    width: 48,
                  },
                }}
                image={
                  MEDIA_URL +
                  '/' +
                  routerLocation.state.storeid +
                  '-data/' +
                  routerLocation.state.image
                }
              />
            )}
            <Box
              sx={{
                flexDirection: 'column',
                alignSelf: 'center',
              }}
            >
              <Header2
                title={routerLocation.state.productname}
                subtitle={routerLocation.state.storename}
              />
            </Box>
          </Box>
          <Box mt={2}>
            <Paper elevation={0} sx={{ paddingTop: 1 }}>
              {messagesData.message.map((msg: any) => (
                <Box key={msg.id}>
                  {msg.message_from === profile.email ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        padding: 1,
                        paddingTop: 0,
                      }}
                    >
                      {/** Question */}
                      <Typography
                        variant='body1'
                        sx={{
                          minWidth: '25%',
                          maxWidth: '75%',
                          borderRadius: 3,
                          p: theme.spacing(0.5, 1, 0.5, 1),
                          background: '#02c2b0',
                          wordWrap: 'break-word',
                        }}
                      >
                        {msg.message}
                        {<br />}
                        {moment(msg.created).format('HH:MM, DD MM')}
                      </Typography>
                      {/** Question end */}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        paddingLeft: 1,
                        paddingBottom: 1,
                      }}
                    >
                      {/** Answer */}
                      <Typography
                        variant='body1'
                        //component="span"
                        //display="inline"
                        sx={{
                          minWidth: '25%',
                          maxWidth: '75%',
                          borderRadius: 3,
                          p: theme.spacing(0.5, 1, 0.5, 1),
                          background: theme.palette.background.default,
                          wordWrap: 'break-word',
                        }}
                      >
                        {msg.message}
                        {<br />}
                        {moment(msg.created).format('HH:MM, DD MM')}
                      </Typography>
                      {/** Answer end */}
                    </Box>
                  )}
                </Box>
              ))}
            </Paper>
            {/** New Message */}
            <Box mt={1}>
              <Typography variant='body1' sx={{ ml: 0.5 }}>
                Nachricht schreiben:
              </Typography>
              <TextField
                sx={{ width: '100%', background: theme.palette.primary.main }}
                variant='outlined'
                multiline
                //rows={4}
                value={message}
                error={messageError.isError}
                helperText={messageError.message}
                onFocus={handleMessageOnFocus}
                onChange={handleMessageChange}
              />
            </Box>
            {/** New Message end */}
          </Box>
          <Box
            mt={3}
            mb={4}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              sx={{
                borderRadius: 30,
                width: '240px',
              }}
              type='submit'
              disabled={!isButtonSendEnabled}
              variant='contained'
              color='secondary'
              onClick={() => {
                addMessage({
                  variables: {
                    message: {
                      id: undefined,
                      chat: messagesData.message[0].chat,
                      message_from: email,
                      message_to: messagesData.message[0].message_to,
                      product_id: productId,
                      message: message,
                      created: undefined,
                      status: 'customeranswered',
                    },
                  },
                });
                setMessage('');
              }}
            >
              Senden
            </Button>
            <Button
              sx={{
                marginTop: 2,
                borderRadius: 30,
                width: '240px',
              }}
              type='submit'
              variant='contained'
              color='secondary'
              onClick={() => navigate('/customerMessagesGroups')}
            >
              Ok
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default CustomerMessagesView;
