const envSettings = window as any;

export class Config {
  static hasura_http_url = envSettings.HASURA_HTTP_URL;
  static hasura_ws_url = envSettings.HASURA_WS_URL;
  static minio_region = envSettings.MINIO_REGION;
  static minio_endpoint = envSettings.MINIO_ENDPOINT;
  static nodemailer_url = envSettings.NODEMAILER_URL;
  static items_per_page = envSettings.ITEMS_PER_PAGE;
}
