import React, { useState } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import Header2 from '../components/Header2';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { AiOutlineQuestionCircle, AiOutlineClose } from 'react-icons/ai';
import { GET_STORE_BY_TENANT_ID } from '../data/StoreData';
import {
  GET_STORESERVICE_BY_STORE_ID,
  TERMINATE_CONTRACT,
  TERMINATE_BACK_CONTRACT,
} from './queries/tenantQueries';
import moment from 'moment';
import 'moment/locale/de';
import { useAuth } from '../authContext';
import { useQuery, useMutation } from '@apollo/client';
import TenantContractHelpView from './help/TenantContractHelpView';

/**
 * TenantContractView stellt die Vertragsdaten
 * eines Tenants dar.
 */
const TenantContractView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [{ profile }] = useAuth();
  const [storeId, setStoreId] = useState<number>(0);
  const [serviceName, setServiceName] = useState<string>('');
  const [servicePrice, setServicePrice] = useState<string>('');
  const [terminateContractDialogVisible, setTerminateContractDialogVisible] =
    useState<boolean>(false);
  const [
    terminateBackContractDialogVisible,
    setTerminateBackContractDialogVisible,
  ] = useState<boolean>(false);
  const routerLocation: any = useRouterLocation();
  const [helpOpen, setHelpOpen] = useState<boolean>(
    routerLocation.state.helpopen
  );

  // get store by tenantId
  const {
    loading: storeLoading,
    error: storeError,
    data: storeData,
    refetch: storeRefetch,
  } = useQuery(GET_STORE_BY_TENANT_ID, {
    variables: { tenantid: profile.tenant_id },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setStoreId(data.store[0].id);
    },
  });

  // get store service
  const {
    loading: storeServiceLoading,
    error: storeServiceError,
    //data: storeServiceData,
  } = useQuery(GET_STORESERVICE_BY_STORE_ID, {
    variables: { storeid: storeId },
    fetchPolicy: 'network-only',
    skip: storeId === 0,
    onCompleted: (data) => {
      setServiceName(data.storeservice[0].servicetype.servicename);
      setServicePrice(data.storeservice[0].servicetype.serviceprice);
    },
  });

  // Vertrag kündigen
  const [terminateContract] = useMutation(TERMINATE_CONTRACT);

  // Kündigung zurückziehen
  const [terminateBackContract] = useMutation(TERMINATE_BACK_CONTRACT);

  moment().locale('de');

  // Beim laden der Storedaten nichts anzeigen.
  if (storeLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage der Storedaten auftritt.
  if (storeError) {
    console.log(`Error! TenantContractView: ${storeError.message}`);
  }

  // Beim laden der Storedaten nichts anzeigen.
  if (storeServiceLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftritt.
  if (storeServiceError) {
    console.log(`Error! TenantContractView: ${storeServiceError.message}`);
  }

  /**
   * Dialog für die Kündigung des Vertrages
   */
  const handleTerminateContractDialog = async (action: string) => {
    if (action === 'terminate') {
      try {
        // In die Datenbank schreiben
        await terminateContract({
          variables: {
            id: storeId,
            terminated: moment().add(1, 'months').format('L'),
          },
          fetchPolicy: 'network-only',
        });
        await storeRefetch();
      } catch (error) {
        console.log('Error! TenantContractView - terminateContract:', error);
      }
      setTerminateContractDialogVisible(false);
    } else {
      setTerminateContractDialogVisible(false);
    }
  };

  /**
   * Dialog für das Zurückziehen der Kündigung
   */
  const handleTerminateBackContractDialog = async (action: string) => {
    if (action === 'terminateback') {
      try {
        // In die Datenbank schreiben
        await terminateBackContract({
          variables: {
            id: storeId,
          },
          fetchPolicy: 'network-only',
        });
        await storeRefetch();
      } catch (error) {
        console.log(
          'Error! TenantContractView - terminateBackContract:',
          error
        );
      }
      setTerminateBackContractDialogVisible(false);
    } else {
      setTerminateBackContractDialogVisible(false);
    }
  };

  // return
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      style={{ width: '100%' }}
    >
      <Container maxWidth='xs'>
        {/** Vertrag kündigen Dialog */}
        <Dialog
          open={terminateContractDialogVisible}
          onClose={() => setTerminateContractDialogVisible(false)}
          aria-labelledby='terminate-contract-dialog-title'
          aria-describedby='terminate-contract-dialog-description'
        >
          <DialogTitle id='terminate-contract-dialog-title'>
            Wollen Sie den Vertrag kündigen?
          </DialogTitle>
          <DialogActions
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => handleTerminateContractDialog('cancel')}
              variant='outlined'
              color='inherit'
            >
              Abbrechen
            </Button>
            <Button
              onClick={() => handleTerminateContractDialog('terminate')}
              variant='outlined'
              color='inherit'
              autoFocus
            >
              Kündigen
            </Button>
          </DialogActions>
        </Dialog>
        {/** Kündigung zurückziehen Dialog */}
        <Dialog
          open={terminateBackContractDialogVisible}
          onClose={() => setTerminateBackContractDialogVisible(false)}
          aria-labelledby='terminate-back-contract-dialog-title'
          aria-describedby='terminate-back-contract-dialog-description'
        >
          <DialogTitle id='terminate-back-contract-dialog-title'>
            Wollen Sie die Kündigung zurückziehen?
          </DialogTitle>
          <DialogActions
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={() => handleTerminateBackContractDialog('cancel')}
              variant='outlined'
              color='inherit'
            >
              Abbrechen
            </Button>
            <Button
              onClick={() => handleTerminateBackContractDialog('terminateback')}
              variant='outlined'
              color='inherit'
              autoFocus
            >
              Zurückziehen
            </Button>
          </DialogActions>
        </Dialog>
        {/** Header */}
        <Box mt={3}>
          <Header2
            title='Vertragsdaten'
            subtitle={`Sie sind angemeldet als ${profile.email}`}
          />
        </Box>
        {/** Header end */}
        {/** Content */}
        <Box
          p={2}
          pt={1}
          mt={2}
          sx={{ backgroundColor: theme.palette.primary.main }}
        >
          <TextField
            sx={{ width: '100%' }}
            color='secondary'
            variant='standard'
            margin='dense'
            label='KndenNr.'
            value={storeId}
            inputProps={{ readOnly: true }}
          />
          <TextField
            sx={{ width: '100%' }}
            color='secondary'
            variant='standard'
            margin='dense'
            label='Service'
            value={
              serviceName +
              ' (' +
              parseFloat(servicePrice).toFixed(2).replace('.', ',') +
              ' pro Monat) '
            }
            inputProps={{ readOnly: true }}
          />
          <TextField
            sx={{ width: '100%' }}
            color='secondary'
            variant='standard'
            margin='dense'
            label='Status'
            value={
              storeData.store[0].storestatus === 'new'
                ? 'neu'
                : storeData.store[0].storestatus === 'free'
                ? 'frei'
                : storeData.store[0].storestatus === 'tryout'
                ? 'ausprobieren (kostenlos)'
                : storeData.store[0].storestatus === 'active'
                ? 'aktiv'
                : storeData.store[0].storestatus === 'closed'
                ? 'geschlossen'
                : ''
            }
            inputProps={{ readOnly: true }}
          />
          <TextField
            sx={{ width: '100%' }}
            color='secondary'
            variant='standard'
            margin='dense'
            label='gültig seit'
            value={moment(storeData.store[0].created).format('L')}
            inputProps={{ readOnly: true }}
          />
          <TextField
            sx={{ width: '100%' }}
            color='secondary'
            variant='standard'
            margin='dense'
            label='Rechnung'
            value={
              storeData.store[0].invoice !== null
                ? moment(storeData.store[0].invoice).format('L')
                : '--/--'
            }
            inputProps={{ readOnly: true }}
          />
          <TextField
            sx={{ width: '100%' }}
            color='secondary'
            variant='standard'
            margin='dense'
            label='gekündigt am'
            value={
              storeData.store[0].terminated !== null
                ? moment(storeData.store[0].terminated).format('L')
                : '--/--'
            }
            inputProps={{ readOnly: true }}
          />
        </Box>
        {/** Content end */}
        {/** Action */}
        <Box
          mt={3}
          mb={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {storeData.store[0].terminated !== null ? (
            <Button
              sx={{
                borderRadius: 30,
                width: '240px',
              }}
              type='submit'
              variant='contained'
              color='secondary'
              onClick={() => setTerminateBackContractDialogVisible(true)}
            >
              Kündigung zurückziehen
            </Button>
          ) : (
            <Button
              sx={{
                borderRadius: 30,
                width: '240px',
              }}
              type='submit'
              variant='contained'
              color='secondary'
              onClick={() => setTerminateContractDialogVisible(true)}
            >
              Vertrag kündigen
            </Button>
          )}
          <Button
            sx={{
              marginTop: 2,
              borderRadius: 30,
              width: '240px',
            }}
            type='submit'
            variant='contained'
            color='secondary'
            onClick={() =>
              navigate('/tenant', {
                state: {
                  helpopen: helpOpen,
                },
              })
            }
          >
            Ok
          </Button>
        </Box>
        {/** Action end */}
      </Container>
      {helpOpen ? (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
          }}
          style={{ backgroundColor: '#9E9E9E', width: '20%' }}
        >
          <Box
            sx={{
              paddingTop: 1,
              paddingLeft: 1.5,
            }}
          >
            <IconButton onClick={() => setHelpOpen(false)}>
              <AiOutlineClose size='24' />
            </IconButton>
          </Box>
          <TenantContractHelpView />
        </Box>
      ) : (
        <Box
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
            paddingRight: 1.5,
            paddingTop: 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={() => setHelpOpen(true)}>
              <AiOutlineQuestionCircle size='24' />
            </IconButton>
            <Typography
              onClick={() => setHelpOpen(true)}
              paragraph
              component='span'
              sx={{
                color: theme.palette.text.primary,
                '&:hover, &:focus, &:active': {
                  textDecoration: `underline ${theme.palette.text.primary}`,
                },
              }}
            >
              Hilfe
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TenantContractView;
