import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  List,
  ListItemButton,
  ListItemText,
  Divider,
  Collapse,
} from '@mui/material';
import { useLocation } from '../../locationContext';
import {
  GET_LOCATION_BRANCHES,
  GET_BRANCH_STORES,
} from '../../data/BranchesData';
import { useQuery } from '@apollo/client';

/**
 * Sidebar für Branchen und die vorhandenen Geschäfte.
 */
const Sidebar = (props: any) => {
  const theme = useTheme();
  const [{ location }] = useLocation();

  // get location branches
  const {
    loading: branchesLoading,
    error: branchesError,
    data: branchesData,
  } = useQuery(GET_LOCATION_BRANCHES, {
    variables: { locations: `{${location.locationlist.toString()}}` },
    fetchPolicy: 'network-only',
  });

  // get branch stores
  const {
    loading: storesLoading,
    error: storesError,
    data: storesData,
  } = useQuery(GET_BRANCH_STORES, {
    variables: {
      storebranchid: props.childPagePropsFromParent.branchid,
      locations: `{${location.locationlist.toString()}}`,
    },
    fetchPolicy: 'network-only',
  });

  /**
   * Beim laden nichts anzeigen.
   */
  if (branchesLoading) {
    return null;
  }

  /**
   * Falls Fehler bei der Abfrage auftritt.
   */
  if (branchesError) {
    console.log(`Error! SideBar: ${branchesError.message}`);
  }

  /**
   * Beim laden nichts anzeigen.
   */
  if (storesLoading) {
    return null;
  }

  /**
   * Falls Fehler bei der Abfrage auftritt.
   */
  if (storesError) {
    console.log(`Error! SideBar: ${storesError.message}`);
  }

  // return
  return (
    <Box
      sx={{
        display: {
          xs: 'none',
          sm: 'none',
          md: 'none',
          lg: 'block',
          xl: 'block',
        },
        minWidth: 260,
        minHeight: '100vh',
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Divider />
      <Box sx={{ pt: 2, pb: 2 }}>
        <List component='div' disablePadding dense>
          {branchesData.merkantdo_locationbranches.map((branch: any) => (
            <React.Fragment key={branch.id}>
              <ListItemButton
                sx={{ height: 32, pl: 2 }}
                disableGutters
                onClick={() => {
                  props.sendChildPageToParent('storesStart');
                  props.sendChildPagePropsToParent({
                    ...props.childPagePropsFromParent,
                    branchid: branch.id,
                    branchname: branch.branchname,
                    storeid: 0,
                    actiontype: 'all',
                    pagenumber: 1,
                    expanded: { [branch.id]: true },
                  });
                }}
              >
                {props.childPagePropsFromParent.expanded[branch.id] ? (
                  <ListItemText>{branch.branchname}</ListItemText>
                ) : (
                  <ListItemText>{branch.branchname}</ListItemText>
                )}
              </ListItemButton>
              <Collapse
                in={props.childPagePropsFromParent.expanded[branch.id]}
                timeout='auto'
                unmountOnExit
              >
                {storesData !== undefined ? (
                  <List component='div' disablePadding dense>
                    {storesData.merkantdo_branchstores.map((store: any) => (
                      <ListItemButton
                        sx={{ height: 32, pl: 4 }}
                        key={store.id}
                        onClick={() => {
                          props.sendChildPageToParent('store');
                          props.sendChildPagePropsToParent({
                            ...props.childPagePropsFromParent,
                            storeid: store.id,
                            storename: store.storename,
                            categoryid: null,
                          });
                        }}
                      >
                        {store.id === props.childPagePropsFromParent.storeid ? (
                          <ListItemText>{store.storename}</ListItemText>
                        ) : (
                          <ListItemText>{store.storename}</ListItemText>
                        )}
                      </ListItemButton>
                    ))}
                  </List>
                ) : (
                  <Typography variant='caption'>Keine vorhanden!</Typography>
                )}
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default Sidebar;
