import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Icon,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@mui/material';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import {
  GET_ALL_PRODUCTCATEGORIES_COUNT,
  GET_PRODUCTCATEGORIES_OFFSET,
  GET_PRODUCTCATEGORIES_ID,
  ADD_PRODUCTCATEGORY,
  CHECK_CATEGORYNAME,
} from '../queries/adminQueries';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { IInputError } from '../../interfaces/interfaces';

/**
 * ProductCategoriesView
 */
const ProductCategoriesView = (props: any) => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sortOrder, setSortOrder] = useState<string>('asc');
  const [createCategoryDialogVisible, setCreateCategoryDialogVisible] =
    useState<boolean>(false);
  const [isButtonCreateEnabled, setIsButtonCreateEnabled] =
    useState<boolean>(false);
  const [newId, setNewId] = useState<number>(0);
  const [newCategory, setNewCategory] = useState<string>('');
  const [newCategoryError, setNewCategoryError] = useState<
    Partial<IInputError>
  >({
    isError: false,
    message: '',
  });

  // Die Anzahl der Einträge ermitteln.
  const {
    loading: countLoading,
    error: countError,
    data: countData,
  } = useQuery(GET_ALL_PRODUCTCATEGORIES_COUNT, {
    fetchPolicy: 'network-only',
  });

  // Die Einträge der einzelnen Offsets holen.
  const {
    loading: offsetLoading,
    error: offsetError,
    data: offsetData,
    fetchMore,
  } = useQuery(GET_PRODUCTCATEGORIES_OFFSET, {
    variables: {
      offset: page * pageSize,
      limit: pageSize,
      sortOrder: sortOrder,
    },
    fetchPolicy: 'network-only',
  });

  // get id
  const [getId] = useLazyQuery(GET_PRODUCTCATEGORIES_ID, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setNewId(data.productcategory[0].id + 1);
    },
  });

  // check categoryname ob vorhanden ist
  const [checkCategoryname] = useLazyQuery(CHECK_CATEGORYNAME);

  // add productcategory
  const [addProductcategory] = useMutation(ADD_PRODUCTCATEGORY, {
    refetchQueries: [
      {
        query: GET_PRODUCTCATEGORIES_OFFSET,
        variables: {
          offset: page * pageSize,
          limit: pageSize,
          sortOrder: sortOrder,
        },
      },
    ],
  });

  /**
   * Falls die Navigation von ProductCategory kommt.
   */
  useEffect(() => {
    if (Object.keys(props.childPagePropsFromParent).length !== 0) {
      setPage(props.childPagePropsFromParent.page);
      setPageSize(props.childPagePropsFromParent.pageSize);
      setSortOrder(props.childPagePropsFromParent.sortOrder);
    }
  }, [props.childPagePropsFromParent]);

  /**
   * setIsButtonCreateEnabled validation
   */
  useEffect(() => {
    if (newCategory.length === 0) {
      setNewCategoryError({
        isError: true,
        message: 'Eigabe ist erforderlich!',
      });
    } else if (newCategory.length < 2) {
      setNewCategoryError({
        isError: true,
        message: 'Eigabe ist zu kurz!',
      });
    } else if (!newCategory.match(/^[A-Z]\w*$/)) {
      setNewCategoryError({
        isError: true,
        message: 'Der erste Buchstaben muß groß sein!',
      });
    } else if (
      !newCategory.match(
        /^[A-Za-z\u00e4\u00f6\u00fc\u00c4\u00d6\u00dc\u00df\040]+$/
      )
    ) {
      setNewCategoryError({
        isError: true,
        message: 'Bitte nur Buchstaben eingeben!',
      });
    } else {
      setNewCategoryError({
        isError: false,
        message: '',
      });
      setIsButtonCreateEnabled(true);
    }
  }, [newCategory]);

  // Beim laden der Daten nichts anzeigen.
  if (countLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (countError) {
    console.log('Error! ProductCategoriesView: ' + countError.message);
  }

  // Beim laden der Daten nichts anzeigen.
  if (offsetLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (offsetError) {
    console.log('Error! ProductCategoriesView: ' + offsetError.message);
  }

  /**
   * Funktion um den Offset zu setzen.
   */
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchMore({
      variables: {
        offset: newPage * pageSize,
        limit: pageSize,
        sortOrder,
      },
    });
  };

  /**
   * Funktion um pageSize zu ändern.
   */
  const handleChangePageSize = (event) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  /**
   * Handle sortOrder
   */
  const handleSortOrder = () => {
    if (sortOrder === 'asc') {
      setSortOrder('desc');
    } else {
      setSortOrder('asc');
    }
  };

  /**
   * Dialog für das Erstellen einer neuen Warrengruppe.
   */
  const handleCreateCategoryDialog = async (action: string) => {
    if (action === 'create' && !newCategoryError.isError) {
      // überprüfen ob schon vorhanden ist
      try {
        const { data } = await checkCategoryname({
          variables: {
            categoryname: newCategory,
          },
          fetchPolicy: 'network-only',
        });
        if (data.productcategory.length === 0) {
          // productcategory nicht vorhanden.
          // wird erstellt.
          addProductcategory({
            variables: {
              productcategory: {
                id: newId,
                categoryname: newCategory,
              },
            },
          });
          setCreateCategoryDialogVisible(false);
          setNewCategory('');
        } else {
          setNewCategoryError({
            isError: true,
            message: 'Diese Warrengruppe ist schon vorhanden.',
          });
        }
      } catch (error) {
        console.log(
          'Error! ProductCategoriesView - handleCreateCategoryDialog:',
          error
        );
      }
    } else if (action === 'cancel') {
      setNewCategory('');
      setCreateCategoryDialogVisible(false);
    }
  };

  // return
  return (
    <Container maxWidth='xs'>
      {/** Create Category Dialog */}
      <Dialog
        open={createCategoryDialogVisible}
        onClose={() => setCreateCategoryDialogVisible(false)}
        aria-labelledby='create-dialog-title'
        aria-describedby='create-dialog-description'
      >
        <DialogTitle id='create-dialog-title'>
          Eine neue Warrengruppe erstellen.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='create-dialog-description'>
            <TextField
              color='secondary'
              required
              sx={{ width: '100%' }}
              variant='standard'
              label='Name der Warrengruppe'
              value={newCategory}
              error={newCategoryError.isError}
              helperText={newCategoryError.message}
              onChange={(e) => setNewCategory(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => handleCreateCategoryDialog('cancel')}
            variant='outlined'
            color='inherit'
          >
            Abbrechen
          </Button>
          <Button
            disabled={!isButtonCreateEnabled}
            onClick={() => handleCreateCategoryDialog('create')}
            variant='outlined'
            color='inherit'
            autoFocus
          >
            Erstellen
          </Button>
        </DialogActions>
      </Dialog>
      {/** Create Category Dialog end */}
      <Box>
        <Typography component='h2' variant='h6' align='center' mt={2}>
          Warengruppen
        </Typography>
      </Box>
      <Box mt={2}>
        <TableContainer sx={{ maxHeight: 560 }}>
          <Table stickyHeader aria-label='sticky table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    '&:hover': {
                      backgroundColor: 'primary.main',
                    },
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  onClick={handleSortOrder}
                >
                  Warrengruppe
                  {sortOrder === 'asc' ? (
                    <Icon>
                      <AiOutlineArrowUp size='16' />
                    </Icon>
                  ) : (
                    <Icon>
                      <AiOutlineArrowDown size='16' />
                    </Icon>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            {offsetData !== undefined &&
            offsetData.productcategory.length > 0 ? (
              <TableBody>
                {offsetData.productcategory.map((productcategory: any) => (
                  <TableRow
                    key={productcategory.id}
                    hover
                    onClick={() => {
                      props.sendChildPagePropsToParent({
                        id: productcategory.id,
                        categoryname: productcategory.categoryname,
                        page,
                        pageSize,
                        sortOrder,
                      });
                      props.sendChildPageToParent('productCategory');
                    }}
                  >
                    <TableCell>{productcategory.categoryname}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <Typography variant='caption'>Keine vorhanden!</Typography>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={'Zeilen:'}
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={countData.productcategory_aggregate.aggregate.totalCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangePageSize}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 4,
          }}
        >
          <Button
            type='submit'
            sx={{
              marginTop: 2,
              borderRadius: 30,
              width: '240px',
            }}
            variant='contained'
            color='secondary'
            onClick={() => {
              setCreateCategoryDialogVisible(true);
              setNewCategory('');
              getId();
            }}
          >
            Neu
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ProductCategoriesView;
