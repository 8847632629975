import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * LogindataCustomerHowToView
 */
const LogindataCustomerHowToView = () => {
  const navigate = useNavigate();

  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={3}>
        <Typography component='h1' variant='h4' align='center' gutterBottom>
          Anmeldedaten verwalten - Kunden
        </Typography>
        <Typography component='h1' variant='h6' align='center' gutterBottom>
          Wie kann ich als registrierter Kunde meine Anmeldedaten ändern?
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography component='p' variant='body1' paragraph>
          Um die Logindaten zu ändern müssen Sie sich anmelden. Nach der
          Anmeldung kommen Sie in Ihre Account-Seite. Rechts befindet sich ein
          Icon Logindaten. Klicken Sie auf dien Icon und Sie sind auf der
          Logindaten-Seite. Hier können Sie Ihre E-Mail und Passwort für das
          Login ändern.
        </Typography>
      </Box>
      <Box
        mt={3}
        mb={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type='submit'
          variant='contained'
          color='secondary'
          sx={{ borderRadius: 30, width: '240px' }}
          onClick={() => navigate('/help')}
        >
          Zurück
        </Button>
      </Box>
    </Container>
  );
};

export default LogindataCustomerHowToView;
