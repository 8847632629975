import { gql } from '@apollo/client';

// graphql query start products by location
export const QUERY_START_PRODUCTS = gql`
  query queryStartProductsByLocations($locations: _int4!) {
    merkantdo_startproducts(args: { locations: $locations }) {
      id
      productcategory_id
      actionstatus
      productname
      description
      price
      discount
      store_id
      storename
      image
    }
  }
`;

// graphql query start branch products by location
export const QUERY_START_BRANCH_PRODUCTS = gql`
  query queryStartBranchProductsByLocations(
    $storebranchid: Int!
    $locations: _int4!
  ) {
    merkantdo_startbranchproducts(
      args: { storebranchid: $storebranchid, locations: $locations }
    ) {
      id
      productcategory_id
      actionstatus
      productname
      description
      price
      discount
      store_id
      storename
      storebranch_id
      image
    }
  }
`;
