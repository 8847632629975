import React from 'react';
import { Container, Box, Typography } from '@mui/material';

/**
 * TenantEmailHelpView
 *
 */
const TenantEmailHelpView = () => {
  // return
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component='h1' variant='h5'>
          Login Email ändern
        </Typography>
        <Typography variant='body1' mt={1}>
          Hier haben Sie die Möglichkeit die Email für das Login zu ändern.
        </Typography>
      </Box>
    </Container>
  );
};

export default TenantEmailHelpView;
