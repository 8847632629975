import React, { useState, useEffect, Fragment } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Container,
  Box,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { ADD_ACCOUNT } from '../data/AccountData';
import {
  ADD_TENANT,
  ADD_STORE,
  ADD_STOREBRANCHES,
  GET_ACTIVESERVICETYPES,
  ADD_STORESERVICE,
} from './queries/tenantQueries';
import { useQuery, useMutation } from '@apollo/client';
import minioClient from '../minioClient';
import {
  CreateBucketCommand,
  PutBucketPolicyCommand,
} from '@aws-sdk/client-s3';
import RegisterLoginStoreForm from './RegisterLoginStoreForm';
import RegisterAddressStoreForm from './RegisterAddressStoreForm';
import RegisterBranchesStoreForm from './RegisterBranchesStoreForm';
import RegisterReviewStoreForm from './RegisterReviewStoreForm';
import Header2 from '../components/Header2';
import axios from 'axios';
import { Config } from '../config';
import CryptoJS from 'crypto-js';

// interface
interface ILoginData {
  serviceId: string;
  serviceName: string;
  invoiceEmail: string;
  email: string;
  password: string;
  confirmedPassword: string;
}

// interface
interface IStoreData {
  storeName: string;
  storeOwner: string;
  locationId: number | null;
  zipcode: string;
  place: string;
  street: string;
  phone: string;
  storeStatus: string;
}

// interface
interface IStoreBranch {
  branchId: number;
  branchName: string;
  checked: false;
}

/**
 * RegisterStoreView um sich am System
 * mit Email und Password als Kunde zu registrieren.
 */
const RegisterStoreView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const routerLocation: any = useRouterLocation();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [loginData, setLoginData] = useState<Partial<ILoginData>>({
    serviceId: '',
    serviceName: '',
    invoiceEmail: '',
    email: '',
    password: '',
    confirmedPassword: '',
  });
  const [storeData, setStoreData] = useState<IStoreData>({
    storeName: '',
    storeOwner: '',
    street: '',
    phone: '',
    locationId: 0,
    zipcode: '',
    place: '',
    storeStatus: '',
  });
  const [branchesData, setBranchesData] = useState<Array<IStoreBranch>>([]);
  const passwordSecret = 'uk72tab';
  const [isButtonStep0Enabled, setIsButtonStep0Enabled] =
    useState<boolean>(false);
  const [isButtonStep1Enabled, setIsButtonStep1Enabled] =
    useState<boolean>(false);
  const [isButtonStep2Enabled, setIsButtonStep2Enabled] =
    useState<boolean>(false);
  const [servicetypes, setServicetypes] = useState<any>([]);
  const NODEMAILER_URL: any =
    process.env.REACT_APP_NODEMAILER_URL !== undefined
      ? process.env.REACT_APP_NODEMAILER_URL
      : Config.nodemailer_url;

  // services
  const {
    loading: serviceLoading,
    error: serviceError,
    data: serviceData,
  } = useQuery(GET_ACTIVESERVICETYPES, {
    skip: servicetypes.length > 0,
  });

  // account hinzufügen
  const [addAccount] = useMutation(ADD_ACCOUNT);

  // tenant hinzufügen
  const [addTenant] = useMutation(ADD_TENANT);

  // store hinzufügen
  const [addStore] = useMutation(ADD_STORE);

  // storeBranch hinzufügen
  const [addStoreBranches] = useMutation(ADD_STOREBRANCHES);

  // storeService hinzufügen
  const [addStoreservice] = useMutation(ADD_STORESERVICE);

  /**
   * Wenn routerLocation aus Preisübersicht kommt.
   */
  useEffect(() => {
    if (routerLocation.state !== null) {
      setLoginData({
        ...loginData,
        serviceId: routerLocation.state.serviceId,
        serviceName: routerLocation.state.serviceName,
      });
    } else {
      setLoginData({ ...loginData, serviceId: '', serviceName: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routerLocation]);

  /**
   * Servicetypes holen und setzen.
   */
  useEffect(() => {
    if (serviceData) {
      setServicetypes(serviceData.merkantdo_activeservicetypes);
    }
  }, [serviceData]);

  // Beim laden der Daten nichts anzeigen.
  if (serviceLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (serviceError) {
    console.log('serviceError! in RegisterStoreView: ' + serviceError.message);
  }

  // Vorhandena Steps
  const steps = ['Logindaten', 'Geschäftsdaten', 'Branchen', 'Übersicht'];

  /**
   * Die Props setzen für die jeweiligen Steps.
   */
  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <RegisterLoginStoreForm
            servicetypesFromParent={servicetypes}
            logindataFromParent={loginData}
            sendLogindataToParent={setLoginData}
            buttonStep0EnabledFromParent={isButtonStep0Enabled}
            sendButtonStep0EnabledToParent={setIsButtonStep0Enabled}
          />
        );
      case 1:
        return (
          <RegisterAddressStoreForm
            storedataFromParent={storeData}
            sendStoredataToParent={setStoreData}
            buttonStep1EnabledFromParent={isButtonStep1Enabled}
            sendButtonStep1EnabledToParent={setIsButtonStep1Enabled}
          />
        );
      case 2:
        return (
          <RegisterBranchesStoreForm
            branchesdataFromParent={branchesData}
            sendBranchesdataToParent={setBranchesData}
            buttonStep2EnabledFromParent={isButtonStep2Enabled}
            sendButtonStep2EnabledToParent={setIsButtonStep2Enabled}
          />
        );
      case 3:
        return (
          <RegisterReviewStoreForm
            logindataFromParent={loginData}
            storedataFromParent={storeData}
            branchesdataFromParent={branchesData}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  };

  /**
   * creatBucket
   */
  const createBUcket = async (storeId: number) => {
    const bucketParams = {
      Bucket: storeId + '-data',
    };
    // policy
    const readOnlyAnonUserPolicy = {
      Version: '2012-10-17',
      Statement: [
        {
          Effect: 'Allow',
          Principal: { AWS: ['*'] },
          Action: ['s3:GetObject'],
          Resource: ['arn:aws:s3:::' + storeId + '-data/*'],
        },
      ],
    };
    // bucket policy params
    const bucketPolicyParams = {
      Bucket: storeId + '-data',
      Policy: JSON.stringify(readOnlyAnonUserPolicy),
    };
    try {
      await minioClient.send(
        new CreateBucketCommand(bucketParams)
      );
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await minioClient.send(
          new PutBucketPolicyCommand(bucketPolicyParams)
        );
        //return response;
      } catch (err) {
        console.log('Error adding policy to S3 bucket.', err);
      }
    } catch (err) {
      console.log('Error creating S3 bucket.', err);
    }
  };

  /**
   * Diese Funktion erstellt einen Account und ruf
   * eine Funktion zum Erstellen eines Tenants auf.
   */
  const registerTenant = async () => {
    // addAccount
    const password: any = loginData.password;
    try {
      const { data: accountData } = await addAccount({
        variables: {
          account: {
            id: undefined,
            email: loginData.email,
            password: CryptoJS.AES.encrypt(password, passwordSecret).toString(),
            userrole: 'tenant',
          },
        },
      });
      // danach addTenant
      try {
        const { data: tenantData } = await addTenant({
          variables: {
            tenant: {
              id: undefined,
              account_id: accountData.insert_account_one.id,
              invoiceemail: loginData.invoiceEmail,
            },
          },
        });
        // danach addStore
        try {
          const { data: storeInsertData } = await addStore({
            variables: {
              store: {
                id: undefined,
                location_id: storeData.locationId,
                tenant_id: tenantData.insert_tenant_one.id,
                storetype: 'stationary',
                storestatus: 'new',
                storename: storeData.storeName,
                storeowner: storeData.storeOwner,
                street: storeData.street,
                phone: storeData.phone,
                created: undefined,
              },
            },
          });
          // danach addStoreBranches
          try {
            const branches: Array<any> = [];
            for (let i = 0; i < branchesData.length; i++) {
              if (branchesData[i].checked) {
                //change the checkedState
                branches.push({
                  store_id: storeInsertData.insert_store_one.id,
                  storebranch_id: branchesData[i].branchId,
                });
              }
            }
            await addStoreBranches({
              variables: {
                branches,
              },
            });
            // danach addStoreService
            try {
              await addStoreservice({
                variables: {
                  storeservice: {
                    id: undefined,
                    store_id: storeInsertData.insert_store_one.id,
                    servicetype_id: loginData.serviceId,
                    service_updated: undefined,
                  },
                },
              });
              // createBUcket
              try {
                await createBUcket(storeInsertData.insert_store_one.id);
                // send email
                try {
                  await axios({
                    baseURL: NODEMAILER_URL,
                    method: 'POST',
                    url: '/noreply',
                    data: {
                      email: loginData.email,
                      subject: 'Regisrierung',
                      message:
                        'Hallo,' +
                        '\n' +
                        '\nVielen Dank für Ihre Registrierung.' +
                        '\n' +
                        '\nMit freundlichen Grüßen,' +
                        '\nIhr MERKANTDO-Kundenservice' +
                        '\n' +
                        '\nBeachten Sie, dass diese E-Mail-Adresse nur zum Versenden, nicht aber zum Empfang von' +
                        '\nE-Mails eingerichtet ist.' +
                        '\n' +
                        '\nBitte antworten Sie nicht auf diese E-Mail.',
                    },
                  });
                } catch (error) {
                  console.log('Error! Unable to send Email: ', error);
                }
              } catch (error) {
                console.log('Error! RegisterStoreView - createBucket:', error);
              }
            } catch (error) {
              console.log('Error! RegisterStoreView - addStoreService:', error);
            }
          } catch (error) {
            console.log('Error! RegisterStoreView - addStoreBranches:', error);
          }
        } catch (error) {
          console.log('Error! RegisterStoreView - addStore:', error);
        }
      } catch (error) {
        console.log('Error! RegisterStoreView - addTenant:', error);
      }
    } catch (error) {
      console.log('Error! RegisterStoreView - addAccount:', error);
    }
  };

  /**
   * Wird aufgerufen wenn "Weiter" geclickt wird.
   */
  const handleNext = () => {
    setActiveStep(activeStep + 1);
    if (activeStep === 3) {
      registerTenant();
    }
  };

  /**
   * Wird aufgerufen wenn "Zurück" geclickt wird.
   */
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  // return
  return (
    <Container maxWidth='xs'>
      <Box mt={3}>
        <Box mt={3} mb={2}>
          <Header2 title='Geschäft registrieren' />
        </Box>
        <Typography variant='caption'>
          Registrieren Sie Ihr Geschäft um die Vorteile des Digital-Marketings
          für Ihr Geschäft zu nutzen.
        </Typography>
      </Box>
      <Box mt={2}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          sx={{
            p: 0,
            backgroundColor: theme.palette.background.default,
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Fragment>
          {activeStep === steps.length ? (
            <Fragment>
              <Typography
                variant='h6'
                gutterBottom
                align='center'
                sx={{ mt: 1 }}
              >
                Vielen Dank für Ihre Entscheidung!
              </Typography>
              <Typography variant='subtitle1' sx={{ mt: 1 }}>
                Sie haben jetzt die Möglichkeit Ihre Produkte auf dem Portal
                Ihren Kunden zu präsentieren, die Kunden über neue Artikel oder
                Preisänderungen zu informieren. Melden Sie sich am Portal an um
                Ihre Produkte einzugeben. Ihr Geschäft ist spätestens in 24
                Stunden online.
              </Typography>
              <Typography variant='h6' gutterBottom align='center'>
                Viel Erfolg!
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginTop: theme.spacing(3),
                }}
              >
                <Button
                  type='submit'
                  sx={{
                    borderRadius: 30,
                    width: '240px',
                    alignSelf: 'center',
                  }}
                  variant='contained'
                  color='secondary'
                  onClick={() => navigate('/login')}
                >
                  Zum Login
                </Button>
              </Box>
            </Fragment>
          ) : (
            <Fragment>
              {getStepContent(activeStep)}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  mb: 2,
                }}
              >
                <Button
                  variant='outlined'
                  color='inherit'
                  onClick={() => navigate('/start')}
                  sx={{ mt: 2, ml: 1 }}
                >
                  Abbrechen
                </Button>
                {activeStep !== 0 && (
                  <Button
                    variant='outlined'
                    color='inherit'
                    onClick={handleBack}
                    sx={{ mt: 2, ml: 1 }}
                  >
                    Zurück
                  </Button>
                )}
                <Button
                  variant='outlined'
                  color='inherit'
                  disabled={
                    activeStep === 0
                      ? !isButtonStep0Enabled
                      : activeStep === 1
                      ? !isButtonStep1Enabled
                      : activeStep === 2
                      ? !isButtonStep2Enabled
                      : false
                  }
                  onClick={handleNext}
                  sx={{ mt: 2, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? 'Registrieren' : 'Weiter'}
                </Button>
              </Box>
            </Fragment>
          )}
        </Fragment>
      </Box>
    </Container>
  );
};

export default RegisterStoreView;
