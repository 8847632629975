import { SignatureV4 } from '@aws-sdk/signature-v4';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { HttpRequest } from '@aws-sdk/types';
import { S3Client } from '@aws-sdk/client-s3';
import { Config } from './config';

const MINIO_ENDPOINT: any =
  process.env.REACT_APP_MINIO_ENDPOINT !== undefined
    ? process.env.REACT_APP_MINIO_ENDPOINT
    : Config.minio_endpoint;
const MINIO_REGION: any =
  process.env.REACT_APP_MINIO_REGION !== undefined
    ? process.env.REACT_APP_MINIO_REGION
    : Config.minio_region;
/**
const MINIO_USER: any =
  process.env.REACT_APP_MINIO_USER !== undefined
    ? process.env.REACT_APP_MINIO_USER
    : Config.minio_user;
const MINIO_SECRET: any =
  process.env.REACT_APP_MINIO_SECRET !== undefined
    ? process.env.REACT_APP_MINIO_SECRET
    : Config.minio_secret;
*/

const MINIO_USER: any = 'minio';

const MINIO_SECRET: any = 'vesmoje39';

/**
 * Minio Client aws-sdk v3
 */
const minioClient = new S3Client({
  forcePathStyle: true, // false Configures to use subdomain/virtual calling format.
  endpoint: MINIO_ENDPOINT,
  region: MINIO_REGION,
  credentials: {
    accessKeyId: MINIO_USER,
    secretAccessKey: MINIO_SECRET,
  },
  signer: async () => ({
    sign: async (request: HttpRequest) => {
      request.headers['host'] = `${request.hostname}:${request.port}`;
      const signatureV4 = new SignatureV4({
        credentials: {
          accessKeyId: MINIO_USER,
          secretAccessKey: MINIO_SECRET,
        },
        region: MINIO_REGION,
        service: 's3',
        sha256: Sha256,
      });
      const authorizatedRequest = await signatureV4.sign(request);
      return authorizatedRequest;
    },
  }),
});

export default minioClient;
