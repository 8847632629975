import React from 'react';
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
} from '@mui/material';
import {
  GET_ALL_STORES_COUNT,
  GET_NEW_STORES_COUNT,
  GET_OPEN_TICKETS_COUNT,
  GET_OPEN_INVOICES_COUNT,
} from '../queries/adminQueries';
import { useQuery } from '@apollo/client';

/**
 * DashboardView
 */
const DashboardView = (props: any) => {
  // Die Anzahl aller Store Einträge ermitteln.
  const {
    loading: allStoresCountLoading,
    error: allStoresCountError,
    data: allStoresCountData,
  } = useQuery(GET_ALL_STORES_COUNT, {
    fetchPolicy: 'network-only',
  });

  // Die Anzahl neuer Store Einträge ermitteln.
  const {
    loading: newStoresCountLoading,
    error: newStoresCountError,
    data: newStoresCountData,
  } = useQuery(GET_NEW_STORES_COUNT, {
    fetchPolicy: 'network-only',
  });

  // Die Anzahl offenen Tickets ermitteln.
  const {
    loading: openTicketsCountLoading,
    error: openTicketsCountError,
    data: openTicketsCountData,
  } = useQuery(GET_OPEN_TICKETS_COUNT, {
    fetchPolicy: 'network-only',
  });

  // Die Anzahl offenen Rechnungen ermitteln.
  const {
    loading: openInvoicesCountLoading,
    error: openInvoicesCountError,
    data: openInvoicesCountData,
  } = useQuery(GET_OPEN_INVOICES_COUNT, {
    fetchPolicy: 'network-only',
  });

  // Beim laden der Daten nichts anzeigen.
  if (allStoresCountLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (allStoresCountError) {
    console.log('Error! AdminView: ', allStoresCountError.message);
  }

  // Beim laden der Daten nichts anzeigen.
  if (newStoresCountLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (newStoresCountError) {
    console.log('Error! AdminView: ', newStoresCountError.message);
  }

  // Beim laden der Daten nichts anzeigen.
  if (openTicketsCountLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (openTicketsCountError) {
    console.log('Error! AdminView: ', openTicketsCountError.message);
  }

  // Beim laden der Daten nichts anzeigen.
  if (openInvoicesCountLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (openInvoicesCountError) {
    console.log('Error! AdminView: ', openInvoicesCountError.message);
  }

  // return
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component='h2' variant='h5' align='center' mt={2}>
          Dashboard
        </Typography>
      </Box>
      <Box mt={2} m={2}>
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Card
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                props.sendChildPageToParent('customers');
              }}
            >
              <CardContent>
                Geschäfte insgesamt:&nbsp;&nbsp;
                {allStoresCountData.store_aggregate.aggregate.totalCount}
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Card
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                props.sendChildPageToParent('customers');
              }}
            >
              <CardContent>
                Geschäfte neu:&nbsp;&nbsp;
                {newStoresCountData.store_aggregate.aggregate.totalCount}
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Card
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                props.sendChildPageToParent('tickets');
              }}
            >
              <CardContent>
                Offene Tickets:&nbsp;&nbsp;
                {openTicketsCountData.ticket_aggregate.aggregate.totalCount}
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <Card
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                props.sendChildPageToParent('invoices');
              }}
            >
              <CardContent>
                Offene Rechnungen:&nbsp;&nbsp;
                {openInvoicesCountData.invoice_aggregate.aggregate.totalCount}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default DashboardView;
