import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * HelpCustomerAdminView
 */
const HelpCustomerAdminView = () => {
  const navigate = useNavigate();

  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={3}>
        <Typography component='h1' variant='h4' align='center' gutterBottom>
          Kundendaten
        </Typography>
        <Typography component='h1' variant='h6' align='center' gutterBottom>
          Wie kann ich als registrierter Kunde meine Daten verwalten?
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography component='p' variant='body1' paragraph>
          Daten sind nur bei registrierten Kunden vorhanden. Nach dem Login
          kommnt man auf die Account-Seite. Diese Seite ist die Hauptseite der
          Administration. Es befinden sich drei Icons (News, Nachrichten und
          Logindaten) auf der Seite. Beim klicken werden Sie in den jeweiligen
          Bereich weitergeleitet.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          1. News - sind die Nachrichten, die Sie von Ihren Favoriten bekommen,
          falls Sie diese Option bei den Favoriten aktiviert haben.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          2. Nacrichten - sind die Nachrichten, die Sie bei Anfragen zu einem
          Artikel von den Geschäften bekommen.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          3. Logindaten - sind die Daten (E-Mail und Passwort), die Sie für das
          Login verwenden.
        </Typography>
      </Box>
      <Box
        mt={3}
        mb={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type='submit'
          variant='contained'
          color='secondary'
          sx={{ borderRadius: 30, width: '240px' }}
          onClick={() => navigate('/help')}
        >
          Zurück
        </Button>
      </Box>
    </Container>
  );
};

export default HelpCustomerAdminView;
