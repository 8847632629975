import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ApolloProvider } from '@apollo/client';
import { LocationProvider } from './locationContext';
import { locationInitialState } from './reducers/locationState';
import { locationReducer } from './reducers/locationReducer';
import { FilterProvider } from './filterContext';
import { filterReducer } from './reducers/filterReducer';
import { filterInitialState } from './reducers/filterState';
import { AuthProvider } from './authContext';
import { authInitialState } from './reducers/authState';
import { authReducer } from './reducers/authReducer';
import AppNavigator from './AppNavigator';
import { ThemeContext } from './themeContext';
import { lightTheme, darkTheme } from './themes';
import apolloClient from './apolloClient';

const themes = { lightTheme, darkTheme };

const getDefaultTheme = () => {
  if (localStorage.getItem('theme') !== null) {
    return localStorage.getItem('theme');
  } else {
    return 'lightTheme';
  }
};

const App = () => {
  const [theme, setTheme] = useState<any>(() => getDefaultTheme());
  const currentTheme = themes[theme];

  /**
   * Umschalten von Themes.
   */
  const toggleTheme = () => {
    const nextTheme = theme === 'darkTheme' ? 'lightTheme' : 'darkTheme';
    setTheme(nextTheme);
    localStorage.setItem('theme', nextTheme);
  };

  // return
  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <ThemeProvider theme={currentTheme}>
        <ApolloProvider client={apolloClient}>
          <LocationProvider
            initialState={locationInitialState}
            reducer={locationReducer}
          >
            <FilterProvider
              initialState={filterInitialState}
              reducer={filterReducer}
            >
              <AuthProvider
                initialState={authInitialState}
                reducer={authReducer}
              >
                <Router>
                  <CssBaseline />
                  <AppNavigator />
                </Router>
              </AuthProvider>
            </FilterProvider>
          </LocationProvider>
        </ApolloProvider>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default App;
