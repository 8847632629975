import { AuthState } from './authState';
import { AuthActions } from './authActions';

export function authReducer(state: AuthState, action: AuthActions) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        profile: action.payload,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        profile: null,
      };
    case 'ADD_PROFILE':
      return {
        ...state,
        profile: action.payload,
      };
    case 'UPDATE_PROFILE':
      return {
        ...state,
        profile: action.payload,
      };
    case 'REMOVE_PROFILE':
      return {
        ...state,
        profile: null,
      };
    default:
      return state;
  }
}
