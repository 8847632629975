type Customer = {
  account_id: number;
  userrole: string;
  customer_id: number;
  email: string;
  password: string;
};

type Tenant = {
  account_id: number;
  userrole: string;
  tenant_id: number;
  email: string;
  password: string;
};

type Portaladmin = {
  account_id: number;
  userrole: string;
  portaladmin_id: number;
  email: string;
  password: string;
};

export type AuthState = {
  isAuthenticated: boolean;
  profile: Customer | Tenant | Portaladmin | null;
};

export const authInitialState: AuthState = {
  isAuthenticated: false,
  profile: null,
};
