import { gql } from '@apollo/client';

// graphql insert message
export const ADD_MESSAGE = gql`
  mutation addMessage($message: message_insert_input!) {
    insert_message_one(object: $message) {
      id
      chat
      message_from
      message_to
      product_id
      message
      created
      status
    }
  }
`;

// graphql update message status
export const UPDATE_MESSAGE_STATUS = gql`
  mutation updateMessageStatus($id: Int!, $status: String!) {
    update_message_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
      id
      status
    }
  }
`;

// graphql subscription subscribe messagegroups
export const SUBSCRIBE_MESSAGEGROUPS = gql`
  subscription subscribeMessageGroups($email: String!) {
    message(
      where: {
        _or: [
          { message_from: { _eq: $email } }
          { message_to: { _eq: $email } }
        ]
      }
      distinct_on: chat
      order_by: [{ chat: desc }, { created: desc }]
    ) {
      id
      chat
      message_from
      message_to
      message
      created
      status
      product {
        id
        productname
        storeproductcategory {
          store {
            id
            storename
          }
        }
        images {
          image
        }
      }
    }
  }
`;

// graphql subsciption subscribe messages
export const SUBSCRIBE_MESSAGES = gql`
  subscription subscribeMessages($email: String!, $chat: String!) {
    message(
      where: {
        _and: [
          { chat: { _eq: $chat } }
          {
            _or: [
              { message_from: { _eq: $email } }
              { message_to: { _eq: $email } }
            ]
          }
        ]
      }
      order_by: { created: asc }
    ) {
      id
      chat
      message_from
      message_to
      message
      created
      status
      product {
        id
        productname
        storeproductcategory {
          store {
            id
            storename
          }
        }
        images {
          image
        }
      }
    }
  }
`;

// graphql mutation delete messages
export const REMOVE_MESSAGES = gql`
  mutation removeMessage($chat: String!) {
    delete_message(where: { chat: { _eq: $chat } }) {
      affected_rows
    }
  }
`;
