import React, { useReducer, createContext, ReactNode } from 'react';
import { FilterState } from './reducers/filterState';

type ContextType = [any, any];

interface ContextProps {
  reducer: any;
  initialState: FilterState;
  children: ReactNode;
}

export const FilterContext = createContext<Partial<ContextType>>([]);

export const FilterProvider = ({
  reducer,
  initialState,
  children,
}: ContextProps) => (
  <FilterContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </FilterContext.Provider>
);

export const useFilter = () => React.useContext(FilterContext);
