import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  CardMedia,
  Button,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useTheme,
} from '@mui/material';
import { AiOutlineDelete, AiOutlineMessage } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
import { useAuth } from '../authContext';
import {
  SUBSCRIBE_MESSAGEGROUPS,
  UPDATE_MESSAGE_STATUS,
  REMOVE_MESSAGES,
} from '../data/MessagesData';
import { useMutation, useSubscription } from '@apollo/client';
import Header2 from '../components/Header2';
import { Config } from '../config';

/**
 * Hier werden die MessagesGroups zu einem Product
 * des Kunden verwaltet.
 */
const CustomerMessagesGroupsView = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [{ profile }] = useAuth();
  const [productName, setProductName] = useState<string>('');
  const [chat, setChat] = useState<string>('');
  const [deleteMessageDialogVisible, setDeleteMessageDialogVisible] =
    useState<boolean>(false);
  const MEDIA_URL: any =
    process.env.REACT_APP_MINIO_ENDPOINT !== undefined
      ? process.env.REACT_APP_MINIO_ENDPOINT
      : Config.minio_endpoint;

  // subscribe message groups
  const {
    loading: messageGroupsLoading,
    error: messageGroupsError,
    data: messageGroupsData,
  } = useSubscription(SUBSCRIBE_MESSAGEGROUPS, {
    variables: { email: profile.email },
    fetchPolicy: 'network-only',
  });

  // update message status
  const [updateMessageStatus] = useMutation(UPDATE_MESSAGE_STATUS);

  // remove messages
  const [removeMessages, { loading: removeLoading, error: removeError }] =
    useMutation(REMOVE_MESSAGES);

  moment().locale('de');

  /**
   * Dialog für das Löschen der Nachrichren
   * zu einem Artikel in der Nachrichtenliste.
   */
  const handleMessagesDeleteDialog = (action: string) => {
    if (action === 'delete') {
      removeMessages({
        variables: {
          chat: chat,
        },
      });
    }
    setDeleteMessageDialogVisible(false);
  };

  /**
   * Navigation zu den Messages zu einem Product.
   */
  const goProductMessages = (
    storename: string,
    chat: string,
    productid: number,
    productname: string,
    storeid: number,
    image: string
  ) => {
    navigate('/customerMessages', {
      state: { storename, chat, productid, productname, storeid, image },
    });
  };

  /**
   * Diese Funktion ändert message status.
   */
  const handleMessageStatus = async (id: number, value: string) => {
    try {
      await updateMessageStatus({
        variables: {
          id: id,
          status: value,
        },
      });
    } catch (error) {
      console.log(
        'Error! CustomerMessagesGroupsView - handleMessageStatus:',
        error
      );
    }
  };

  // Beim laden nichts anzeigen.
  if (messageGroupsLoading) {
    return null;
  }

  // Falls Fehler auftritt.
  if (messageGroupsError) {
    console.log(
      `Error! CustomerMessagesGroupsView: ${messageGroupsError.message}`
    );
  }

  // Beim laden nichts anzeigen.
  if (removeLoading) {
    return null;
  }

  // Falls Fehler beim Löschen auftreten.
  if (removeError) {
    console.log(`Error! CustomerMessagesGroupsView: ${removeError.message}`);
  }

  // return
  return (
    <Container maxWidth='xs'>
      <Dialog
        open={deleteMessageDialogVisible}
        onClose={() => setDeleteMessageDialogVisible(false)}
        aria-labelledby='delete-messages-dialog-title'
        aria-describedby='delete-messages-dialog-description'
      >
        <DialogTitle id='delete-messages-dialog-title'>
          Sollen die Nachrichten gelöscht werden?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='delete-messages-dialog-description'>
            {' "'}
            {productName !== '' ? productName : null}
            {'" '}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => handleMessagesDeleteDialog('cancel')}
            variant='outlined'
            color='inherit'
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => handleMessagesDeleteDialog('delete')}
            variant='outlined'
            color='inherit'
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
      <Box mt={3}>
        <Header2
          title='Liste der Nachrichten'
          subtitle={`Sie sind angemeldet als ${profile.email}`}
        />
      </Box>
      <Box mt={2}>
        {!messageGroupsLoading &&
        messageGroupsData &&
        messageGroupsData.message.length === 0 ? (
          <Box>
            <Typography
              component='h2'
              variant='subtitle1'
              mb={1}
              align='center'
            >
              Keine Nachrichten vorhanden.
            </Typography>
          </Box>
        ) : (
          <>
            {!messageGroupsLoading &&
              messageGroupsData &&
              messageGroupsData.message.map((messageGroup: any) => (
                <Paper
                  elevation={0}
                  sx={{ mb: 0.2 }}
                  key={messageGroup.product.id}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    {messageGroup.product.images.length === 0 ? (
                      <CardMedia
                        sx={{
                          height: 100,
                          width: 75,
                          [theme.breakpoints.down('xs')]: {
                            height: 64,
                            width: 48,
                          },
                        }}
                        image='/images/placeholder.png'
                      />
                    ) : (
                      <CardMedia
                        sx={{
                          height: 100,
                          width: 75,
                          [theme.breakpoints.down('xs')]: {
                            height: 64,
                            width: 48,
                          },
                        }}
                        image={
                          MEDIA_URL +
                          '/' +
                          messageGroup.product.storeproductcategory.store.id +
                          '-data/' +
                          messageGroup.product.images[0].image
                        }
                      />
                    )}
                    <Box
                      sx={{
                        ml: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        width: '80%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <Typography
                          variant='body1'
                          sx={{
                            color:
                              messageGroup.status === 'tenantanswered'
                                ? '#FF6D00' // orange
                                : messageGroup.status === 'tenanthadread'
                                ? '#2979FF' // blau
                                : undefined,
                            fontWeight: theme.typography.fontWeightBold,
                          }}
                        >
                          {
                            messageGroup.product.storeproductcategory.store
                              .storename
                          }
                        </Typography>
                        <Box>
                          <IconButton
                            color='inherit'
                            size='small'
                            onClick={() => {
                              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                              messageGroup.status === 'tenantanswered'
                                ? handleMessageStatus(
                                    messageGroup.id,
                                    'customerhadread'
                                  )
                                : null;
                              goProductMessages(
                                messageGroup.product.storeproductcategory.store
                                  .storename,
                                messageGroup.chat,
                                messageGroup.product.id,
                                messageGroup.product.productname,
                                messageGroup.product.storeproductcategory.store
                                  .id,
                                messageGroup.product.images.length !== 0
                                  ? messageGroup.product.images[0].image
                                  : 'none'
                              );
                            }}
                          >
                            <AiOutlineMessage size='22' />
                          </IconButton>
                          <IconButton
                            color='inherit'
                            size='small'
                            onClick={() => {
                              setProductName(messageGroup.product.productname);
                              setChat(messageGroup.chat);
                              setDeleteMessageDialogVisible(true);
                            }}
                          >
                            <AiOutlineDelete size='22' />
                          </IconButton>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          style={{
                            color: theme.palette.text.primary,
                            textDecoration: 'none',
                            fontWeight: theme.typography.fontWeightBold,
                          }}
                        >
                          {messageGroup.product.productname}
                        </Typography>
                        <Typography
                          variant='body1'
                          sx={{ wordWrap: 'break-word' }}
                        >
                          {messageGroup.message}
                        </Typography>
                        <Typography variant='body1'>
                          {moment(messageGroup.created).format('HH:MM, DD MM')}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              ))}
          </>
        )}
      </Box>
      <Box
        mt={3}
        mb={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            borderRadius: 30,
            width: '240px',
          }}
          type='submit'
          variant='contained'
          color='secondary'
          onClick={() => navigate('/customer')}
        >
          Ok
        </Button>
      </Box>
    </Container>
  );
};

export default CustomerMessagesGroupsView;
