import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import { AuthState } from './reducers/authState';

type ContextType = [any, any, any];

interface ContextProps {
  reducer: any;
  initialState: AuthState;
  children: ReactNode;
}

export const AuthContext = createContext<Partial<ContextType>>([]);

export const AuthProvider = ({
  reducer,
  initialState,
  children,
}: ContextProps) => (
  <AuthContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </AuthContext.Provider>
);

export const useAuth = () => useContext(AuthContext);
