import React from 'react';
import {
  Container,
  Box,
  Paper,
  Typography,
  Button,
  IconButton,
  useTheme,
} from '@mui/material';
import { AiOutlineEdit } from 'react-icons/ai';
import { useAuth } from '../../authContext';
import Header from '../../components/Header';

/**
 * Hier werden die Logindaten verwaltet.
 */
const LogindataView = (props: any) => {
  const theme = useTheme();
  const [{ profile }] = useAuth();

  // return
  return (
    <Container maxWidth='xs'>
      <Box mt={3}>
        <Header
          title='Logindaten'
          subtitle='Hier haben Sie die Möglichkeit Ihre Logindaten zu ändern.'
        />
      </Box>
      <Box mt={2}>
        <Paper elevation={0} sx={{ p: 1.5 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant='caption'
              sx={{ fontWeight: theme.typography.fontWeightMedium }}
            >
              E-Mail:
            </Typography>
            <IconButton
              color='inherit'
              size='small'
              onClick={() => props.sendChildPageToParent('email')}
            >
              <AiOutlineEdit size='22' />
            </IconButton>
          </Box>
          <Box>
            <Typography variant='body1'>{profile.email}</Typography>
          </Box>
        </Paper>
        <Paper elevation={0} sx={{ p: 1.5, mt: 0.5 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant='caption'
              sx={{ fontWeight: theme.typography.fontWeightMedium }}
            >
              Passwort:
            </Typography>
            <IconButton
              color='inherit'
              size='small'
              onClick={() => props.sendChildPageToParent('password')}
            >
              <AiOutlineEdit size='22' />
            </IconButton>
          </Box>
          <Box>
            <Typography variant='body1'>******</Typography>
          </Box>
        </Paper>
      </Box>
      <Box
        mt={3}
        mb={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            borderRadius: 30,
            width: '240px',
          }}
          type='submit'
          variant='contained'
          color='secondary'
          onClick={() => props.sendChildPageToParent('dashboard')}
        >
          Ok
        </Button>
      </Box>
    </Container>
  );
};

export default LogindataView;
