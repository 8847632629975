import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Container,
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const tiers = [
  {
    title: 'Allgemeine Fragen',
    content: [
      { description: 'Was ist merkantdo?', link: 'merkantdoInfo' },
      { description: 'Für wen ist merkantdo?', link: 'merkantdoFor' },
    ],
  },
  {
    title: 'Kunde',
    content: [
      {
        description: 'Wofür brauche ich als Kunde ein Nutzerkonto?',
        link: 'helpCustomerAccount',
      },
      {
        description: 'Wie kann ich als Kunde ein Nutzerkonto erstellen?',
        link: 'helpCustomerAccountCreate',
      },
      {
        description: 'Wie kann ich als Kunde meine Daten verwalten?',
        link: 'helpCustomerAdmin',
      },
      {
        description:
          'Wie kann ich als registrierter Kunde meine Favoriten verwalten?',
        link: 'favoriteCustomerHowto',
      },
      {
        description:
          'Wie kann ich als registrierter Kunde meine Anmeldedaten ändern?',
        link: 'logindataCustomerHowto',
      },
    ],
  },
  {
    title: 'Geschäft',
    content: [
      {
        description: 'Welche Vorteile ergeben sich für mein Geschäft?',
        link: 'storeAdvantages',
      },
      {
        description: 'Wie kann ich mein Geschäft registrieren?',
        link: 'storeRegisterHowTo',
      },
      {
        description: 'Wie kann ich die Daten meines Geschäfts verwalten?',
        link: 'storeAdmin',
      },
      {
        description: 'Wie kann ich mit meinen Kunden kommunizieren?',
        link: 'storeCommunicateHowTo',
      },
      {
        description: 'Wie verwalte ich meine Artikel in meinem Geschäft?',
        link: 'storeProductAdminHowTo',
      },
    ],
  },
];

/**
 * HelpView
 */
const HelpView = () => {
  const theme = useTheme();

  // return
  return (
    <Container maxWidth={false}>
      <Box pt={4}>
        <Typography component='h1' variant='h4' align='center' gutterBottom>
          Hilfe
        </Typography>
        <Typography component='h1' variant='h6' align='center' gutterBottom>
          Eine Übersicht über verschiedene Themen der Hilfe.
        </Typography>
      </Box>
      <Container maxWidth='md' sx={{ pt: 2, pb: 2 }}>
        <Grid container spacing={5} alignItems='flex-start'>
          {tiers.map((tier) => (
            <Grid item key={tier.title} xs={12} sm={6} md={4}>
              <Card elevation={0}>
                <CardHeader
                  title={tier.title}
                  titleTypographyProps={{ align: 'center' }}
                  sx={{
                    backgroundColor:
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[900],
                  }}
                />
                <CardContent>
                  <ul
                    style={{
                      listStyleType: 'none',
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {tier.content.map((item) => (
                      <li key={item.link}>
                        <RouterLink
                          to={`/${item.link}`}
                          style={{
                            textDecoration: 'none',
                          }}
                          color='textPrimary'
                        >
                          <Typography
                            variant='body1'
                            sx={{
                              mb: 1,
                              color: theme.palette.text.primary,
                              '&:hover, &:focus, &:active': {
                                textDecoration: `underline ${theme.palette.text.primary}`,
                              },
                            }}
                          >
                            {item.description}
                          </Typography>
                        </RouterLink>
                      </li>
                    ))}
                  </ul>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Container>
  );
};

export default HelpView;
