import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ImageList,
  ImageListItem,
  CardMedia,
  Link,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { ADD_MESSAGE } from '../data/MessagesData';
import { AiOutlineLeft, AiOutlineHome } from 'react-icons/ai';
import { useAuth } from '../authContext';
import { GET_STORE_BY_ID, GET_PRODUCT_BY_ID } from '../data/StoreData';
import { GET_STORE_BRANCHES } from '../data/BranchesData';
import { useQuery, useMutation } from '@apollo/client';
import { IInputError, IImage } from '../interfaces/interfaces';
import { Config } from '../config';
import Header from '../components/Header';

/**
 * ProductView stellt Products
 * eines Stores in Categories dar.
 */
const ProductView = () => {
  const theme = useTheme();
  const matches800 = useMediaQuery('(max-width:800px)');
  const matches600 = useMediaQuery('(max-width:600px)');
  const navigate = useNavigate();
  const routerLocation: any = useRouterLocation();
  const [{ isAuthenticated, profile }] = useAuth();
  const [activeImage, setActiveImage] = useState<Partial<IImage>>({
    id: 0,
    image: undefined,
  });
  const [loginDialogVisible, setLoginDialogVisible] = useState<boolean>(false);
  const [messageDialogVisible, setMessageDialogVisible] =
    useState<boolean>(false);
  const [notMessageDialogVisible, setNotMessageDialogVisible] =
    useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [messageError, setMessageError] = useState<Partial<IInputError>>({
    isError: false,
    message: '',
  });
  const [isButtonSendEnabled, setIsButtonSendEnabled] =
    useState<boolean>(false);

  // get store by id
  const {
    loading: storeLoading,
    error: storeError,
    data: storeData,
  } = useQuery(GET_STORE_BY_ID, {
    variables: { storeid: routerLocation.state.storeid },
  });

  // get products by id
  const {
    loading: productLoading,
    error: productError,
    data: productData,
  } = useQuery(GET_PRODUCT_BY_ID, {
    variables: { productid: routerLocation.state.productid },
  });

  // add message
  const [addMessage] = useMutation(ADD_MESSAGE);

  // get store branches
  const {
    loading: storeBranchesLoading,
    error: storeBranchesError,
    data: storeBranchesData,
  } = useQuery(GET_STORE_BRANCHES, {
    variables: { storeid: routerLocation.state.storeid },
  });

  // media url
  const MEDIA_URL: any =
    process.env.REACT_APP_MINIO_ENDPOINT !== undefined
      ? process.env.REACT_APP_MINIO_ENDPOINT
      : Config.minio_endpoint;

  /**
   * Handling Send Button Status.
   */
  useEffect(() => {
    if (message === '' || messageError.isError) {
      setIsButtonSendEnabled(false);
    } else {
      setIsButtonSendEnabled(true);
    }
  }, [message, messageError.isError]);

  /**
   * Wenn action 'messageSend' als
   * parameter gesendet wird.
   */
  useEffect(() => {
    if (routerLocation.state.action === 'messageSend') {
      setMessageDialogVisible(true);
    } else if (routerLocation.state.action === 'messageNotSend') {
      setNotMessageDialogVisible(true);
    }
  }, [routerLocation.state]);

  /**
   * activeImage setzen
   */
  useEffect(() => {
    if (productData) {
      setActiveImage({
        id: productData.merkantdo_product[0].images[0].id,
        image: productData.merkantdo_product[0].images[0].image,
      });
    }
  }, [productData]);

  // Beim laden der Storedaten nichts anzeigen.
  if (storeLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage der Storedaten auftritt.
  if (storeError) {
    console.log(`Error! ProductView: ${storeError.message}`);
  }

  // Beim laden der Productdaten ProgressBar anzeigen.
  if (productLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage der Productdaten auftritt.
  if (productError) {
    console.log(`Error! ProductView: ${productError.message}`);
  }

  // Beim laden nichts anzeigen.
  if (storeBranchesLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (storeBranchesError) {
    console.log(`Error! TenantStoreView: ${storeBranchesError.message}`);
  }

  /**
   * Handle Dialog für das Login um eine Message
   * zu senden.
   */
  const handleLoginDialog = (action: string) => {
    if (action === 'login') {
      setLoginDialogVisible(false);
      navigate('/login', {
        state: {
          srcroute: '/product',
          parentsrcroute: routerLocation.state.srcroute,
          childpage: routerLocation.state.childpage,
          branchid: routerLocation.state.branchid,
          expanded: routerLocation.state.expanded,
          storeid: routerLocation.state.storeid,
          categoryid: routerLocation.state.categoryid,
          productid: routerLocation.state.productid,
          actiontype: routerLocation.state.actiontype,
          pagenumber: routerLocation.state.pagenumber,
          searchparam: routerLocation.state.searchparam,
          action: 'messageSend',
        },
      });
    } else {
      setLoginDialogVisible(false);
    }
  };

  /**
   * Wenn man eine Machricht senden will, muss
   * überprüft werden ob der Kunde angemeldet ist.
   * Wenn nicht, muss umgeleitet werden zum LoginView.
   */
  const handleMessageSend = () => {
    if (!isAuthenticated) {
      setLoginDialogVisible(true);
    } else if (profile.customer_id !== 0) {
      setMessageDialogVisible(true);
    }
  };

  /**
   * Message Dialog für die Eingabe
   * der Nachricht.
   */
  const handleMessageDialog = (action: string) => {
    if (action === 'send') {
      addMessage({
        variables: {
          message: {
            id: undefined,
            chat: profile.email + productData.merkantdo_product[0].id,
            message_from: profile.email,
            message_to: storeData.store[0].tenant.account.email,
            product_id: productData.merkantdo_product[0].id,
            message: message,
            created: undefined,
            status: 'new',
          },
        },
      });
      setMessage('');
      setIsButtonSendEnabled(false);
    }
    setMessageDialogVisible(false);
  };

  /**
   * On Focus werden Error
   * Messages zurückgesetzt.
   */
  const handleMessageOnFocus = () => {
    setMessageError({
      isError: false,
      message: '',
    });
  };

  /**
   * Event zum setzen der
   * messages im State.
   * @param event
   */
  const handleMessageChange = (event: any) => {
    setMessage(event.target.value);
    const regexp = /[\S\s]+[\S]+/;
    if (!regexp.test(event.target.value)) {
      setMessageError({
        isError: true,
        message: 'Eingabe ist fehlerhaft!',
      });
    } else {
      setMessageError({
        isError: false,
        message: '',
      });
    }
  };

  /**
   * Error Überprüfung und setzen der
   * Error Message.
   
  const checkMessageInput = () => {
    if (message === '') {
      setMessageError({
        isError: true,
        message: 'Nachrichtentext ist erforderlich!',
      });
    } else {
      setMessageError({
        isError: false,
        message: '',
      });
      setIsButtonSendEnabled(true);
    }
  };
  */

  /**
   * goBack navigation in Abhängigkeit von srcroute.
   * Es sind vier Möglichkeiten vorhanden.
   */
  const goBack = () => {
    if (routerLocation.state !== null) {
      if (routerLocation.state.srcroute === '/start') {
        navigate('/start', {
          state: {
            actiontype: routerLocation.state.actiontype,
            pagenumber: routerLocation.state.pagenumber,
          },
        });
      } else if (
        routerLocation.state.srcroute === '/stores' &&
        routerLocation.state.childpage === 'storesStart'
      ) {
        navigate('/stores', {
          state: {
            childpage: routerLocation.state.childpage,
            branchid: routerLocation.state.branchid,
            branchname: routerLocation.state.branchname,
            expanded: routerLocation.state.expanded,
            storeid: 0,
            actiontype: routerLocation.state.actiontype,
            pagenumber: routerLocation.state.pagenumber,
          },
        });
      } else if (
        routerLocation.state.srcroute === '/stores' &&
        routerLocation.state.childpage === 'store'
      ) {
        navigate('/stores', {
          state: {
            childpage: routerLocation.state.childpage,
            branchid: routerLocation.state.branchid,
            expanded: routerLocation.state.expanded,
            storeid: routerLocation.state.storeid,
            categoryid: routerLocation.state.categoryid,
            pagenumber: routerLocation.state.pagenumber,
          },
        });
      } else if (
        routerLocation.state.srcroute === '/stores' &&
        routerLocation.state.childpage === 'storeSearch'
      ) {
        navigate('/stores', {
          state: {
            childpage: routerLocation.state.childpage,
            branchid: routerLocation.state.branchid,
            expanded: routerLocation.state.expanded,
            storeid: routerLocation.state.storeid,
            storename: routerLocation.state.storename,
            categoryid: routerLocation.state.categoryid,
            pagenumber: routerLocation.state.pagenumber,
            searchparam: routerLocation.state.searchparam,
          },
        });
      } else if (routerLocation.state.srcroute === '/search') {
        navigate('/search', {
          state: {
            searchparam: routerLocation.state.searchparam,
            pagenumber: routerLocation.state.pagenumber,
          },
        });
      } else {
        navigate('/start');
      }
    }
  };

  /**
   * Store Navigation Function
   */
  const goStore = () => {
    navigate('/stores', {
      state: {
        childpage: 'store',
        branchid:
          routerLocation.state.branchid === undefined
            ? storeBranchesData.storebranch[0].id
            : routerLocation.state.branchid,
        expanded:
          routerLocation.state.expanded === undefined
            ? { [storeBranchesData.storebranch[0].id]: true }
            : routerLocation.state.expanded,
        storeid:
          routerLocation.state.storeid === undefined
            ? productData.merkantdo_product[0].store_id
            : routerLocation.state.storeid,
        categoryid:
          routerLocation.state.categoryid === undefined
            ? productData.merkantdo_product[0].productcategory_id
            : routerLocation.state.categoryid,
        pagenumber: routerLocation.state.pagenumber,
        searchparam: '',
      },
    });
  };

  // return
  return (
    <Container maxWidth={false} disableGutters>
      {/** Login Dialog */}
      <Dialog
        open={loginDialogVisible}
        onClose={() => setLoginDialogVisible(false)}
        aria-labelledby='login-dialog-title'
        aria-describedby='login-dialog-description'
      >
        <DialogTitle id='login-dialog-title'>
          Anmeldung erforderlich!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='login-dialog-description'>
            Um eine Nachricht zu schreiben müssen Sie sich anmelden.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => handleLoginDialog('cancel')}
            variant='outlined'
            color='inherit'
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => handleLoginDialog('login')}
            variant='outlined'
            color='inherit'
            autoFocus
          >
            Anmelden
          </Button>
        </DialogActions>
      </Dialog>
      {/** Login Dialog end */}
      {/** Message Dialog */}
      <Dialog
        sx={{ width: '100%' }}
        fullWidth
        maxWidth='sm'
        open={messageDialogVisible}
        onClose={() => setMessageDialogVisible(false)}
        aria-labelledby='message-dialog-title'
        aria-describedby='message-dialog-description'
      >
        <DialogTitle id='message-dialog-title'>
          Ihre Nachricht schreiben.
        </DialogTitle>
        <DialogContent>
          <DialogContentText component='span' id='message-dialog-description'>
            <TextField
              sx={{ width: '100%' }}
              required
              multiline
              rows={6}
              variant='outlined'
              error={messageError.isError}
              helperText={messageError.message}
              value={message}
              onFocus={handleMessageOnFocus}
              onChange={(e) => handleMessageChange(e)}
              //onBlur={checkMessageInput}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => handleMessageDialog('cancel')}
            variant='outlined'
            color='inherit'
          >
            Abbrechen
          </Button>
          <Button
            disabled={!isButtonSendEnabled}
            onClick={() => handleMessageDialog('send')}
            variant='outlined'
            color='inherit'
            autoFocus
          >
            Senden
          </Button>
        </DialogActions>
      </Dialog>
      {/** Message Dialog end */}
      {/** notMessage Dialog */}
      <Dialog
        maxWidth={false}
        open={notMessageDialogVisible}
        onClose={() => setNotMessageDialogVisible(false)}
        aria-labelledby='no-message-dialog-title'
        aria-describedby='no-message-dialog-description'
      >
        <DialogTitle id='no-message-dialog-title'>
          Nachricht schreiben nicht möglich.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='no-message-dialog-description'>
            Sie haben sich nicht als registrierte Kunde angemeldet.
          </DialogContentText>
          <DialogContentText id='no-message-dialog-description'>
            Das Schreiben von Nachrichten ist nur den registrierten Kunden
            vorbehalten.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => setNotMessageDialogVisible(false)}
            variant='outlined'
            color='inherit'
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/** noMessage Dialog end */}
      <Divider />
      {/** Header Appbar */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          margin: theme.spacing(0, 0, 1, 0),
          padding: theme.spacing(0.5, 0, 0.5, 0),
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: theme.spacing(2),
          }}
        >
          <IconButton color='inherit' size='small' onClick={() => goBack()}>
            <AiOutlineLeft size='22' />
          </IconButton>
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
                lg: 'block',
                xl: 'block',
              },
            }}
          >
            <Link
              underline='none'
              component='button'
              color='textPrimary'
              style={{
                textDecoration: 'none',
              }}
              onClick={() => goBack()}
            >
              <Typography
                component='h2'
                variant='subtitle1'
                sx={{
                  color: theme.palette.text.primary,
                  '&:hover': {
                    textDecoration: `underline ${theme.palette.text.primary}`,
                  },
                }}
              >
                Zurück
              </Typography>
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component='h2' variant='h6'>
            {storeData.store[0].storename}
          </Typography>
          <Typography component='h2' variant='subtitle1'>
            {storeData.store[0].street}, {storeData.store[0].location.zipcode}{' '}
            {storeData.store[0].location.place}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingRight: theme.spacing(2),
          }}
        >
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
                lg: 'block',
                xl: 'block',
              },
            }}
          >
            <Link
              underline='none'
              component='button'
              color='textPrimary'
              style={{
                textDecoration: 'none',
              }}
              onClick={() => goStore()}
            >
              <Typography
                component='h2'
                variant='subtitle1'
                sx={{
                  color: theme.palette.text.primary,
                  '&:hover': {
                    textDecoration: `underline ${theme.palette.text.primary}`,
                  },
                }}
              >
                Zum Geschäft
              </Typography>
            </Link>
          </Box>
          <IconButton color='inherit' size='small' onClick={() => goStore()}>
            <AiOutlineHome size='22' />
          </IconButton>
        </Box>
      </Box>
      {/** Header Appbar end */}
      {/** Content */}
      {productData.merkantdo_product[0].productstatus === 'active' ? (
        <Box
          sx={{
            ml: matches600 === true ? 2 : 0,
            mt: matches800 === true ? 0 : 6,
            display: 'flex',
            flexDirection: matches800 === true ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/** Image List Box + Media Box */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: matches800 === true ? 400 : 340,
              mt: matches800 === true ? 1 : 0,
              backgroundColor: theme.palette.primary.main,
            }}
          >
            {/** Image List Box */}
            <Box>
              <ImageList
                sx={{
                  mt: 0,
                  mr: 0.2,
                  mb: 0.2,
                  ml: 1.5,
                  width: 40,
                }}
                cols={1}
                rowHeight={40}
                gap={1}
              >
                {productData.merkantdo_product[0].images.map((img: any) => (
                  <ImageListItem
                    key={img.id}
                    sx={{
                      border: activeImage.id === img.id ? 1 : 0,
                      color: theme.palette.secondary.main,
                    }}
                  >
                    <img
                      src={`${
                        MEDIA_URL +
                        '/' +
                        productData.merkantdo_product[0].store_id +
                        '-data' +
                        '/' +
                        img.image
                      }`}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      loading='lazy'
                      onMouseOver={() => {
                        setActiveImage({ id: img.id, image: img.image });
                      }}
                      onClick={() => {
                        setActiveImage({ id: img.id, image: img.image });
                      }}
                      alt=''
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
            {/** Image List Box end */}
            {/** Media Box */}
            <Box
              sx={{
                width: matches800 === true ? 390 : 340,
              }}
            >
              {productData.merkantdo_product[0].actionstatus === 'None' ? (
                <Typography
                  color='secondary'
                  variant='body1'
                  sx={{
                    mt: 1,
                    ml: 1,
                    position: 'absolute',
                    color: theme.palette.secondary.main,
                  }}
                >
                  {''}
                </Typography>
              ) : (
                <Typography
                  color='secondary'
                  variant='body1'
                  sx={{
                    mt: 1,
                    ml: 1,
                    position: 'absolute',
                    color: theme.palette.secondary.main,
                  }}
                >
                  {productData.merkantdo_product[0].actionstatus === 'New'
                    ? 'Neu'
                    : productData.merkantdo_product[0].actionstatus === 'Sale'
                    ? 'Reduziert' +
                      '\u00A0' +
                      '\u00A0' +
                      '\u00A0' +
                      '\u00A0' +
                      '-' +
                      productData.merkantdo_product[0].discount +
                      '%'
                    : productData.merkantdo_product[0].actionstatus}
                </Typography>
              )}
              <CardMedia
                sx={{
                  height: matches800 === true ? 423 : 400,
                  width: matches800 === true ? 317 : 300,
                }}
                image={
                  MEDIA_URL +
                  '/' +
                  productData.merkantdo_product[0].store_id +
                  '-data' +
                  '/' +
                  activeImage.image
                }
              />
            </Box>
            {/** Media Box end */}
          </Box>
          {/** Image List Box + Media Box end */}
          {/** Data Box */}
          <Box
            sx={{
              p: 2,
              mb: matches800 === true ? 1 : 0,
              ml: matches800 === true ? 0 : 0.5,
              height: matches800 === true ? 260 : 400,
              width: matches800 === true ? 400 : 300,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <Box>
              <Typography
                variant='h6'
                sx={{ fontWeight: theme.typography.fontWeightMedium }}
              >
                {productData.merkantdo_product[0].productname}
              </Typography>
              {productData.merkantdo_product[0].actionstatus === 'Sale' ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Typography
                    variant='h6'
                    sx={{
                      mt: 0.5,
                      fontWeight: theme.typography.fontWeightMedium,
                      color: theme.palette.text.disabled,
                      textDecorationLine: 'line-through',
                    }}
                  >
                    {productData.merkantdo_product[0].price.trim()} €
                  </Typography>
                  <Typography
                    variant='h6'
                    sx={{
                      mt: 0.5,
                      ml: 2,
                      fontWeight: theme.typography.fontWeightMedium,
                    }}
                  >
                    {(
                      Math.round(
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        (parseFloat(
                          productData.merkantdo_product[0].price.trim()
                        ).toFixed(2) -
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          (parseFloat(
                            productData.merkantdo_product[0].price.trim()
                          ).toFixed(2) /
                            100) *
                            productData.merkantdo_product[0].discount) *
                          100
                      ) / 100
                    ).toFixed(2)}{' '}
                    €
                  </Typography>
                </Box>
              ) : (
                <Typography
                  variant='h6'
                  sx={{ fontWeight: theme.typography.fontWeightMedium }}
                >
                  {productData.merkantdo_product[0].price.trim()} €
                </Typography>
              )}
              <Typography variant='body1' sx={{ mt: 0.5 }}>
                {productData.merkantdo_product[0].description}
              </Typography>
              <Typography variant='body1' sx={{ mt: 0.5 }}>
                Verfügbarkeit:{' '}
                {productData.merkantdo_product[0].availability === 'available'
                  ? 'vorhanden'
                  : productData.merkantdo_product[0].availability === 'reserved'
                  ? 'reserviert'
                  : productData.merkantdo_product[0].availability === 'soldout'
                  ? 'ausverkauft'
                  : null}
              </Typography>
            </Box>
            {profile === null || profile.userrole === 'customer' ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <Button
                  type='submit'
                  sx={{
                    borderRadius: 30,
                    width: '240px',
                  }}
                  variant='contained'
                  color='secondary'
                  onClick={() => handleMessageSend()}
                >
                  Nachricht schreiben
                </Button>
              </Box>
            ) : null}
          </Box>
          {/** Data Box end */}
        </Box>
      ) : (
        <Box mt={4}>
          <Header subtitle={`Der Artikel ist nicht mehr vorhanden.`} />
        </Box>
      )}
      {/** Content end */}
    </Container>
  );
};

export default ProductView;
