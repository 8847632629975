import { gql } from '@apollo/client';

// graphql add productcategory to store
export const ADD_PRODUCTCATEGORY = gql`
  mutation addProductCategory($productcategory: productcategory_insert_input!) {
    insert_productcategory_one(object: $productcategory) {
      id
      categoryname
    }
  }
`;

// graphql add productcategory to store
export const ADD_STORE_PRODUCTCATEGORY = gql`
  mutation addStoreProductCategory(
    $productcategory: storeproductcategories_insert_input!
  ) {
    insert_storeproductcategories_one(object: $productcategory) {
      store_id
      productcategory_id
    }
  }
`;

// graphql mutation delete storeproductcategories
export const REMOVE_STORE_PRODUCTCATEGORY = gql`
  mutation removeStoreProductCategory(
    $storeid: Int!
    $productcategoryid: Int!
  ) {
    delete_storeproductcategories(
      where: {
        _and: [
          { store_id: { _eq: $storeid } }
          { productcategory_id: { _eq: $productcategoryid } }
        ]
      }
    ) {
      affected_rows
    }
  }
`;

// graphql query product  by id
export const GET_PRODUCT_BY_ID = gql`
  query getProductById($productid: Int!) {
    merkantdo_product(args: { productid: $productid }) {
      id
      productstatus
      actionstatus
      availability
      productname
      description
      price
      discount
      productcategory_id
      categoryname
      store_id
      storename
      images
    }
  }
`;

// graphql query store  by id
export const GET_STORE_BY_ID = gql`
  query getStoreById($storeid: Int!) {
    store(where: { id: { _eq: $storeid } }) {
      id
      location_id
      storestatus
      storename
      storeowner
      street
      phone
      created
      activated
      invoice
      terminated
      location {
        zipcode
        place
        state
        community
        countrycode
      }
      tenant {
        account {
          email
        }
      }
    }
  }
`;

// graphql query store  by tenantid
export const GET_STORE_BY_TENANT_ID = gql`
  query getStoreByTenant($tenantid: Int!) {
    store(where: { tenant_id: { _eq: $tenantid } }) {
      id
      location_id
      storestatus
      storename
      storeowner
      street
      phone
      created
      activated
      invoice
      terminated
      location {
        zipcode
        place
        state
        community
        countrycode
      }
      tenant {
        account {
          email
        }
      }
    }
  }
`;

// graphql query storeid  by tenantid
export const GET_STORE_ID_BY_TENANT_ID = gql`
  query getStoreIdByTenant($tenantid: Int!) {
    store(where: { tenant_id: { _eq: $tenantid } }) {
      id
    }
  }
`;

// graphql query storeservice
export const GET_STORE_SERVICE = gql`
  query getStoreService($storeid: Int!) {
    store(where: { id: { _eq: $storeid } }) {
      storeservices {
        servicetype {
          servicename
          actionnumber
        }
      }
    }
  }
`;

// graphql query store startproductcount
export const GET_STORE_STARTPRODUCTS_COUNT = gql`
  query geStoreStartProductsCount($storeid: Int!) {
    product_aggregate(
      where: {
        _and: [
          { store_id: { _eq: $storeid } }
          { start_on: { _eq: true } }
          { productstatus: { _eq: "active" } }
        ]
      }
    ) {
      aggregate {
        totalCount: count
      }
    }
  }
`;

// graphql query product categories by store
export const GET_PRODUCT_CATEGORIES_BY_STORE = gql`
  query getProductCategoriesByStore($storeid: Int!) {
    storeproductcategories(
      where: {
        _and: [
          { store_id: { _eq: $storeid } }
          { products: { productstatus: { _eq: "active" } } }
        ]
      }
      order_by: { productcategory: { categoryname: asc } }
    ) {
      productcategory {
        id
        categoryname
      }
    }
  }
`;

// graphql query all product categories by store
export const GET_ALL_PRODUCT_CATEGORIES_BY_STORE = gql`
  query getAllProductCategoriesByStore($storeid: Int!) {
    productcategory(
      where: { storeproductcategories: { store: { id: { _eq: $storeid } } } }
      order_by: { categoryname: asc }
    ) {
      id
      categoryname
    }
  }
`;

// graphql query all product categories
export const GET_ALL_PRODUCT_CATEGORIES = gql`
  query getAllProductCategories {
    productcategory(order_by: { categoryname: asc }) {
      id
      categoryname
    }
  }
`;

// graphql query store actions
export const GET_STORE_ACTIONS = gql`
  query getActionsByStoreId($storeid: Int!) {
    merkantdo_storeactionproducts(args: { storeid: $storeid }) {
      id
      actionstatus
      productname
      description
      price
      discount
      store_id
      storename
      image
    }
  }
`;

// graphql query store category products
export const GET_ACTIVE_STORE_CATEGORY_PRODUCTS = gql`
  query getActiveStoreCategoryProducts($storeid: Int!, $categoryid: Int!) {
    merkantdo_storecategoryproducts(
      args: { storeid: $storeid, categoryid: $categoryid }
    ) {
      id
      actionstatus
      productname
      description
      price
      discount
      store_id
      storename
      image
    }
  }
`;

// graphql query store category products
export const QUERY_ALL_STORE_CATEGORY_PRODUCTS = gql`
  query getAllStoreCategoryProducts($storeid: Int!, $categoryid: Int!) {
    merkantdo_storeallcategoryproducts(
      args: { storeid: $storeid, categoryid: $categoryid }
    ) {
      id
      productstatus
      actionstatus
      availability
      productname
      description
      price
      discount
      store_id
      storename
      image
    }
  }
`;

// graphql query check store category products
export const CHECK_STORE_CATEGORY_PRODUCTS = gql`
  query checkStoreCategoryProducts($storeid: Int!, $categoryid: Int!) {
    product(
      where: {
        _and: [
          { storeproductcategory: { store: { id: { _eq: $storeid } } } }
          {
            storeproductcategory: {
              productcategory: { id: { _eq: $categoryid } }
            }
          }
        ]
      }
    ) {
      id
    }
  }
`;

// graphql query check product category
export const CHECK_PRODUCT_CATEGORY = gql`
  query checkProductCategory($categoryname: String!) {
    productcategory(where: { categoryname: { _eq: $categoryname } }) {
      id
      categoryname
    }
  }
`;
