import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Divider,
  useTheme,
} from '@mui/material';
import { UPDATE_STORE_STATUS } from '../queries/adminQueries';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import 'moment/locale/de';

/**
 * CustomerView
 */
const CustomerView = (props: any) => {
  const theme = useTheme();
  const [storestatus, setStorestatus] = useState<string>(
    props.childPagePropsFromParent.status
  );
  const [isStatusEdit, setIsStatusEdit] = useState<boolean>(false);
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
  const [isButtonSaveEnabled, setIsButtonSaveEnabled] =
    useState<boolean>(false);

  // storestatus update
  const [updateStoreStatus] = useMutation(UPDATE_STORE_STATUS);

  moment().locale('de');

  /**
   * Button Status setzen
   */
  useEffect(() => {
    if (isDataChanged) {
      setIsButtonSaveEnabled(true);
    } else {
      setIsButtonSaveEnabled(false);
    }
  }, [isDataChanged]);

  /**
   * Wenn storeStatus geändert wird.
   */
  const handleStorestatusChange = (event: any) => {
    setStorestatus(event.target.value);
    setIsDataChanged(true);
  };

  /**
   * Diese Funktion ändert storestatus.
   */
  const handleStoreStatus = async () => {
    try {
      await updateStoreStatus({
        variables: {
          id: props.childPagePropsFromParent.id,
          storestatus,
        },
      });
      setIsButtonSaveEnabled(false);
      setIsDataChanged(false);
      setIsDataChanged(false);
    } catch (error) {
      console.log('Error! CustomerView - handleStoreStatus:', error);
    }
  };

  // return
  return (
    <Container maxWidth='xs'>
      <Typography component='h2' variant='h6' align='center' mt={2}>
        Kunde
      </Typography>
      <Box>
        <Box>
          <TextField
            color='secondary'
            variant='standard'
            required
            sx={{ width: '100%' }}
            label='KunddenNr.'
            autoComplete='off'
            type='text'
            value={props.childPagePropsFromParent.id}
            inputProps={{ readOnly: true }}
          />
          {!isStatusEdit ? (
            <TextField
              color='secondary'
              variant='standard'
              required
              sx={{ width: '100%' }}
              label='Status'
              autoComplete='off'
              type='text'
              value={storestatus}
              inputProps={{ readOnly: true }}
            />
          ) : (
            <Box>
              <FormControl component='fieldset' sx={{ width: '100%' }}>
                <FormLabel
                  sx={{
                    color: theme.palette.text.disabled,
                    fontSize: 10,
                    '&.Mui-focused': {
                      color: theme.palette.secondary.main,
                      fontSize: 10,
                    },
                  }}
                  required
                  color='secondary'
                >
                  Status
                </FormLabel>
                <RadioGroup
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                  aria-label='actionstatus'
                  name='actionstatus'
                  value={storestatus}
                  onChange={handleStorestatusChange}
                >
                  <FormControlLabel
                    disabled={!isStatusEdit}
                    value='new'
                    control={<Radio color='secondary' />}
                    label='new'
                  />
                  <FormControlLabel
                    disabled={!isStatusEdit}
                    value='tryout'
                    control={<Radio color='secondary' />}
                    label='tryout'
                  />
                  <FormControlLabel
                    disabled={!isStatusEdit}
                    value='active'
                    control={<Radio color='secondary' />}
                    label='active'
                  />
                  <FormControlLabel
                    disabled={!isStatusEdit}
                    value='free'
                    control={<Radio color='secondary' />}
                    label='free'
                  />
                  <FormControlLabel
                    disabled={!isStatusEdit}
                    value='closed'
                    control={<Radio color='secondary' />}
                    label='closed'
                  />
                </RadioGroup>
              </FormControl>
              <Divider
                sx={{
                  borderBottomWidth: '1px',
                  background: theme.palette.text.disabled,
                }}
              />
            </Box>
          )}
          <TextField
            color='secondary'
            variant='standard'
            required
            sx={{ width: '100%' }}
            label='PLZ Ort'
            autoComplete='off'
            type='text'
            value={
              props.childPagePropsFromParent.zipcode +
              ' ' +
              props.childPagePropsFromParent.place
            }
          />
          <TextField
            color='secondary'
            variant='standard'
            required
            sx={{ width: '100%' }}
            label='Geschäftsname'
            autoComplete='off'
            type='text'
            value={props.childPagePropsFromParent.storename}
            inputProps={{ readOnly: true }}
          />
          <TextField
            color='secondary'
            variant='standard'
            required
            sx={{ width: '100%' }}
            label='Inhaber'
            autoComplete='off'
            type='text'
            value={props.childPagePropsFromParent.storeowner}
          />
          <TextField
            color='secondary'
            variant='standard'
            required
            sx={{ width: '100%' }}
            label='Straße'
            autoComplete='off'
            type='text'
            value={props.childPagePropsFromParent.street}
          />
          <TextField
            color='secondary'
            variant='standard'
            required
            sx={{ width: '100%' }}
            label='Erstellt'
            autoComplete='off'
            type='text'
            value={moment(props.childPagePropsFromParent.created).format('L')}
          />
          <TextField
            color='secondary'
            variant='standard'
            required
            sx={{ width: '100%' }}
            label='Aktiviert'
            autoComplete='off'
            type='text'
            value={
              props.childPagePropsFromParent.activated !== null
                ? moment(props.childPagePropsFromParent.activated).format('L')
                : '--/--'
            }
          />
          <TextField
            color='secondary'
            variant='standard'
            required
            sx={{ width: '100%' }}
            label='Rechnung'
            autoComplete='off'
            type='text'
            value={
              props.childPagePropsFromParent.invoice !== null
                ? moment(props.childPagePropsFromParent.invoice).format('L')
                : '--/--'
            }
          />
          <TextField
            color='secondary'
            variant='standard'
            required
            sx={{ width: '100%' }}
            label='Gekündigt'
            autoComplete='off'
            type='text'
            value={
              props.childPagePropsFromParent.terminated !== null
                ? moment(props.childPagePropsFromParent.terminated).format('L')
                : '--/--'
            }
          />
        </Box>
      </Box>
      {isStatusEdit ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            type='submit'
            disabled={!isButtonSaveEnabled}
            sx={{
              marginTop: 3,
              borderRadius: 30,
              width: '240px',
            }}
            variant='contained'
            color='secondary'
            onClick={handleStoreStatus}
          >
            Übernehmen
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            type='submit'
            sx={{
              marginTop: 3,
              borderRadius: 30,
              width: '240px',
            }}
            variant='contained'
            color='secondary'
            onClick={() => {
              setIsStatusEdit(true);
              setIsDataChanged(false);
            }}
          >
            Bearbeiten
          </Button>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type='submit'
          sx={{
            marginTop: 2,
            borderRadius: 30,
            width: '240px',
          }}
          variant='contained'
          color='secondary'
          onClick={() => {
            props.sendChildPagePropsToParent({
              page: props.childPagePropsFromParent.page,
              pageSize: props.childPagePropsFromParent.pageSize,
              sortOrder: props.childPagePropsFromParent.sortOrder,
            });
            props.sendChildPageToParent('customers');
          }}
        >
          Ok
        </Button>
      </Box>
    </Container>
  );
};

export default CustomerView;
