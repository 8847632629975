import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

/**
 * HelpCustomerAccountView
 */
const HelpCustomerAccountView = () => {
  const navigate = useNavigate();

  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={3}>
        <Typography component='h1' variant='h4' align='center' gutterBottom>
          Nutzerkonto - Kunden
        </Typography>
        <Typography component='h1' variant='h6' align='center' gutterBottom>
          Wofür brauche ich als Kunde ein Nutzerkonto?
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography component='p' variant='body1' paragraph>
          Die Regisrierung ermöglicht den personliche Kontakt mit den
          Geschäften.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          1. Als registrierter Nutzer haben Sie die Möglichkeit Favoriten
          anzulegen, vom Favoriten, wenn Sie es wünschen, über neue Angebote
          oder Aktionen informiert zu werden.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          2. Zu jedem Geschäft Kontakt aufnehmen um Fragen zu einem Artikel zu
          stellen.
        </Typography>
      </Box>
      <Box
        mt={3}
        mb={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          type='submit'
          variant='contained'
          color='secondary'
          sx={{ borderRadius: 30, width: '240px' }}
          onClick={() => navigate('/help')}
        >
          Zurück
        </Button>
      </Box>
    </Container>
  );
};

export default HelpCustomerAccountView;
