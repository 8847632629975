import { gql } from '@apollo/client';

// graphql query search global portal products by name
export const SEARCH_PORTAL_PRODUCTS = gql`
  query searchPortalProducts(
    $locations: _int4!
    $searchname: String!
    $minprice: Int
    $maxprice: Int
  ) {
    merkantdo_search_portal_products(
      args: {
        locations: $locations
        searchname: $searchname
        minprice: $minprice
        maxprice: $maxprice
      }
    ) {
      id
      productstatus
      actionstatus
      productname
      description
      price
      discount
      store_id
      storename
      image
    }
  }
`;
