export interface LocationState {
  location: {
    id: number;
    zipcode: string;
    place: string;
    distance: number;
    locationlist: number[];
  };
}

export const locationInitialState: LocationState = {
  location: {
    id: 0,
    zipcode: '',
    place: '',
    distance: 0,
    locationlist: [],
  },
};
