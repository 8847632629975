import React from 'react';
import { Container, Box, Typography } from '@mui/material';

/**
 * TenantHelpView
 *
 */
const TenantHelpView = () => {
  // return
  return (
    <Container maxWidth={false}>
      <Box>
        <Typography component='h1' variant='h5'>
          Account
        </Typography>
        <Typography variant='body1' mt={1}>
          Dies ist die Startseite der Verwaltung Ihres Geschäfts.
        </Typography>
        <Typography variant='body1' mt={1}>
          Nach der Registrierung eines Geschäfts müssen als erstes die
          Warengruppen ausgewählt werden. Danach sind die Eingaben Ihrer Artikel
          im Sortiment in den jeweiligen Warengruppen möglich.
        </Typography>
      </Box>
    </Container>
  );
};

export default TenantHelpView;
