import React, { useReducer, createContext, ReactNode } from 'react';
import { LocationState } from './reducers/locationState';

type ContextType = [any, any];

interface ContextProps {
  reducer: any;
  initialState: LocationState;
  children: ReactNode;
}

export const LocationContext = createContext<Partial<ContextType>>([]);

export function LocationProvider({
  reducer,
  initialState,
  children,
}: ContextProps) {
  return (
    <LocationContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </LocationContext.Provider>
  );
}

export const useLocation = () => React.useContext(LocationContext);
