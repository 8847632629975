import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import Header2 from '../components/Header2';

/**
 * AboutUsView
 *
 */
const AboutUsView = () => {
  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={3} mb={2}>
        <Header2 title='Über uns' />
      </Box>
      <Box pt={2}>
        <Typography component='h1' variant='h5' sx={{ pt: 1, pb: 1 }}>
          Unsere Vision
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Der Einzelhandel befindet sich im Wandel. Dies ist in vielen
          Innenstädten nicht zu übersehen. Leere Ladenflächen und wenige Kunden
          in den übrigen Geschäften. Vielerorts befindet sich der stationäre
          Einzelhandel in einer Krise. Durch den Ausbruch der Corona-Pandemie
          wurde die Situation der Unternehmen nochmals verschärft. Die Pandemie
          ist jedoch nicht der Grund für die Situation.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Die Digitalisierung schreitet in jeder Branche voran, auch im
          Einzelhandel. Dies stellt den stationären Handel vor neue
          Herausforderungen, bringt aber auch neue Möglichkeiten im Bereich des
          Marketings.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Das Internet eröffnet für den stationären Händler neue Möglichkeiten
          für Kaufanbahnung, Werbung und Kundenbindung. Der stationäre Handel
          hat die Möglichkeit mit merkantdo das Marketing digital ausrichten um
          Kunden besser zu erreichen.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Die mobilen Kommunikationsmöglichkeiten des Smartphones werden immer
          wichtiger. Mit den Apps von merkantdo haben die Kunden jederzeit und
          überall die Möglichkeit sich über das Angebot der Händler in Ihrer
          Stadt und Umgebung zu informieren und falls erwünscht Kontakt
          aufzunehmen. Newsletter-Abonnenten können über neue Angebote oder
          Aktionen informiert werden.
        </Typography>
        <Typography component='h1' variant='h5' sx={{ pt: 1, pb: 1 }}>
          Unsere Geschichte
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Aufgrund gesundheitlicher Probleme eines Familienmitglieds war es
          erforderlich kurze Spazierfarten mit dem Rollstuhl in der frischen
          Luft durchzuführen. Wir führten die Fahrten meistens in der Innenstadt
          durch. Dies war sehr praktisch, da man auch notwendige Besorgungen
          machen konnte.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Besonders an den Markttagen war es nie langweilig. In dieser Zeit
          waren wir wohl mit die bestinformierten Bürger über die Angebote
          unserer Geschäfte. Diese Spazierfarten dauerten sechs Jahre.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          In diesen Jahren haben wir jede Veränderung in unserer Innenstadt
          miterlebt. Es war nicht zu übersehen das einige Geschäfte aufgaben und
          die übrigen nicht viel Publikum hatten. Was wir aber auch beobachten
          konnten das immer mehr Leute mit einem Smartphone unterwegs waren.
          Swipe und Tap war immer zu beobachten.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Uns wurde klar das die Angebote unserer Händler auf Smartphones
          vorhanden sein müssen. Unser Verhalten hat sich durch die
          Digitalisierung sehr verändert. Es waren diese Beobachtungen, die
          letztlich zur Entwicklung eines Marketing-Portals für den stationären
          Handel führten.
        </Typography>
      </Box>
    </Container>
  );
};

export default AboutUsView;
