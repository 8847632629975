import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import Header2 from '../components/Header2';

/**
 * MarketingView
 *
 */
const MarketingView = () => {
  // return
  return (
    <Container maxWidth='sm'>
      <Box mt={3} mb={2}>
        <Header2 title='Marketing mit merkantdo' />
      </Box>
      <Box mt={2}>
        <Typography component='h1' variant='h5' sx={{ pt: 1, pb: 1 }}>
          Digitales Marketing
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Das Internet mit den verwendeten Technologien und Platformen wie PCs,
          Tablets und Smartphones ermöglichen es, die Benutzer auf diesen
          Platformen zu erreichen. Digitales Marketing ist die Kommunikation
          mittels dieser Technologien.
        </Typography>
        <Typography component='p' variant='body1' paragraph>
          Die Vorteile dieser Technologien sind für den stationären Händler und
          seinen Kunden enorm.
          <br />
          <br />
          Merkantdo bietet dem stationären Händler die Möglichkeit seine
          potentiellen Kunden mittels dieser Technologien über sein Angebot zu
          informieren.
          <br />
          <br />
          Für den potentiellen Kunden bedeutet das große Zeitersparnis. Man kann
          sich schnell und bequem von überall über den gesuchten Artikel
          informieren und gezielt das Geschäft aufsuchen. Man muss nicht lange
          in der Stadt rumlaufen um etwas zu finden.
        </Typography>
      </Box>
    </Container>
  );
};

export default MarketingView;
