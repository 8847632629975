import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Divider,
  Grid,
  Link,
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  useTheme,
  Pagination,
} from '@mui/material';
import { AiOutlineHeart } from 'react-icons/ai';
import {
  useNavigate,
  useLocation as useRouterLocation,
} from 'react-router-dom';
import { useAuth } from '../../authContext';
import StoreProductCard from '../../components/StoreProductCard';
import {
  GET_STORE_BY_ID,
  GET_PRODUCT_CATEGORIES_BY_STORE,
  GET_STORE_ACTIONS,
  GET_ACTIVE_STORE_CATEGORY_PRODUCTS,
} from '../../data/StoreData';
import {
  ADD_FAVORITE,
  GET_FAVORITES,
  GET_FAVORITE,
} from '../../data/FavoriteData';
import { useLocation } from '../../locationContext';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Config } from '../../config';

/**
 * StoreView stellt Produkte eines
 * Geschäftes in Kategorien dar.
 */
const StoreView = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [{ location }] = useLocation();
  const routerLocation: any = useRouterLocation();
  const [{ isAuthenticated, profile }] = useAuth();
  const [value, setValue] = React.useState(0); // value für tabs
  const [categoryId, setCategoryId] = useState<number | null>(
    props.childPagePropsFromParent.categoryid
  ); // 0 bedeutet actions
  const [customerId, setCustomerId] = useState<number>(0);
  const [pagesTotal, setPagesTotal] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(
    props.childPagePropsFromParent.pagenumber
  );
  const [pageStart, setPageStart] = useState<number>(1);
  const [pageEnd, setPageEnd] = useState<number>(1);
  const [loginDialogVisible, setLoginDialogVisible] = useState<boolean>(false);
  const [favoriteMessagesOn, setFavoriteMessagesOn] = useState<boolean>(false);
  const [addFavoriteDialogVisible, setAddFavoriteDialogVisible] =
    useState<boolean>(false);
  const [notAddFavoriteDialogVisible, setNotAddFavoriteDialogVisible] =
    useState<boolean>(false);
  const [favoriteIsAvailable, setFavoriteIsAvailable] =
    useState<boolean>(false);
  const [
    favoriteIsAvailableDialogVisible,
    setFavoriteIsAvailableDialogVisible,
  ] = useState(false);

  // media url setzen
  const MEDIA_URL: any =
    process.env.REACT_APP_MINIO_ENDPOINT !== undefined
      ? process.env.REACT_APP_MINIO_ENDPOINT
      : Config.minio_endpoint;

  // search store products
  const ITEMS_PER_PAGE: any =
    process.env.REACT_APP_ITEMS_PER_PAGE !== undefined
      ? process.env.REACT_APP_ITEMS_PER_PAGE
      : Config.items_per_page;

  // Geschäftsdaten "storeData" mit der id
  // des Geschäts mit "useQuery" holen.
  const {
    loading: storeLoading,
    error: storeError,
    data: storeData,
  } = useQuery(GET_STORE_BY_ID, {
    variables: {
      storeid: props.childPagePropsFromParent.storeid,
    },
  });

  // Die vorhandenen Warengruppen wie z.B. Damenmode oder Herrenmode
  // des Geschäfts mit useQuery holen.
  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: categoriesData,
  } = useQuery(GET_PRODUCT_CATEGORIES_BY_STORE, {
    variables: {
      storeid: props.childPagePropsFromParent.storeid,
    },
    fetchPolicy: 'network-only',
  });

  // Aktive Artikel einer Warengruppe mit useLazyQuery
  // wie z.B. Damenmode oder Herrenmode holen.
  const [
    getProducts,
    { loading: productsLoading, error: productsError, data: productsData },
  ] = useLazyQuery(GET_ACTIVE_STORE_CATEGORY_PRODUCTS, {
    onCompleted: (productsData) => {
      setPagesTotal(
        Math.ceil(
          productsData.merkantdo_storecategoryproducts.length / ITEMS_PER_PAGE
        )
      );
    },
  });

  // Aktionen des Geschäfts z.B. neu, top, reduziert "actions"
  // holen und falls vorhanden categoryId auf 0 setzen.
  // categoryId === 0 bedeutet category sind actions.
  const {
    loading: actionsLoading,
    error: actionsError,
    data: actionsData,
  } = useQuery(GET_STORE_ACTIONS, {
    variables: {
      storeid: props.childPagePropsFromParent.storeid,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (
        // actions vorhanden und props null
        data.merkantdo_storeactionproducts.length !== 0 &&
        props.childPagePropsFromParent.categoryid === null
      ) {
        setCategoryId(0);
        setPagesTotal(
          Math.ceil(data.merkantdo_storeactionproducts.length / ITEMS_PER_PAGE)
        );
        setPageNumber(1);
      } else if (
        // actions vorhanden und props === 0
        data.merkantdo_storeactionproducts.length !== 0 &&
        props.childPagePropsFromParent.categoryid === 0
      ) {
        setCategoryId(props.childPagePropsFromParent.categoryid);
        setPagesTotal(
          Math.ceil(data.merkantdo_storeactionproducts.length / ITEMS_PER_PAGE)
        );
        setPageNumber(props.childPagePropsFromParent.pagenumber);
      }
    },
  });

  // Favoriten des Kunden holen. Die Abfrage wird nicht ausgeführt
  // falls Kunde nicht angemeldet ist customerId === 0.
  const {
    loading: favoriteLoading,
    error: favoriteError,
    data: favoriteData,
  } = useQuery(GET_FAVORITE, {
    variables: {
      customerid: customerId,
      storeid: props.childPagePropsFromParent.storeid,
    },
    fetchPolicy: 'network-only',
    skip: customerId === 0,
  });

  // Favoriten eines Kunden hinzufügen und
  // eine neue Abfrage mit "refetchQueries" durchführen.
  const [addFavorite] = useMutation(ADD_FAVORITE, {
    refetchQueries: [
      {
        query: GET_FAVORITES,
        variables: { customerid: customerId },
      },
    ],
    awaitRefetchQueries: true,
  });

  /**
   * Falls Location nicht gesetzt ist wird
   * umgeleitet.
   */
  useEffect(() => {
    if (location.locationlist.length === 0) {
      navigate('/location');
    }
  }, [location.locationlist, navigate]);

  /**
   * Am Anfang beim ersten Aufruf:
   * Falls keine Aktion "actions" (neu, top, reduziert)
   * vorhanden sind, wird die erste Warengruppe "category" gesetzt
   * und mit "getProducts()" die Artikel der Warengruppe geholt.
   */
  useEffect(() => {
    // action nicht vorhanden. erste category setzen und products holen.
    if (
      actionsData &&
      categoriesData &&
      actionsData.merkantdo_storeactionproducts.length === 0 &&
      props.childPagePropsFromParent.categoryid === null
    ) {
      setCategoryId(
        categoriesData.storeproductcategories[0].productcategory.id
      );
      getProducts({
        variables: {
          storeid: props.childPagePropsFromParent.storeid,
          categoryid:
            categoriesData.storeproductcategories[0].productcategory.id,
        },
      });
      setPageNumber(1);
    }
  }, [actionsData, categoriesData, getProducts, props]);

  /**
   * Wenn die Navigation vom Product kommt
   * werden die Parameter von childPagePropsFromParent
   * benutzt um den alten Zustand zu erhalten.
   */
  useEffect(() => {
    // props vorhanden für category
    if (props.childPagePropsFromParent.categoryid > 0) {
      setCategoryId(props.childPagePropsFromParent.categoryid);
      getProducts({
        variables: {
          storeid: props.childPagePropsFromParent.storeid,
          categoryid: props.childPagePropsFromParent.categoryid,
        },
      });
      setPageNumber(props.childPagePropsFromParent.pagenumber);
    }
  }, [
    getProducts,
    props.childPagePropsFromParent.categoryid,
    props.childPagePropsFromParent.pagenumber,
    props.childPagePropsFromParent.storeid,
  ]);

  /**
   * Beim blättern mit der Pagination wird immer ein Ausschnitt
   * des Inhals des Array dargestellt. Dies wird mit
   * pageStart und pageEnd für pageNumber gesetzt.
   * Sobald pageNumber sich ändert müssen pageStart und pageEnd
   * ermittelt und im State werden.
   */
  useEffect(() => {
    setPageStart((pageNumber - 1) * ITEMS_PER_PAGE);
    setPageEnd(pageNumber * ITEMS_PER_PAGE);
  }, [ITEMS_PER_PAGE, pageNumber]);

  /**
   * Falls Customer angemeldet ist wird
   * customerId im State gespeichert um die Abfragen
   * durchzuführen.
   */
  useEffect(() => {
    if (profile !== null && profile.userrole === 'customer') {
      setCustomerId(profile.customer_id);
    } else {
      setCustomerId(0);
    }
  }, [customerId, profile]);

  /**
   * Hier überprüft ob es einen Eintrag das geschäft
   * schon in den Favoriten eingetragen ist und im State
   * gespeischert. Wenn man sich davor anmelden muss
   * werden die jeweiligen Dialoge gestartet.
   */
  useEffect(() => {
    if (!favoriteLoading && favoriteData) {
      if (favoriteData.favorite.length === 1) {
        setFavoriteIsAvailable(true);
        if (
          routerLocation.state !== null &&
          routerLocation.state.action === 'favoriteInsert'
        ) {
          setFavoriteIsAvailableDialogVisible(true);
        }
      } else if (favoriteData.favorite.length === 0) {
        setFavoriteIsAvailable(false);
        if (
          routerLocation.state !== null &&
          routerLocation.state.action === 'favoriteInsert'
        ) {
          setAddFavoriteDialogVisible(true);
        }
      }
    } else if (
      routerLocation.state !== null &&
      routerLocation.state.action === 'favoriteNotInsert'
    ) {
      setNotAddFavoriteDialogVisible(true);
    }
  }, [favoriteLoading, favoriteData, routerLocation.state]);

  /**
   * SetPageNumber
   */
  const handlePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageNumber(value);
    //props.sendChildPagePropsToParent({
    //  ...props.childPagePropsFromParent,
    //  pagenumber: value,
    //});
  };

  // Beim laden der Storedaten nichts anzeigen.
  if (storeLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage der Storedaten auftritt.
  if (storeError) {
    console.log(`Error! StoreView: ${storeError.message}`);
  }

  // Beim laden der Storedaten nichts anzeigen.
  if (actionsLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage der Storedaten auftritt.
  if (actionsError) {
    console.log(`Error! StoreView: ${actionsError.message}`);
  }

  // Beim laden der Productsdaten nichts anzeigen.
  if (productsLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage der Productsdaten auftritt.
  if (productsError) {
    console.log(`Error! StoreView: ${productsError.message}`);
  }

  // Beim laden der Storedaten nichts anzeigen.
  if (categoriesLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage der Storedaten auftritt.
  if (categoriesError) {
    console.log(`Error! StoreView: ${categoriesError.message}`);
  }

  // Beim laden des Favoriten nichts anzeigen.
  if (favoriteLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage dem Favoriten auftreten.
  if (favoriteError) {
    console.log(`Error! StoreView: ${favoriteError.message}`);
  }

  // goToProduct navigation
  const goToProduct = (storeid: number, productid: number) => {
    navigate('/product', {
      state: {
        srcroute: '/stores',
        childpage: 'store',
        drawerisopen: false,
        branchid: props.childPagePropsFromParent.branchid,
        expanded: props.childPagePropsFromParent.expanded,
        storeid,
        productid,
        categoryid: categoryId,
        pagenumber: pageNumber,
      },
    });
  };

  /**
   * Dialog für das Login um sich die Einträge
   * in der Favoritenliste anzuzeigen.
   */
  const handleLoginDialog = (action: string) => {
    if (action === 'login') {
      setLoginDialogVisible(false);
      navigate('/login', {
        state: {
          srcroute: '/stores',
          childpage: 'store',
          branchid: props.childPagePropsFromParent.branchid,
          expanded: props.childPagePropsFromParent.expanded,
          storeid: props.childPagePropsFromParent.storeid,
          categoryid: categoryId,
          action: 'favoriteInsert',
          pagenumber: pageNumber,
        },
      });
    } else {
      setLoginDialogVisible(false);
    }
  };

  /**
   * Favorite Dialog als Abfrage "Hinzufügen"
   * oder "Abbrechen".
   */
  const handleFavoriteDialog = (action: string) => {
    if (action === 'add') {
      addFavorite({
        variables: {
          favorite: {
            id: undefined,
            customer_id: profile.customer_id,
            store_id: props.childPagePropsFromParent.storeid,
            messages_on: favoriteMessagesOn,
          },
        },
      });
    }
    setAddFavoriteDialogVisible(false);
  };

  /**
   * Falls man den Store zu den Favorite
   * hinzufügen will.
   */
  const handleFavoriteInsert = () => {
    if (!isAuthenticated) {
      setLoginDialogVisible(true);
    } else if (profile.customer_id !== 0) {
      if (favoriteIsAvailable) {
        setFavoriteIsAvailableDialogVisible(true);
      } else if (!favoriteIsAvailable) {
        setAddFavoriteDialogVisible(true);
      }
    }
  };

  /**
   * dummy für tabs
   */
  const handleValueChange = (
    event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setValue(newValue);
  };

  // return
  return (
    <Container maxWidth={false} disableGutters>
      {/** Login Dialog */}
      <Dialog
        open={loginDialogVisible}
        onClose={() => setLoginDialogVisible(false)}
        aria-labelledby='login-dialog-title'
        aria-describedby='login-dialog-description'
      >
        <DialogTitle id='login-dialog-title'>
          Anmeldung erforderlich!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='login-dialog-description'>
            Um das Geschäft zu den Favoriten hinzuzufügen müssen Sie sich
            anmelden.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => handleLoginDialog('cancel')}
            variant='outlined'
            color='inherit'
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => handleLoginDialog('login')}
            variant='outlined'
            color='inherit'
            autoFocus
          >
            Anmelden
          </Button>
        </DialogActions>
      </Dialog>
      {/** Login Dialog end */}
      {/** Add Favorite Dialog */}
      <Dialog
        open={addFavoriteDialogVisible}
        onClose={() => setAddFavoriteDialogVisible(false)}
        aria-labelledby='add-favorite-title'
        aria-describedby='add-favorite-description'
      >
        <DialogTitle id='add-favorite-title'>
          Zu Favoriten hinzufügen?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='add-favorite-description'>
            Soll {'"'}
            {storeData.store[0].storename}
            {'"'} zu den Favoriten hinzugefügt werden?
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={favoriteMessagesOn}
                onChange={() => setFavoriteMessagesOn(!favoriteMessagesOn)}
                color='secondary'
              />
            }
            label='Möchten Sie über neue Angebote informiert werden?'
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => handleFavoriteDialog('cancel')}
            variant='outlined'
            color='inherit'
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => handleFavoriteDialog('add')}
            variant='outlined'
            color='inherit'
            autoFocus
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
      {/** Add Favorite Dialog end */}
      {/** Not Add Favorite Dialog */}
      <Dialog
        maxWidth={false}
        open={notAddFavoriteDialogVisible}
        onClose={() => setNotAddFavoriteDialogVisible(false)}
        aria-labelledby='no-favorite-dialog-title'
        aria-describedby='no-favorite-dialog-description'
      >
        <DialogTitle id='no-favorite-dialog-title'>
          Zu Favoriten hinzufügen nicht möglich.
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='no-favorite-dialog-description'>
            Sie haben sich nicht als registrierter Kunde angemeldet.
          </DialogContentText>
          <DialogContentText id='no-favorite-dialog-description'>
            Das Hinzufügen zu Favoriten ist nur den registrierten Kunden
            vorbehalten.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => setNotAddFavoriteDialogVisible(false)}
            variant='outlined'
            color='inherit'
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/** Not Add Favorite Dialog end */}
      {/** Favorite Available Dialog */}
      <Dialog
        open={favoriteIsAvailableDialogVisible}
        onClose={() => setFavoriteIsAvailableDialogVisible(false)}
        aria-labelledby='available-favorite-title'
        aria-describedby='available-favorite-description'
      >
        <DialogTitle id='available-favorite-title'>
          In Favoriten vorhanden!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='available-favorite-description'>
            Das Geschäft {'"'}
            {storeData.store[0].storename}
            {'"'} ist in den Favoriten vorhanden.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => setFavoriteIsAvailableDialogVisible(false)}
            variant='outlined'
            color='inherit'
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/** Favorite Available Dialog end */}
      {/** Store Content */}
      <Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(0.5, 0, 0.5, 0),
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Box sx={{ marginLeft: 12 }}></Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box>
              <Typography component='h2' variant='h6'>
                {storeData.store[0].storename}
              </Typography>
            </Box>
            <Box>
              <Typography component='h2' variant='subtitle1'>
                {storeData.store[0].street},{' '}
                {storeData.store[0].location.zipcode}{' '}
                {storeData.store[0].location.place}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              marginRight: 4,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {profile === null || profile.userrole === 'customer' ? (
              <>
                <Box
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'block',
                      md: 'block',
                      lg: 'block',
                      xl: 'block',
                    },
                  }}
                >
                  <Typography
                    sx={{
                      '&:hover': {
                        textDecoration: `underline ${theme.palette.text.primary}`,
                        cursor: 'pointer',
                      },
                    }}
                    onClick={() => handleFavoriteInsert()}
                    component='span'
                    variant='subtitle1'
                  >
                    Zu Favoriten hinzufügen
                  </Typography>
                </Box>
                <IconButton
                  color='inherit'
                  onClick={() => handleFavoriteInsert()}
                >
                  <AiOutlineHeart size='22' />
                </IconButton>
              </>
            ) : null}
          </Box>
        </Box>
        <Divider />
        <AppBar position='static' elevation={0}>
          <Toolbar variant='dense'>
            <Tabs
              value={value}
              onChange={handleValueChange}
              variant='scrollable'
              scrollButtons={true}
              allowScrollButtonsMobile
              textColor='primary'
              sx={{
                maxWidth: 900,
              }}
            >
              {actionsData &&
              actionsData.merkantdo_storeactionproducts.length !== 0 ? (
                <div>
                  <Tab
                    label='Aktionen'
                    value={categoryId}
                    component='button'
                    disableRipple
                    onClick={() => {
                      setCategoryId(0);
                      setPagesTotal(
                        Math.ceil(
                          actionsData.merkantdo_storeactionproducts.length /
                            ITEMS_PER_PAGE
                        )
                      );
                      setPageNumber(1);
                    }}
                    sx={{
                      color: theme.palette.text.primary,
                      opacity: 1,
                      fontSize: 14,
                      textDecoration: categoryId === 0 ? 'underline' : 'none',
                      '&:hover, &:focus, &:active': {
                        textDecoration: `underline ${theme.palette.text.primary}`,
                        cursor: 'pointer',
                      },
                    }}
                  />
                </div>
              ) : null}
              {categoriesData.storeproductcategories.map((category: any) => {
                return (
                  <div key={category.productcategory.id}>
                    <Tab
                      key={category.productcategory.id}
                      label={category.productcategory.categoryname}
                      value={categoryId}
                      component='button'
                      disableRipple
                      onClick={() => {
                        setCategoryId(category.productcategory.id);
                        getProducts({
                          variables: {
                            storeid: props.childPagePropsFromParent.storeid,
                            categoryid: category.productcategory.id,
                          },
                        });
                        setPageNumber(1);
                      }}
                      sx={{
                        color: theme.palette.text.primary,
                        opacity: 1,
                        fontSize: 14,
                        textDecoration:
                          categoryId === category.productcategory.id
                            ? 'underline'
                            : 'none',
                        '&:hover, &:focus, &:active': {
                          textDecoration: `underline ${theme.palette.text.primary}`,
                          cursor: 'pointer',
                        },
                      }}
                    />
                  </div>
                );
              })}
            </Tabs>
          </Toolbar>
        </AppBar>
        <Box>
          {categoryId === 0 ? (
            <Box mt={1} mb={3}>
              <Grid container spacing={1} justifyContent='center'>
                {actionsData &&
                  actionsData.merkantdo_storeactionproducts
                    .slice(pageStart, pageEnd)
                    .map((product: any, index: number) => (
                      <Grid item key={index} xs='auto' sm='auto' md='auto'>
                        <Link
                          underline='none'
                          component='button'
                          onClick={() =>
                            goToProduct(product.store_id, product.id)
                          }
                        >
                          <StoreProductCard
                            mediaUri={
                              MEDIA_URL + '/' + product.store_id + '-data/'
                            }
                            image={product.image}
                            actionstatus={
                              product.actionstatus === 'New'
                                ? 'Neu'
                                : product.actionstatus === 'Sale'
                                ? 'Reduziert'
                                : product.actionstatus
                            }
                            price={product.price}
                            discount={product.discount}
                            productname={product.productname}
                          />
                        </Link>
                      </Grid>
                    ))}
              </Grid>
            </Box>
          ) : (
            <Box mt={1} mb={3}>
              <Grid container spacing={1} justifyContent='center'>
                {!productsLoading &&
                  productsData !== undefined &&
                  productsData.merkantdo_storecategoryproducts
                    .slice(pageStart, pageEnd)
                    .map((product: any, index: number) => (
                      <Grid item key={index} xs='auto' sm='auto' md='auto'>
                        <Link
                          underline='none'
                          component='button'
                          onClick={() =>
                            goToProduct(product.store_id, product.id)
                          }
                        >
                          <StoreProductCard
                            mediaUri={
                              MEDIA_URL + '/' + product.store_id + '-data/'
                            }
                            image={product.image}
                            actionstatus={
                              product.actionstatus === 'New'
                                ? 'Neu'
                                : product.actionstatus === 'Sale'
                                ? 'Reduziert'
                                : product.actionstatus
                            }
                            price={product.price.trim()}
                            discount={product.discount}
                            productname={product.productname}
                          />
                        </Link>
                      </Grid>
                    ))}
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
      {/** Store Content end */}
      <Box
        sx={{
          mb: 4,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Pagination
          count={pagesTotal}
          page={pageNumber}
          onChange={handlePage}
        />
      </Box>
    </Container>
  );
};

export default StoreView;
