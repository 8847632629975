import React, { useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { GET_ALL_BRANCHES } from '../data/BranchesData';
import { useQuery } from '@apollo/client';

/**
 * RegisterBranchesStoreForm um Branches
 * für den Store auszuwählen.
 */
const RegisterBranchesStoreForm = (props: any) => {
  const { loading, error, data } = useQuery(GET_ALL_BRANCHES, {
    skip: props.branchesdataFromParent.length > 0,
  });

  /**
   * Vorhandene Branches holen und in parent speichern.
   */
  useEffect(() => {
    if (data) {
      const tempData: Array<any> = [];
      for (let i = 0; i < data.storebranch.length; i++) {
        tempData.push({
          branchId: data.storebranch[i].id,
          branchName: data.storebranch[i].branchname,
          checked: false,
        });
      }
      props.sendBranchesdataToParent(tempData);
    }
  }, [data, props]);

  // Beim laden der Daten nichts anzeigen.
  if (loading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (error) {
    console.log('Error! in RegisterBranchesStoreForm: ' + error.message);
  }

  /**
   * Bei der Auswahl der Branches wird im parent aktualisiert.
   */
  const handleBranches = (id: number, checkedState: boolean) => {
    const tempBranches: Array<any> = [];
    let checkedAvailable = 0;
    for (let i = 0; i < props.branchesdataFromParent.length; i++) {
      if (props.branchesdataFromParent[i].branchId === id) {
        //change the checkedState
        tempBranches.push({
          branchId: props.branchesdataFromParent[i].branchId,
          branchName: props.branchesdataFromParent[i].branchName,
          checked: checkedState,
        });
      } else {
        tempBranches.push(props.branchesdataFromParent[i]);
      }
    }
    props.sendBranchesdataToParent(tempBranches);
    for (let i = 0; i < tempBranches.length; i++) {
      if (tempBranches[i].checked === true) {
        checkedAvailable = checkedAvailable + 1;
      }
    }
    if (checkedAvailable > 0) {
      props.sendButtonStep2EnabledToParent(true);
    } else {
      props.sendButtonStep2EnabledToParent(false);
    }
  };

  // return
  return (
    <Container maxWidth='xs' disableGutters>
      <Typography component='h1' variant='h6' align='center' sx={{ mt: 1 }}>
        Geschäftsbranchen
      </Typography>
      <Box mt={1}>
        {props.branchesdataFromParent.map((storebranch: any) => (
          <Box key={storebranch.branchId}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={storebranch.checked}
                  onChange={() =>
                    handleBranches(storebranch.branchId, !storebranch.checked)
                  }
                  color='secondary'
                />
              }
              label={storebranch.branchName}
            />
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default RegisterBranchesStoreForm;
