import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Icon,
  Button,
} from '@mui/material';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import moment from 'moment';
import 'moment/locale/de';
import {
  GET_ALL_TICKETS_COUNT,
  GET_TICKETS_OFFSET,
} from '../queries/adminQueries';
import { useQuery } from '@apollo/client';

/**
 * TicketsView
 */
const TicketsView = (props: any) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortOrder, setSortOrder] = useState<string>('desc');

  // Die Anzahl der Einträge ermitteln.
  const {
    loading: countLoading,
    error: countError,
    data: countData,
    refetch: countRefetch,
  } = useQuery(GET_ALL_TICKETS_COUNT, {
    fetchPolicy: 'network-only',
    pollInterval: 3000, // <-- refetch every 3 sec
  });

  // Die Einträge der einzelnen Offsets holen.
  const {
    loading: offsetLoading,
    error: offsetError,
    data: offsetData,
    refetch: offsetRefetch,
    fetchMore,
  } = useQuery(GET_TICKETS_OFFSET, {
    variables: {
      offset: page * pageSize,
      limit: pageSize,
      sortOrder: sortOrder,
    },
    fetchPolicy: 'network-only',
    pollInterval: 3000, // <-- refetch every 3 sec
  });

  moment().locale('de');

  /**
   * Falls die Navigation vom Customer kommt.
   */
  useEffect(() => {
    if (Object.keys(props.childPagePropsFromParent).length !== 0) {
      setPage(props.childPagePropsFromParent.page);
      setPageSize(props.childPagePropsFromParent.pageSize);
      setSortOrder(props.childPagePropsFromParent.sortOrder);
    }
  }, [props.childPagePropsFromParent]);

  /**
   * Funktion um den Offset zu setzen.
   */
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    fetchMore({
      variables: {
        offset: newPage * pageSize,
        limit: pageSize,
        sortOrder,
      },
    });
  };

  /**
   * Funktion um pageSize zu ändern.
   */
  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  /**
   * Handle sortOrder
   */
  const handleSortOrder = () => {
    if (sortOrder === 'asc') {
      setSortOrder('desc');
    } else {
      setSortOrder('asc');
    }
  };

  // Beim laden der Daten nichts anzeigen.
  if (countLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (countError) {
    console.log('Error! TicketsView: ' + countError.message);
  }

  // Beim laden der Daten nichts anzeigen.
  if (offsetLoading) {
    return null;
  }

  // Falls Fehler bei der Abfrage auftreten.
  if (offsetError) {
    console.log('Error! TicketsView: ' + offsetError.message);
  }

  // return
  return (
    <Container maxWidth='lg'>
      <Box>
        <Typography component='h2' variant='h5' align='center' mt={2}>
          Tickets
        </Typography>
      </Box>
      <Box sx={{ marginTop: 2, width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 560 }}>
          <Table stickyHeader aria-label='sticky table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    '&:hover': {
                      backgroundColor: 'primary.main',
                    },
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  }}
                  onClick={handleSortOrder}
                >
                  TicketNr.
                  {sortOrder === 'asc' ? (
                    <Icon>
                      <AiOutlineArrowUp size='16' />
                    </Icon>
                  ) : (
                    <Icon>
                      <AiOutlineArrowDown size='16' />
                    </Icon>
                  )}
                </TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Geschäft</TableCell>
                <TableCell>PLZ</TableCell>
                <TableCell>Ort</TableCell>
                <TableCell>Kategorie</TableCell>
                <TableCell>Betreff</TableCell>
                <TableCell>Erstellt</TableCell>
                <TableCell>Bearbeitet</TableCell>
                <TableCell>Geschlossen</TableCell>
              </TableRow>
            </TableHead>
            {offsetData !== undefined && offsetData.ticket.length > 0 ? (
              <TableBody>
                {offsetData.ticket.map((ticket: any) => (
                  <TableRow
                    sx={{
                      backgroundColor:
                        ticket.status === 'open' ? '#FF3D00' : undefined,
                    }}
                    key={ticket.id}
                    hover
                    onClick={() => {
                      props.sendChildPagePropsToParent({
                        id: ticket.id,
                        status: ticket.status,
                        store: ticket.store.storename,
                        zipcode: ticket.store.location.zipcode,
                        place: ticket.store.location.place,
                        category: ticket.category,
                        subject: ticket.subject,
                        contact: ticket.contact,
                        phone: ticket.phone,
                        email: ticket.email,
                        ticket: ticket.ticket,
                        answer: ticket.answer,
                        created: ticket.created,
                        answered: ticket.answered,
                        closed: ticket.closed,
                        page,
                        pageSize,
                        sortOrder,
                      });
                      props.sendChildPageToParent('ticket');
                    }}
                  >
                    <TableCell>{ticket.id}</TableCell>
                    <TableCell>{ticket.status}</TableCell>
                    <TableCell>{ticket.store.storename}</TableCell>
                    <TableCell>{ticket.store.location.zipcode}</TableCell>
                    <TableCell>{ticket.store.location.place}</TableCell>
                    <TableCell>{ticket.category}</TableCell>
                    <TableCell>{ticket.subject}</TableCell>
                    <TableCell>{moment(ticket.created).format('L')}</TableCell>
                    <TableCell>
                      {ticket.answered !== null
                        ? moment(ticket.answered).format('L')
                        : null}
                    </TableCell>
                    <TableCell>
                      {ticket.closed !== null
                        ? moment(ticket.closed).format('L')
                        : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <Typography variant='subtitle1' align='center'>
                Keine vorhanden!
              </Typography>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage={'Zeilen:'}
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={countData.ticket_aggregate.aggregate.allTicketsCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangePageSize}
        />
      </Box>
      <Box
        mt={3}
        mb={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            borderRadius: 30,
            width: '240px',
          }}
          type='submit'
          variant='contained'
          color='secondary'
          onClick={() => {
            countRefetch();
            offsetRefetch();
          }}
        >
          Aktualisieren
        </Button>
      </Box>
    </Container>
  );
};

export default TicketsView;
